import {css} from '@linaria/core';

import {styleVariables} from 'constants/globalVariables';

export const cssWrapper = css`
  padding: 20px;
  color: white;
`;
export const cssSwitchContainer = css`
  font-size: 16px;
  display: flex;
  margin-bottom: 12px;
  flex-direction: row;
  align-items: center;
`;
export const cssSwitch = css`
  margin-left: 10px;
`;
export const cssNote = css`
  font-size: 13px;
  line-height: 18px;
  white-space: pre-wrap;
  margin-bottom: 16px;
`;
export const cssSection = css`
  margin-bottom: 24px;
`;
export const cssAlertsSection = css`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
export const cssAlertsLabel = css`
  display: flex;
  align-items: center;
  color: ${styleVariables.colorWarmGreyOne};
`;
export const cssSectionHeader = css`
  font-size: 16px;
  margin-bottom: 16px;
`;
export const cssButtons = css`
  position: fixed;
  bottom: 0;
  width: 360px;
  display: flex;
  flex-direction: column;
  background-color: #4b577e;
  height: fit-content;
  padding: 12px 20px;
  border-top: solid 1px #5c6590;
`;
export const cssRemoveAutomation = css`
  margin-bottom: 10px;
`;
export const cssTitleContainer = css`
  width: 360px;
  display: flex;
  flex-direction: row;
  background-color: #4b577e;
  height: 50px;
  align-items: center;
  border-bottom: solid 1px #5c6590;
`;
export const cssTitle = css`
  color: white;
  font-size: 18px;
  padding-left: 20px;
`;
export const cssIconPosition = css`
  &.MuiIconButton-root {
    position: fixed;
    right: 20px;
    width: 33px;
    height: 33px;
  }
`;
export const cssCloseIcon = css`
  &.MuiSvgIcon-root {
    height: 23px;
    color: ${styleVariables.colorWarmGreyOne};
  }
`;
export const cssSidePanel = css`
  padding-bottom: 100px;
`;
