import React, {useCallback} from 'react';

import {MenuButton} from '@adjust/dashboard-components';
import {useDispatch, useSelector} from 'react-redux';

import {showMainMenu} from 'modules/common/actions';
import {selectIsMainMenuReady} from 'modules/common/reducers';

import classes from './GlobalScreen.css';

export const LogoContainer = () => {
  const isMainMenuReady = useSelector(selectIsMainMenuReady);
  const dispatch = useDispatch();

  const handleLogoClick = useCallback(() => {
    if (!isMainMenuReady) {
      window.location.href = '/';
    } else {
      dispatch(showMainMenu());
    }
  }, [dispatch, isMainMenuReady]);

  return (
    <div className={classes.logoContainer}>
      <MenuButton ariaLabel='Main menu button' onClick={handleLogoClick} />
    </div>
  );
};
