import React from 'react';

import {css} from '@linaria/core';
import {flow, join, map, startCase} from 'lodash/fp';

const cssWrapper = css`
  margin-top: -18px;
`;

type Props = {
  indexDimensions: string[];
};
export const IndexHeader = ({indexDimensions}: Props) => {
  const title = flow(map(startCase), join(' > '))(indexDimensions);

  return <div className={cssWrapper}>{title}</div>;
};
