import {fork} from 'redux-saga/effects';

import commonSaga from 'modules/common/sagas';
import historySaga from 'modules/history/sagas';
import reportSaga from 'modules/report/sagas';

export default function* root() {
  try {
    yield fork(reportSaga);
    yield fork(historySaga);
    yield fork(commonSaga);
  } catch (e) {
    console.error('Root saga error', e);
  }
}
