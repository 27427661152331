import {flow, omit, set} from 'lodash/fp';
import {parse as parseSearch, stringify} from 'query-string';

import {Query, ReportParams} from 'modules/report/types';
import {BE_types} from 'types/backendServicesTypes';

export const PARENT_REPORT_ID = 'parent_report_id';

export const trimParentReportId = flow(
  parseSearch,
  omit(PARENT_REPORT_ID),
  (query: Query) =>
    `?${stringify(query, {encode: false, arrayFormat: 'comma'})}`
);

export const addParentReportId = (
  params: Partial<ReportParams>,
  id: BE_types['ReportModel']['id']
) => set(['general', PARENT_REPORT_ID], String(id), params);
