import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {Button} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ListIcon from '@material-ui/icons/List';
import cx from 'classnames';
import {
  map,
  startCase,
  max,
  min,
  isEmpty,
  includes,
  concat,
  pull,
  remove,
} from 'lodash/fp';
import {useSelector} from 'react-redux';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from 'recharts';

import {LoadingIndicator, NullState} from 'common-components';
import {SelectableRowsContext} from 'common-components/ModernGrid/extensions/SelectableRows';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  colorDarkBlue,
  colorPrimaryDarkBlueDisabled,
  styleVariables,
} from 'constants/globalVariables';
import {selectParams} from 'modules/report/reducers';

import {
  CHART_HEIGHT,
  CHART_LOADING_INDICATOR_TEST_ID,
  HIGHLIGHTED_OPACITY,
  NON_HIGHLIGHTED_OPACITY,
} from './constants';
import {Legend} from './Legend';
import {useChartData} from './useChartData';
import {extractYValuesFromSeries} from './utils';

import type {ChartType, Axes} from './types';

const cssTooltipContainer = css`
  background-color: white;
  padding: 0.8em;
  border: 1px solid black; /* TODO: color */
  border-radius: 3px;
`;

const cssIcon = css`
  width: 16px;
  fill: currentColor;
`;

const ChartHeader = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const ToggleLegendButton = styled(Button)`
  &.MuiButton-root {
    height: 25px;
    text-transform: none;
    padding-left: 4px;
    padding-right: 4px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    background-color: ${colorPrimaryDarkBlueDisabled};

    &:hover {
      background-color: ${colorDarkBlue};
    }
  }
`;
const cssButtonActive = css`
  &.MuiButton-root {
    background-color: ${styleVariables.colorWarmGreyOne};
  }
`;

type Props = {type: ChartType};
export const ReportChart = React.memo(({type}: Props) => {
  const intl = useIntl();
  const reportParams = useSelector(selectParams);

  const {selectedRows, rows} = useContext(SelectableRowsContext);

  const [isLegendShown, setLegendShown] = useState(false);
  const [hiddenGroupIds, setHiddenGroupIds] = useState<string[]>([]);

  const toggleIsLegendShown = useCallback(() => {
    setLegendShown(value => !value);
  }, [setLegendShown]);

  const handleOnLegendSeriesClick = useCallback(
    (groupId: string) => {
      const isGroupIdShown = !includes(groupId, hiddenGroupIds);
      const newHiddenGroupIds = isGroupIdShown
        ? concat(hiddenGroupIds, groupId)
        : pull(groupId, hiddenGroupIds);

      setHiddenGroupIds(newHiddenGroupIds);
    },
    [hiddenGroupIds]
  );

  // chart data
  const {
    chartTitle,
    isLoading,
    series,
    xAxisDomain,
    xAxisTickFormatter,
    xAxisId,
    xAxisGroupIdField,
    yAxisId,
    yAxisTickFormatter,
  } = useChartData({
    reportParams,
    chartType: type,
  });
  const visibleSeries = useMemo(
    () => remove(({groupId}) => includes(groupId, hiddenGroupIds), series),
    [series, hiddenGroupIds]
  );
  const yAxisDomain = useMemo(() => {
    const visibleYAxisValues = extractYValuesFromSeries(visibleSeries, yAxisId);
    return [min(visibleYAxisValues), max(visibleYAxisValues)] as Axes;
  }, [visibleSeries, yAxisId]);

  useEffect(() => {
    setHiddenGroupIds([]);
  }, [series]);

  const toggleLegendLabel = intl.formatMessage({
    id: 'aa.charts.button.toggleLegend',
    defaultMessage: 'Legend',
  });

  const highlightedGroupId = useMemo(() => {
    if (selectedRows.length !== 1) {
      return undefined;
    }
    const onlySelectedRowIndex = selectedRows[0];
    const onlySelectedRow = rows[onlySelectedRowIndex];

    return (
      type === 'dimension' &&
      onlySelectedRow &&
      onlySelectedRow[xAxisGroupIdField]
    );
  }, [selectedRows, rows, type, xAxisGroupIdField]);

  // render
  if (isLoading) {
    return <LoadingIndicator data-testid={CHART_LOADING_INDICATOR_TEST_ID} />;
  }
  if (isEmpty(series)) {
    return (
      <NullState
        icon={<AssessmentIcon />}
        title={
          <FormattedMessage
            id='aa.table.emptyState.noData'
            defaultMessage='No data available'
          />
        }
        subtitle={<span>{chartTitle}</span>}
      />
    );
  }
  return (
    <>
      <ChartHeader>
        <span>{chartTitle}</span>
        <ToggleLegendButton
          title={toggleLegendLabel}
          color='primary'
          onClick={toggleIsLegendShown}
          className={cx({
            [cssButtonActive]: isLegendShown,
          })}
        >
          <ListIcon className={cssIcon} />
          <span>{toggleLegendLabel}</span>
        </ToggleLegendButton>
      </ChartHeader>
      <ResponsiveContainer width='100%' height={CHART_HEIGHT}>
        <ScatterChart margin={{top: 15, left: 5, right: 5}}>
          <CartesianGrid />
          <ChartTooltip
            // @ts-expect-error payload is two points
            content={({payload: [x, y]}) => {
              if (!x || !y) {
                return null;
              }

              const seriesName =
                type === 'dimension'
                  ? x.payload[xAxisGroupIdField]
                  : startCase(x.payload.selMetric);
              const metricName =
                type === 'dimension'
                  ? startCase(y.name)
                  : startCase(x.payload.selMetric);

              return (
                <div className={cssTooltipContainer}>
                  <p>{seriesName}</p>
                  <p>
                    <FormattedMessage
                      id='aa.placeholder.dateColon'
                      defaultMessage='Date: {date}'
                      values={{date: xAxisTickFormatter(x.value)}}
                    />
                  </p>
                  <p>
                    {metricName}: {y.value}
                  </p>
                </div>
              );
            }}
          />
          <XAxis
            dataKey={xAxisId}
            name='Date'
            domain={xAxisDomain}
            scale='time'
            allowDuplicatedCategory={false}
            tickFormatter={xAxisTickFormatter}
          />

          <YAxis
            type='number'
            dataKey={yAxisId}
            name='Metric'
            domain={yAxisDomain}
            yAxisId={yAxisId}
            orientation={type === 'dimension' ? 'left' : 'right'}
            tickMargin={10}
            tickFormatter={yAxisTickFormatter}
          />

          {map(({groupData, groupId, color}: Record<string, any>) => {
            const opacity =
              highlightedGroupId && groupId !== highlightedGroupId
                ? NON_HIGHLIGHTED_OPACITY
                : HIGHLIGHTED_OPACITY;

            return (
              <Scatter
                key={groupId}
                data={groupData}
                name={groupId}
                yAxisId={yAxisId}
                line={{
                  stroke: color,
                  strokeWidth: 3,
                }}
                // points become bolder
                // stroke={color}
                opacity={opacity}
                fill={color}
                strokeWidth={3}
                isAnimationActive={false}
              />
            );
          }, visibleSeries)}
        </ScatterChart>
      </ResponsiveContainer>
      {isLegendShown && (
        <Legend
          series={series}
          onSeriesClick={handleOnLegendSeriesClick}
          hiddenGroupIds={hiddenGroupIds}
        />
      )}
    </>
  );
});
