import {flow, filter, values, some} from 'lodash/fp';
import {put, takeLatest, takeEvery} from 'redux-saga/effects';

import {loadOpsStatsRequest} from 'modules/common/actions';
import {showConfirmationDialog} from 'modules/common/sagas/common';
import {
  editChanges,
  approveChanges,
  discardChanges,
  revertChanges,
  retryChanges,
} from 'modules/history/api';
import {selectHistoryData} from 'modules/history/reducers/history';
import {safe} from 'sagas/utils';
import {call, select} from 'utils/libs/TypedReduxSaga';

import {
  loadEditChangesOptimisticSuccess,
  confirmChanges,
  loadApproveChangesRequest,
  loadApproveChangesSuccess,
  loadApproveChangesFailure,
  loadDiscardChangesRequest,
  loadDiscardChangesSuccess,
  loadDiscardChangesFailure,
  loadRevertChangesRequest,
  loadRevertChangesSuccess,
  loadRevertChangesFailure,
  loadEditChangesRequest,
  loadEditChangesSuccess,
  loadEditChangesFailure,
  loadHistoryDataRequest,
  loadRetryChangesFailure,
  loadRetryChangesSuccess,
  loadRetryChangesRequest,
} from '../actions/HistoryActions';
import {daysNumberUntilExpiredDate, checkIsExpired} from './libs/validation';

import type {EditAttributeModel} from 'modules/common/types';

export function* loadApproveChangesFlow({
  payload: changeIds,
}: {
  payload: number[];
}) {
  try {
    const result = yield* call(approveChanges, changeIds);
    yield put(loadApproveChangesSuccess(result));
    yield put(loadOpsStatsRequest());
  } catch (error) {
    console.error(error);
    yield put(loadApproveChangesFailure(error));
  }
}

export function* watchLoadApproveChangesFlow() {
  yield takeLatest(loadApproveChangesRequest, safe(loadApproveChangesFlow));
}

export function* loadDiscardChangesFlow({
  payload: changeIds,
}: {
  payload: number[];
}) {
  try {
    const result = yield* call(discardChanges, changeIds);
    yield put(loadDiscardChangesSuccess(result));
    yield put(loadHistoryDataRequest());
    yield put(loadOpsStatsRequest());
  } catch (error) {
    console.error(error);
    yield put(loadDiscardChangesFailure(error));
  }
}

export function* watchLoadDiscardChangesFlow() {
  yield takeLatest(loadDiscardChangesRequest, safe(loadDiscardChangesFlow));
}

function* confirmExpiredChanges(changeIds: number[]) {
  const isBulkApprove = changeIds.length > 1;
  const isConfirmed = yield* call(showConfirmationDialog, {
    header: 'aa.label.areYouSure',
    body: isBulkApprove
      ? 'aa.popup.confirmChanges.expired.body.bulk'
      : 'aa.popup.confirmChanges.expired.body',
    isPreformatted: true,
    confirmText: isBulkApprove
      ? 'aa.popup.confirmChanges.submit.bulk'
      : 'aa.popup.confirmChanges.submit',
    cancelText: isBulkApprove
      ? 'aa.popup.confirmChanges.discard.bulk'
      : 'aa.popup.confirmChanges.discard',
    values: {daysNumber: daysNumberUntilExpiredDate},
  });

  const selectedAction = isConfirmed
    ? loadApproveChangesRequest
    : loadDiscardChangesRequest;

  yield put(selectedAction(changeIds));
}

export function* confirmChangesFlow({payload: changeIds}: {payload: number[]}) {
  const allChanges = yield* select(selectHistoryData);
  const currentChanges = flow(
    values,
    filter(({id}) => changeIds.includes(id))
  )(allChanges);

  if (some(checkIsExpired, currentChanges)) {
    yield* call(confirmExpiredChanges, changeIds);
    return;
  }

  // Here will be another checks

  yield put(loadApproveChangesRequest(changeIds));
}

export function* watchConfirmChangesFlow() {
  yield takeLatest(confirmChanges, safe(confirmChangesFlow));
}

export function* loadRevertChangesFlow({
  payload,
}: {
  payload: {attr_change_id: number; current_value: object}[];
}) {
  try {
    const result = yield* call(revertChanges, payload);
    yield put(loadRevertChangesSuccess(result));
    yield put(loadHistoryDataRequest());
  } catch (error) {
    console.error(error);
    yield put(loadRevertChangesFailure(error));
  }
}

export function* watchLoadRevertChangesFlow() {
  yield takeLatest(loadRevertChangesRequest, safe(loadRevertChangesFlow));
}

export function* loadRetryChangesFlow({
  payload: changeIds,
}: {
  payload: number[];
}) {
  try {
    const result = yield* call(retryChanges, changeIds);
    yield put(loadRetryChangesSuccess(result));
    yield put(loadHistoryDataRequest());
  } catch (error) {
    console.error(error);
    yield put(loadRetryChangesFailure(error));
  }
}

export function* watchLoadRetryChangesFlow() {
  yield takeLatest(loadRetryChangesRequest, safe(loadRetryChangesFlow));
}

type EditChangesFlowParams = {
  payload: EditAttributeModel[];
};
export function* loadEditChangesFlow({
  payload: attr_changes,
}: EditChangesFlowParams) {
  const currentAllChanges = yield* select(selectHistoryData);
  yield put(loadEditChangesOptimisticSuccess(attr_changes));
  try {
    const result = yield* call(editChanges, attr_changes);
    yield put(loadEditChangesSuccess(result));
  } catch (error) {
    yield put(loadEditChangesFailure(currentAllChanges));
  }
}

export function* watchLoadEditChangesFlow() {
  yield takeEvery(loadEditChangesRequest, safe(loadEditChangesFlow));
}
