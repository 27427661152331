import React, {lazy, Suspense, useEffect} from 'react';

import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectCustomerConfig} from 'modules/common/reducers/customerConfig';

const LazyCVBuilder = lazy(
  () => import(/* webpackChunkName: "CV2Builder" */ './index')
);

export const CVBuilderScreen = () => {
  const {adjust_superadmin} = useSelector(selectCustomerConfig);

  useEffect(() => {
    document.title = 'Adjust | CV2 Builder';
  }, []);

  if (!adjust_superadmin) {
    return <Redirect to='/' />;
  }

  return (
    <Suspense fallback={<span>Loading CV2 Builder...</span>}>
      <LazyCVBuilder />
    </Suspense>
  );
};
