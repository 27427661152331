/* eslint-disable no-case-declarations */
import {constant, mapValues} from 'lodash/fp';

import {
  CombiSelectState,
  CombiSelectStateActions,
  CombiSelectStateActionTypes,
} from './types';

export const CombiSelectReducer = (
  state: CombiSelectState,
  action: CombiSelectStateActions
): CombiSelectState => {
  switch (action.type) {
    case CombiSelectStateActionTypes.UPDATE_PICKER_VALUE:
      const {pickerId, value} = action.payload;
      return {
        ...state,
        pickersValues: {...state.pickersValues, [pickerId]: value},
      };

    case CombiSelectStateActionTypes.CLEAN_PICKER_VALUES:
      return {
        ...state,
        pickersValues: mapValues(constant(null), state.pickersValues),
      };

    case CombiSelectStateActionTypes.UPDATE_SELECTED_VALUES:
      return {
        ...state,
        selectedValues: action.payload,
      };

    default:
      throw new Error('Unrecognized action dispatched');
  }
};
