import {map, sortBy} from 'lodash/fp';

// type Options = {
//   nameField?: string,
//   valueField?: string,
//   stringifyId?: boolean,
//   sort?: string
// };

export function getOptions(
  entities,
  {
    nameField = 'name',
    valueField = 'id',
    shortNameField = 'shortName',
    stringifyId = false,
    sort = false,
  } = {}
) {
  const safeEntities = entities ?? [];
  const options = map(
    entity => ({
      label: entity[nameField],
      title: entity[nameField],
      value: stringifyId ? entity[valueField].toString() : entity[valueField],
      shortName: entity[shortNameField],
    }),
    safeEntities
  );

  return sort ? sortBy('label', options) : options;
}
