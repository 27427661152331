import React, {useState, useEffect, useMemo} from 'react';

import * as Sentry from '@sentry/browser';
import {useSelector} from 'react-redux';

import {IntlProvider} from 'common-components/utils/libs/ReactIntl';
import {IS_DEV_ENV} from 'config';
import {selectCustomerConfig} from 'modules/common/reducers/customerConfig';
import {initTranslationCatalogs, DEBUG_LOCALE} from 'utils/i18n';

import type {Dictionary} from 'types/utils';

const MISSING_TRANSLATION_ERROR_MESSAGE = 'MISSING_TRANSLATION';
const logIntlError = (error: Error) => {
  if (!IS_DEV_ENV) return;

  if (error.message.includes(MISSING_TRANSLATION_ERROR_MESSAGE)) {
    // console.count(MISSING_TRANSLATION_ERROR_MESSAGE); <-- More than 1K items logged
    return;
  }

  // eslint-disable-next-line no-console
  console.warn(error);
};

const DEFAULT_LOCALE = 'en';

export const I18nLoader: React.FC = ({children}) => {
  const {locale = DEFAULT_LOCALE} = useSelector(selectCustomerConfig);
  const [isDebugMode, setIsDebugMode] = useState(false);
  const [translationCatalogs, setTranslationCatalogs] = useState<
    Dictionary<Dictionary<string>>
  >({});
  const [isTranslationsLoaded, setIsTranslationsLoaded] = useState(false);

  useEffect(() => {
    initTranslationCatalogs(locale)
      .then(setTranslationCatalogs)
      .catch(Sentry.captureException)
      .finally(() => setIsTranslationsLoaded(true));
  }, [locale]);

  useEffect(() => {
    window.toggleLexiDebug = () => {
      setIsDebugMode(isDebug => !isDebug);
      // eslint-disable-next-line no-console
      console.warn('🎉 You toggled lexi debug mode! Have an awesome day! 😎');
    };
  });

  const debugLocale = useMemo(
    () => (isDebugMode ? DEBUG_LOCALE : locale),
    [isDebugMode, locale]
  );

  if (!isTranslationsLoaded) {
    return null;
  }
  return (
    <IntlProvider
      messages={translationCatalogs[debugLocale]}
      locale={locale}
      onError={logIntlError}
    >
      {children}
    </IntlProvider>
  );
};
