import {fork} from 'redux-saga/effects';

import {
  watchLoadReportAttrsFlow,
  watchLoadOpsForReportAttrsFlow,
  watchLoadCreateChangeGroupFlow,
  watchPollQueuedOperationsFlow,
  watchPollLoadingAttributesFlow,
  watchLoadEnrichOperationsFlow,
  watchLoadMoreReportAttrsFlow,
} from 'modules/report/sagas/attributes';
import {
  watchLoadOpsStatsFlow,
  watchLoadCreateReportFlow,
  watchLoadUpdateReportFlow,
  watchLoadDeleteReportFlow,
  watchLoadSaveReportAsDefaultFlow,
  watchLoadSaveReportAsNewFlow,
  watchLoadCreateRuleFlow,
  watchLoadUpdateRuleFlow,
  watchUpdateEmailAlertsFlow,
  watchLoadToggleRuleStateFlow,
  watchLoadDeleteRuleFlow,
  watchRepeatingLoadOperationsStatsFlow,
  watchLoadRestoreDeletedReportFlow,
  watchReportListActions,
  watchLoadDuplicateReportFlow,
} from 'modules/report/sagas/config';
import {
  watchApplyReportParamsFlow,
  watchRemoveParentReportIdFlow,
  watchUpdateParentReportIdFlow,
  watchInitReportFlow,
  watchLoadDrilldownLabelsFlow,
  watchLoadReportDataFlow,
  watchLoadReportSpecFlow,
  watchNavigateToReportFlow,
  watchReportFiltersFlow,
  watchUpdateReportUrlFlow,
  watchLoadAddPivotReportDataFlow,
} from 'modules/report/sagas/report';

export default function* mainReportSaga() {
  // report
  yield fork(watchApplyReportParamsFlow);
  yield fork(watchRemoveParentReportIdFlow);
  yield fork(watchUpdateParentReportIdFlow);
  yield fork(watchInitReportFlow);
  yield fork(watchLoadDrilldownLabelsFlow);
  yield fork(watchLoadReportDataFlow);
  yield fork(watchLoadReportSpecFlow);
  yield fork(watchNavigateToReportFlow);
  yield fork(watchReportFiltersFlow);
  yield fork(watchUpdateReportUrlFlow);

  // attributes
  yield fork(watchLoadReportAttrsFlow);
  yield fork(watchLoadOpsForReportAttrsFlow);
  yield fork(watchLoadCreateChangeGroupFlow);
  yield fork(watchPollQueuedOperationsFlow);
  yield fork(watchPollLoadingAttributesFlow);
  yield fork(watchLoadEnrichOperationsFlow);

  // config
  yield fork(watchLoadOpsStatsFlow);
  yield fork(watchLoadCreateReportFlow);
  yield fork(watchLoadUpdateReportFlow);
  yield fork(watchLoadDeleteReportFlow);
  yield fork(watchLoadSaveReportAsDefaultFlow);
  yield fork(watchLoadSaveReportAsNewFlow);
  yield fork(watchLoadCreateRuleFlow);
  yield fork(watchUpdateEmailAlertsFlow);
  yield fork(watchLoadUpdateRuleFlow);
  yield fork(watchLoadToggleRuleStateFlow);
  yield fork(watchLoadDeleteRuleFlow);
  yield fork(watchRepeatingLoadOperationsStatsFlow);
  yield fork(watchLoadRestoreDeletedReportFlow);
  yield fork(watchReportListActions);
  yield fork(watchLoadAddPivotReportDataFlow);
  yield fork(watchLoadMoreReportAttrsFlow);
  yield fork(watchLoadDuplicateReportFlow);
}
