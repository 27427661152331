import {createContext} from 'react';

import {SortDirection, SortKey} from '../types';

type SortableColumnContextType = {
  sortKey: SortKey;
  handleColumnSortChange?: ({
    key,
    initialSortDirection,
  }: {
    key: string;
    initialSortDirection?: SortDirection;
  }) => void;
};
export const SortableColumnContext = createContext<SortableColumnContextType>({
  sortKey: '',
});
