import React, {useState, useCallback, useMemo, useEffect} from 'react';

import {css} from '@linaria/core';
import WarningIcon from '@material-ui/icons/Warning';
import {
  differenceBy,
  get,
  flow,
  uniq,
  find,
  filter,
  map,
  union,
  keyBy,
  unionBy,
  compact,
  reject,
  without,
  isString,
} from 'lodash/fp';
import {components} from 'react-select';

import SelectNew from 'common-components/SelectNew';
import {SELECT_ALL_OPTION_VALUE} from 'common-components/SelectNew/utils';
import SpaceFiller from 'common-components/SpaceFiller';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {removeCustomPrefix} from 'utils/stringUtils';

export const SELECT_ALL_OPTION_DEFAULT_LABEL = 'Select All';
export const SHOW_SELECT_ALL_OPTION_THRESHOLD = 3;
export const SHOW_SELECT_ALL_OPTION_ON_SEARCH_THRESHOLD = 1;

const cssSelectedInput = css`
  color: #454f76;
  background-color: #e6e6e6;
  font-size: 13px;
  padding: 2.5px 5px;
  white-space: nowrap;
`;
const cssMoreInput = css`
  color: #454f76;
  white-space: nowrap;
`;

const sanitizeFoundOptions = reject(['value', SELECT_ALL_OPTION_VALUE]);

export const MultiSelectValueContainer = ({
  children: [selectedOptions, inputElement],
  ...props
}) => {
  const intl = useIntl();

  let visibleElement = selectedOptions;
  let warning = null;
  const selectedCount = selectedOptions?.length;
  const {
    selectProps: {showWarning = false, menuIsOpen, isCompact},
    hasValue,
  } = props;

  if (isCompact && selectedCount > 1) {
    const selectedItemsLabel = intl.formatMessage(
      {
        id: 'aa.label.selectedItems',
        defaultMessage: '{selectedCount} selected',
      },
      {selectedCount}
    );

    visibleElement = [
      <span key='info' className={cssSelectedInput}>
        {selectedItemsLabel}
      </span>,
    ];
  } else if (selectedCount > 2) {
    const moreItemsLabel = intl.formatMessage(
      {
        id: 'aa.label.moreItems',
        defaultMessage: '+{count} more',
      },
      {count: selectedCount - 2}
    );

    visibleElement = [
      selectedOptions.slice(0, 2),
      <span key='info' className={cssMoreInput}>
        {moreItemsLabel}
      </span>,
    ];
  }

  if (!hasValue) {
    visibleElement = selectedOptions;
  }

  if (showWarning) {
    warning = [
      <SpaceFiller key='filler' />,
      <WarningIcon
        style={{color: '#f7bc2f', width: 20, marginRight: 8, marginLeft: 8}}
        key='warningIcon'
      />,
    ];
  }

  if (menuIsOpen) {
    visibleElement = null;
    warning = null;
  }

  return (
    <components.ValueContainer {...props}>
      {visibleElement}
      {inputElement}
      {warning}
    </components.ValueContainer>
  );
};

const MultiValueRemove = props => {
  const {
    selectProps: {isCompact},
  } = props;
  const locked = get('data.locked', props);
  return locked || isCompact ? null : (
    <components.MultiValueRemove {...props} />
  );
};

export const selectedAwareSort = (opts, value, valueWithLockedOptions) => {
  // Unknown values are options that backend no longer supports, but they got captured in the report URL.
  // These Unknown selected values are inserted just after selected known values
  const optionsValues = map('value', opts);
  const selectedUnknownValues = filter(
    val => !optionsValues.includes(val),
    value
  );
  const selectedUnknownValuesOptions = map(
    val => ({
      label: isString(val) ? removeCustomPrefix(val) : val,
      value: val,
    }),
    selectedUnknownValues
  );
  const optionsByValue = keyBy('value', opts);
  const selectedOptions = compact(
    map(val => optionsByValue[val])(valueWithLockedOptions)
  );

  return unionBy(
    'value',
    [...selectedOptions, ...selectedUnknownValuesOptions],
    opts
  );
};

const CheckboxMultiSelect = ({
  pendoId,
  value,
  onChange,
  options,
  components: componentsOverride = {},
  styles = {},
  limitOptionsTo,
  isCompact = false,
  ...props
}) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [queryMatchedOptions, setQueryMatchedOptions] = useState([]);

  const selectAllOption = useMemo(
    () => ({
      label: intl.formatMessage({
        id: 'aa.label.selectAll',
        defaultMessage: SELECT_ALL_OPTION_DEFAULT_LABEL,
      }),
      value: SELECT_ALL_OPTION_VALUE,
    }),
    [intl]
  );

  const valueWithLockedOptions = useMemo(
    () => flow(filter({locked: true}), map('value'), union(value))(options),
    [value, options]
  );

  const [sortedOptions, setSortedOptions] = useState(
    selectedAwareSort(options, value, valueWithLockedOptions)
  );

  useEffect(() => {
    setSortedOptions(selectedAwareSort(options, value, valueWithLockedOptions));
    // we only want to update sorted options when available options change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleMenuOpen = useCallback(() => {
    setSortedOptions(selectedAwareSort(options, value, valueWithLockedOptions));
  }, [options, value, valueWithLockedOptions]);

  const allOptions = useMemo(() => {
    const addSelectAllOption = opts =>
      opts.length > SHOW_SELECT_ALL_OPTION_THRESHOLD &&
      opts.length < limitOptionsTo
        ? [selectAllOption, ...opts]
        : opts;

    const addDisabledPropForLockedOptions = map(option => ({
      ...option,
      isDisabled: option.locked || option.isDisabled,
    }));

    return flow(
      addSelectAllOption,
      addDisabledPropForLockedOptions
    )(sortedOptions);
  }, [sortedOptions, selectAllOption, limitOptionsTo]);

  const localStyles = useMemo(
    () => ({
      ...styles,
      multiValueLabel: (labelStyles, {data: {locked = false}}) => ({
        ...labelStyles,
        backgroundColor: locked ? '#d0d5e2' : labelStyles.backgroundColor,
        paddingRight: locked ? 6 : labelStyles.paddingRight,
      }),
    }),
    [styles]
  );

  const handleOnChange = (selected, event) => {
    let result = without([SELECT_ALL_OPTION_VALUE], selected);

    if (event.option?.value === SELECT_ALL_OPTION_VALUE) {
      if (event.action === 'select-option') {
        if (inputValue) {
          result = union(map('value', queryMatchedOptions), result);
        } else {
          result = map('value', options);
        }
      } else if (event.action === 'deselect-option') {
        if (inputValue) {
          result = without(map('value', queryMatchedOptions), result);
        } else {
          result = [];
        }
      }
    }

    // drop locked options
    result = result.filter(val => !find({value: val})?.locked, allOptions);
    // drop duplicates
    result = uniq(result);

    return onChange(result);
  };

  const selectedHandler = (option, selected) => {
    if (option.value === SELECT_ALL_OPTION_VALUE) {
      if (inputValue) {
        return (
          differenceBy('value', queryMatchedOptions, selected).length === 0
        );
      }

      return selected.length === options.length;
    }

    return Boolean(find({value: option.value}, selected));
  };

  const handleInputChange = (query, {action}) => {
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') {
      setInputValue(query);
      return query;
    }

    return inputValue;
  };

  const handleLoadOptions = flow(sanitizeFoundOptions, setQueryMatchedOptions);

  const enhanceLoadedOptions = loadedOptions => {
    const sanitizedFoundOptions = sanitizeFoundOptions(loadedOptions);

    if (
      sanitizedFoundOptions.length < limitOptionsTo &&
      sanitizedFoundOptions.length > SHOW_SELECT_ALL_OPTION_ON_SEARCH_THRESHOLD
    ) {
      return [selectAllOption, ...sanitizedFoundOptions];
    }

    return sanitizedFoundOptions;
  };

  return (
    <SelectNew
      pendoId={pendoId}
      isCompact={isCompact}
      showOptionCheckbox
      {...props}
      value={valueWithLockedOptions}
      styles={localStyles}
      options={allOptions}
      onChange={handleOnChange}
      isMulti
      plainValue
      noWrap
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      components={{
        ValueContainer: MultiSelectValueContainer,
        MultiValueRemove,
        ...componentsOverride,
      }}
      isOptionSelected={selectedHandler}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onLoadOptions={handleLoadOptions}
      loadedOptionsEnhancer={enhanceLoadedOptions}
      onMenuOpen={handleMenuOpen}
      limitOptionsTo={limitOptionsTo}
    />
  );
};

export default CheckboxMultiSelect;
