import * as Sentry from '@sentry/browser';
import {noop} from 'lodash/fp';

import {SENTRY_CATEGORY_TAGS} from 'constants/globalVariables';

class CaughtJsonParseError extends Error {
  constructor(error: Error) {
    super();

    Object.assign(this, error);
    this.name = 'CaughtJsonParseError';
    this.message = `Invalid JSON passed to 'safeJsonParse': ${error.message}`;
  }
}

type SafeJsonParseAdditionalParams = {
  onError?: (error: Error) => void;
  onErrorReturn?: (text: string) => any;
  shouldCaptureSentryException?: boolean;
};
export const safeJsonParse = (
  text: string,
  {
    onError = noop,
    onErrorReturn = noop,
    shouldCaptureSentryException = false,
  }: SafeJsonParseAdditionalParams = {}
): any => {
  try {
    return JSON.parse(text);
  } catch (error) {
    onError(error as Error);

    if (shouldCaptureSentryException) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        scope.setTag('category', SENTRY_CATEGORY_TAGS.JSON_PARSE_CAUGHT);
        scope.setExtra('errorInfo', error);
        scope.setExtra('value-to-parse', text);
        Sentry.captureException(new CaughtJsonParseError(error as Error));
      });
    }

    return onErrorReturn(text);
  }
};

export const isValidJson = (json: string): boolean => {
  try {
    JSON.parse(json);
    return true;
  } catch {
    return false;
  }
};
