import {css} from '@linaria/core';

import {styleVariables} from 'constants/globalVariables';

export const cssPanel = css`
  display: flex;
  padding: 20px;
  width: 500px;
  height: 260px;

  &.MuiPaper-root {
    color: white;
  }
`;

export const cssLeftTile = css`
  flex: 50%;
  padding-right: 20px;
  border-right: 2px solid ${styleVariables.colorWarmGreyThree};
`;

export const cssRightTile = css`
  flex: 50%;
  padding-left: 20px;
  padding-right: 5px;
  overflow-y: auto;
`;

export const cssEmptyValuesContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const cssTextAlignCenter = css`
  text-align: center;
`;

export const cssFormLabel = css`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
`;

export const cssFormButtonContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const cssDeleteOptionIcon = css`
  cursor: pointer;
  &.MuiSvgIcon-root {
    height: 23px;
    color: ${styleVariables.colorWarmGreyOne};
  }
`;

export const cssPickerSelectContainer = css`
  margin-top: 20px;
`;

export const cssPickerSelectLabel = css`
  color: #96a1bd;
  font-weight: bold;
`;

export const cssLineBreak = css`
  height: 2px;
  width: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${styleVariables.colorWarmGreyThree};
`;

export const cssValueContainer = css`
  display: flex;
  border: 1px solid ${styleVariables.colorWarmGreyThree};
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 5px;
`;

export const cssValueLabel = css`
  flex-grow: 1;
`;

export const cssValueRemoveButton = css`
  flex-grow: 0;
`;

export const cssRightAlignedMenu = css`
  right: 0;
`;
