import React, {useMemo} from 'react';

import {css} from '@linaria/core';
import {Tooltip} from '@material-ui/core';
import AutomationDisabledIcon from '@material-ui/icons/FlashOff';
import AutomationEnabledIcon from '@material-ui/icons/FlashOn';
import AlertsEnabledIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AlertsDisabledIcon from '@material-ui/icons/NotificationsOffOutlined';
import cx from 'classnames';
import {flow, mapValues, keyBy, map, get} from 'lodash/fp';
import {useSelector} from 'react-redux';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {selectFiltersOptions} from 'modules/report/reducers';
import {isTemplateReportId} from 'modules/report/utils';
import {isPopulated} from 'utils/lodash+';
import {
  composeAlertsRecipientsLabel,
  countTotalEmails,
  extractChangeActions,
  extractEmailAction,
} from 'utils/reportAutomationActions';

import {craftAutomationStatus, craftHumanReadableRules} from '../utils';

import type {EnrichedReport} from 'modules/report/types';
import {BE_types} from 'types/backendServicesTypes';

const cssAutomationLabelContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const cssNoAutomation = css`
  span {
    color: #8f9eb4;
  }
  svg {
    color: #d4dae7;
  }
`;
const cssIconsContainer = css`
  display: flex;
  gap: 4px;
`;

type Props = {
  report: EnrichedReport;
};

export const AutomationLabel = ({
  report: {actions, are_actions_enabled: hasAutomationEnabled, id: reportId},
}: Props) => {
  const intl = useIntl();
  const filterOptions = useSelector(selectFiltersOptions);
  const filterOptionsAttributes = filterOptions?.attributes;

  const attrSlugToReadableName = useMemo(
    (): any => flow(keyBy('id'), mapValues('name'))(filterOptionsAttributes),
    [filterOptionsAttributes]
  );

  if (!filterOptionsAttributes) {
    return <FormattedMessage id='aa.label.loading' defaultMessage='Loading' />;
  }

  const changeActions = extractChangeActions(actions);
  const emailAction = extractEmailAction(actions);

  const actionValuesWithNames = map(
    flow(get('value'), (value: BE_types['ActionModel']['value']) => ({
      ...value,
      attributeName: attrSlugToReadableName[value.attribute_slug!],
    }))
  )(changeActions);

  const humanReadableRules = craftHumanReadableRules(
    intl,
    actionValuesWithNames
  );

  const hasChangeActions = isPopulated(changeActions);
  const emailRecipientsCount = countTotalEmails(emailAction?.value);
  const hasEmailRecipients = emailRecipientsCount > 0;

  const automationStatus = craftAutomationStatus(
    hasChangeActions,
    hasAutomationEnabled
  );

  const attrsLabel =
    automationStatus === 'none' ? (
      <FormattedMessage id='aa.label.disabled' defaultMessage='OFF' />
    ) : (
      humanReadableRules
    );

  const AutomationStatusIcon =
    automationStatus === 'enabled'
      ? AutomationEnabledIcon
      : AutomationDisabledIcon;
  const AlertsStatusIcon = hasEmailRecipients
    ? AlertsEnabledIcon
    : AlertsDisabledIcon;
  const alertsStatusIconTooltipTitle = composeAlertsRecipientsLabel(
    intl,
    emailRecipientsCount
  );

  const isTemplateReport = isTemplateReportId(reportId);

  if (isTemplateReport) {
    return <>—</>;
  }

  return (
    <div
      className={cx(cssAutomationLabelContainer, {
        [cssNoAutomation]: automationStatus === 'none',
      })}
    >
      <span>{attrsLabel}</span>
      <div className={cssIconsContainer}>
        <AutomationStatusIcon
          color='primary'
          data-pendoid='report-automationStatusIcon'
          data-testid={`automationStatusIcon-${reportId}-${automationStatus}`}
        />
        <Tooltip title={alertsStatusIconTooltipTitle}>
          <AlertsStatusIcon
            color='primary'
            data-pendoid='report-alertsStatusIcon'
            data-testid={`alertsStatusIcon-${reportId}`}
            aria-disabled={!hasEmailRecipients}
          />
        </Tooltip>
      </div>
    </div>
  );
};
