import React, {useCallback} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {selectAutomationEnabled} from 'modules/common/reducers/customerConfig';
import {toggleAutomationPanel} from 'modules/report/actions/ReportActions';
import {selectActiveReport} from 'modules/report/reducers';

import {ActionsIcon} from '../ActionsIcon';
import {StyledReportButton} from './StyledReportButton';

export const ActionsButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeReport = useSelector(selectActiveReport);

  const handleClick = useCallback(() => {
    dispatch(toggleAutomationPanel());
  }, [dispatch]);

  const isAutomationFeatureEnabled = useSelector(selectAutomationEnabled);
  if (!isAutomationFeatureEnabled) {
    return null;
  }
  const hasAutomationEnabled = activeReport?.are_actions_enabled;

  const label = intl.formatMessage({
    id: 'aa.reportSettings.buttons.automate',
    defaultMessage: 'Automate',
  });
  const enabledAutomationTitle = intl.formatMessage({
    id: 'aa.reportSettings.buttons.automation',
    defaultMessage: 'Manage actions',
  });

  return (
    <StyledReportButton
      data-pendoid='tabs-automateButton'
      title={enabledAutomationTitle}
      color='primary'
      onClick={handleClick}
    >
      <ActionsIcon isActive={hasAutomationEnabled} />
      <span>{label}</span>
    </StyledReportButton>
  );
};
