import React from 'react';

import {css} from '@linaria/core';
import {useDispatch} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {toggleQuickFilterOpen} from 'modules/report/reducers/quickFilterOpen';

import {StyledReportButton} from './StyledReportButton';

import FilterIcon from 'modules/report/components/ReportSettings/img/filter.svgc';

const cssIcon = css`
  width: 16px;
  margin: 0 6px;
  fill: currentColor;
`;

export const FilterButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFilterButtonClick = () => dispatch(toggleQuickFilterOpen());
  const label = intl.formatMessage({
    id: 'aa.reportSettings.buttons.filter',
    defaultMessage: 'Filter',
  });
  return (
    <StyledReportButton
      data-pendoid='tabs-filterButton'
      title={label}
      color='primary'
      onClick={handleFilterButtonClick}
    >
      <FilterIcon className={cssIcon} />
      <span>{label}</span>
    </StyledReportButton>
  );
};
