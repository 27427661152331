import React, {FC, useCallback, useContext, useMemo} from 'react';

import CheckboxMUI, {CheckboxProps} from '@material-ui/core/Checkbox';
import {deepPurple, blueGrey} from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import {includes} from 'lodash/fp';

import {MODERNGRID_CHECKBOX_CELL_TEST_ID} from 'common-components/ModernGrid/constants';
import {isPopulated} from 'utils/lodash+';

import {SELECTABLE_COLUMN_KEY} from '../constants';
import {SelectableRowsContext} from '../SelectableRowsContext';

import {
  ColumnData,
  RenderFn,
  RenderHeaderFn,
} from 'common-components/ModernGrid/types';

const Checkbox = withStyles({
  root: {
    '&$checked': {
      color: deepPurple[600],
    },
    '&$disabled': {
      color: blueGrey[100],
    },
    '&$indeterminate': {
      color: deepPurple[400],
    },
  },
  checked: {},
  disabled: {},
  indeterminate: {},
  // withStyles(styles) can't be used as a decorator in TypeScript: https://material-ui.com/guides/typescript/#decorating-components
  // @ts-expect-error Type '{ children?: ReactNode; } | { children?: Rea... Remove this comment to see the full error message
})(props => <CheckboxMUI {...props} />) as FC<CheckboxProps>;

const CheckboxCell: RenderFn = ({value, rowIndex}) => {
  const {disabledRows, onSelect} = useContext(SelectableRowsContext);
  const handleSelect = useCallback(
    e => {
      onSelect(rowIndex, e);
    },
    [onSelect, rowIndex]
  );

  const isDisabled = includes(rowIndex, disabledRows);

  return useMemo(
    () => (
      <Checkbox
        data-testid={MODERNGRID_CHECKBOX_CELL_TEST_ID}
        data-disabled={isDisabled}
        color='default'
        checked={value}
        disabled={isDisabled}
        onClick={e => e.stopPropagation()}
        onChange={handleSelect}
      />
    ),
    [handleSelect, value, isDisabled]
  );
};

const HeaderCheckboxCell: RenderHeaderFn = () => {
  const {isAllSelected, disabledRows, onSelectAll, selectedRows, rows} =
    useContext(SelectableRowsContext);
  const handleSelect = useCallback(() => onSelectAll(), [onSelectAll]);

  const isSelected = isPopulated(selectedRows);
  const isIndeterminate = isSelected && !isAllSelected;
  const isDisabled = disabledRows.length === rows.length;

  return useMemo(
    () => (
      <Checkbox
        data-testid={MODERNGRID_CHECKBOX_CELL_TEST_ID}
        data-disabled={isDisabled}
        color='default'
        checked={isSelected}
        indeterminate={isIndeterminate}
        disabled={isDisabled}
        onClick={e => e.stopPropagation()}
        onChange={handleSelect}
      />
    ),
    [handleSelect, isSelected, isIndeterminate, isDisabled]
  );
};

export const CheckboxColumn: ColumnData = {
  key: SELECTABLE_COLUMN_KEY,
  title: '[]',
  align: 'center',
  isFixed: true,
  gridWidth: 'min-content',
  render: CheckboxCell,
  renderHeader: HeaderCheckboxCell,
  isSortable: false,
};
