import React, {useState} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {Menu, MenuItem, Tooltip} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import {useDispatch, useSelector} from 'react-redux';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {selectDefaultReportId} from 'modules/common/reducers/customerConfig';
import {loadSaveReportAsDefaultRequest} from 'modules/report/actions';
import {selectActiveReport} from 'modules/report/reducers';
import {isTemplateReportId} from 'modules/report/utils';
import {useDeleteReportModal, useRenameReportModal} from 'utils/reportActions';

import {StyledReportButton} from './StyledReportButton';

import {EnrichedReport} from 'modules/report/types';

const ImportedButton = styled(StyledReportButton)`
  min-width: auto;
`;
const cssIcon = css`
  &.MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
`;
const DisabledIcon = styled(MoreHoriz)`
  &.MuiSvgIcon-root {
    height: 20px;
    width: 20px;
    color: #e1e6ea;
  }
`;
// required to show the tooltip for disabled state
const MoreMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    &.Mui-disabled {
      pointer-events: auto;
    }
  }
`;

const cssMenu = css`
  & .MuiPaper-root {
    background-color: #4b577e;
    color: white;
  }
`;

export const MORE_ACTIONS_MENU_ID = 'MORE_ACTIONS_MENU';

export const MoreActionsButton = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const matchedReport: EnrichedReport | undefined =
    useSelector(selectActiveReport);
  const defaultReportId = useSelector(selectDefaultReportId);

  const [anchorEl, setAnchorEl] = useState(null);

  const openRenameModal = useRenameReportModal(matchedReport);
  const openDeleteReportModal = useDeleteReportModal(matchedReport);

  if (!matchedReport) {
    return (
      <ImportedButton disabled>
        <DisabledIcon />
      </ImportedButton>
    );
  }

  const isSetAsDefaultDisabled = defaultReportId === matchedReport.id;

  const isTemplateReport = isTemplateReportId(matchedReport.id);

  const setAsDefaultTitle = isSetAsDefaultDisabled
    ? intl.formatMessage({
        id: 'aa.moreMenu.tooltip.defaultReport',
        defaultMessage: 'This is the default report',
      })
    : '';

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    if (isTemplateReport) return;

    openRenameModal();
    handleClose();
  };
  const handleDelete = () => {
    if (isTemplateReport) return;

    openDeleteReportModal();
    handleClose();
  };
  const handleSetAsDefault = () => {
    if (isSetAsDefaultDisabled) return;

    dispatch(loadSaveReportAsDefaultRequest(matchedReport.id));
  };

  return (
    <>
      <ImportedButton
        color='primary'
        aria-controls={MORE_ACTIONS_MENU_ID}
        aria-haspopup='true'
        onClick={handleClick}
        data-testid={MORE_ACTIONS_MENU_ID}
        data-pendoid='moreActionsMenuId'
      >
        <MoreHoriz className={cssIcon} />
      </ImportedButton>
      <Menu
        className={cssMenu}
        id={MORE_ACTIONS_MENU_ID}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MoreMenuItem
          onClick={handleRename}
          disabled={isTemplateReport}
          data-pendoid='report-editButton'
        >
          <FormattedMessage id='aa.label.rename' defaultMessage='Rename' />
        </MoreMenuItem>
        <MoreMenuItem
          onClick={handleDelete}
          disabled={isTemplateReport}
          data-pendoid='report-deleteButton'
        >
          <FormattedMessage id='aa.label.delete' defaultMessage='Delete' />
        </MoreMenuItem>
        <Tooltip title={setAsDefaultTitle}>
          <MoreMenuItem
            onClick={handleSetAsDefault}
            disabled={isSetAsDefaultDisabled}
            data-pendoid='report-setAsDefaultButton'
          >
            <FormattedMessage
              id='aa.label.setAsDefault'
              defaultMessage='Set as default'
            />
          </MoreMenuItem>
        </Tooltip>
      </Menu>
    </>
  );
};
