import React, {useMemo} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import {constant, noop, isString} from 'lodash/fp';
import {Field, FieldRenderProps, Form} from 'react-final-form';
import {useSelector, useDispatch} from 'react-redux';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';
import {hideInputDialog} from 'modules/common/actions';
import {
  selectIsInputDialogOpen,
  selectInputDialogParams,
} from 'modules/common/reducers';

const cssDialog = css`
  & .MuiPaper-root.MuiDialog-paper {
    background-color: #4b577e;
    color: white;
  }
`;
const SInputContainer = styled.div`
  min-width: 420px;
`;
const STextField = styled(TextField)`
  & .MuiFormLabel-root {
    text-transform: uppercase;
  }

  & .MuiInputBase-input {
    color: white;
  }

  & .MuiFormLabel-root.Mui-error,
  & .MuiFormLabel-root.Mui-focused.Mui-error,
  & .MuiFormHelperText-root.Mui-error {
    color: ${styleVariables.colorFieldError};
  }

  & .MuiInput-underline.Mui-error:after {
    border-bottom-color: ${styleVariables.colorFieldError};
  }
`;
const SButtonContainer = styled.div`
  margin-top: 20px;
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-template-columns: repeat(2, auto);
`;

type ConnectedTextFieldProps = FieldRenderProps<string> & {
  inputLabelKey: string;
  helpLabelKey: string;
  maxInputLength: number;
};

const ConnectedTextField = (props: ConnectedTextFieldProps) => {
  const {
    inputLabelKey,
    helpLabelKey,
    maxInputLength,
    input: {value, onChange},
    meta: {error, modified},
    pendoId,
  } = props;

  const intl = useIntl();
  const helperText =
    modified && error
      ? (isString(error) && error) || undefined
      : intl.formatMessage({id: helpLabelKey});

  const inputFieldPendoTag = pendoId ? `modal-${pendoId}Input` : null;

  return (
    <>
      <STextField
        data-pendoid={inputFieldPendoTag}
        inputProps={{
          ...(maxInputLength ? {maxLength: maxInputLength} : {}),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        autoFocus
        autoComplete='off'
        onChange={onChange}
        margin='dense'
        id='input-dialog-input'
        type='text'
        fullWidth
        error={modified && Boolean(error)}
        helperText={helperText}
        label={intl.formatMessage({id: inputLabelKey})}
        value={value}
      />

      {Boolean(maxInputLength) && (
        <Typography color='textSecondary'>
          {intl.formatMessage(
            {
              id: 'aa.saveReportDialog.charactersLeftMessage',
              defaultMessage: '{characters}/{max} characters',
            },
            {
              characters: value.length,
              max: maxInputLength,
            }
          )}
        </Typography>
      )}
    </>
  );
};

export const InputDialog = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isDialogOpen = useSelector(selectIsInputDialogOpen);
  const {
    headerLabelKey,
    inputLabelKey = '',
    cancelLabelKey = 'aa.form.submitBtn.cancel',
    submitLabelKey = 'aa.form.submitBtn.submit',
    helpLabelKey = '',
    maxInputLength,
    onClose = noop,
    onSubmit,
    validate = constant(true),
    initialInputValue = '',
    pendoId,
  } = useSelector(selectInputDialogParams);

  const initialValues = useMemo(
    () => ({
      name: initialInputValue,
    }),
    [initialInputValue]
  );

  const handleClose = () => {
    onClose();
    dispatch(hideInputDialog());
  };

  const handleFormSubmit = ({name}: {name: string}) => {
    onSubmit(name);
    handleClose();
  };

  const cancelLabel = intl.formatMessage({
    id: cancelLabelKey,
    defaultMessage: 'Cancel',
  });

  const submitLabel = intl.formatMessage({
    id: submitLabelKey,
    defaultMessage: 'Submit',
  });

  const cancelButtonPendoTag = pendoId ? `modal-${pendoId}Cancel` : null;
  const confirmButtonPendoTag = pendoId ? `modal-${pendoId}Confirm` : null;

  return (
    <Dialog
      className={cssDialog}
      color='secondary'
      open={isDialogOpen}
      onClose={handleClose}
      data-testid='input-dialog'
    >
      <DialogTitle>
        <FormattedMessage id={headerLabelKey} />
      </DialogTitle>
      <DialogContent>
        <Form initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({invalid, submitting, handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <SInputContainer>
                <Field
                  pendoId={pendoId}
                  name='name'
                  component={ConnectedTextField}
                  helpLabelKey={helpLabelKey}
                  inputLabelKey={inputLabelKey}
                  maxInputLength={maxInputLength}
                  validate={validate}
                />

                <SButtonContainer>
                  <Button
                    data-pendoid={cancelButtonPendoTag}
                    color='primary'
                    variant='contained'
                    onClick={handleClose}
                  >
                    {cancelLabel}
                  </Button>

                  <Button
                    data-pendoid={confirmButtonPendoTag}
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={invalid || submitting}
                  >
                    {submitLabel}
                  </Button>
                </SButtonContainer>
              </SInputContainer>
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};
