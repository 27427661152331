import React, {CSSProperties, useState, useMemo, SyntheticEvent} from 'react';

import {css} from '@linaria/core';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import {truncate} from 'lodash/fp';
import Select, {
  components as originalComponents,
  MenuProps,
} from 'react-select';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';

import {BACKDROP_Z_INDEX, Backdrop} from '../Backdrop/Backdrop';

import type {SelectComponents} from 'react-select/src/components';

import type {CommonProps, ValueContainerProps} from 'react-select';
import type {Dictionary} from 'types/utils';

const cssDropDownContainer = css`
  position: relative;
`;
const cssDropDownTarget = css`
  cursor: pointer;
  outline: none;
  display: flex;
  width: fit-content;
`;
const cssDropDownMenu = css`
  margin-top: 6px;
  position: absolute;
  z-index: ${BACKDROP_Z_INDEX + 1};
`;
const cssSelectSearchIcon = css`
  color: #b5bdd1;
  max-height: 18px;
  max-width: 18px;
`;
const cssSelectSearchContainer = css`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

export type SelectStylesOverride = Record<
  string,
  (
    style: CSSProperties,
    selectProps: CommonProps<any, true>['selectProps']
  ) => CSSProperties
>;
export const dropdownSelectStyles: SelectStylesOverride = {
  container: style => ({
    ...style,
    color: 'white',
    backgroundColor: '#4b577e',
    width: '420px',
  }),
  control: style => ({
    ...style,
    background: 'transparent',
    borderWidth: 0,
    borderBottom: 'solid 2px #b5bdd1 !important',
    cursor: 'pointer',
    boxShadow: 'none',
    backgroundColor: '#4b577e',
    margin: '0 12px',
    borderRadius: 0,
  }),
  menu: () => ({padding: '4px'}),
  menuList: () => ({
    maxHeight: '420px',
    overflowY: 'auto',
  }),
  noOptionsMessage: style => ({
    ...style,
    color: 'white',
  }),
  option: (style, {isFocused, isDisabled}) => ({
    ...style,
    color: 'inherit',
    cursor: 'pointer',
    fontSize: '13px',
    backgroundColor: 'inherit',
    ...(!isDisabled
      ? {
          '&:hover, &:active, &:focus': {
            backgroundColor: '#5d6a8c',
          },
        }
      : {}),
    ...(isFocused && !isDisabled ? {backgroundColor: '#5d6a8c'} : {}),
  }),
  valueContainer: style => ({
    ...style,
    flexWrap: 'nowrap',
    flex: '1 0 auto',
    padding: '0 8px',
  }),
  input: style => ({...style, color: 'white', margin: 0, padding: 0}),
};

type ControlledDropdownProps = {
  pendoId: string | null;
  isOpen: boolean;
  onOpen: (e: SyntheticEvent) => void;
  onClose: (e: SyntheticEvent) => void;
  value?: string;
  emptyLabel?: string;
  children: React.ReactNode;
  containerClasses?: string;
  triggerClasses?: string;
  menuClasses?: string;
};
const ControlledDropdown = ({
  pendoId = null,
  isOpen,
  onOpen,
  onClose,
  value = '',
  emptyLabel,
  children,
  containerClasses,
  triggerClasses,
  menuClasses,
}: ControlledDropdownProps) => {
  const intl = useIntl();

  const defaultEmptyLabel = intl.formatMessage({
    id: 'aa.label.select',
    defaultMessage: 'Select',
  });
  const dropdownMenuPendoId = pendoId || null;

  const DropdownTrigger = () => (
    <div
      className={cx(cssDropDownTarget, triggerClasses)}
      onClick={onOpen}
      role='button'
      tabIndex={0}
      data-pendoid={dropdownMenuPendoId}
    >
      <span style={{flex: '1 0 auto'}}>
        {(value || emptyLabel) ?? defaultEmptyLabel}
      </span>
      <ArrowDown />
    </div>
  );

  return (
    <div className={cx(cssDropDownContainer, containerClasses)}>
      <DropdownTrigger />
      <Backdrop onClick={onClose} open={isOpen} />
      {isOpen && (
        <div className={cx(cssDropDownMenu, menuClasses)}>{children}</div>
      )}
    </div>
  );
};

type DropdownSelectComponentsOverride =
  | (Partial<SelectComponents<any, false>> & {
      FixedOption?: SelectComponents<any, false>['Option'];
    })
  | undefined;
const dropdownSelectComponents: DropdownSelectComponentsOverride = {
  DropdownIndicator: null,
  IndicatorSeparator: null,
  Placeholder: () => null,
  ValueContainer: (props: ValueContainerProps<any, false>) => {
    const {
      clearValue,
      selectProps: {inputValue, pendoId = null},
    } = props;
    const dropdownMenuSearchBarPendoId = pendoId ? `${pendoId}SearchBar` : null;

    return (
      <div
        data-pendoid={dropdownMenuSearchBarPendoId}
        className={cssSelectSearchContainer}
      >
        <SearchIcon className={cssSelectSearchIcon} />
        <originalComponents.ValueContainer {...props} />
        {inputValue && (
          <ClearIcon className={cssSelectSearchIcon} onClick={clearValue} />
        )}
      </div>
    );
  },
};

interface BasicOption {
  label: string;
  value: any;
}
type Option = Dictionary<any> & BasicOption;
export type SelectFilterOption = (option: Option, rawInput: string) => boolean;
type DropdownSelectProps = {
  pendoId: string | null;
  onChange: (selOption: Option) => void;
  options: Option[];
  filterOption?: SelectFilterOption;
  style?: CSSProperties;
  styles?: SelectStylesOverride;
  value: Option | null;
  emptyLabel?: string;
  components?: DropdownSelectComponentsOverride;
  dropdownStyles?: Dictionary<string>;
};
export const DropdownSelect = ({
  pendoId = null,
  onChange,
  options,
  filterOption,
  style,
  styles: stylesOverride,
  value: currentValue,
  emptyLabel,
  components: componentsOverride,
  dropdownStyles = {},
}: DropdownSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (selOption: Option) => {
    onChange(selOption);
    setIsOpen(false);
  };

  const enhancedSelectStyles = useMemo(
    () => ({
      ...dropdownSelectStyles,
      container: (
        styleOverride: CSSProperties,
        selectPropsOverride: CommonProps<any, true>['selectProps']
      ) => ({
        ...dropdownSelectStyles?.container?.(
          styleOverride,
          selectPropsOverride
        ),
        ...style,
      }),
      ...stylesOverride,
    }),
    [style, stylesOverride]
  );

  const enhancedComponentsOverride = useMemo(
    () => ({
      ...componentsOverride,
      Menu: (props: MenuProps<any, false>) => {
        const {children} = props;
        const {Menu: OriginalMenu} = originalComponents;

        const CustomMenu = componentsOverride?.Menu || OriginalMenu;

        const dropdownMenuReportNamePendoId = pendoId
          ? `${pendoId}ReportName`
          : null;

        return (
          <CustomMenu {...props}>
            <>
              <div data-pendoid={dropdownMenuReportNamePendoId}>{children}</div>
            </>
          </CustomMenu>
        );
      },
    }),
    [componentsOverride, pendoId]
  );

  return (
    <ControlledDropdown
      pendoId={pendoId}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      value={
        currentValue
          ? `${truncate({length: 70}, currentValue.label)}`
          : undefined
      }
      emptyLabel={emptyLabel}
      containerClasses={dropdownStyles.container}
      triggerClasses={dropdownStyles.trigger}
      menuClasses={dropdownStyles.menu}
    >
      {/* @ts-expect-error No overload matches this call. */}
      <Select
        pendoId={pendoId}
        autoFocus
        menuIsOpen
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        tabSelectsValue={false}
        styles={enhancedSelectStyles}
        onChange={handleChange}
        options={options}
        filterOption={filterOption}
        noOptionsMessage={() => (
          <FormattedMessage
            id='aa.label.noMatchingOptions'
            defaultMessage='No matching options'
          />
        )}
        components={{
          ...dropdownSelectComponents,
          ...enhancedComponentsOverride,
        }}
      />
    </ControlledDropdown>
  );
};
