import {shuffleFirstLast} from 'utils/lodash+';

const COLORS = [
  '#00429d',
  '#2e62ac',
  '#4983ba',
  '#64a5c7',
  '#83c7d3',
  '#aee7dd',
  '#ffb66b',
  '#fa8e65',
  '#ec665d',
  '#d63f53',
  '#b91a47',
  '#93003a',
];

export const CONTRASTED_COLORS = shuffleFirstLast(COLORS);

export const MAX_LINES_AMOUNT = COLORS.length;

export const DATE_BREAKDOWN = 'day';

export const MAX_HEIGHT_LEGEND = 130;
export const CHART_HEIGHT = 300;

export const NON_HIGHLIGHTED_OPACITY = 0.1;
export const HIGHLIGHTED_OPACITY = 1;

export const METRIC_VALUE_DATA_KEY = 'metricValue';
export const PARSED_DATE_DATA_KEY = 'parsedDate';

// TEST ID's
export const CHART_LOADING_INDICATOR_TEST_ID =
  'CHART_LOADING_INDICATOR_TEST_ID';
