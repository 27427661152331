import React, {memo} from 'react';

import {css} from '@linaria/core';
import {flow, head, isEmpty, noop, split, startCase} from 'lodash/fp';
import {useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {selectCanEditAttr} from 'modules/common/reducers/customerConfig';
import {isOperationFailureStatus} from 'utils/operations';
import {useTargetingTooltipFromAttr} from 'utils/useTargetingTooltipFromAttr';

import {CellStatus} from '../CellStatus';
import {AttributeIcon} from './AttributeIcon';
import {EditableNumberCell} from './cells/EditableNumberCell';
import {LiteralCell} from './cells/LiteralCell';
import {MultipleCell} from './cells/MultipleCell';
import {SelectCell} from './cells/SelectCell';
import {UnsupportedTypeCell} from './cells/UnsupportedTypeCell';

import {MultiplePercentageCell} from 'modules/common/components/AttributeCell/cells/MultiplePercentageCell';

import {BE_types} from 'types/backendServicesTypes';

const cssWrapper = css`
  display: contents;
`;
// utils
const firstWordCapitalized = flow(startCase, split(' '), head);
const createStatusTooltipFromAttr = (attr: any) => {
  if (!attr?.message) return '';

  const errorPrefix = isOperationFailureStatus(attr?.status)
    ? `${firstWordCapitalized(attr?.status)}: `
    : '';

  const statusMessage = `${errorPrefix}${attr?.message}`;

  return statusMessage;
};

const getCellComponent = (type: BE_types['AttributeDataModel']['type']) => {
  switch (type) {
    case 'select':
      return SelectCell;
    case 'money':
    case 'percentage':
      return EditableNumberCell;
    case 'multiple_money':
      return MultipleCell;
    case 'multiple_percentage':
      return MultiplePercentageCell;
    case 'auto':
    case 'multiple_auto':
    case 'mixed':
    case 'unknown':
      return LiteralCell;
    default:
      return UnsupportedTypeCell;
  }
};

type Props = {
  pendoId?: string | null;
  attrData?: any; // AttributeValue
  isLoading?: boolean;
  onChange?: (value: string) => void;
  isSelected?: boolean;
  isEditable?: boolean;
  rowDataTargeting?: Record<string, string>;
};

// main component
export const AttributeCell = ({
  pendoId = null,
  attrData,
  isLoading = false,
  onChange = noop,
  isEditable = true,
  isSelected = false,
  rowDataTargeting,
}: Props) => {
  const intl = useIntl();
  const canUserEditAttr = useSelector(selectCanEditAttr);
  const loadingLabel = intl.formatMessage({
    id: 'aa.label.loading',
    defaultMessage: 'Loading',
  });

  const attribute = isLoading
    ? {
        is_loading: true,
        inherited: false,
        readonly: true,
        type: 'unknown',
        value: {},
        message: `${loadingLabel}...`,
      }
    : attrData;

  const targetingTooltipText = useTargetingTooltipFromAttr(
    attribute,
    rowDataTargeting
  );
  const statusTooltipText = createStatusTooltipFromAttr(attribute);

  if (!attribute || isEmpty(attribute)) {
    return null;
  }

  const effectiveSlug =
    (attribute.is_loading && 'is_loading') ||
    (attribute.is_error && 'is_error') ||
    attribute.status;

  const Cell = getCellComponent(attribute.type);

  return (
    <div
      className={cssWrapper}
      // used by e2e attribute selector
      data-testid={`${attribute.slug || 'unknown'}-attribute`}
      data-pendoid={`${attribute.slug || 'unknown'}-attribute`}
    >
      <CellStatus statusSlug={effectiveSlug} tooltipText={statusTooltipText} />

      <Cell
        pendoId={pendoId}
        attrData={attribute}
        tooltipText={targetingTooltipText}
        isSelected={isSelected}
        onChange={onChange}
        isEditable={canUserEditAttr && isEditable}
      />
      <AttributeIcon
        iconPendoId={pendoId}
        tooltipText={targetingTooltipText}
        attr={attribute}
      />
    </div>
  );
};

export default memo(AttributeCell);
