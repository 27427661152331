import {RefObject, useLayoutEffect, useState} from 'react';

import {isFunction, isNil, noop} from 'lodash/fp';
import ResizeObserver from 'resize-observer-polyfill';

type ElementDimensions = {
  width?: number;
  height?: number;
};

const getElementDimensions = (el: HTMLDivElement): ElementDimensions => ({
  width: el.clientWidth,
  height: el.clientHeight,
});

export const checkIfRefInitialized = (node: RefObject<HTMLDivElement>) =>
  !isNil(node) && !isNil(node.current);

export default function useNodeSize(node: RefObject<HTMLDivElement>) {
  const [dimensions, setDimensions] = useState<ElementDimensions>({});
  const isRefInitialized = checkIfRefInitialized(node);

  useLayoutEffect(() => {
    if (!isFunction(ResizeObserver) || !checkIfRefInitialized(node)) {
      return noop;
    }

    const measureNode = () =>
      window.requestAnimationFrame(() => {
        if (checkIfRefInitialized(node)) {
          const currentElementDimensions = getElementDimensions(node.current!);
          setDimensions(currentElementDimensions);
        }
      });
    measureNode();

    const resizeObserver = new ResizeObserver(measureNode);
    resizeObserver.observe(node.current!);

    return () => {
      resizeObserver.disconnect();
    };
  }, [node, isRefInitialized]);

  return dimensions;
}
