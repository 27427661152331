import React from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';

import Label from './Label';

const cssFilter = css`
  margin-right: 20px;
`;
const cssFilterLabel = css`
  margin-top: 10px;
`;
const cssAdjustFilterContent = css`
  margin-top: 0px;
`;

const Filter = ({pendoId = null, className = '', label = '', children}) => (
  <div className={cx(cssFilter, className)}>
    <Label className={cssFilterLabel}>{label}</Label>
    <div data-pendoid={pendoId} className={cssAdjustFilterContent}>
      {children}
    </div>
  </div>
);

export default Filter;
