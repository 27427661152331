import React from 'react';

const SpaceFiller = ({style = {}, ...props}) => (
  <div
    style={{
      flex: 1,
      ...style,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default SpaceFiller;
