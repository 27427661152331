import {MAX_REPORT_NAME_LENGTH} from 'constants/globalVariables';

import type {
  InformationDialogParams,
  InputDialogParams,
  ToastParams,
} from 'modules/common/types';
import type {EnrichedReport} from 'modules/report/types';
import type {Dictionary, PartialKeys} from 'types/utils';

export const defaultUnsavedChangesDialogParams = {
  header: 'aa.automation.unsavedChanges.header',
  body: 'aa.automation.unsavedChanges.body',
  confirmText: 'aa.popup.confirmChanges.discard',
  cancelText: 'aa.form.submitBtn.cancel',
};

export const getAttrChangeDialogParams = (
  confirmCallback: InformationDialogParams['confirmCallback']
) => ({
  header: 'aa.label.areYouSure',
  body: 'aa.popup.attributeChange.body',
  isPreformatted: true,
  confirmCallback,
  confirmText: 'aa.popup.confirmation.confirm',
  cancelText: 'aa.popup.confirmation.cancel',
});

export const getDeleteAutomationDialogParams = (
  confirmCallback: InformationDialogParams['confirmCallback']
) => ({
  header: 'aa.label.areYouSure',
  body: 'aa.popup.deleteAutomation.body',
  confirmCallback,
  confirmText: 'aa.popup.deleteAutomation.confirm',
  cancelText: 'aa.form.submitBtn.cancel',
});

export const getUnsavedChangesDialogParams = (
  confirmCallback: InformationDialogParams['confirmCallback']
) => ({
  ...defaultUnsavedChangesDialogParams,
  confirmCallback,
});

export const getRevertChangeDialogParams = (
  confirmCallback: InformationDialogParams['confirmCallback'],
  copy: Dictionary<string>
) => ({
  header: 'aa.popup.confirmation.header',
  body: 'aa.popup.revertChange.body',
  confirmCallback,
  confirmText: 'aa.popup.confirmation.confirm',
  cancelText: 'aa.popup.confirmation.cancel',
  values: copy,
});

export const getUpdateParamDialogParams = () => ({
  header: 'aa.popup.updateParamChoice.header',
  body: 'aa.popup.updateParamChoice.body',
  isPreformatted: true,
  confirmText: 'aa.popup.updateParamChoice.confirm',
  cancelText: 'aa.popup.updateParamChoice.cancel',
});

export const getUrlValidationDialogParams = (url: any) => ({
  header: 'aa.popup.url_validation_error.header',
  body: 'aa.popup.url_validation_error.body',
  closeCallback: () => {
    window.location = url;
  },
  isPreformatted: true,
  confirmText: 'aa.popup.url_validation_error.confirm',
});

export const getMajorChangesDialogParams = ({
  body,
  values,
}: InformationDialogParams) => ({
  header: 'aa.popup.confirmation.header',
  body,
  values,
  isPreformatted: true,
  confirmText: 'aa.popup.majorChanges.confirm',
  cancelText: 'aa.popup.majorChanges.cancel',
});

export const createDeleteReportDialogParams = (
  {title}: EnrichedReport,
  confirmCallback: InformationDialogParams['confirmCallback']
) => ({
  header: 'aa.deleteReportDialog.header',
  body: 'aa.deleteReportDialog.body',
  confirmCallback,
  values: {
    reportName: title,
  },
  confirmText: 'aa.label.delete',
  cancelText: 'aa.form.submitBtn.cancel',
});

export const createSaveReportDialogParams = (
  params: PartialKeys<InputDialogParams, 'headerLabelKey'>
) => ({
  headerLabelKey: 'aa.saveReportDialog.title',
  inputLabelKey: 'aa.saveReportDialog.newReportNameLabel',
  submitLabelKey: 'aa.label.save',
  errorLabelKey: 'aa.saveReportDialog.reportAlreadyExists',
  maxInputLength: MAX_REPORT_NAME_LENGTH,
  ...params,
});

export const createSaveReportWithAutomationDialogParams = (
  confirmCallback: InformationDialogParams['confirmCallback']
) => ({
  header: 'aa.saveReportWithAutomationDialog.header',
  body: 'aa.saveReportWithAutomationDialog.body',
  confirmCallback,
  confirmText: 'aa.label.save',
  cancelText: 'aa.form.submitBtn.cancel',
});

// Toast
export const REPORT_SAVED_TOAST_PARAMS: ToastParams = {
  category: 'informational',
  messageKey: 'aa.toast.reportSaved.message',
};
export const REPORT_RENAMED_TOAST_PARAMS: ToastParams = {
  category: 'informational',
  messageKey: 'aa.toast.reportRenamed.message',
};
export const REPORT_DELETED_TOAST_PARAMS: ToastParams = {
  category: 'informational',
  messageKey: 'aa.toast.reportDeleted.message',
};
export const REPORT_SET_AS_DEFAULT_TOAST_PARAMS: ToastParams = {
  category: 'informational',
  messageKey: 'aa.toast.reportSetAsDefault.message',
};
export const REPORT_RULES_UPDATED_TOAST_PARAMS: ToastParams = {
  category: 'informational',
  messageKey: 'aa.toast.reportRulesUpdated.message',
};
