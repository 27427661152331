import React from 'react';

import {isNil} from 'lodash/fp';

import {cssTwoLinesMaxClassName} from 'common-components/TwoLinesMax';
import {useTargetingTooltipFromAttr} from 'utils/useTargetingTooltipFromAttr';

import {FormattedTooltip} from 'modules/common/components';

import type {AttributeValue} from 'modules/common/types';

type Props = {
  value?: string;
  rowData: {
    attribute: AttributeValue;
    targeting: Record<string, string>;
  };
};

export const TargetNameColumn = ({
  value,
  rowData: {attribute, targeting: rowDataTargeting},
}: Props) => {
  const targetingTooltipText = useTargetingTooltipFromAttr(
    attribute,
    rowDataTargeting
  );
  const tooltipWithTargetName = `${value}\n\n${targetingTooltipText}`;

  return !isNil(value) ? (
    <FormattedTooltip title={tooltipWithTargetName} interactive>
      <span className={cssTwoLinesMaxClassName}>{value}</span>
    </FormattedTooltip>
  ) : (
    <>—</>
  );
};
