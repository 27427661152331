import React from 'react';

import cx from 'classnames';
import {flow, join, map} from 'lodash/fp';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  PATHNAME_HISTORY,
  CONTROL_CENTER_HISTORY,
  OPERATION_FAILURE_STATUSES,
  styleVariables,
} from 'constants/globalVariables';
import {
  selectUnapprovedCount,
  selectOperationErrors,
  selectCanViewAttr,
} from 'modules/common/reducers/customerConfig';

import classes from './Tabs.css';

export const HistoryTab = () => {
  const unapprovedCount = useSelector(selectUnapprovedCount);
  const operationErrors = useSelector(selectOperationErrors);
  const canViewAttr = useSelector(selectCanViewAttr);
  const {pathname = ''} = useLocation();
  const historyActive = pathname.endsWith(CONTROL_CENTER_HISTORY);
  const allURLOperationErrors = flow(
    map(status => `"${status}"`),
    join(',')
  )(OPERATION_FAILURE_STATUSES);
  const intl = useIntl();
  const errorsCountUnitsLabel =
    operationErrors <= 1
      ? intl.formatMessage({id: 'aa.constant.error', defaultMessage: 'Error'})
      : intl.formatMessage({
          id: 'aa.constant.errors',
          defaultMessage: 'Errors',
        });

  if (!canViewAttr) {
    return null;
  }

  return (
    <>
      <Link
        to={{
          pathname: PATHNAME_HISTORY,
          state: {fromTabClick: true},
        }}
        className={cx({
          [classes.active]: historyActive,
        })}
      >
        <FormattedMessage
          id='aa.historyTab.changeHistory'
          defaultMessage='Change History'
        />
      </Link>
      {Boolean(unapprovedCount) && (
        <Link
          to={{
            pathname: PATHNAME_HISTORY,
            search: '?status__in="pending"&date_period=all_time',
            state: {fromTabClick: true},
          }}
          title={intl.formatMessage(
            {
              id: 'aa.historyTab.unapprovedCount',
              defaultMessage: '{count} Pending',
            },
            {
              count: unapprovedCount,
            }
          )}
          style={{
            // position: 'relative',
            // bottom: 10,
            marginLeft: 4,
          }}
          className={classes.nonUnderlined}
        >
          {/* <Chip
            label={unapprovedCount}
            size='small'
            variant='outlined'
            color='secondary'
            clickable
          /> */}
          ({unapprovedCount})
        </Link>
      )}
      {Boolean(operationErrors) && (
        <Link
          to={{
            pathname: PATHNAME_HISTORY,
            search: `?status__in=${allURLOperationErrors}&date_period=all_time`,
            state: {fromTabClick: true},
          }}
          title={intl.formatMessage(
            {
              id: 'aa.historyTab.errorsCount.title',
              defaultMessage: '{count} Operation {errorsUnit}',
            },
            {
              count: operationErrors,
              errorsUnit: errorsCountUnitsLabel,
            }
          )}
          style={{
            color: styleVariables.colorLipstickTwo,
            marginLeft: 4,
          }}
          className={classes.nonUnderlined}
        >
          ({operationErrors})
        </Link>
      )}
    </>
  );
};
