import React from 'react';

import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {LoadingIndicator} from 'common-components';
import {CONTROL_CENTER, PATHNAME_BUILDER} from 'constants/globalVariables';
import {
  selectDefaultUrl,
  selectIsCustomerConfigLoaded,
} from 'modules/common/reducers/customerConfig';

export const DefaultRedirect = () => {
  const isConfigLoaded = useSelector(selectIsCustomerConfigLoaded);
  const defaultUrl = useSelector(selectDefaultUrl);

  if (!isConfigLoaded) {
    return <LoadingIndicator verticallyCentered />;
  }

  return (
    <Redirect
      to={defaultUrl ? `${CONTROL_CENTER}${defaultUrl}` : PATHNAME_BUILDER}
    />
  );
};
