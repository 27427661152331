import React, {useMemo, useState} from 'react';

import {noop} from 'lodash/fp';
import {Form} from 'react-final-form';

import {CVSchemes} from './constants';
import {
  getDefaultSchemeState,
  getInitialSchemeParams,
} from './lib/getInitialSchemeParams';
import {validateForm} from './lib/validateForm';
import {SchemeContext} from './SchemeContext';
import {schemes} from './schemes';

import {FormContents} from './components/FormContents';

import {FormState} from './types';

const defaultInitialState = getDefaultSchemeState(
  schemes[CVSchemes.Purchases].fields
) as FormState;

const CV2Builder = () => {
  const {initialScheme, initialState} = useMemo(
    () => getInitialSchemeParams(defaultInitialState),
    []
  );

  const [schemeType, setSchemeType] = useState(initialScheme as CVSchemes);
  const {fields} = schemes[schemeType];

  return (
    <SchemeContext.Provider
      value={{scheme: schemeType, setScheme: setSchemeType}}
    >
      <Form
        onSubmit={noop}
        initialValues={initialState}
        validate={validateForm(fields)}
        render={FormContents}
      />
    </SchemeContext.Provider>
  );
};

export default CV2Builder;
