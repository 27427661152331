import React, {ReactElement} from 'react';

import {css} from '@linaria/core';
import Button from '@material-ui/core/Button';
import cx from 'classnames';
import {noop, isString} from 'lodash/fp';

import {styleVariables} from 'constants/globalVariables';

export const NULLSTATE_TEST_ID = 'nullState';

const cssWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
`;
const cssBlock = css`
  margin-top: 30px;
`;
const cssTitle = css`
  font-size: 20px;
  font-weight: ${styleVariables.fontWeightMedium};
`;
const cssSubtitle = css`
  font-size: 18px;
  color: ${styleVariables.colorBlack54};
`;
const cssIcon = css`
  &.MuiSvgIcon-root {
    width: 64px;
    height: 64px;
    fill: rgba(0, 0, 0, 0.24);
  }
`;

type Props = {
  className?: string;
  icon?: ReactElement;
  title: ReactElement;
  subtitle?: ReactElement;
  buttonLabel?: string | ReactElement;
  onButtonClick?: () => void;
};
const NullState: React.FC<Props> = ({
  className = '',
  children,
  icon,
  title,
  subtitle,
  buttonLabel = '',
  onButtonClick = noop,
}) => (
  <div className={cx(cssWrapper, className)} data-testid={NULLSTATE_TEST_ID}>
    {icon &&
      React.cloneElement(icon, {
        className: cssIcon,
      })}

    {title && <div className={cx(cssBlock, cssTitle)}>{title}</div>}

    {subtitle && <div className={cx(cssBlock, cssSubtitle)}>{subtitle}</div>}

    {buttonLabel && (
      <Button
        variant='contained'
        className={cssBlock}
        color='primary'
        title={isString(buttonLabel) ? buttonLabel : undefined}
        onClick={onButtonClick}
      >
        {buttonLabel}
      </Button>
    )}

    {children && <div className={cssBlock}>{children}</div>}
  </div>
);

export default NullState;
