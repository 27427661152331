import {
  flow,
  filter,
  values,
  map,
  sum,
  reject,
  isNil,
  size,
  remove,
  find,
} from 'lodash/fp';

import {isPopulated} from './lodash+';

import type {IntlShape} from 'common-components/utils/libs/ReactIntl';
import type {BE_types} from 'types/backendServicesTypes';
import type {Dictionary} from 'types/utils';

export const countTotalEmails = (
  emailAction: BE_types['ActionModel']['value'] = {}
) => flow(values, map(size), sum)(emailAction);

// We don't have more than 1 actions of type email in report
export const extractEmailAction = find<BE_types['ActionModel']>({
  type: 'email',
});
export const removeEmailAction = remove({type: 'email'});
export const extractChangeActions = filter({type: 'change'});
export const removeChangeActions = remove({type: 'change'});

export const craftRuleActions = (
  inputValues: Dictionary<any>,
  inputs: BE_types['AttributeSlug'][]
): BE_types['ActionModel'][] => {
  const craftAmount = map((attrType: BE_types['AttributeSlug']) => {
    const amount: string = inputValues[attrType];

    if (isPopulated(amount)) {
      return {
        type: 'change',
        value: {attribute_slug: attrType, operation: amount},
      };
    }
    return undefined;
  });
  const filterInvalid = reject(isNil);
  return flow(craftAmount, filterInvalid)(inputs);
};

export const composeAlertsRecipientsLabel = (
  intl: IntlShape,
  recipientsCount: number
) => {
  switch (recipientsCount) {
    case 0:
      return intl.formatMessage({
        id: 'aa.alerts.noEmailRecipients',
        defaultMessage: 'No email recipients',
      });
    case 1:
      return intl.formatMessage({
        id: 'aa.alerts.singleEmailRecipient',
        defaultMessage: '1 email recipient',
      });
    default:
      return intl.formatMessage(
        {
          id: 'aa.automationPanel.emailRecipientsCount',
          defaultMessage: '{count} email recipients',
        },
        {count: recipientsCount}
      );
  }
};
