import React, {useState} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {Button, Menu, MenuItem} from '@material-ui/core';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import cx from 'classnames';
import {isEqual} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';
import {showInputDialog, showToast} from 'modules/common/actions';
import {
  loadUpdateReportRequest,
  removeParentReportId,
  loadSaveReportAsNewRequest,
} from 'modules/report/actions';
import {selectReportMatchedByParentReportId} from 'modules/report/reducers';
import {isTemplateReportId} from 'modules/report/utils';
import {
  createSaveReportDialogParams,
  REPORT_SAVED_TOAST_PARAMS,
} from 'utils/dialog';
import {trimParentReportId} from 'utils/parentReportId';
import {useNameValidator} from 'utils/reportActions';

const SAVE_REPORT_BUTTON_ID = 'saveReportButton';

const SaveIcon = styled(SaveRoundedIcon)`
  width: 20px;
  margin-right: 6px;
`;

const cssButton = css`
  &.MuiButton-root {
    border-radius: 4px;
    text-transform: none;

    height: 32px;
  }

  &.MuiButton-root:hover {
    color: #4b577e;
  }

  &.MuiButton-text {
    padding: 3px 6px;
  }
`;
const cssButtonActive = css`
  &.${cssButton}.MuiButton-root {
    background-color: ${styleVariables.colorAdjustGreenish};
    color: white;
  }

  &.${cssButton}.MuiButton-root:hover {
    background-color: #19adc4;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    background-color: ${styleVariables.colorMenuBackground};
    color: white;
    /* So the arrow can be visible */
    overflow: visible;
    margin-top: 14px;
  }

  /* Arrow */
  & .MuiMenu-paper::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 10px 12px;
    border-color: transparent transparent ${styleVariables.colorMenuBackground}
      transparent;

    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  & .MuiMenuItem-root {
    font-size: 14px;
  }

  & .MuiMenuItem-root:hover {
    background-color: #5d6a8c;
  }
`;

export const SaveReportButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const parentReport = useSelector(selectReportMatchedByParentReportId);

  const hasChangedParams = !isEqual(
    parseSearch(parentReport?.url || ''),
    parseSearch(trimParentReportId(search))
  );
  const isCurrentReportUpdatable =
    Boolean(parentReport) &&
    !isTemplateReportId(parentReport!.id) &&
    hasChangedParams;

  const validate = useNameValidator();

  const closeMenu = () => setAnchorEl(null);

  const saveNewReport = (newReportName: string) => {
    const requestReadySearch = trimParentReportId(search);
    const newReportPayload = {
      url: requestReadySearch,
      title: newReportName,
    };

    dispatch(loadSaveReportAsNewRequest(newReportPayload));
    dispatch(showToast(REPORT_SAVED_TOAST_PARAMS));
  };

  const handleSaveAsNewClick = () => {
    closeMenu();

    dispatch(
      showInputDialog(
        createSaveReportDialogParams({
          onSubmit: saveNewReport,
          validate,
        })
      )
    );
  };

  const handleSaveClick = () => {
    if (!parentReport) {
      return;
    }

    const url = trimParentReportId(search);
    dispatch(loadUpdateReportRequest({id: parentReport.id, url}));
    dispatch(removeParentReportId());
    closeMenu();
    dispatch(showToast(REPORT_SAVED_TOAST_PARAMS));
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isCurrentReportUpdatable) {
      // Open menu
      setAnchorEl(e.currentTarget);
    } else {
      // Open save as dialog
      handleSaveAsNewClick();
    }
  };

  const saveButtonLabel = isCurrentReportUpdatable
    ? intl.formatMessage({
        id: 'aa.label.save',
        defaultMessage: 'Save',
      })
    : intl.formatMessage({
        id: 'aa.reportTabs.saveAs',
        defaultMessage: 'Save as',
      });

  return (
    <>
      <Button
        data-testid={SAVE_REPORT_BUTTON_ID}
        data-pendoid={SAVE_REPORT_BUTTON_ID}
        className={cx(cssButton, {
          [cssButtonActive]: isCurrentReportUpdatable,
        })}
        title={saveButtonLabel}
        color='primary'
        onClick={handleClick}
      >
        <SaveIcon />

        <span>{saveButtonLabel}</span>
      </Button>

      {isMenuOpen && (
        <StyledMenu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
          // anchorOrigin doesn't work without this prop set to null
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <MenuItem
            data-pendoid='tabs-saveMenuChanges'
            onClick={handleSaveClick}
          >
            {intl.formatMessage({
              id: 'aa.saveButton.saveChanges',
              defaultMessage: 'Save changes',
            })}
          </MenuItem>

          <MenuItem
            data-pendoid='tabs-saveMenuNewReport'
            onClick={handleSaveAsNewClick}
          >
            {intl.formatMessage({
              id: 'aa.saveButton.saveAsNewReport',
              defaultMessage: 'Save as new report',
            })}
          </MenuItem>
        </StyledMenu>
      )}
    </>
  );
};
