import React from 'react';

import {Route, Switch} from 'react-router-dom';

import {SpaceFiller} from 'common-components';
import {PATHNAME_REPORT} from 'constants/globalVariables';

import {AllReportsTab} from './AllReportsTab';
import {ReportButtons} from './Buttons/ReportButtons';
import {CredentialManagementTab} from './CredentialManagementTab';
import {HistoryTab} from './HistoryTab';
import classes from './Tabs.css';

export const TabsContainer = () => (
  <div className={classes.tabsContainer}>
    <AllReportsTab />
    <HistoryTab />
    <CredentialManagementTab />
    <SpaceFiller />
    <Switch>
      <Route exact path={PATHNAME_REPORT}>
        <ReportButtons />
      </Route>
    </Switch>
  </div>
);
