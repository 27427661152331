import React from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';

import {styleVariables} from 'constants/globalVariables';

const cssLabel = css`
  color: ${styleVariables.colorFiltersTitles};
  font-size: 12px;
  font-weight: normal;
  margin-top: 16px;
  transition: 'all 450ms ease-in-out';
  text-transform: uppercase;
`;

const Label = ({className = '', children}) => (
  <div className={cx(className, cssLabel)}>{children}</div>
);

export default Label;
