import {useEffect} from 'react';

import {isEqual} from 'lodash/fp';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {
  PATHNAME_HISTORY,
  PATHNAME_REPORT_LIST,
} from 'constants/globalVariables';
import {selectActiveReport} from 'modules/report/reducers';

const isHistoryPathname = isEqual(PATHNAME_HISTORY);
const isReportsPathname = isEqual(PATHNAME_REPORT_LIST);

const useDocumentTitle = (title: string) => {
  const originalTitle = document.title;

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = originalTitle;
    };
  }, [title, originalTitle]);
};

export const DocumentTitle = () => {
  const location = useLocation();
  const isHistoryScreen = isHistoryPathname(location.pathname);
  const isReportsScreen = isReportsPathname(location.pathname);

  const activeReport = useSelector(selectActiveReport);

  const intl = useIntl();
  const defaultDocumentTitle = intl.formatMessage({
    id: 'aa.menu.controlCenter',
    defaultMessage: 'Automate',
  });
  const historyTitle = intl.formatMessage({
    id: 'aa.historyScreen.title',
    defaultMessage: 'History',
  });
  const reportsListTitle = intl.formatMessage({
    id: 'aa.reportsList.title',
    defaultMessage: 'Reports',
  });

  let currentTitle = defaultDocumentTitle;
  if (isHistoryScreen) {
    currentTitle = `${defaultDocumentTitle} | ${historyTitle}`;
  } else if (isReportsScreen) {
    currentTitle = `${defaultDocumentTitle} | ${reportsListTitle}`;
  } else if (activeReport) {
    currentTitle = `${defaultDocumentTitle} | ${activeReport.title}`;
  }

  useDocumentTitle(currentTitle);

  return null;
};
