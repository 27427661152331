import React from 'react';

import {css} from '@linaria/core';
import {Button} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';

import {NullState} from 'common-components';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';

type Props = {
  showResetColumnFiltersButton?: boolean;
  showResetDrilldownButton?: boolean;
  onResetColumnFilters: () => void;
  onResetDrilldown: () => void;
};

const cssButtonDrillDown = css`
  &.MuiButton-root {
    margin-left: 10px;
  }
`;

export const ReportNullState = ({
  showResetColumnFiltersButton = false,
  showResetDrilldownButton = false,
  onResetColumnFilters,
  onResetDrilldown,
}: Props) => {
  const intl = useIntl();

  return (
    <NullState
      icon={<AssessmentIcon />}
      title={
        <FormattedMessage
          id='aa.table.emptyState.noData'
          defaultMessage='No data available'
        />
      }
      subtitle={
        <FormattedMessage
          id='aa.table.emptyState.checkSettings'
          defaultMessage='Try removing or changing some filters'
        />
      }
    >
      {showResetColumnFiltersButton && (
        <Button
          variant='contained'
          color='primary'
          title={intl.formatMessage({
            id: 'aa.table.emptyState.resetColumnFiltersBtn',
            defaultMessage: 'Reset column filters',
          })}
          onClick={onResetColumnFilters}
        >
          <FormattedMessage
            id='aa.table.emptyState.resetColumnFiltersBtn'
            defaultMessage='Reset column filters'
          />
        </Button>
      )}

      {showResetDrilldownButton && (
        <Button
          className={cssButtonDrillDown}
          variant='contained'
          color='primary'
          title={intl.formatMessage({
            id: 'aa.table.emptyState.resetDrilldownBtn',
            defaultMessage: 'Reset drilldown',
          })}
          onClick={onResetDrilldown}
        >
          <FormattedMessage
            id='aa.table.emptyState.resetDrilldownBtn'
            defaultMessage='Reset drilldown'
          />
        </Button>
      )}
    </NullState>
  );
};
