import {
  flow,
  isEmpty,
  omitBy,
  mapValues,
  reduce,
  difference,
  pickBy,
  map,
  get,
} from 'lodash/fp';

import {pickAndRenameProps} from './operations';

import type {
  RowData,
  AttributeValue,
  AttributesResponse,
  UpdateAttributeModel,
} from 'modules/common/types';
import type {AttributesResponseShape, AttrUpdate} from 'modules/report/types';
import {BE_types} from 'types/backendServicesTypes';
import type {Dictionary} from 'types/utils';

export const reshapeRowsToEnrich = (
  rows: RowData[],
  valueKey?: 'targeting' | 'attr_dependency',
  keyBy?: string
) =>
  rows.reduce<Record<number, RowData>>(
    (acc, val, idx) => ({
      ...acc,
      [keyBy ? val[keyBy] : idx]:
        (valueKey ? (val[valueKey] as RowData) : val) || {},
    }),
    {}
  );

export const flattenAttributeResponse = reduce<
  AttributesResponse,
  Dictionary<AttributeValue>
>(
  (acc, val: AttributesResponseShape) => ({
    ...acc,
    ...val.attr_values,
  }),
  {}
);

const ASResponseErrorMessages = {
  NO_ROW: 'no row',
  CORRUPTED_MAPPING: 'corrupted mapping hash for row',
  INCORRECT_ACCURACY: 'incorrect accuracy for row',
};
export function validateASResponse(
  requestedRows: {[colNum: number]: RowData},
  response: AttributesResponse
) {
  const requestedNumbers = Object.keys(requestedRows);
  const messageErrorReducer =
    (explanation: string) =>
    (
      message: string,
      erroredRows: string[],
      attribute: BE_types['AttributeSlug']
    ) =>
      message.concat(
        isEmpty(erroredRows)
          ? ''
          : `AS answer has ${explanation} #${JSON.stringify(
              erroredRows
            )} for ${attribute}\n`
      );

  const unpresentedNumbers = mapValues(
    flow(get('mapping'), Object.keys, difference(requestedNumbers)),
    response
  );

  const rowErrorMsg = reduce(
    // @ts-expect-error No overload matches this call.
    messageErrorReducer(ASResponseErrorMessages.NO_ROW),
    '',
    unpresentedNumbers
  );
  if (rowErrorMsg) {
    throw rowErrorMsg;
  }

  const invalidMappings = mapValues(
    flow(
      get('mapping'),
      omitBy(hash => String(hash).length === 64),
      Object.keys
    ),
    response
  );

  const hashErrorMsg = reduce(
    // @ts-expect-error No overload matches this call.
    messageErrorReducer(ASResponseErrorMessages.CORRUPTED_MAPPING),
    '',
    invalidMappings
  );
  if (hashErrorMsg) {
    throw hashErrorMsg;
  }

  const incorrectAccuracy = mapValues(
    flow(
      get('attr_values'),
      omitBy((attribute: AttributeValue) => attribute.type !== 'money'),
      omitBy((attribute: AttributeValue) => {
        const {amount, accuracy} = attribute.value;
        const decimalPart = amount.split('.')[1] || '';
        return accuracy === 0 || decimalPart.length <= accuracy;
      })
    ),
    response
  );

  const accuracyErrorMsg = reduce(
    // @ts-expect-error No overload matches this call.
    messageErrorReducer(ASResponseErrorMessages.INCORRECT_ACCURACY),
    '',
    incorrectAccuracy
  );
  if (accuracyErrorMsg) {
    console.error(accuracyErrorMsg);
  }
}

const pickQueued = pickBy({status: 'queued'});
export const reshapeAttributesForOps = flow(pickQueued, pickAndRenameProps);

export const isMoneyAttribute = (
  type: BE_types['AttributeDataModel']['type']
) => type === 'money';
export const isStatusAttribute = (
  type: BE_types['AttributeDataModel']['type']
) => type === 'select';

export const convertAttrUpdateToChange = map(
  ({
    slug,
    app_network,
    type,
    value,
    to,
    targeting,
  }: AttrUpdate): UpdateAttributeModel => {
    const value_to = isMoneyAttribute(type)
      ? {
          amount: to,
          currency: value.currency,
          accuracy: value.accuracy,
        }
      : {
          value: to,
        };

    return {
      attribute_slug: slug,
      type,
      app_network,
      value_from: value,
      // @ts-expect-error Type '{ amount: string; currency: string; accuracy... Remove this comment to see the full error message
      value_to,
      targeting,
    };
  }
);

export function hasPercentageChange(
  newValueString: string,
  newValueNum: number,
  oldValueNum: number,
  slug: string
): any {
  const isPositive = newValueString.includes('+');
  const isNegative = newValueString.includes('-');
  const finalValueIncrease = oldValueNum + (newValueNum / 100) * oldValueNum;
  const finalValueDecrease = oldValueNum - (newValueNum / 100) * oldValueNum;
  const MAX_PERCENTAGE_CHANGE = 130;
  const MIN_PERCENTAGE_CHANGE = 90;
  if (
    (isPositive && newValueNum >= MAX_PERCENTAGE_CHANGE) ||
    (isNegative && newValueNum >= MIN_PERCENTAGE_CHANGE)
  ) {
    return {
      changeAmount: isPositive
        ? finalValueIncrease / oldValueNum
        : oldValueNum / finalValueDecrease,
      body: `aa.popup.majorChanges.body.percentage.${
        isPositive ? 'increasing' : 'decreasing'
      }`,
      values: {
        slug,
        newValue: newValueString,
      },
    };
  }

  return null;
}
export function hasRelativeChange(
  newValueString: string,
  newValueNum: number,
  oldValueNum: number,
  slug: string
): any {
  const MAX_CHANGE = 2.5;
  const isPositive = newValueString.includes('+');
  const changeAmount = isPositive
    ? (oldValueNum + newValueNum) / oldValueNum
    : oldValueNum / (oldValueNum - newValueNum);
  if (changeAmount >= MAX_CHANGE) {
    return {
      changeAmount,
      body: `aa.popup.majorChanges.body.relative.${
        isPositive ? 'increasing' : 'decreasing'
      }`,
      values: {
        slug,
        newValue: newValueString,
        change: changeAmount.toFixed(1),
      },
    };
  }

  return null;
}
export function hasAbsoluteChange(
  newValueString: string,
  newValueNum: number,
  oldValueNum: number,
  slug: string
): any {
  const MAX_CHANGE = 2.5;
  const isPositive = newValueNum > oldValueNum;
  const changeAmount = isPositive
    ? newValueNum / oldValueNum
    : oldValueNum / newValueNum;
  if (changeAmount >= MAX_CHANGE) {
    return {
      changeAmount,
      body: `aa.popup.majorChanges.body.absolute.${
        isPositive ? 'increasing' : 'decreasing'
      }`,
      values: {
        slug,
        newValue: newValueString,
        change: changeAmount.toFixed(1),
      },
    };
  }

  return null;
}
