import {capitalize, flow, join, keys, map, startCase} from 'lodash/fp';
import {IntlShape} from 'react-intl';
import {useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {selectAttrTargetingLabels} from 'modules/common/reducers';

import {AttributeValue, RootState} from 'modules/common/types';
import {Dictionary} from 'types/utils';

const craftHumanReadableTargeting = (
  targeting: Dictionary<string>,
  intl: IntlShape
) => {
  if (!targeting) {
    return '';
  }

  const prettifyKey: Dictionary<string> = {
    partner: intl.formatMessage({
      id: 'aa.targeting.partner',
      defaultMessage: 'partner',
    }),
    campaign_id_network: intl.formatMessage({
      id: 'aa.targeting.campaign_id_network',
      defaultMessage: 'campaign',
    }),
    adgroup_id_network: intl.formatMessage({
      id: 'aa.targeting.adgroup_id_network',
      defaultMessage: 'adgroup',
    }),
    budget_id: intl.formatMessage({
      id: 'aa.targeting.budget_id',
      defaultMessage: 'budget',
    }),
    creative_id_network: intl.formatMessage({
      id: 'aa.targeting.creative_id_network',
      defaultMessage: 'creative',
    }),
  };

  return flow(
    keys,
    map(
      key =>
        `${startCase(prettifyKey[key] || key)}: ${capitalize(targeting[key])}`
    ),
    join('\n\n')
  )(targeting);
};

const targetingIdKeyToLabelKey: Record<string, string> = {
  partner: 'partner',
  campaign_id_network: 'campaign_network',
  adgroup_id_network: 'adgroup_network',
  budget_id: 'budget',
  creative_id_network: 'creative_network',
};

const craftTargetingLabel = (targetingID: string, targetingLabel: string) => {
  if (targetingID === targetingLabel) return targetingLabel;
  if (targetingLabel) return `${targetingLabel} (${targetingID})`;
  return `(${targetingID})`;
};

export function useTargetingTooltipFromAttr(
  attr: AttributeValue,
  rowDataTargeting?: Record<string, string>
) {
  const intl = useIntl();

  const storeAttrTargetingLabels = useSelector(
    (state: RootState) => selectAttrTargetingLabels(state)[attr?.hash as string]
  );

  if (!attr || !attr.targeting) {
    return '';
  }

  const attrTargetingWithLabels = Object.entries(attr.targeting).reduce(
    (acc, [key, targetingId]) => {
      let label = '';

      // use targeting already included in history.rows when available
      if (rowDataTargeting) {
        label = rowDataTargeting[targetingIdKeyToLabelKey[key]];
      }
      // use targeting labels from store when on Reports view
      else if (storeAttrTargetingLabels) {
        label = storeAttrTargetingLabels[key];
      }

      const targetingLabel = craftTargetingLabel(targetingId, label);

      return {...acc, [key]: targetingLabel};
    },
    {}
  );

  return craftHumanReadableTargeting(attrTargetingWithLabels, intl);
}
