import React, {KeyboardEvent, useState, useMemo} from 'react';

import {
  constant,
  identity,
  isEmpty,
  map,
  uniq,
  compact,
  flow,
  trim,
} from 'lodash/fp';
import {components, OptionsType} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {isPopulated} from 'utils/lodash+';

import {composeChipInputStyles, cssErrorMessage} from './ChipInput.styles';

// this custom component is required to handle `onPaste` events
// since react-select does not forward `onPaste` handler by default
// more context: https://github.com/JedWatson/react-select/issues/1672#issuecomment-831444054
const CustomInput = (props: any) => {
  const {
    selectProps: {onPaste},
  } = props;

  return <components.Input onPaste={onPaste} {...props} />;
};

const customComponents = {
  DropdownIndicator: null,
  Input: CustomInput,
};

type ChipOption = {
  label: string;
  value: string;
};
const createChipOption = (label: string): ChipOption => ({
  label,
  value: label,
});
const sanitizeChips = flow(map(trim), uniq, compact);

type ChipInputProps = {
  chips: string[];
  setChips: (chips: string[]) => void;
  errorMessage?: string;
  placeholder?: string;
  handlePasteFormatter: (chip: string) => string;
  checkChipValidity?: (chip: string) => boolean;
  pendoId?: string;
};

export const ChipInput = ({
  chips,
  setChips,
  handlePasteFormatter = identity,
  placeholder = '',
  errorMessage,
  checkChipValidity = constant(true),
  pendoId,
}: ChipInputProps) => {
  const [inputValue, setInputValue] = useState('');

  // this callback handles chips deletion on "Backspace" press;
  // react-select handles the logic here and removes the last option for us,
  // so that we don't need to remove it manually
  const handleChange = (updatedValues: OptionsType<ChipOption>) =>
    setChips(map('value', updatedValues));

  const handleKeyDown = (
    event: KeyboardEvent<HTMLElement> & {target: {value: string}}
  ) => {
    const {key} = event;
    const {value} = event.target;

    if (key === 'Enter' || key === ',' || key === 'Tab') {
      event.preventDefault();

      setChips(sanitizeChips([...chips, value]));
      setInputValue('');
    }
  };
  const handleOnPaste = (event: ClipboardEvent) => {
    const {clipboardData} = event;
    // preventing default flow allows to clear the input after paste
    event.preventDefault();

    const pastedChips = clipboardData?.getData('Text').split(',') ?? [];
    const formattedChips = map(handlePasteFormatter, pastedChips);

    setChips(sanitizeChips([...chips, ...formattedChips]));
    setInputValue('');
  };
  const handleOnBlur = () => {
    if (isPopulated(inputValue) && checkChipValidity(inputValue)) {
      setChips(sanitizeChips([...chips, inputValue]));
    }
  };

  const options = map(createChipOption, chips);
  const isValid = isEmpty(errorMessage);
  const styles = useMemo(() => composeChipInputStyles(isValid), [isValid]);

  return (
    <div data-pendoid={pendoId}>
      <CreatableSelect
        components={customComponents}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onPaste={handleOnPaste}
        onBlur={handleOnBlur}
        value={options}
        // @ts-expect-error: event handler does not expect to have target.value for some reason
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        isMulti
        isClearable={false}
        menuIsOpen={false}
        styles={styles}
      />
      <span className={cssErrorMessage}>{errorMessage}</span>
    </div>
  );
};
