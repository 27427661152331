import {combineReducers} from 'redux';

import common from 'modules/common/reducers';
import history from 'modules/history/reducers';
import report from 'modules/report/reducers';

export const rootReducer = {
  common,
  report,
  history,
};

const reducer = combineReducers(rootReducer);

export default reducer;
