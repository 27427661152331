import React from 'react';

import {css} from '@linaria/core';
import Drawer from '@material-ui/core/Drawer';
import cx from 'classnames';

const DEFAULT_ANIMATION_DURATION = 200;
const DEFAULT_ANCHOR = 'right';
const DEFAULT_VARIANT = 'persistent';

const cssSidePanelBody = css`
  position: relative;
  width: 360px;
  height: 100%;
  background-color: #4d567e;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer */
  ::-webkit-scrollbar {
    /*chrome*/
    display: none;
  }
`;

type Props = {
  open?: boolean;
  animationDuration?: number;
  anchorState?: 'right' | 'left' | 'top' | 'bottom' | undefined;
  variantState?: 'permanent' | 'persistent' | 'temporary' | undefined;
  className?: string;
};
export const SidePanel: React.FC<Props> = ({
  children,
  open = false,
  animationDuration = DEFAULT_ANIMATION_DURATION,
  anchorState = DEFAULT_ANCHOR,
  variantState = DEFAULT_VARIANT,
  className = '',
}) => (
  <Drawer
    transitionDuration={animationDuration}
    open={open}
    anchor={anchorState}
    variant={variantState}
  >
    <div className={cx(cssSidePanelBody, className)}>{children}</div>
  </Drawer>
);
