import React from 'react';

import {isNil} from 'lodash/fp';
import moment from 'moment';

import {DASHED_DATE_FORMAT} from 'utils/dateUtils';

import {RenderFn} from 'common-components/ModernGrid/types';

export const DashedDateColumn: RenderFn = ({value}) =>
  !isNil(value) ? <>{moment.utc(value).format(DASHED_DATE_FORMAT)}</> : <>—</>;
