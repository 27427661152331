import {styled} from '@linaria/react';
import {Button} from '@material-ui/core';

export const StyledReportButton = styled(Button)`
  &.MuiButton-root {
    height: 32px;
    text-transform: none;

    &:hover {
      color: #4b577e;
    }

    &.Mui-disabled {
      color: #dce0e8;
      pointer-events: auto;
    }
  }
`;
