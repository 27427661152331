import {combineReducers, createReducer} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';

import {isPopulated} from 'utils/lodash+';

import {loadCustomerColumnSizes} from '../actions';

import type {RootState, TableColumnSizes, TableKey} from 'modules/common/types';

export type ColumnSizesState = Record<TableKey, TableColumnSizes>;
const initialColumnSizesState: ColumnSizesState = {
  report: {},
  history: {},
};
const columnSizesReducer = createReducer<ColumnSizesState>(
  initialColumnSizesState,
  builder =>
    builder.addCase(
      loadCustomerColumnSizes,
      (state, {payload: customerColumnSizes}) =>
        isPopulated(customerColumnSizes) ? customerColumnSizes : state
    )
);

export const customerPreferences = combineReducers({
  columnSizes: columnSizesReducer,
});

// Selectors
export const selectCustomerPreferences = (state: RootState) =>
  state.common.customerPreferences;
export const selectCustomerPreferencesColumnSizes = createSelector(
  selectCustomerPreferences,
  ({columnSizes}) => columnSizes
);
export const selectReportColumnSizes = createSelector(
  selectCustomerPreferencesColumnSizes,
  ({report: reportColumnSizes}) => reportColumnSizes
);
export const selectHistoryColumnSizes = createSelector(
  selectCustomerPreferencesColumnSizes,
  ({history: historyColumnSizes}) => historyColumnSizes
);
