import React, {useEffect, useContext, useMemo} from 'react';

import {css} from '@linaria/core';
import {ValidationErrors} from 'final-form';
import {find, isEmpty} from 'lodash/fp';

import {CVSchemeOptions} from '../constants';
import {refreshSchemeQuery} from '../lib/refreshSchemeQuery';
import {SchemeContext} from '../SchemeContext';
import {schemes} from '../schemes';
import {InputField, JSONPreview} from './index';
import {SchemeSelect, SchemeSelectOption} from './SchemeSelect';

import {FormState} from '../types';

const cssScreen = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 24px;
  justify-content: center;
  align-items: center;
`;
const cssContainer = css`
  display: flex;
  width: 100%;
  background-color: white;
  padding: 24px;
  flex: 1;
  min-height: 300px;
`;
const cssBuilder = css`
  flex: 1;
  margin-right: 64px;
`;
const cssForm = css`
  display: flex;
  flex-direction: column;
`;

export const FormContents = ({
  values,
  errors,
}: {
  values: FormState;
  errors: ValidationErrors;
}) => {
  const {scheme: schemeType} = useContext(SchemeContext);
  const {fields, precision, schemeGenerator} = schemes[schemeType];

  const defaultSelectValue = useMemo(
    () => find<SchemeSelectOption>(['value', schemeType])(CVSchemeOptions),
    [schemeType]
  );

  useEffect(() => {
    refreshSchemeQuery(schemeType, values);
  }, [values, schemeType]);

  return (
    <div className={cssScreen}>
      <SchemeSelect defaultValue={defaultSelectValue} />

      <div className={cssContainer}>
        <div className={cssBuilder}>
          <form className={cssForm}>
            {fields.map(fieldProps => (
              <InputField
                key={fieldProps.id}
                precision={precision}
                {...fieldProps}
              />
            ))}
          </form>
        </div>

        <JSONPreview
          data={schemeGenerator({...values, errors})}
          isValid={isEmpty(errors)}
        />
      </div>
    </div>
  );
};
