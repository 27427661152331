import React, {memo} from 'react';

import cx from 'classnames';

import {
  defaultRender,
  DEFAULT_ALIGN,
} from 'common-components/ModernGrid/constants';
import {SELECTABLE_COLUMN_KEY} from 'common-components/ModernGrid/extensions/SelectableRows';
import {
  cssCell,
  cssHighlighted,
} from 'common-components/ModernGrid/ModernGrid.styles';

import {DivAlign} from '../../DivAlign';

import {ExtendedColumnData, RowData} from 'common-components/ModernGrid/types';

type ExtractCellContentParams = {
  column: ExtendedColumnData;
  row?: RowData;
  rowIndex: number;
};
const extractCellContent = ({
  column: {key: columnKey, render = defaultRender},
  row,
  rowIndex,
}: ExtractCellContentParams) => {
  const content = row?.[columnKey];

  return render({
    key: columnKey,
    value: content,
    rowData: row,
    isSelected: row?.[SELECTABLE_COLUMN_KEY],
    rowIndex,
  });
};

type BodyCellProps = {
  column: ExtendedColumnData;
  row?: RowData;
  rowIndex: number;
};
const BodyCell = ({column, row, rowIndex}: BodyCellProps) => {
  const {align = DEFAULT_ALIGN} = column;
  const cellContent = extractCellContent({
    column,
    row,
    rowIndex,
  });

  return (
    <DivAlign
      className={cx(cssCell, {
        [cssHighlighted]: row?.[SELECTABLE_COLUMN_KEY],
      })}
      align={align}
    >
      {cellContent}
    </DivAlign>
  );
};

export default memo(BodyCell);
