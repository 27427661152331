import React, {useState, useCallback, useContext, useRef} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {Tooltip} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import cx from 'classnames';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {ColumnFiltersContext} from 'modules/report/utils';

import type {Alignment} from 'common-components/ModernGrid/types';

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

const cssWrapper = css`
  display: flex;
  flex-direction: column;
  margin: -8px 0;
  width: 100%;
  padding-inline-end: 4px;
`;
const cssInput = css`
  display: flex;
  width: 100%;
`;
const cssAlignMetricRight = css`
  text-align: end;
  cursor: text;
`;
const cssTextField = css`
  flex-grow: 1;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
  background-color: inherit;

  &:hover,
  &:focus {
    border-bottom: 1px solid #333;
  }
`;
const cssConfirmButton = css`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: #a1a1a1;
  align-self: flex-end;
  height: 16px;

  &:hover,
  &:focus {
    color: #333;
  }
`;
const cssCheckIcon = css`
  &.MuiSvgIcon-root {
    height: 16px;
  }
`;
const StyledLabel = styled.label<{align: string}>`
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;

  text-align: ${props => props.align};
`;
const cssCursor = css`
  cursor: pointer;
`;

type Props = {
  labelText: string;
  initialValue: string;
  columnType: 'metric' | 'dimension';
  columnKey: string;
  headerClickHandler?: () => void;
  align?: Alignment;
  isMovableColumn?: boolean;
};

export const FilterableHeader = React.memo(
  ({
    labelText,
    initialValue = '',
    columnType,
    columnKey,
    headerClickHandler,
    isMovableColumn = false,
    align = 'right',
  }: Props) => {
    const {applyColumnFilterDrafts, columnFilterDrafts, setColumnFilterDraft} =
      useContext(ColumnFiltersContext);
    const [isEditing, setIsEditing] = useState(false);
    const inputEl = useRef(null);
    const submitEl = useRef(null);
    const intl = useIntl();

    const draftValue = columnFilterDrafts[columnKey]?.value;
    const handleInput = setColumnFilterDraft(columnType, columnKey);

    const formSubmitHandler = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();
        applyColumnFilterDrafts();
        setTimeout(() => {
          // blur() does not exist on some elements.
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }, 0);
      },
      [applyColumnFilterDrafts]
    );

    const formBlurHandler = useCallback(
      e => {
        const switchedInsideOneForm = [
          submitEl.current,
          inputEl.current,
        ].includes(e.relatedTarget);
        if (!switchedInsideOneForm) {
          setIsEditing(false);
        }
      },
      [submitEl, inputEl]
    );
    const inputChangeHandler = useCallback(
      e => handleInput(e.target.value),
      [handleInput]
    );

    const fieldFocusHandler = useCallback(() => {
      setIsEditing(true);
    }, []);

    const headerTooltipMessage =
      columnType === 'metric'
        ? intl.formatMessage({
            id: 'aa.filters.metric.tooltip',
            defaultMessage: "e.g. '<30, >10' for range, '>50%', '=10'",
          })
        : intl.formatMessage({
            id: 'aa.filters.dimension.tooltip',
            defaultMessage:
              '"abc", -"123" shows rows that contain \'abc\' and exclude \'123\'\n="kml" for exact match',
          });

    return (
      <form
        data-testid={`columnFilter-${columnKey}`}
        className={cssWrapper}
        onBlur={formBlurHandler}
        onSubmit={formSubmitHandler}
      >
        <Tooltip
          arrow
          placement='left'
          title={intl.formatMessage(
            {
              id: 'aa.reportColumnHeader.sortByTitle',
              defaultMessage: 'Sort by {title}',
            },
            {
              title: labelText,
            }
          )}
        >
          <StyledLabel
            htmlFor={`${labelText}filter`}
            onClick={headerClickHandler}
            className={cx({
              [cssCursor]: headerClickHandler || isMovableColumn,
            })}
            align={align}
          >
            {labelText}
          </StyledLabel>
        </Tooltip>
        <Tooltip
          arrow
          placement='right'
          disableHoverListener
          title={headerTooltipMessage}
        >
          <div className={cssInput}>
            <input
              id={`${labelText}filter`}
              type='text'
              ref={inputEl}
              size={8}
              autoComplete='off'
              value={draftValue ?? initialValue}
              // placeholder="Search"
              className={cx(cssTextField, {
                [cssAlignMetricRight]: align === 'right',
              })}
              onClick={stopPropagation}
              onChange={inputChangeHandler}
              onFocus={fieldFocusHandler}
            />

            {isEditing && (
              <button
                type='submit'
                className={cssConfirmButton}
                onClick={stopPropagation}
                ref={submitEl}
              >
                <Check className={cssCheckIcon} />
              </button>
            )}
          </div>
        </Tooltip>
      </form>
    );
  }
);
