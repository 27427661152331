import {createSlice} from '@reduxjs/toolkit';

import type {RootState} from 'modules/common/types';

const quickFilterOpenSlice = createSlice({
  name: 'REPORT/QUICK_FILTER_OPEN',
  initialState: false,
  reducers: {
    toggleQuickFilterOpen: state => !state,
  },
});

export const selectIsQuickFiltersOpen = (state: RootState) =>
  state.report.quickFilterOpen;

export const {toggleQuickFilterOpen} = quickFilterOpenSlice.actions;
export const quickFilterOpen = quickFilterOpenSlice.reducer;
