import React from 'react';

import {css} from '@linaria/core';
import GetIcon from '@material-ui/icons/GetApp';
import {stringify as stringifyQuery} from 'query-string';
import {useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {composeEnvAwareURL} from 'config';
import {EXTERNAL_CSV_REPORT} from 'constants/globalVariables';
import {selectParams} from 'modules/report/reducers';
import {buildReportQuery} from 'utils/query';

import {StyledReportButton} from './StyledReportButton';

import type {ReportParams} from 'modules/report/types';

const cssWrapper = css`
  display: contents;
`;
const cssIcon = css`
  &.MuiSvgIcon-root {
    margin-top: 2px;
    margin-bottom: -2px;
  }
`;

export const buildCsvReportURL = (reportParams: ReportParams) => {
  const csvParams = buildReportQuery(reportParams);
  const csvURL = stringifyQuery(csvParams);

  return composeEnvAwareURL(`${EXTERNAL_CSV_REPORT}${csvURL}`);
};
export const CSVReportButton = () => {
  const intl = useIntl();
  const reportParams = useSelector(selectParams);
  const csvReportURL = buildCsvReportURL(reportParams);

  const title = intl.formatMessage({
    id: 'aa.reportSettings.buttons.downloadAsCSV',
    defaultMessage: 'Download as CSV',
  });
  const label = intl.formatMessage({
    id: 'aa.reportSettings.buttons.export',
    defaultMessage: 'Export',
  });

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      className={cssWrapper}
      href={csvReportURL}
    >
      <StyledReportButton
        data-pendoid='tabs-CSVReportButton'
        title={title}
        color='primary'
      >
        <GetIcon className={cssIcon} />
        <span>{label}</span>
      </StyledReportButton>
    </a>
  );
};
