import React, {useContext} from 'react';

import {flow, isEmpty, map, pick} from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';

import {SelectableRowsContext} from 'common-components/ModernGrid/extensions/SelectableRows';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {
  confirmChanges,
  loadDiscardChangesRequest,
} from 'modules/history/actions/HistoryActions';
import {selectHistoryData} from 'modules/history/reducers/history';

import {ActionPanel, SubmitButton} from 'modules/common/components';

export const HistoryActionPanel = () => {
  const dispatch = useDispatch();
  const historyData = useSelector(selectHistoryData);
  const {selectedRows, discardSelection} = useContext(SelectableRowsContext);

  const pickSelectedChanges = pick(selectedRows);
  const getId = map('id');
  const getSelectedChangesIds = flow(pickSelectedChanges, getId);
  const changeIds = getSelectedChangesIds(historyData);

  if (isEmpty(selectedRows)) {
    return null;
  }

  const approveHandler = () => {
    dispatch(confirmChanges(changeIds));
    discardSelection();
  };
  const discardHandler = () => {
    dispatch(loadDiscardChangesRequest(changeIds));
    discardSelection();
  };

  return (
    <ActionPanel>
      <FormattedMessage
        id='aa.historyActionPanel.selectedRows'
        defaultMessage='You have selected {numSelectedRows} row(s)'
        values={{numSelectedRows: selectedRows.length}}
      />
      <SubmitButton
        color='default'
        variant='contained'
        type='button'
        onClick={approveHandler}
      >
        <FormattedMessage
          id='aa.history.actionPanel.approveChanges'
          defaultMessage='Approve changes'
        />
      </SubmitButton>
      <SubmitButton
        color='secondary'
        variant='outlined'
        type='button'
        onClick={discardHandler}
      >
        <FormattedMessage
          id='aa.history.actionPanel.discardChanges'
          defaultMessage='Discard changes'
        />
      </SubmitButton>
    </ActionPanel>
  );
};
