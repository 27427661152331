import React from 'react';

import cx from 'classnames';
import {map, noop, toLower} from 'lodash/fp';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {components, OptionProps} from 'react-select';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {
  CONTROL_CENTER,
  CONTROL_CENTER_HISTORY,
  PATHNAME_REPORT_LIST,
} from 'constants/globalVariables';
import {
  selectEnrichedReports,
  selectActiveReport,
} from 'modules/report/reducers';
import {isTemplateReportId} from 'modules/report/utils';
import {isPopulated} from 'utils/lodash+';

import DropdownSelect from '../DropdownSelect';
import {
  SelectStylesOverride,
  dropdownSelectStyles,
  SelectFilterOption,
} from '../DropdownSelect/DropdownSelect';
import {ActionsIcon} from '../Tabs/ActionsIcon';
import classes from './GlobalScreen.css';

import {ReportLink} from 'modules/report/components/ReportLink';

import type {EnrichedReport} from 'modules/report/types';

const createReportOption = ({
  id,
  title,
  urlWithParentReportId,
  actions,
  are_actions_enabled: isActionsEnabled,
}: EnrichedReport) => ({
  reportId: id,
  label: title,
  value: urlWithParentReportId,
  hasActions: isPopulated(actions),
  isActionsEnabled,
});

const useBuildEmptyLabelFromPathname = () => {
  const intl = useIntl();
  const {pathname: currentPathname = ''} = useLocation();

  const isHistoryActive = currentPathname.endsWith(CONTROL_CENTER_HISTORY);
  const isReportListActive = currentPathname.endsWith(PATHNAME_REPORT_LIST);

  let label;
  if (isHistoryActive) {
    label = intl.formatMessage({
      id: 'aa.label.openReport',
      defaultMessage: 'Open Report',
    });
  } else if (isReportListActive) {
    label = intl.formatMessage({
      id: 'aa.label.reports',
      defaultMessage: 'Reports',
    });
  } else {
    label = intl.formatMessage({
      id: 'aa.label.newReport',
      defaultMessage: 'New Report',
    });
  }

  return label;
};

const CustomSelectOption = (props: OptionProps<any, false>) => {
  const {
    data: {label, hasActions, isActionsEnabled, value: url, reportId},
  } = props;

  const isTemplateReport = isTemplateReportId(reportId);

  return (
    <ReportLink
      url={url}
      className={classes.reportDropdownOptionLink}
      data-pendoid={
        isTemplateReport
          ? `navigation-template-report-${reportId}`
          : 'navigation-report-title'
      }
    >
      <components.Option {...props}>
        <span>
          {label}
          {hasActions && (
            <ActionsIcon
              isActive={isActionsEnabled}
              iconStyles={{marginBottom: '-6px', marginLeft: '4px'}}
            />
          )}
        </span>
      </components.Option>
    </ReportLink>
  );
};

const stylesOverride: SelectStylesOverride = {
  option: (style, selectProps) => ({
    ...style,
    ...dropdownSelectStyles.option(style, selectProps),
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    minHeight: '42px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'inherit',
  }),
};

export const ReportDropdown = () => {
  const matchedReport = useSelector(selectActiveReport);
  const allReports = useSelector(selectEnrichedReports);

  const emptyLabel = useBuildEmptyLabelFromPathname();
  const {pathname: currentPathname = ''} = useLocation();

  const isReportViewActive = currentPathname.endsWith(CONTROL_CENTER);
  const dropdownContainerClasses = cx(classes.reportDropdownContainer, {
    [classes.reportActiveDropdownContainer]: isReportViewActive,
  });

  const selectedOption =
    isReportViewActive && matchedReport
      ? createReportOption(matchedReport)
      : null;

  const customerReportOptions = map(createReportOption)(allReports);

  const handleFilterOption: SelectFilterOption = ({label}, input) =>
    toLower(label).includes(toLower(input));

  return (
    <DropdownSelect
      pendoId='dropdownMenu'
      value={selectedOption}
      emptyLabel={emptyLabel}
      onChange={noop}
      options={customerReportOptions}
      filterOption={handleFilterOption}
      styles={stylesOverride}
      components={{
        Option: CustomSelectOption,
      }}
      dropdownStyles={{
        container: dropdownContainerClasses,
        trigger: classes.reportDropdownTarget,
      }}
    />
  );
};
