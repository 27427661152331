import React from 'react';

import {Tooltip} from '@material-ui/core';
import {isNil, truncate} from 'lodash/fp';
import moment from 'moment';

import {OneLine} from 'common-components/OneLine/OneLine';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {DASHED_DATE_FORMAT} from 'utils/dateUtils';

type Props = {
  value?: string;
  rowData: {id: number; created_by: string; action_result_id: number};
};

export const CreatedByColumn = ({
  value,
  rowData: {id, created_by, action_result_id},
}: Props) => {
  const intl = useIntl();
  const createdBy = isNil(action_result_id)
    ? intl.formatMessage(
        {
          id: 'aa.createdBy.name',
          defaultMessage: 'by {name}',
        },
        {name: created_by}
      )
    : intl.formatMessage({
        id: 'aa.createdBy.automation',
        defaultMessage: 'by daily automation',
      });

  return !isNil(value) ? (
    <Tooltip
      title={
        <span>
          {moment.utc(value).format('YYYY-MM-DD HH:mm:ss')} (
          <FormattedMessage key='aa.label.utc' defaultMessage='UTC' />)
          <br />
          {createdBy}
          <br />
          <FormattedMessage
            id='aa.historyChangeIdTitle'
            defaultMessage='Change ID'
          />
          : {id}
        </span>
      }
    >
      <div data-divfortooltip>
        <OneLine>
          {truncate(
            {},
            `${moment.utc(value).format(DASHED_DATE_FORMAT)} ${createdBy}`
          )}
        </OneLine>
      </div>
    </Tooltip>
  ) : (
    <>—</>
  );
};
