import {flow, concat, map} from 'lodash/fp';

import {constructParams} from './constructParams';
import groupParams from './groupParams';
import parseQuery, {ADDITIONAL_QUICK_FILTER_KEYS} from './parseQuery';
import setOrder from './setOrder';

import {FiltersOptions, Filters} from 'modules/common/types';
import {Query, ReportParams} from 'modules/report/types';

const getFiltersNames = map('filter_param');

export const parseReportQuery = (
  allFilters: Filters,
  filtersOptions: FiltersOptions,
  query: Query
): ReportParams => {
  const quickFiltersNames = concat(
    getFiltersNames(allFilters),
    ADDITIONAL_QUICK_FILTER_KEYS
  );

  const params = flow(
    parseQuery(allFilters),
    groupParams(quickFiltersNames),
    setOrder,
    constructParams(filtersOptions)
  )(query);

  return params;
};

export const parseHistoryQuery = parseQuery;
