import React, {memo, useCallback, useEffect, useState, useMemo} from 'react';

import {css} from '@linaria/core';
import {uniq, upperFirst} from 'lodash/fp';
import {components, IndicatorContainerProps} from 'react-select';

import {SelectNew} from 'common-components';

import {FormattedTooltip} from '../../FormattedTooltip';

const cssContainer = css`
  width: 82px;
`;

const createOption = (value: string) => ({value, label: upperFirst(value)});

type Props = {
  pendoId: string | null;
  onChange(value: string): void;
  attrData: any;
  isEditable?: boolean;
  isSelected?: boolean;
  tooltipText: string;
};

export const SelectCell = memo(
  ({
    pendoId,
    attrData,
    onChange,
    isEditable,
    isSelected,
    tooltipText,
  }: Props) => {
    const {
      value: {value, options: rawOptions},
    } = attrData;

    const [localValue, setLocalValue] = useState(value);

    const localHandleChange = useCallback(
      ({value: newValue}) => {
        if (newValue === value) return;

        setLocalValue(newValue);
        onChange(newValue);
      },
      [value, onChange]
    );

    useEffect(() => setLocalValue(value), [value]);

    const currentOption = useMemo(() => createOption(localValue), [localValue]);

    const options = useMemo(
      () => uniq([value, ...rawOptions]).map(createOption),
      [value, rawOptions]
    );

    const isAttributeEditable =
      isEditable && attrData?.readonly === false && !isSelected;

    const customSelectComponents = useMemo(() => {
      const CustomIndicatorsContainer = (
        props: IndicatorContainerProps<any, false>
      ) => {
        const {
          selectProps: {menuIsOpen},
        } = props;

        if (menuIsOpen) return <components.IndicatorsContainer {...props} />;
        return (
          <FormattedTooltip title={tooltipText} disableFocusListener>
            <div>
              <components.IndicatorsContainer {...props} />
            </div>
          </FormattedTooltip>
        );
      };

      return {
        IndicatorsContainer: CustomIndicatorsContainer,
      };
    }, [tooltipText]);

    return (
      <div className={cssContainer} data-pendoid={pendoId}>
        <SelectNew
          value={currentOption}
          onChange={localHandleChange}
          options={options}
          isDisabled={!isAttributeEditable}
          isCell
          isSmall={false}
          autosize={false}
          components={customSelectComponents}
        />
      </div>
    );
  }
);
