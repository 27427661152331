import React, {useMemo} from 'react';

import {INDEX_COLUMN_KEY} from './constants';
import {IndexCell} from './IndexCell';
import {IndexHeader} from './IndexHeaderCell';

import {ColumnData} from 'common-components/ModernGrid/types';

type Params = {
  indexDimensions: string[];
};

type ReturnType = {
  indexColumn: ColumnData;
};
export const useIndexColumn = ({indexDimensions}: Params): ReturnType => {
  const indexColumn = useMemo(
    () =>
      ({
        key: INDEX_COLUMN_KEY,
        align: 'left',
        headerAlign: 'left',
        isFixed: true,
        isSortable: false,
        orderCollection: 4,
        shouldRenderWithoutData: true,
        render: IndexCell,
        renderHeader: () => <IndexHeader indexDimensions={indexDimensions} />,
      } as ColumnData),
    [indexDimensions]
  );

  return {indexColumn};
};
