import React from 'react';

import * as Sentry from '@sentry/browser';
import {isError} from 'lodash/fp';
import moment from 'moment';
import {render} from 'react-dom';

// eslint-disable-next-line import/order
import sagaMiddleware from 'sagas/sagaMiddleware';

import 'moment/locale/ru';

import {
  CC_SENTRY_DSN,
  CC_SENTRY_RELEASE_VERSION,
  IS_PRODUCTION_ENV,
  IS_DEV_ENV,
  RELEASE_ENV,
} from 'config';
import {SENTRY_CATEGORY_TAGS} from 'constants/globalVariables';
import {isDemo} from 'modules/report/utils';
import rootSaga from 'sagas';

import Root from './Root';
import {configureStore} from './store';

moment.locale('en');
moment.updateLocale(moment.locale(), {
  week: {
    dow: 1,
  },
});

if (IS_PRODUCTION_ENV) {
  Sentry.init({
    dsn: CC_SENTRY_DSN,
    environment: isDemo() ? 'demo' : RELEASE_ENV,
    /**
     * `release` property is required to associate issues and stack traces with specific releases and source maps.
     * It's set as a part of CI process (in our case it's a git commit hash).
     *
     * Please visit these links to find out more:
     * https://docs.sentry.io/platforms/javascript/configuration/releases/
     * https://sentry.io/organizations/adjust-automate/releases/?environment=staging&project=5857589
     */
    release: CC_SENTRY_RELEASE_VERSION,
  });
}
const store = configureStore({}, sagaMiddleware);

sagaMiddleware.run(rootSaga);

const renderApp = () => {
  render(<Root store={store} />, document.getElementById('root'));
};

const originalConsoleError = console.error.bind(console);

console.error = (...args: any[]) => {
  if (IS_PRODUCTION_ENV) {
    Sentry.withScope(scope => {
      scope.setTag('category', SENTRY_CATEGORY_TAGS.CONSOLE_ERROR);
      Sentry.captureException(args.filter(isError)?.[0] || args[0]);
    });
  }

  return originalConsoleError(...args);
};

renderApp();

if (IS_DEV_ENV) {
  if (module.hot) {
    module.hot.accept('./Root', renderApp);
    module.hot.accept('./Routes', renderApp);
  }

  /*
  import('@welldone-software/why-did-you-render').then(({default: whyDidYouRender}) => {
    whyDidYouRender(React);
  });
  */
}
