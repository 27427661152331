import {css} from '@linaria/core';

export const cssHighlighted = css``;
export const cssTotalCell = css``;
export const cssSortArrowActive = css``;
export const cssTotalRowEnabled = css``;
export const cssAdjustSortArrowStyle = css``;

export const cssCell = css`
  background-color: white;
  height: calc(60px - 2em); /* cell height - double padding */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 1em;
`;

export const cssRow = css`
  display: contents;
`;

export const cssTotalRow = css`
  font-weight: 500;
`;

export const cssClickable = css`
  cursor: pointer;
`;

export const cssTrimmedCell = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  width: 100%;
`;

export const cssSortableHelper = css`
  z-index: 3;
  background: transparent;
  border: none;
`;

export const cssSortArrow = css`
  opacity: 0;
  cursor: pointer;
`;

export const cssMove = css`
  display: grid;
  --defaultColumnTemplate: repeat(var(--colNumber), 1fr);
  grid-template-columns: var(
    --gridTemplateColumns,
    var(--defaultColumnTemplate)
  );
`;

export const cssHeader = css`
  font-weight: 500;
  position: sticky;
  color: rgba(0, 0, 0, 0.54);
  height: calc(60px - 2em); /* cell height - double padding */
  top: calc(var(--headerLevel) * 61px); /* cell height + border 1px */
  z-index: 2;

  &.${cssCell}:not(.${cssSortableHelper}):hover
    .${cssSortArrow}:not(.${cssSortArrowActive}) {
    opacity: 0.66;
  }

  & .${cssSortArrow}.${cssSortArrowActive} {
    opacity: 1;
  }
`;

export const cssHeaderCell = css`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & .${cssAdjustSortArrowStyle} {
    position: relative;
    top: 1px;
    flex-shrink: 0;
    width: 10px;
    height: 16px;
    margin: -8px 4px -8px 0;
    fill: #96a1bd;
  }
`;

export const cssFixed = css`
  position: sticky;
  left: 0;
  display: grid;
  --defaultColumnTemplate: repeat(var(--colNumber), 1fr);
  grid-template-columns: var(
    --gridTemplateColumns,
    var(--defaultColumnTemplate)
  );
  z-index: 3;

  & .${cssHeader} {
    z-index: 4;
  }
`;

export const cssTable = css`
  display: grid;
  grid-template-columns: repeat(var(--colNumber), auto);
  align-items: start;
  justify-content: start;
  width: 100%;
  overflow-x: auto;
  position: relative;
  flex: 1;

  &,
  & * {
    box-sizing: border-box;
  }

  &:not(:hover)
    .${cssMove}
    .${cssCell}:not(.${cssHeader}):not(.${cssTotalCell}):not(.${cssHighlighted}) {
    &:nth-child(odd) {
      background-color: #fff;
    }

    &:nth-child(even) {
      background-color: #fbfcfc;
    }
  }

  & .${cssFixed}, & .${cssMove} {
    grid-template-rows: repeat(var(--rowCount), auto) 1fr;
    align-items: start;
    height: 100%;
  }

  &
    .${cssMove}
    .${cssRow}:hover
    .${cssCell}:not(.${cssHeader}):not(.${cssTotalCell}):not(.${cssHighlighted}) {
    background-color: #fff;
  }

  & .${cssTotalRow} {
    font-weight: inherit;
  }

  & .${cssCell} {
    background-color: #f7f8f9;
    border-bottom: 3px solid #f3f4f6;
    border-right: 3px solid #f3f4f6;
    letter-spacing: -0.04em;
    height: var(--rowHeight);
    padding: 15px 10px;

    &.${cssTotalCell} {
      background-color: #e9ecf0;
      border-bottom: 0;
      color: #454f76;
      border-top: 3px solid #f3f4f6;
      position: sticky;
      bottom: 0;

      &:nth-child(even) {
        background-color: #e4e7ed;
      }
    }

    &.${cssHighlighted} {
      background-color: #ede7f6;
    }
  }

  .${cssTotalRowEnabled} & .${cssRow}:nth-last-child(2) .${cssCell} {
    border-bottom: 0;
  }

  & .${cssHeader} {
    background-color: #e4e7ed;
    color: #454f76;

    &.${cssCell} {
      align-self: start;
      background-color: #e9ecf0;
      height: var(--headerHeight); /* cell height - double padding */
      top: calc(
        var(--headerLevel) * (var(--headerHeight) + 1px)
      ); /* cell height + border 1px */
    }

    &.${cssCell}:nth-child(even) {
      background-color: #e4e7ed;
    }

    &.${cssCell}:hover {
      background-color: #e4e7ed;
    }
  }

  & .${cssFixed} .${cssCell}:not(.${cssHighlighted}) {
    background-color: #e4e7ed;
  }
`;
