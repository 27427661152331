import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  useRef,
  FocusEvent,
} from 'react';

import {css} from '@linaria/core';
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import cx from 'classnames';
import {noop} from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';

import {showInformationDialog} from 'modules/common/actions';
import {selectShouldShowChangePopup} from 'modules/common/reducers/customerConfig';
import {createAttrValueRegExp} from 'modules/report/utils';
import {createAttrFormatter} from 'utils/conversions';
import {getAttrChangeDialogParams} from 'utils/dialog';

import {AttributeValue} from '../../../types';
import {FormattedTooltip} from '../../FormattedTooltip';

const cssMoneyCell = css`
  margin-left: -8px;
  position: relative;
  display: flex;
`;
const cssConfirmButton = css`
  background-color: transparent;
  border: none;
  outline: none;
  color: #e1e6ea;
  align-self: flex-end;
`;
const cssTextField = css`
  border: none;
  outline: none;
  background-color: inherit;
  padding: 2px 4px;
  margin: 0 4px 0 2px;
  border-bottom: 1px solid transparent;
  text-align: right;

  &:focus {
    cursor: text;
    background-color: papayawhip;
    border-bottom: 1px solid #666;
  }
`;
const cssTextFieldEditable = css`
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #ccc;
  }
`;
const cssEditableIcon = css`
  cursor: pointer;
  color: #96a1bd;

  &:hover,
  &:focus {
    color: #4b577e;
  }
`;
const cssIconStyles = css`
  &.MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`;

type EditControlProps = {
  pendoId: string | null;
  isEditing: boolean;
  onStartEdit(
    event: React.MouseEvent<any, MouseEvent> | FocusEvent<HTMLButtonElement>
  ): void;
  editTooltip: string;
  isEditable: boolean;
};

const EditControl = React.forwardRef<HTMLButtonElement, EditControlProps>(
  ({pendoId, isEditing, onStartEdit, editTooltip, isEditable}, ref) => {
    if (isEditing) {
      return (
        <button
          type='submit'
          className={cx(cssConfirmButton, {
            [cssEditableIcon]: isEditable,
          })}
          ref={ref}
        >
          <Check className={cssIconStyles} />
        </button>
      );
    }
    return (
      <button
        data-pendoid={pendoId}
        type='button'
        className={cssConfirmButton}
        onFocus={isEditable ? onStartEdit : noop}
      >
        <FormattedTooltip
          title={editTooltip}
          disableHoverListener={!isEditable}
          interactive
        >
          <Edit
            className={cx(cssConfirmButton, cssIconStyles, {
              [cssEditableIcon]: isEditable,
            })}
            onClick={isEditable ? onStartEdit : noop}
          />
        </FormattedTooltip>
      </button>
    );
  }
);

const getAttrValueStr = (attrData: AttributeValue) => {
  switch (attrData.type) {
    case 'money':
      return String(attrData.value.amount);
    case 'percentage':
      return String(attrData.value.value);
    default:
      return String(NaN);
  }
};

type EditableNumberCellProps = {
  pendoId: string | null;
  attrData: AttributeValue;
  onChange: Function;
  tooltipText: string;
  isEditable: boolean;
  isSelected: boolean;
};

export const EditableNumberCell = memo(
  ({
    pendoId,
    attrData,
    onChange,
    tooltipText,
    isEditable,
    isSelected,
  }: EditableNumberCellProps) => {
    const {
      accuracy,
      currency,
      relative_operation: relativeOperation,
    } = attrData?.value || {};
    const initialAmount = getAttrValueStr(attrData);

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(initialAmount);
    const inputEl = useRef<HTMLInputElement>(null);
    const submitEl = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
    const shouldShowPopup = useSelector(selectShouldShowChangePopup);

    useEffect(() => {
      setInputValue(initialAmount);
    }, [initialAmount]);

    const handleSubmit = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();

        if (inputValue && inputValue !== initialAmount) {
          const changeCallback = () => {
            setIsEditing(false);
            onChange(inputValue);
          };
          if (shouldShowPopup) {
            dispatch(
              showInformationDialog(getAttrChangeDialogParams(changeCallback))
            );
          } else {
            changeCallback();
          }
        } else {
          setInputValue(initialAmount);
        }
        // @ts-expect-error Object is possibly 'null'
        setTimeout(() => document.activeElement.blur(), 0);
      },
      [inputValue, initialAmount, onChange, dispatch, shouldShowPopup]
    );

    const handleBlur = useCallback(
      e => {
        const switchedInsideOneForm = [
          submitEl.current,
          inputEl.current,
        ].includes(e.relatedTarget);
        if (!switchedInsideOneForm) {
          setInputValue(initialAmount);
          setIsEditing(false);
        }
      },
      [initialAmount, submitEl, inputEl]
    );

    const handleFocus = useCallback(() => {
      setIsEditing(true);
      inputEl.current?.focus();
      inputEl.current?.select();
    }, [inputEl]);

    const inputPattern = createAttrValueRegExp(accuracy);

    const handleChange = useCallback(
      e => {
        if (!inputPattern.test(e.target.value)) {
          return;
        }

        setInputValue(e.target.value);
      },
      [inputPattern]
    );

    const isPercentageType = attrData.type === 'percentage';

    const formatter = createAttrFormatter({
      accuracy,
      currency,
      style: isPercentageType ? 'percent' : '',
    });
    const formattedInputValue = formatter.format(
      isPercentageType ? Number(inputValue) / 100 : Number(inputValue)
    );

    const formattedValue = relativeOperation
      ? `${relativeOperation} (${formattedInputValue})`
      : formattedInputValue;
    const valLength = formattedValue.length;
    const fieldSize = Math.max(valLength + Math.floor(valLength / 3), 5);

    const isAttributeEditable =
      isEditable && attrData?.readonly === false && !isSelected;

    return (
      <form
        className={cssMoneyCell}
        onBlur={handleBlur}
        onSubmit={handleSubmit}
      >
        <input
          data-pendoid='EditableNumberCellInput'
          type='text'
          ref={inputEl}
          size={fieldSize}
          autoComplete='false'
          disabled={!isAttributeEditable}
          value={
            isEditing ||
            inputValue.startsWith('+') ||
            inputValue.startsWith('-')
              ? inputValue
              : formattedValue
          }
          className={cx(cssTextField, {
            [cssTextFieldEditable]: isAttributeEditable,
          })}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <EditControl
          pendoId={pendoId}
          isEditable={isAttributeEditable}
          isEditing={isEditing}
          editTooltip={tooltipText}
          onStartEdit={handleFocus}
          ref={submitEl}
        />
      </form>
    );
  }
);
