import React, {RefObject, useCallback, useContext, useEffect} from 'react';

import {useNodeSize, checkIfRefInitialized} from 'common-components/hooks';

import {ExpandableContext} from './ExpandableContext';
import {cssRolledUpWrapper} from './ExpandableGrid.styles';

import {ResizingContainer} from './components/ResizingContainer';

type UseExpandableGridParams = {
  minimumVisibleRowsThreshold: number;
};
export const useExpandableGrid = ({
  minimumVisibleRowsThreshold,
}: UseExpandableGridParams) => {
  const ResizingWrapper = useCallback<React.FC>(
    ({children}) => (
      <ResizingContainer
        minimumVisibleRowsThreshold={minimumVisibleRowsThreshold}
      >
        {children}
      </ResizingContainer>
    ),
    [minimumVisibleRowsThreshold]
  );

  return {ResizingContainer: ResizingWrapper};
};

/**
 * calculates if the height of the table is not enough
 * to display {shouldShowTotalRow} rows
 *
 * if the height is too small it disables vertical scrolling and
 * unrolls MG to the full height such that scrolling is enabled
 * on the parent element
 */
type UseInternalShouldExpandGridParams = {
  headerHeight: number;
  rowHeight: number;
  tableRef: RefObject<HTMLDivElement>;
  shouldShowTotalRow: boolean;
};
export const useInternalShouldExpandGrid = ({
  headerHeight,
  rowHeight,
  tableRef,
  shouldShowTotalRow,
}: UseInternalShouldExpandGridParams) => {
  const {
    containerRef: resizingContainerRef,
    minimumVisibleRowsThreshold,
    setIsExpanded,
  } = useContext(ExpandableContext);
  const {height: resizingContainerHeight} = useNodeSize(resizingContainerRef);
  let isExpanded = false;

  if (
    checkIfRefInitialized(tableRef) &&
    checkIfRefInitialized(resizingContainerRef)
  ) {
    const screenHeight = window.innerHeight;
    const tableOffsetTop = tableRef.current!.offsetTop || 0;
    const actualHeaderHeight = headerHeight || rowHeight;
    const totalRowHeight = shouldShowTotalRow ? rowHeight : 0;

    const minTableHeight =
      actualHeaderHeight +
      rowHeight * minimumVisibleRowsThreshold +
      totalRowHeight;

    isExpanded = screenHeight < tableOffsetTop + minTableHeight;
  }

  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [setIsExpanded, isExpanded, resizingContainerHeight]);

  return {isExpanded, cssRolledUpWrapper};
};
