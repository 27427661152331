import {isNil} from 'lodash/fp';

export const LOCALE = 'en-US';

export const createAttrFormatter = ({
  accuracy,
  currency,
  style: styleProp,
}: {
  accuracy?: number;
  currency?: string;
  style?: string;
}) => {
  const defaultStyle = !isNil(currency) ? 'currency' : 'decimal';
  const style = styleProp || defaultStyle;

  return new Intl.NumberFormat(LOCALE, {
    ...(currency && {currency}),
    style,
    minimumFractionDigits: 0,
    maximumFractionDigits: accuracy ?? 6,
  });
};
