import React, {useCallback, useRef} from 'react';

import {isNil} from 'lodash/fp';
import Draggable from 'react-draggable';

import {useIntl} from 'common-components/utils/libs/ReactIntl';

import {
  DEFAULT_MIN_COLUMN_WIDTH,
  DEFAULT_MAX_COLUMN_WIDTH,
  COLUMN_RESIZER_SHADOW_DOM_ID,
  TABLE_WRAPPER_DOM_ID,
  COLUMN_RESIZER_DEFAULT_MESSAGE,
} from '../../constants';
import classes from './ColumnResizer.css';

import {ColumnResizeCallback} from 'common-components/ModernGrid/types';

const getResizeBoundaries = ({
  currentWidth,
  minWidth,
  maxWidth,
}: {
  currentWidth: number;
  minWidth: number;
  maxWidth?: number;
}) => ({
  left: Math.min(-currentWidth + minWidth, 0),
  ...(maxWidth ? {right: maxWidth - currentWidth} : {}),
});

/**
 * Ensure value is within limits
 *
 * @param value - numeric value
 * @param limits - object with optional: minLimit, maxLimit
 */
export const trimToLimits = (
  value: number,
  {minLimit, maxLimit}: {minLimit?: number; maxLimit?: number}
) => {
  if (!isNil(minLimit) && value < minLimit) {
    return minLimit;
  }

  if (!isNil(maxLimit) && value > maxLimit) {
    return maxLimit;
  }

  return value;
};

type ColumnResizerProps = {
  columnKey: string;
  currentWidth: number;
  maxWidth?: number;
  onColumnResize: ColumnResizeCallback;
};
const ColumnResizer = ({
  columnKey,
  currentWidth,
  maxWidth = DEFAULT_MAX_COLUMN_WIDTH,
  onColumnResize,
}: ColumnResizerProps) => {
  const intl = useIntl();

  const minWidth = DEFAULT_MIN_COLUMN_WIDTH; // All columns are resizable down to 120 px - https://app.asana.com/0/1149145398566403/1198963984045725/f,
  const sanitizedCurrentWidth = trimToLimits(currentWidth, {
    minLimit: minWidth,
    maxLimit: maxWidth,
  });

  const fixedPosition = {x: 0, y: 0};
  const resizeDirectionAxis = 'x';
  const resizeBoundaries = getResizeBoundaries({
    currentWidth: sanitizedCurrentWidth,
    minWidth,
    maxWidth,
  });

  const resizeHandlerRef = useRef<HTMLDivElement>(null);
  const resizeHandlerShadowElement = document.getElementById(
    COLUMN_RESIZER_SHADOW_DOM_ID
  ); // TODO: change to ref
  const tableWrapperElement = document.getElementById(TABLE_WRAPPER_DOM_ID); // TODO: change to ref

  const handleColumnResizeStart = useCallback(
    dragEvent => {
      if (isNil(tableWrapperElement) || isNil(resizeHandlerShadowElement)) {
        return;
      }

      const handlerOffsetLeft =
        dragEvent.clientX +
        tableWrapperElement.scrollLeft -
        tableWrapperElement.offsetLeft;

      resizeHandlerShadowElement.style.left = `${handlerOffsetLeft + 4}px`;

      dragEvent.stopPropagation();
      dragEvent.preventDefault();
    },
    [resizeHandlerShadowElement, tableWrapperElement]
  );
  const handleColumnResizeDrag = useCallback(
    (dragEvent, {x, deltaX}) => {
      if (
        isNil(resizeHandlerShadowElement) ||
        isNil(resizeHandlerRef) ||
        isNil(resizeHandlerRef.current)
      ) {
        return;
      }

      const isDragged = Math.abs(x) > 1;

      if (isDragged) {
        resizeHandlerShadowElement.style.display = 'block';
        resizeHandlerRef.current.style.opacity = '0';
      }

      resizeHandlerShadowElement.style.left = `${
        parseInt(resizeHandlerShadowElement.style.left, 10) + deltaX
      }px`;

      dragEvent.stopPropagation();
      dragEvent.preventDefault();
    },
    [resizeHandlerShadowElement, resizeHandlerRef]
  );
  const handleColumnResizeStop = useCallback(
    (_, {x}) => {
      if (
        isNil(resizeHandlerShadowElement) ||
        isNil(resizeHandlerRef) ||
        isNil(resizeHandlerRef.current)
      ) {
        return;
      }

      resizeHandlerShadowElement.style.display = 'none';
      resizeHandlerRef.current.style.opacity = '1';

      const newColumnSize = sanitizedCurrentWidth + Math.floor(x);
      const newSanitizedColumnSize = trimToLimits(newColumnSize, {
        minLimit: minWidth,
        maxLimit: maxWidth,
      });

      onColumnResize(columnKey, newSanitizedColumnSize);
    },
    [
      resizeHandlerRef,
      resizeHandlerShadowElement,
      onColumnResize,
      columnKey,
      sanitizedCurrentWidth,
      minWidth,
      maxWidth,
    ]
  );

  const handleColumnSizeReset = useCallback(
    () => onColumnResize(columnKey, null),
    [columnKey, onColumnResize]
  );

  return (
    <Draggable
      axis={resizeDirectionAxis}
      bounds={resizeBoundaries}
      position={fixedPosition}
      onStart={handleColumnResizeStart}
      onDrag={handleColumnResizeDrag}
      onStop={handleColumnResizeStop}
    >
      <div
        ref={resizeHandlerRef}
        className={classes.resizeHandler}
        onDoubleClick={handleColumnSizeReset}
        title={intl.formatMessage({
          id: 'aa.columnResizer.tooltip',
          defaultMessage: COLUMN_RESIZER_DEFAULT_MESSAGE,
        })}
      />
    </Draggable>
  );
};

export const ColumnResizerShadow = () => {
  const tableWrapperRef = document.getElementById(TABLE_WRAPPER_DOM_ID); // TODO: change to ref

  if (!tableWrapperRef) return null;

  return (
    <div
      id={COLUMN_RESIZER_SHADOW_DOM_ID}
      className={classes.dragShadow}
      style={{height: tableWrapperRef.scrollHeight}}
    />
  );
};

export default ColumnResizer;
