import React, {memo} from 'react';

import {createAttrFormatter} from 'utils/conversions';

import {AttributeValue} from '../../../types';

type Props = {
  attrData: AttributeValue;
};

export const MultipleCell = memo(({attrData}: Props) => {
  const {accuracy, currency, amount_from, amount_to} = attrData?.value || {};
  const formatter = createAttrFormatter({accuracy, currency});
  return (
    <span>
      {formatter.format(amount_from)}&nbsp;— {formatter.format(amount_to)}
    </span>
  );
});
