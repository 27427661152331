import {
  assignAll,
  flow,
  includes,
  keyBy,
  map,
  mapValues,
  pick,
  unset,
  values,
} from 'lodash/fp';

import {OPERATION_FAILURE_STATUSES} from 'constants/globalVariables';
import {renameKey} from 'utils/lodash+';

import type {Dictionary} from 'types/utils';

const requiredPropsFromOps = ['status', 'value', 'message', 'hash'];
const renameValueFieldConditionally = (op: Dictionary<any>) => {
  const keyToRename = op.status === 'pending' ? 'value_from' : 'value_to';
  return renameKey(keyToRename, 'value')(op);
};
const renameHashField = renameKey('attribute_hash', 'hash');
const renameFields = flow(renameValueFieldConditionally, renameHashField);
const removeRelativeOpValue = unset('value.relative_operation');
const getRequiredDataFromOps = map(
  flow(renameFields, pick(requiredPropsFromOps), removeRelativeOpValue)
);
const keyByHash = keyBy('hash');
export const prepareOpsForAttrs = flow(getRequiredDataFromOps, keyByHash);

const getAttrVals = mapValues('attr_values');
const flattenToCollection = flow(values, assignAll, values);
const requiredPropsFromAttrs = ['targeting', 'slug', 'hash'];
const renameSlugField = renameKey('slug', 'attribute_slug');
export const pickAndRenameProps = map(
  flow(pick(requiredPropsFromAttrs), renameSlugField)
);
export const prepareAttrsForOps = flow(
  getAttrVals,
  flattenToCollection,
  pickAndRenameProps
);

export const isOperationFailureStatus = (status?: string) =>
  includes(status, OPERATION_FAILURE_STATUSES);
