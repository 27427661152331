import React, {useEffect} from 'react';

import {css} from '@linaria/core';
import * as Sentry from '@sentry/browser';
import {isEmpty, isObject, isString, upperFirst} from 'lodash/fp';

import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';

const cssMessageWrapper = css`
  color: firebrick;
`;

export const UnsupportedTypeCell = ({attrData}: any) => {
  const {hash, slug, value, showValue} = attrData;
  useEffect(() => {
    if (showValue) {
      return;
    }
    const previousAttribute = window.attrsSnapshot?.[hash];
    const attributeInEnrich =
      window.enrichResponse?.[slug]?.attr_values?.[hash];
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Error);
      scope.setTag('unknown-with-value', 'unknown-with-value');
      scope.setExtra('before update', previousAttribute || '-');
      scope.setExtra('attr from enrich', attributeInEnrich || '-');
      scope.setExtra('merged attr', attrData);
      Sentry.captureMessage('Unsupported type of attribute captured');
    });
  }, [hash, slug, attrData, showValue]);

  if (isEmpty(value)) {
    return (
      <span className={cssMessageWrapper}>
        <FormattedMessage
          id='aa.cell.unsupportedCell.emptyValue'
          defaultMessage='ERR'
        />
      </span>
    );
  }

  if (showValue) {
    if (value.value) {
      return <span>{upperFirst(value.value)}</span>;
    }

    if (isString(value.amount)) {
      return <span>{value.amount}</span>;
    }

    if (isObject(value.amount)) {
      return <span>{value.amount.value}</span>;
    }
  }

  return null;
};
