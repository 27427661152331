import {
  ActionCreatorWithPayload,
  AnyAction,
  createAction,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';
import {flow, includes, map} from 'lodash/fp';

// Multiple action creators to return proper action type
export const createModuleAction = (moduleName: string) => ({
  createActionWithPrepare: <PA extends PrepareAction<any>>(
    actionType: string,
    prepareAction: PA
  ) => createAction<PA>(`${moduleName}/${actionType}`, prepareAction),
  createAction: <P = void>(actionType: string) =>
    createAction<P>(`${moduleName}/${actionType}`),
});

// Custom combineActions (redux-actions alike) to be used
// with '@reduxjs/toolkit' createReducer -> builder.addMatcher
export const combineActions =
  <P>(actionCreators: ActionCreatorWithPayload<P>[]) =>
  (action: AnyAction): action is PayloadAction<P> =>
    flow(map('type'), includes(action.type))(actionCreators);

export function limitPayloadSize(payload = {}, limit = 20000) {
  if (!payload) {
    return null;
  }

  try {
    return JSON.stringify(payload)?.substr(0, limit);
  } catch (e) {
    return null;
  }
}
