import {pickBy, toPairs, forEach} from 'lodash/fp';

import {isPopulated} from 'utils/lodash+';

import {history} from '../browserHistory';
import {CVSchemes} from '../constants';

export const refreshSchemeQuery = (schemeType: CVSchemes, values: any) => {
  const searchParams = new URLSearchParams();
  const populatedValues = pickBy(isPopulated)(values);

  searchParams.append('scheme', schemeType);
  // eslint-disable-next-line lodash-fp/no-for-each
  forEach(
    ([slug, value]) => searchParams.append(slug, value),
    toPairs(populatedValues)
  );

  const {pathname} = history.location;
  history.push(`${pathname}?${searchParams.toString()}`);
};
