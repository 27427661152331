import {
  find,
  flow,
  fromPairs,
  includes,
  initial,
  intersection,
  map,
  max,
  slice,
  union,
  update,
} from 'lodash/fp';
import {v4 as uuidv4} from 'uuid';

import {PATHNAME_REPORT, PATHNAME_BUILDER} from 'constants/globalVariables';
import {cancelOnDispatched, callOnlyWithinPathnames, Fn} from 'sagas/utils';
import {isPopulated} from 'utils/lodash+';

import {reportScreenUnmounted} from '../actions';

import {RowIndexStatus, ReportParams, BackendDimensions} from '../types';
import {RowData, PivotRowData} from 'modules/common/types';
import {BE_types} from 'types/backendServicesTypes';

export const cancelOnReportUnmount = cancelOnDispatched(reportScreenUnmounted);

export const withinReportPathnames = callOnlyWithinPathnames([
  PATHNAME_REPORT,
  `${PATHNAME_REPORT}/`,
  PATHNAME_BUILDER,
]);

export const cancelOnStaleReport = (saga: Fn) =>
  withinReportPathnames(cancelOnReportUnmount(saga));

// Pivot Report
export const craftParamsWithAllDimensionsAndEmptyIndex = update(
  'quick',
  ({index, dimensions, ...otherQuickParams}) => ({
    ...otherQuickParams,
    dimensions: union(dimensions, index),
    index: [],
  })
);

export const enhanceReportSpec = (
  reportSpec: BE_types['SpecResponseModel'],
  {quick: {index}}: ReportParams
) =>
  update(
    'dimensions',
    map((dim: BE_types['SpecResponseModel']['dimensions'][number]) => ({
      ...dim,
      isHidden: includes(dim.slug, index),
    }))
  )(reportSpec);

type IndexProperties = {
  level: number;
  status: RowIndexStatus;
};
const obtainIndexLevelProperties = ({
  quick: {index, dimensions},
}: ReportParams): IndexProperties => {
  const usedIndexDimensionsNum = intersection(index, dimensions).length;
  const currentLevel = max([0, usedIndexDimensionsNum - 1]) as number;
  const maxLevel = index.length - 1;

  const currentInactiveStatus = currentLevel === maxLevel ? 'last' : 'closed';

  return {
    level: currentLevel,
    status: currentInactiveStatus,
  };
};

export const enhanceReportResponseData = (
  data: BE_types['ReportResponseModel'],
  params: ReportParams,
  parentRowData?: RowData
): BE_types['ReportResponseModel'] => {
  const {index} = params.quick;
  const hasIndexParam = isPopulated(index);

  const dataWithHashedRows = update(
    'rows',
    map((row: RowData) => ({
      ...row,
      hash: uuidv4(),
    }))
  )(data);

  if (!hasIndexParam) {
    return dataWithHashedRows;
  }

  const {level: indexLevel, status: indexStatus} =
    obtainIndexLevelProperties(params);

  return update(
    'rows',
    map((row: RowData) => ({
      ...row,
      isLoading: false,
      indexLevel,
      indexStatus,
      // TODO: (pivots second phase) Implement re-opened pivot data from cache with parentHash AND 'isShown [new field]'
      parentHash: parentRowData ? parentRowData.hash : null,
    }))
  )(dataWithHashedRows);
};

const FILTER_SUFFIX = '__in';

export const enhancePivotDrillParams = (
  params: ReportParams,
  backendDimensions: BackendDimensions = [],
  rowData: PivotRowData
) => {
  const {indexLevel} = rowData;
  const {index: indexParam, dimensions: dimensionsParam} = params.quick;

  const indexDimensions = slice(0, indexLevel! + 2, indexParam);
  const newDimensions = union(dimensionsParam)(indexDimensions);

  const usedIndexDimensions = initial(indexDimensions);
  const dimensionFilterSlugs = union(dimensionsParam)(usedIndexDimensions);

  const dimensionFilterParams = flow(
    map((dim: string) => {
      const backendDimension = find(['slug', dim], backendDimensions);
      const {navigation} = backendDimension!;

      const drilldownValue = navigation
        ? rowData.attr_dependency?.[navigation]
        : undefined;
      const fallbackDrilldownValue = rowData[dim];

      const drilldownParam = `${
        drilldownValue ? navigation : dim
      }${FILTER_SUFFIX}`;

      return [drilldownParam, drilldownValue ?? fallbackDrilldownValue];
    }),
    fromPairs
  )(dimensionFilterSlugs as string[]);

  const newParams = {
    ...params,
    quick: {
      ...params.quick,
      ...dimensionFilterParams,
      dimensions: newDimensions,
    },
  };

  return newParams;
};
