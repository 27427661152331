import {fork} from 'redux-saga/effects';

import {
  watchLoadTargetingLabelsFlow,
  loadAuthSaga,
  watchLoadCustomerColumnSizesFlow,
  watchUpdateCustomerColumnSizeFlow,
  watchLoadCustomerConfigFlow,
  watchHandleNavigationSideEffects,
  loadDashboardCustomerData,
  watchLoadAdjustCustomerDataFlow,
} from './common';

export default function* mainCommonSaga() {
  yield fork(watchLoadTargetingLabelsFlow);
  yield fork(watchLoadCustomerColumnSizesFlow);
  yield fork(watchUpdateCustomerColumnSizeFlow);
  yield fork(watchLoadCustomerConfigFlow);
  yield fork(watchHandleNavigationSideEffects);
  yield fork(loadAuthSaga);
  yield fork(loadDashboardCustomerData);
  yield fork(watchLoadAdjustCustomerDataFlow);
}
