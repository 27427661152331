import React from 'react';

import {components} from 'react-select';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';

import {SelectNew} from 'common-components';

// @ts-expect-error
function arrayMove(array, itemIndex, newItemPositionIndex) {
  if (itemIndex === newItemPositionIndex) {
    return array;
  }

  const newArray = array.slice();
  const movedItem = newArray.splice(itemIndex, 1)[0];
  newArray.splice(newItemPositionIndex, 0, movedItem);

  return newArray;
}

const SortableMultiValue = SortableElement((props: any) => {
  const onMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = {onMouseDown};
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(SelectNew);

export const IndexSortableMultiSelect = ({onChange, value, ...props}: any) => {
  const onSortEnd: SortEndHandler = ({oldIndex, newIndex}) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    onChange(newValue);
  };

  return (
    <SortableSelect
      axis='xy'
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({node}: any) => node.getBoundingClientRect()}
      isMulti
      plainValue
      value={value}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
      }}
      closeMenuOnSelect={false}
      {...props}
    />
  );
};
