import React, {useRef, useMemo, useCallback, CSSProperties, FC} from 'react';

import {css} from '@linaria/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssessmentIcon from '@material-ui/icons/Assessment';
import cx from 'classnames';
import {isEmpty, partition, isNil} from 'lodash/fp';
import {SortEndHandler} from 'react-sortable-hoc';

import LoadingIndicator from 'common-components/LoadingIndicator';
import NullState from 'common-components/NullState';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {isPopulated} from 'utils/lodash+';

import {
  MAX_MOBILE_SCREEN_WIDTH,
  MODERNGRID_FIXED_COLUMNS_CONTAINER_TEST_ID,
  MODERNGRID_LOADING_INDICATOR_TEST_ID,
  MODERNGRID_MOVE_COLUMNS_CONTAINER_TEST_ID,
  MODERNGRID_NULLSTATE_WRAPPER_TEST_ID,
  TABLE_WRAPPER_DOM_ID,
} from './constants';
import {
  MovableColumnContext,
  ResizableColumnContext,
  SortableColumnContext,
} from './contexts';
import {useInternalShouldExpandGrid} from './extensions/ExpandableGrid/useExpandableGrid';
import {
  cssFixed,
  cssMove,
  cssTable,
  cssTotalRowEnabled,
} from './ModernGrid.styles';
import {
  arrangeColumns,
  getGridColumnsSizes,
  getSortDirectionPrefix,
  invertSortKey,
  useIsWiderBy,
  createRowKey,
} from './utils';

import {ColumnResizerShadow} from './components/ColumnResizer/ColumnResizer';
import {BodyRow, TotalRow, HeaderRow} from './components/Rows';

import {
  ColumnData,
  ColumnKey,
  ColumnResizeCallback,
  ExtendedColumnData,
  HeaderData,
  RowData,
  SortKey,
} from './types';

const cssLoadingIndicator = css`
  position: fixed;
  left: 50vw;
  transform: translateX(-50%);
  grid-column-end: span 2;
  height: 60px;
`;

const IS_FIXED_COLUMNS_TOO_WIDE_THRESHOLD = 0.6;
const DEFAULT_ROW_HEIGHT = 43;

const cssNullStateWithHeadersWrapper = css`
  position: fixed;
  left: 50vw;
  margin-top: 100px;
  padding-top: 100px;
  padding-left: 0px;
  padding-right: 0px;
  transform: translateX(-50%);
  z-index: 3;
`;
const cssNullStateWithNoHeadersWrapper = css`
  padding: 130px 200px;
`;

const DefaultNullStateComponent: FC = () => (
  <NullState
    icon={<AssessmentIcon />}
    title={
      <FormattedMessage
        id='aa.table.emptyState.noData'
        defaultMessage='No data available'
      />
    }
    subtitle={
      <FormattedMessage
        id='aa.table.emptyState.checkSettings'
        defaultMessage='Try removing or changing some filters'
      />
    }
  />
);

/**
 * ModernGrid props
 *
 * (following params are expected as props)
 * @param className - additional 'className' applied to table container
 * @param columns - array of column definitions
 * @param columnsOrder - record of column keys and their index of appearance (from left to right)
 * @param data - array of data rows that will be rendered in table's body; each row is also passed for cell rendering as rowData; value of cell rendering is obtained by rowData[column.key]
 * @param headerData - additional data for header row; it is passed for headerCell rendering as headerData;
 * @param headerHeight - height of header row in pixels; if not defined, height will be the same as body rows
 * @param isLoading - while true: table will show empty data
 * @param keyProp - key to extract unique row id from data rows; used as data[i][keyProp]
 * @param nullState - component to be rendered if table is in 'null' state (no data && not isLoading)
 * @param onColumnResize - callback that handles column resize
 * @param onMoveColumn - callback that handles column re-order / arrange
 * @param onSortChange - callback that handles new columns sorting
 * @param rowHeight - height of body row in pixels; if not defined, it defaults to 43px
 * @param shouldHideFixedColumns - while true: all columns will be 'movable' on horizontal scroll (x-overflow)
 * @param showHeaderInNullState - while true: header row will be shown on 'null' state (no data && not isLoading)
 * @param sortKey - current table sorting column key; if '-' prefix is added, sorting will have a 'descending' direction, otherwise it will have 'ascending' direction
 * @param style - additional 'style' applied to table container
 * @param totalData - record of column keys and their total value (displayed at the bottom of the table)
 */
export type ModernGridProps = {
  className?: string;
  columns: ColumnData[];
  columnsOrder?: Record<ColumnKey, number>;
  data?: RowData[];
  headerData?: HeaderData;
  headerHeight?: number;
  isLoading?: boolean;
  keyProp?: string;
  nullState?: FC;
  onColumnResize?: ColumnResizeCallback;
  onMoveColumn?: SortEndHandler;
  onSortChange?: (newSortKey: SortKey) => void;
  rowHeight?: number;
  shouldHideFixedColumns?: boolean;
  showHeaderInNullState?: boolean;
  sortKey?: SortKey;
  style?: CSSProperties;
  totalData?: Record<ColumnKey, any>;
};
const ModernGrid = ({
  className = '',
  columns: rawColumnsData,
  columnsOrder = {},
  data: rawBodyData = [],
  headerData: rawHeadersData,
  headerHeight,
  isLoading = false,
  keyProp = '',
  nullState: TableNullState = DefaultNullStateComponent,
  onColumnResize,
  onMoveColumn,
  onSortChange,
  rowHeight = DEFAULT_ROW_HEIGHT,
  shouldHideFixedColumns: shouldHideFixedColumnsProp = false,
  showHeaderInNullState = false,
  sortKey = '',
  style = {},
  totalData = {},
}: ModernGridProps) => {
  // data preparation
  // ========================
  const columnsData: ExtendedColumnData[] = useMemo(() => {
    if (isLoading) {
      return [];
    }

    if (isPopulated(columnsOrder)) {
      return arrangeColumns(rawColumnsData, columnsOrder);
    }

    return rawColumnsData;
  }, [isLoading, columnsOrder, rawColumnsData]);

  const [fixedColumnsData, moveColumnsData] = useMemo(
    () => partition('isFixed', columnsData),
    [columnsData]
  );

  // handlers
  // ========================
  const handleColumnSortChange = useCallback(
    ({key: columnKey, initialSortDirection}) => {
      if (isNil(onSortChange)) {
        return;
      }

      const isSameColumn = sortKey.replace('-', '') === columnKey;
      const newSortKey = isSameColumn
        ? invertSortKey(sortKey)
        : `${getSortDirectionPrefix(initialSortDirection)}${columnKey}`;

      onSortChange(newSortKey);
    },
    [sortKey, onSortChange]
  );

  // render helpers
  // ========================
  const renderBodyRows = useCallback(
    columns => {
      if (!isPopulated(columns)) return null;

      return rawBodyData.map((row: RowData, index: number) => {
        const rowKey = createRowKey(keyProp, row, columns, index);
        return (
          <BodyRow key={rowKey} columns={columns} row={row} rowIndex={index} />
        );
      });
    },
    [rawBodyData, keyProp]
  );

  const tableRef = useRef<HTMLDivElement>(null);
  const fixedRef = useRef<HTMLDivElement>(null);

  const isFixedColumnsTooWide = useIsWiderBy(
    fixedRef,
    tableRef,
    IS_FIXED_COLUMNS_TOO_WIDE_THRESHOLD
  );
  const isMobileScreen = useMediaQuery(
    `(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`
  );
  const shouldHideFixedColumns =
    shouldHideFixedColumnsProp || isMobileScreen || isFixedColumnsTooWide;
  const fixedColumnsContainerClassName = shouldHideFixedColumns
    ? cssMove
    : cssFixed;

  const isNullState = isEmpty(rawBodyData) && isLoading === false;

  const shouldShowTotalRow = isPopulated(totalData) && !isNullState;

  const nullStateWrapperClassName = showHeaderInNullState
    ? cssNullStateWithHeadersWrapper
    : cssNullStateWithNoHeadersWrapper;
  const TableNullStateWithWrapper = useMemo(
    () => (
      <div
        className={nullStateWrapperClassName}
        data-testid={MODERNGRID_NULLSTATE_WRAPPER_TEST_ID}
      >
        <TableNullState />
      </div>
    ),
    [TableNullState, nullStateWrapperClassName]
  );

  const {isExpanded, cssRolledUpWrapper} = useInternalShouldExpandGrid({
    headerHeight: headerHeight || rowHeight,
    rowHeight,
    tableRef,
    shouldShowTotalRow,
  });

  // render
  // ========================
  if (isNullState && !showHeaderInNullState) {
    return TableNullStateWithWrapper;
  }

  return (
    <SortableColumnContext.Provider
      value={{
        sortKey,
        handleColumnSortChange,
      }}
    >
      <MovableColumnContext.Provider value={{onMoveColumn}}>
        <ResizableColumnContext.Provider value={{onColumnResize}}>
          <div
            className={cx({
              [cssRolledUpWrapper]: !isExpanded,
            })}
          >
            <div
              id={TABLE_WRAPPER_DOM_ID}
              className={cx(
                cssTable,
                {
                  [cssTotalRowEnabled]: isPopulated(totalData),
                },
                className
              )}
              style={
                {
                  ...style,
                  '--colNumber': 2,
                  '--rowCount': rawBodyData.length,
                  '--rowHeight': `${rowHeight}px`,
                  '--headerHeight': `${headerHeight ?? rowHeight}px`,
                } as CSSProperties
              }
              ref={tableRef}
            >
              <ColumnResizerShadow />
              <div
                data-testid={MODERNGRID_FIXED_COLUMNS_CONTAINER_TEST_ID}
                data-pendoid={MODERNGRID_FIXED_COLUMNS_CONTAINER_TEST_ID}
                className={fixedColumnsContainerClassName}
                style={
                  {
                    '--colNumber': fixedColumnsData.length,
                    '--gridTemplateColumns':
                      getGridColumnsSizes(fixedColumnsData),
                  } as CSSProperties
                }
                ref={fixedRef}
              >
                <HeaderRow
                  columns={fixedColumnsData}
                  headerData={rawHeadersData}
                />
                {renderBodyRows(fixedColumnsData)}
                {shouldShowTotalRow && (
                  <TotalRow columns={fixedColumnsData} row={totalData} />
                )}
              </div>

              <div
                data-testid={MODERNGRID_MOVE_COLUMNS_CONTAINER_TEST_ID}
                className={cssMove}
                style={
                  {
                    '--colNumber': moveColumnsData.length,
                    '--gridTemplateColumns':
                      getGridColumnsSizes(moveColumnsData),
                  } as CSSProperties
                }
              >
                <HeaderRow
                  columns={moveColumnsData}
                  headerData={rawHeadersData}
                />
                {renderBodyRows(moveColumnsData)}
                {shouldShowTotalRow && (
                  <TotalRow columns={moveColumnsData} row={totalData} />
                )}
              </div>

              {isNullState && TableNullStateWithWrapper}

              {isLoading && (
                <div data-testid={MODERNGRID_LOADING_INDICATOR_TEST_ID}>
                  <LoadingIndicator
                    verticallyCentered
                    className={cssLoadingIndicator}
                  />
                </div>
              )}
            </div>
          </div>
        </ResizableColumnContext.Provider>
      </MovableColumnContext.Provider>
    </SortableColumnContext.Provider>
  );
};

export default React.memo(ModernGrid);
