import React from 'react';

import {css} from '@linaria/core';
import {startCase, isNil} from 'lodash/fp';
import moment from 'moment';

import {OneLine} from 'common-components/OneLine/OneLine';
import {useIntl} from 'common-components/utils/libs/ReactIntl';

import {CellStatus} from 'modules/common/components';

const cssWrapper = css`
  display: flex;
  align-items: center;
`;

type Props = {
  value?: string;
  rowData: {
    status_applied_at: string;
    status_description: string;
    approved_by: string;
  };
};
export const StatusColumn = ({
  value,
  rowData: {status_applied_at = '', status_description, approved_by},
}: Props) => {
  const intl = useIntl();

  if (isNil(value)) {
    return <>—</>;
  }

  const approved = !isNil(approved_by)
    ? intl.formatMessage(
        {
          id: 'aa.approvedBy.name',
          defaultMessage: 'Approved by {name}',
        },
        {
          name: approved_by,
        }
      )
    : '';

  const statusDescription = status_description ?? startCase(value);
  const appliedAt = moment.utc(status_applied_at).format('YYYY-MM-DD HH:mm');
  const atLabel = intl.formatMessage({
    id: 'aa.label.atTime',
    defaultMessage: 'at',
  });
  const tooltipText = `${statusDescription}\n${atLabel} ${appliedAt} UTC\n${approved}`;

  return (
    <div className={cssWrapper}>
      <CellStatus statusSlug={value} tooltipText={tooltipText} />

      <OneLine>{startCase(value)}</OneLine>
    </div>
  );
};
