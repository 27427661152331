import React from 'react';

import {css} from '@linaria/core';
import CheckIcon from '@material-ui/icons/Check';

import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';

const cssIcon = css`
  max-height: 24px;
  max-width: 24px;
  margin-right: 6px;
`;

const cssInformationalToast = css`
  align-items: center;
  background-color: ${styleVariables.colorAdjustBlue};
  color: white;
  display: flex;
  padding: 10px 15px;
  font-weight: bold;
`;

type Props = {
  messageKey: string;
};
export const InformationalToast = ({messageKey}: Props) => (
  <div className={cssInformationalToast}>
    <CheckIcon className={cssIcon} />
    <FormattedMessage id={messageKey} />
  </div>
);
