import * as Sentry from '@sentry/browser';
import axios, {AxiosRequestConfig, AxiosResponse, Cancel} from 'axios';
import {
  camelCase,
  get,
  has,
  identity,
  isString,
  mapKeys,
  startCase,
  truncate,
} from 'lodash/fp';

import {API_HOST, ACCOUNT_API_URL} from 'config';
import {
  PATHNAME_BUILDER,
  PATHNAME_HISTORY,
  SENTRY_CATEGORY_TAGS,
} from 'constants/globalVariables';
import {showInformationDialog} from 'modules/common/actions';
import {selectCustomerConfig} from 'modules/common/reducers/customerConfig';
import {isDemo} from 'modules/report/utils';
import {getStore, dispatchAction} from 'store';

import {safeJsonParse} from './jsonUtils';

function transformResponse(data: any) {
  if (!isString(data) || data.length === 0) return undefined;

  return safeJsonParse(data, {
    onError: e => console.error('Invalid JSON from BE:', e.message),
    onErrorReturn: identity,
  });
}

export const request = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  transformResponse: [transformResponse],
});

export const dashboardRequest = axios.create({
  baseURL: ACCOUNT_API_URL,
  withCredentials: true,
  transformResponse: [transformResponse],
});

const extractErrorHeaderMessage = (error: any) => {
  const errorCode = error?.response?.data?.error_code;
  return errorCode ? startCase(errorCode) : 'A server error has occurred';
};

const extractErrorBodyMessage = (error: any, shouldShowDetails = false) => {
  const errorDesc = error?.response?.data?.error_desc || 'Unknown error';
  const status = error?.response?.status;
  const {url} = error.config;
  const requestData =
    error.config?.data || JSON.stringify(error.config?.params);
  return shouldShowDetails
    ? `${errorDesc}\n\nHTTP code: ${status}, URL: ${url}\n\nRequest data: ${requestData}`
    : errorDesc;
};

function showErrorDialog(error: any) {
  const state = getStore()?.getState();
  const status = error?.response?.status;
  const data = error?.response?.data;

  const isSuperAdmin = selectCustomerConfig(state)?.adjust_superadmin;
  const shouldShowDetails =
    process.env.RELEASE_ENV !== 'production' || isSuperAdmin;

  const header = extractErrorHeaderMessage(error);
  const body = extractErrorBodyMessage(error, shouldShowDetails);

  if (isDemo() && status > 400) {
    console.error(header, data);
    return;
  }

  dispatchAction(
    showInformationDialog({
      header,
      body,
      isPreformatted: true,
      isWhiteDialog: true,
    })
  );
}

export const ERROR_STATUS_REPORTING_THRESHOLD = 501;
function sendNetworkErrorToSentry(error: any) {
  // skip logging for known warnings
  const errorCode = error.response?.data?.error_code;
  if (['validation_error', 'auth_error'].includes(errorCode)) return;

  const errorHeader = get('message', error) || get('statusText', error);
  const errorData = {
    ...(has('config.url', error) ? {url: error.config.url} : null),
    ...(has('config.method', error) ? {method: error.config.method} : null),
    ...(has('response.status', error) ? {status: error.response.status} : null),
    ...(has('config.data', error) ? {safe_request: error.config.data} : null),
    ...(has('response.data', error)
      ? {safe_response: truncate({length: 300}, error.response.data)}
      : null),
  };
  Sentry.withScope(scope => {
    scope.setExtra('error', errorData);
    scope.setTag('category', SENTRY_CATEGORY_TAGS.NETWORK);
    Sentry.captureMessage(errorHeader);
  });
}

export class NoConnectionError extends Error {}
export interface NetworkError extends Error {
  response: {status: number};
}
const offlineMessages = {
  NO_INTERNET_CONNECTION: 'No internet connection',
  MAINTENANCE: 'Maintenance',
};
request.interceptors.response.use(
  response => {
    if (response.status === 200 && get('data.error', response)) {
      return Promise.reject(response);
    }

    return response;
  },
  error => {
    // @ts-expect-error 'Cancel' only refers to a type, but is being used ... Remove this comment to see the full error message
    if (error instanceof Cancel) {
      return undefined;
    }

    // suppress /stats errors: https://app.asana.com/0/1199200026345179/1200004656446171/f
    if (error.config.url === 'operations-service/stats') {
      return undefined;
    }

    const status = error?.response?.status;

    if (!status) {
      dispatchAction(
        showInformationDialog({
          header: offlineMessages.NO_INTERNET_CONNECTION,
          body: '',
        })
      );
      return Promise.reject(new NoConnectionError(error));
    }

    if (status === 503) {
      if (isDemo()) {
        console.error(503, offlineMessages.MAINTENANCE);
      } else {
        dispatchAction(
          showInformationDialog({
            header: 'aa.popup.error.maintenance.header',
            body: 'aa.popup.error.maintenance.body',
            isWhiteDialog: true,
          })
        );
      }
    } else if ([401, 403].includes(status)) {
      dispatchAction(
        showInformationDialog({
          header: 'aa.popup.error.noAccess.header',
          body: error.response.data.error_desc,
          isPreformatted: true,
          confirmText: 'aa.popup.error.noAccess.confirm',
          closeCallback: () => {
            window.location.href = '/';
          },
          confirmCallback: () => {
            window.location.href = '/';
          },
        })
      );
    } else if (status === 504) {
      if (
        error.config.url === 'reports-service/report' &&
        [PATHNAME_BUILDER, PATHNAME_HISTORY].includes(window.location.pathname)
      ) {
        // swallow error popup
        return undefined;
      }

      showErrorDialog(error);
    } else if (status >= 400) {
      showErrorDialog(error);
    }

    if (status >= ERROR_STATUS_REPORTING_THRESHOLD) {
      sendNetworkErrorToSentry(error);
    }

    return Promise.reject(error);
  }
);

request.interceptors.response.use(response =>
  // @ts-expect-error Property 'convertToCamelCase' does not exist on ty... Remove this comment to see the full error message
  response?.config?.convertToCamelCase
    ? {
        ...response,
        data: mapKeys(camelCase, (response as any).data),
      }
    : response
);

type TransformableResponseType = AxiosResponse & {
  config?: AxiosRequestConfig & {
    rawData?: boolean;
  };
};
const transformResponseData = (response: TransformableResponseType) =>
  response?.config?.rawData ? response : response?.data;

request.interceptors.response.use(transformResponseData);

dashboardRequest.interceptors.response.use(
  transformResponseData,
  console.error
);

export const copyAxiosInstance = (instance: any) => {
  const newInstance = axios.create(instance.defaults);
  // eslint-disable-next-line lodash-fp/no-for-each
  instance.interceptors.request.forEach(({fulfilled, rejected}: any) => {
    newInstance.interceptors.request.use(fulfilled, rejected);
  });
  // eslint-disable-next-line lodash-fp/no-for-each
  instance.interceptors.response.forEach(({fulfilled, rejected}: any) => {
    newInstance.interceptors.response.use(fulfilled, rejected);
  });

  return newInstance;
};
