import {flow, curry, find, constant, includes, mapKeys} from 'lodash/fp';
import {ParsedQuery as QueryObject} from 'query-string';

import {safeJsonParse} from 'utils/jsonUtils';
import {uncappedMapValues, dropEmptyParams} from 'utils/lodash+';

import {isDimensionFilter, isMetricFilter} from '../postfix';

import {Filters} from 'modules/common/types';
import {Dictionary} from 'types/utils';

export const ADDITIONAL_QUICK_FILTER_KEYS = ['metrics'];

const parseDimensionFilterValue = (value: string) =>
  safeJsonParse(`[${value}]`, {
    shouldCaptureSentryException: true,
    onErrorReturn: constant([value]),
  });

const getComponentType = (key: string, filters: Filters) => {
  const {component} =
    find(({filter_param}) => filter_param === key, filters) || {};

  return component;
};
const parseQueryValue = (filters: Filters) => (value: string, key: string) => {
  if (isDimensionFilter(key)) {
    return parseDimensionFilterValue(value);
  }

  const component = getComponentType(key, filters);

  if (
    isMetricFilter(key) ||
    includes(key, ADDITIONAL_QUICK_FILTER_KEYS) ||
    component === 'multiselect'
  ) {
    return value.split(',');
  }

  return value;
};

const parseQueryValues = (filters: Filters) =>
  uncappedMapValues(parseQueryValue(filters));

const oldToNewKeyMapping: Dictionary<string> = {
  cost_mode: 'ad_spend_mode',
  ad_revenue_mode: 'iap_revenue_mode',
};

const parseQuery = curry((allFilters: Filters, query: QueryObject<string>) =>
  flow(
    dropEmptyParams,
    parseQueryValues(allFilters),
    mapKeys((key: string) => oldToNewKeyMapping[key] || key)
  )(query)
);

export default parseQuery;
