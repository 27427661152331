import React, {useContext} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import cx from 'classnames';

import {WARNING_COLOR, PREVIEW_TEST_ID, Labels} from '../constants';
import {SchemeContext} from '../SchemeContext';
import {download} from '../utils';

const cssContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 24px;
`;
const cssContainerError = css`
  border-color: ${WARNING_COLOR};
`;
const cssPreview = css`
  width: 100%;
  overflow-y: scroll;
`;
const cssButtonContainer = css`
  display: flex;
`;
const cssError = css`
  font-size: 18px;
  color: ${WARNING_COLOR};
  align-self: center;
`;
const Button = styled.button`
  margin: 16px;
  padding: 8px;
  background-color: #00bed5;
  border-radius: 2px;
  color: white;
`;

type JSONPreviewProps = {
  data: any;
  isValid: boolean;
};
export const JSONPreview = ({data, isValid}: JSONPreviewProps) => {
  const {scheme: schemeType} = useContext(SchemeContext);
  const content = JSON.stringify(data, null, 2);

  return (
    <div
      className={cx(cssContainer, {[cssContainerError]: !isValid})}
      data-testid={PREVIEW_TEST_ID}
    >
      {isValid ? (
        <>
          <pre className={cssPreview}>{content}</pre>

          <div className={cssButtonContainer}>
            <Button type='button' onClick={() => download(data, schemeType)}>
              Download
            </Button>
            <Button
              type='button'
              onClick={() => navigator.clipboard.writeText(content)}
            >
              Copy
            </Button>
          </div>
        </>
      ) : (
        <span className={cssError}>{Labels.PreviewErrorMessage}</span>
      )}
    </div>
  );
};
