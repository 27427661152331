import {
  flow,
  intersection,
  isEmpty,
  map,
  merge,
  noop,
  keys,
  partition,
  get,
  pick,
  pickBy,
  values,
  negate,
  uniq,
  mapValues,
  differenceWith,
} from 'lodash/fp';
import {
  cancel,
  delay,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {history} from 'browserHistory';
import {OPERATION_FAILURE_STATUSES} from 'constants/globalVariables';
import {
  loadOpsStatsRequest,
  loadTargetingLabelsRequest,
} from 'modules/common/actions';
import {
  createChangeGroup,
  fetchEnrichAttributes,
  fetchOperations,
} from 'modules/common/api';
import {
  loadCreateChangeGroupApprove,
  loadCreateChangeGroupFailure,
  loadCreateChangeGroupRequest,
  loadCreateChangeGroupSuccess,
  loadReportAttrsFailure,
  loadReportAttrsRequest,
  loadReportAttrsSuccess,
  loadOpsForReportAttrsFailure,
  loadOpsForReportAttrsSuccess,
  reloadAttrsSuccess,
  loadEnrichOperationsRequest,
  loadEnrichOperationsFailure,
  reloadOpsFailure,
  reloadAttrsFailure,
  loadCreateChangeGroupRevertFailedUpdates,
  loadMoreReportAttrsRequest,
  loadMoreReportAttrsSuccess,
  loadMoreReportAttrsFailure,
} from 'modules/report/actions';
import {
  selectAttrData,
  selectReportData,
  selectQuickParams,
} from 'modules/report/reducers';
import {
  selectAsData,
  selectIsDebugMode,
} from 'modules/report/reducers/debugInfo';
import {approveAttrChanges} from 'modules/report/sagas/report';
import {safe} from 'sagas/utils';
import {
  reshapeRowsToEnrich,
  flattenAttributeResponse,
  validateASResponse,
  reshapeAttributesForOps,
  convertAttrUpdateToChange,
} from 'utils/attributes';
import {call, select, fork} from 'utils/libs/TypedReduxSaga';
import {flippedIncludes, isPopulated} from 'utils/lodash+';
import {prepareAttrsForOps, prepareOpsForAttrs} from 'utils/operations';

import {cancelOnStaleReport} from './utils';

import type {
  AttrForOps,
  AttributesResponse,
  RowData,
} from 'modules/common/types';
import type {AttrUpdate, Attributes} from 'modules/report/types';
import {BE_types} from 'types/backendServicesTypes';

export type LoadReportAttrsFlowParams = {
  payload: {
    reportData: {rows: (RowData & {attr_dependency: RowData})[]};
    attributes: BE_types['AttributeSlug'][];
  };
};
export function* loadReportAttrsFlow({
  payload: {
    reportData: {rows: reportRows},
    attributes,
  },
}: LoadReportAttrsFlowParams) {
  try {
    let attrData;
    const debug = yield* select(selectIsDebugMode);
    const asData = yield* select(selectAsData);

    if (debug && isPopulated(asData)) {
      attrData = asData;
    } else {
      const rows = reshapeRowsToEnrich(reportRows, 'attr_dependency'); // TODO: add 'hash' as third param and resolve issues / errors
      if (isPopulated(rows)) {
        attrData = yield* call(fetchEnrichAttributes, {
          reqBody: {rows, attributes},
        });
      }
      if (attrData) {
        yield put(loadTargetingLabelsRequest(attrData));
        validateASResponse(rows, attrData);
      }
    }

    if (attrData) {
      yield put(loadReportAttrsSuccess(attrData));
    }
  } catch (error) {
    console.error(error);
    yield put(loadReportAttrsFailure(error));
  }
}

export function* watchLoadReportAttrsFlow() {
  yield takeLatest(loadReportAttrsRequest, safe(loadReportAttrsFlow));
}

// TODO: try to merge this saga with loadReportAttrsFlow
export function* loadMoreReportAttrsFlow({
  payload: {
    reportData: {rows: reportRows},
    attributes,
  },
}: LoadReportAttrsFlowParams) {
  try {
    const rows = reshapeRowsToEnrich(reportRows, 'attr_dependency', 'hash');

    if (isEmpty(rows)) {
      return;
    }

    const attrData = yield* call(fetchEnrichAttributes, {
      reqBody: {rows, attributes},
    });

    if (isEmpty(attrData)) {
      return;
    }

    yield put(loadTargetingLabelsRequest(attrData));

    validateASResponse(rows, attrData);

    yield put(loadMoreReportAttrsSuccess(attrData));
  } catch (error) {
    console.error(error);
    yield put(loadMoreReportAttrsFailure(error));
  }
}

export function* watchLoadMoreReportAttrsFlow() {
  yield takeEvery(loadMoreReportAttrsRequest, safe(loadMoreReportAttrsFlow));
}

export function* loadOpsForReportAttrsFlow({
  payload: attributes,
}: {
  payload: AttributesResponse;
}) {
  try {
    const reshapedAttributes: AttrForOps[] = prepareAttrsForOps(attributes);
    const {rows: operations} = yield* call(fetchOperations, reshapedAttributes);
    if (isPopulated(operations)) {
      yield put(loadEnrichOperationsRequest(operations));
    }
  } catch (error) {
    console.error(error);
    yield put(loadOpsForReportAttrsFailure(error));
  }
}
export function* watchLoadOpsForReportAttrsFlow() {
  yield takeEvery(
    [loadReportAttrsSuccess, loadMoreReportAttrsSuccess],
    safe(loadOpsForReportAttrsFlow)
  );
}

export function* loadCreateChangeGroupFlow({
  payload: {attrUpdates, shouldApprove = false, onChangeGroupCreated = noop},
}: {
  payload: {
    attrUpdates: AttrUpdate[];
    shouldApprove: boolean;
    onChangeGroupCreated?: () => void;
  };
}) {
  try {
    if (isEmpty(attrUpdates)) {
      yield put(loadCreateChangeGroupFailure(new Error()));
      return;
    }
    const attrData = yield* select(selectAttrData);
    const changes = convertAttrUpdateToChange(attrUpdates);
    const isChangesApproved = yield* call(approveAttrChanges, changes);
    if (!isChangesApproved) {
      yield put(loadCreateChangeGroupFailure(new Error()));
      return;
    }
    yield put(loadCreateChangeGroupApprove({attrUpdates}));
    const {rows: createdOperations} = yield* call(createChangeGroup, {
      attr_changes: changes,
      approve: shouldApprove,
      report_url: history.location.search,
      ignore_same_values: true,
    });
    const failedOperations = differenceWith(
      ({hash}, {attribute_hash}) => hash === attribute_hash,
      attrUpdates,
      createdOperations
    );
    if (isPopulated(failedOperations)) {
      // TODO: Display warnings from BE

      // Revert values and status
      const oldAttributeValues = pick(map('hash', failedOperations), attrData);
      yield put(
        loadCreateChangeGroupRevertFailedUpdates({
          oldAttributeValues,
        })
      );
    }
    yield put(loadCreateChangeGroupSuccess(createdOperations));
    onChangeGroupCreated();
    if (!shouldApprove) {
      yield put(loadOpsStatsRequest());
    }
  } catch (error) {
    console.error(error);
    yield put(loadCreateChangeGroupFailure(error));
  }
}

export function* watchLoadCreateChangeGroupFlow() {
  yield takeEvery(
    loadCreateChangeGroupRequest,
    safe(loadCreateChangeGroupFlow)
  );
}

export function* reloadOpsFlow() {
  while (true) {
    try {
      const REPOLL_TIME = 4000;
      yield delay(REPOLL_TIME);
      const attrs: Attributes = yield* select(selectAttrData);
      if (isEmpty(attrs)) {
        return;
      }
      const reshapedAttributes: AttrForOps[] = reshapeAttributesForOps(attrs);
      if (isPopulated(reshapedAttributes)) {
        const {rows: operations} = yield* call(
          fetchOperations,
          reshapedAttributes
        );
        if (isPopulated(operations)) {
          yield put(loadEnrichOperationsRequest(operations));
        }
      }
    } catch (e) {
      console.error(e);
      reloadOpsFailure(e);
    }
  }
}

export function* pollQueuedOperationsFlow() {
  const pollTask = yield* fork(cancelOnStaleReport(reloadOpsFlow));

  yield take(loadReportAttrsRequest);
  yield cancel(pollTask);
}

export function* watchPollQueuedOperationsFlow() {
  yield takeEvery(loadReportAttrsSuccess, safe(pollQueuedOperationsFlow));
}

export function* loadEnrichOperationsFlow({
  payload: operations,
}: {
  payload: any[];
}) {
  try {
    const statusesToEnrich = ['success', ...OPERATION_FAILURE_STATUSES];
    const shouldBeEnriched = flow(
      get('status'),
      flippedIncludes(statusesToEnrich)
    );
    const [notReadyOps, readyOps] = partition(shouldBeEnriched)(operations);

    const attributesSlugs = flow(map('attribute_slug'), uniq)(notReadyOps);

    // dispatching if there are no operations to enrich
    if (isEmpty(notReadyOps)) {
      yield put(loadOpsForReportAttrsSuccess(prepareOpsForAttrs(readyOps)));
      return;
    }

    const notReadyHashes = map('attribute_hash')(notReadyOps);
    const rowsQuery = reshapeRowsToEnrich(notReadyOps, 'targeting');

    const rawAttrs = yield* call(fetchEnrichAttributes, {
      reqBody: {rows: rowsQuery, attributes: attributesSlugs},
    });
    const allAttributes = flattenAttributeResponse(rawAttrs);
    const attributes = pick(notReadyHashes)(allAttributes);
    const reshapedOps = prepareOpsForAttrs([...readyOps, ...notReadyOps]);
    yield put(loadOpsForReportAttrsSuccess(merge(reshapedOps, attributes)));
  } catch (e) {
    console.error(e);
    yield put(loadEnrichOperationsFailure(e));
  }
}
export function* watchLoadEnrichOperationsFlow() {
  yield takeEvery(loadEnrichOperationsRequest, safe(loadEnrichOperationsFlow));
}

export function* reloadAttrsFlow() {
  while (true) {
    try {
      const REPOLL_TIME = 5000;
      yield delay(REPOLL_TIME);

      const attrs: Attributes = yield* select(selectAttrData);
      const {attributes} = yield* select(selectQuickParams);
      const rows = yield* select(selectReportData);
      if (isEmpty(attrs) || isEmpty(rows)) {
        return;
      }
      const keyedDeps = reshapeRowsToEnrich(rows!);
      const hashesToReload = flow(pickBy('is_loading'), keys)(attrs);
      const hasHashes = (hashes: string[]) =>
        flow(values, intersection(hashes), negate(isEmpty));
      const rowsToReload = pickBy(hasHashes(hashesToReload))(keyedDeps);
      const rowsQuery = mapValues('attr_dependency', rowsToReload);
      if (isPopulated(rowsToReload)) {
        const attrData = yield* call(fetchEnrichAttributes, {
          reqBody: {
            rows: rowsQuery,
            attributes,
          },
        });
        if (attrData) {
          validateASResponse(rowsQuery, attrData);
          // log attributes state before merging with response
          window.attrsSnapshot = attrs;
          window.enrichResponse = attrData;
          yield put(reloadAttrsSuccess(attrData));
        }
      }
    } catch (e) {
      console.error(e);
      yield put(reloadAttrsFailure(e));
    }
  }
}

export function* pollLoadingAttributesFlow() {
  const pollTask = yield* fork(cancelOnStaleReport(reloadAttrsFlow));

  yield take(loadReportAttrsRequest);
  yield cancel(pollTask);
}

export function* watchPollLoadingAttributesFlow() {
  yield takeEvery(loadReportAttrsSuccess, safe(pollLoadingAttributesFlow));
}
