import {curry, includes} from 'lodash/fp';

export const DIVIDER = '__';
const COLUMN = 'column';
const BUILDER = 'builder';
const COLUMN_POSTFIX = `${DIVIDER}${COLUMN}`;
const BUILDER_POSTFIX = `${DIVIDER}${BUILDER}`;

const metricFiltersKinds = ['gt', 'gte', 'eq', 'lt', 'lte'];
const dimensionFiltersKinds = ['contains', 'in', 'exclude'];

export const isDimensionFilter = (key: string) =>
  dimensionFiltersKinds.some(kind => key.includes(`${DIVIDER}${kind}`));
export const isMetricFilter = (key: string) =>
  metricFiltersKinds.some(kind => key.includes(`${DIVIDER}${kind}`));

const trimPostfix = curry((postfix: string, str: string) =>
  str.replace(postfix, '')
);

export const trimColumnPostfix = trimPostfix(COLUMN_POSTFIX);
export const trimBuilderPostfix = trimPostfix(BUILDER_POSTFIX);

export const isColumnParam = (_: any, key: string) =>
  includes(COLUMN_POSTFIX, key);
export const isBuilderParam = (_: any, key: string) =>
  includes(BUILDER_POSTFIX, key);

export const addColumnPostfix = (str: string) => `${str}${COLUMN_POSTFIX}`;
export const addBuilderPostfix = (str: string) => `${str}${BUILDER_POSTFIX}`;
