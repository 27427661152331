import {createModuleAction} from 'utils/reduxUtils';

import {AttributeValue} from 'modules/common/types';
import {BE_types} from 'types/backendServicesTypes';

const {createAction} = createModuleAction('REPORT');

export const initReportRequest = createAction('INIT_REPORT_REQUEST');
export const initReportSuccess = createAction('INIT_REPORT_SUCCESS');
export const initReportFailure = createAction<any>('INIT_REPORT_FAILURE');

export const applyReportParams = createAction<any>('APPLY_REPORT_PARAMS');
export const updateReportParams = createAction<any>('UPDATE_REPORT_PARAMS');
export const setReportParams = createAction<any>('SET_REPORT_PARAMS');
export const removeParentReportId = createAction('REMOVE_PARENT_REPORT_ID');
export const updateParentReportId = createAction<any>(
  'UPDATE_PARENT_REPORT_ID'
);

export const loadReportDataRequest = createAction('LOAD_REPORT_DATA_REQUEST');
export const loadReportDataSuccess = createAction<any>(
  'LOAD_REPORT_DATA_SUCCESS'
);
export const loadReportDataFailure = createAction<any>(
  'LOAD_REPORT_DATA_FAILURE'
);

export const loadReportAttrsRequest = createAction<any>(
  'LOAD_REPORT_ATTRS_REQUEST'
);
export const loadReportAttrsSuccess = createAction<any>(
  'LOAD_REPORT_ATTRS_SUCCESS'
);
export const loadReportAttrsFailure = createAction<any>(
  'LOAD_REPORT_ATTRS_FAILURE'
);

export const loadMoreReportAttrsRequest = createAction<any>(
  'LOAD_MORE_REPORT_ATTRS_REQUEST'
);
export const loadMoreReportAttrsSuccess = createAction<any>(
  'LOAD_MORE_REPORT_ATTRS_SUCCESS'
);
export const loadMoreReportAttrsFailure = createAction<any>(
  'LOAD_MORE_REPORT_ATTRS_FAILURE'
);

export const reloadAttrsSuccess = createAction<any>('RELOAD_ATTRS_SUCCESS');
export const reloadAttrsFailure = createAction<any>('RELOAD_ATTRS_FAILURE');

export const reloadOpsFailure = createAction<any>('RELOAD_OPS_FAILURE');

// @deprecated
export const loadOpsForReportAttrsRequest = createAction<any>(
  'LOAD_OPS_FOR_REPORT_ATTRS_REQUEST'
);
export const loadOpsForReportAttrsSuccess = createAction<any>(
  'LOAD_OPS_FOR_REPORT_ATTRS_SUCCESS'
);
export const loadOpsForReportAttrsFailure = createAction<any>(
  'LOAD_OPS_FOR_REPORT_ATTRS_FAILURE'
);

export const loadEnrichOperationsRequest = createAction<any>(
  'LOAD_ENRICH_OPERATIONS_REQUEST'
);
export const loadEnrichOperationsFailure = createAction<any>(
  'LOAD_ENRICH_OPERATIONS_FAILURE'
);

export const loadCreateChangeGroupRequest = createAction<any>(
  'LOAD_CREATE_CHANGE_GROUP_REQUEST'
);
export const loadCreateChangeGroupApprove = createAction<any>(
  'LOAD_CREATE_CHANGE_GROUP_APPROVE'
);
type LoadCreateChangeGroupRevertFailedUpdates = {
  oldAttributeValues: {[hash: string]: AttributeValue};
};
export const loadCreateChangeGroupRevertFailedUpdates =
  createAction<LoadCreateChangeGroupRevertFailedUpdates>(
    'LOAD_CREATE_CHANGE_GROUP_REVERT_FAILED_UPDATES'
  );
export const loadCreateChangeGroupSuccess = createAction<any>(
  'LOAD_CREATE_CHANGE_GROUP_SUCCESS'
);
export const loadCreateChangeGroupFailure = createAction<any>(
  'LOAD_CREATE_CHANGE_GROUP_FAILURE'
);

export const setReportDebugMode = createAction<any>('SET_REPORT_DEBUG_MODE');
export const setReportDebugData = createAction<any>('SET_REPORT_DEBUG_DATA');

export const navigateToReport = createAction<any>('NAVIGATE_TO_REPORT');
export const loadSaveReportAsNewRequest = createAction<any>(
  'LOAD_SAVE_REPORT_AS_NEW_REQUEST'
);
export const loadSaveReportAsDefaultRequest = createAction<any>(
  'LOAD_SAVE_REPORT_AS_DEFAULT_REQUEST'
);
export const loadSaveReportAsDefaultSuccess = createAction(
  'LOAD_SAVE_REPORT_AS_DEFAULT_SUCCESS'
);
export const loadDuplicateReportRequest = createAction<any>(
  'LOAD_DUPLICATE_REPORT_REQUEST'
);
export const loadCreateReportRequest = createAction<any>(
  'LOAD_CREATE_REPORT_REQUEST'
);
export const loadCreateReportSuccess = createAction<any>(
  'LOAD_CREATE_REPORT_SUCCESS'
);
export const loadUpdateReportRequest = createAction<any>(
  'LOAD_UPDATE_REPORT_REQUEST'
);
export const loadUpdateReportSuccess = createAction<any>(
  'LOAD_UPDATE_REPORT_SUCCESS'
);
export const loadDeleteReportRequest = createAction<any>(
  'LOAD_DELETE_REPORT_REQUEST'
);
export const loadRestoreDeletedReportRequest = createAction<any>(
  'LOAD_RESTORE_DELETED_REPORT_REQUEST'
);
export const removeDeletedReports = createAction('REMOVE_DELETED_VIEWS');

export const loadCreateRuleRequest = createAction<any>(
  'LOAD_CREATE_RULE_REQUEST'
);
// @deprecated
export const loadCreateRuleSuccess = createAction<any>(
  'LOAD_CREATE_RULE_SUCCESS'
);
export const loadUpdateRuleRequest = createAction<any>(
  'LOAD_UPDATE_RULE_REQUEST'
);
// @deprecated
export const loadUpdateRuleSuccess = createAction<any>(
  'LOAD_UPDATE_RULE_SUCCESS'
);
export const loadToggleRuleStateRequest = createAction(
  'LOAD_TOGGLE_RULE_STATE_REQUEST'
);
// @deprecated
export const loadToggleRuleStateSuccess = createAction<any>(
  'LOAD_TOGGLE_RULE_STATE_SUCCESS'
);
export const loadDeleteRuleRequest = createAction('LOAD_DELETE_RULE_REQUEST');
// @deprecated
export const loadDeleteRuleSuccess = createAction<any>(
  'LOAD_DELETE_RULE_SUCCESS'
);
export const loadUpdateEmailAlertsRequest = createAction<any>(
  'LOAD_UPDATE_EMAIL_ALERTS_REQUEST'
);

export const loadReportSpecRequest = createAction('LOAD_REPORT_SPEC_REQUEST');
export const loadReportSpecSuccess = createAction<any>(
  'LOAD_REPORT_SPEC_SUCCESS'
);
// @deprecated
export const openAutomationPanel = createAction<any>('OPEN_AUTOMATION_PANEL');
export const closeAutomationPanel = createAction('CLOSE_AUTOMATION_PANEL');
export const toggleAutomationPanel = createAction('TOGGLE_AUTOMATION_PANEL');

export const loadReportFiltersRequest = createAction(
  'LOAD_REPORT_FILTERS_REQUEST'
);
export const loadReportFiltersSuccess = createAction(
  'LOAD_REPORT_FILTERS_SUCCESS'
);
export const setFilters = createAction<any>('SET_FILTERS');
export const setFiltersOptions = createAction<any>('SET_FILTERS_OPTIONS');

export const loadUpdateReportUrlRequest = createAction<
  {isSameEntry?: boolean} | undefined
>('LOAD_UPDATE_REPORT_URL_REQUEST');

export const updateReportUrlQuerySuccess = createAction(
  'UPDATE_REPORT_URL_QUERY_SUCCESS'
);

export const reportScreenUnmounted = createAction('REPORT_SCREEN_UNMOUNTED');

export const loadAddPivotReportDataRequest = createAction<string>(
  'LOAD_ADD_PIVOT_REPORT_DATA_REQUEST'
);
export const loadAddPivotReportDataSuccess = createAction<{
  hash: string;
  newPivotData: BE_types['ReportResponseModel'];
}>('LOAD_ADD_PIVOT_REPORT_DATA_SUCCESS');
export const hidePivotReportData = createAction<string>(
  'HIDE_PIVOT_REPORT_DATA'
);
