import React from 'react';

import {css} from '@linaria/core';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import cx from 'classnames';

const cssFrontTooltip = css`
  white-space: pre-wrap;
`;

export const FormattedTooltip = ({classes, ...props}: TooltipProps) => (
  <Tooltip
    classes={{
      ...classes,
      popper: cx(cssFrontTooltip, classes?.popper),
    }}
    {...props}
  />
);
