import {omit, flow, update, values, set, assignAll, sortBy} from 'lodash/fp';

import {dropEmptyParams} from 'utils/lodash+';
import {
  extractMetricFilterParams,
  METRIC_FILTER_PARAMS,
} from 'utils/reportFilters';

import stringifyValues from './stringifyValues';
import {transformParams} from './transformParams';

import {ReportParams, Query, Order} from 'modules/report/types';

export const reorderArr = (arr: string[], order: Order) => {
  const appendToTailIndex = order.length;
  return sortBy(item => order[item] ?? appendToTailIndex, arr);
};

const flatParams = flow(values, assignAll);
const reorderDimensions = (query: any) =>
  update(
    'dimensions',
    dimensions => reorderArr(dimensions, query.order),
    query
  );
const reorderAttributes = (query: any) =>
  update(
    'attributes',
    attributes => reorderArr(attributes, query.order),
    query
  );
const setMetrics = (query: any) => {
  const {order} = query;
  const metrics: string[] = extractMetricFilterParams(query);
  return set('metrics', reorderArr(metrics, order), query);
};
// app params to skip in URL
const omitUnnecessaryParams = omit([...METRIC_FILTER_PARAMS, 'order']);

export const buildReportQuery = (params: ReportParams) =>
  flow(
    transformParams,
    flatParams,
    reorderDimensions,
    reorderAttributes,
    setMetrics,
    omitUnnecessaryParams,
    stringifyValues,
    dropEmptyParams
  )(params) as Query;

export const buildHistoryQuery = flow(stringifyValues, dropEmptyParams);
