import {RefObject} from 'react';

import {flow, get, isNil, join, map, sortBy} from 'lodash/fp';

import {useNodeSize} from 'common-components/hooks';
import {uncappedMap} from 'utils/lodash+';

import {DEFAULT_MIN_COLUMN_WIDTH} from './constants';

import {
  Alignment,
  ColumnData,
  ExtendedColumnData,
  RowData,
  SortDirection,
  SortKey,
} from './types';
import type {Dictionary} from 'types/utils';

export const arrangeColumns = (
  columns: ColumnData[],
  columnOrder: Dictionary<number>
) =>
  flow(
    sortBy([
      ({isFixed}: ColumnData) => Number(!isFixed),
      ({key: columnKey}: ColumnData) => get(columnKey, columnOrder),
    ]),
    uncappedMap((val: ColumnData, idx: number) => ({
      ...val,
      order: idx,
    }))
  )(columns) as ExtendedColumnData[];

/**
 * Returns true if child element is wider than parent by at least threshold (percentage)
 */
export function useIsWiderBy(
  childRef: RefObject<HTMLDivElement>,
  parentRef: RefObject<HTMLDivElement>,
  parentPercentageThreshold: number
) {
  const {width: currentParentWidth} = useNodeSize(parentRef);
  const {width: currentChildWidth} = useNodeSize(childRef);

  if (isNil(currentParentWidth) || isNil(currentChildWidth)) {
    return false;
  }

  return currentParentWidth * parentPercentageThreshold <= currentChildWidth;
}

export function getJustification(align?: Alignment) {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
    default:
      return 'flex-end';
  }
}

const getColumnSize = ({
  gridWidth,
  maxWidth,
  width,
  defaultWidth,
}: ColumnData) => {
  if (width) {
    return `${width}px`;
  }

  if (gridWidth) {
    return gridWidth;
  }

  if (defaultWidth) {
    return `minmax(${defaultWidth}px, auto)`;
  }

  if (maxWidth) {
    return `minmax(${DEFAULT_MIN_COLUMN_WIDTH}px, ${maxWidth}px)`;
  }

  return '1fr';
};
export const getGridColumnsSizes = (columns: ColumnData[]) =>
  flow(map(getColumnSize), join(' '))(columns);

export const isNegativeSort = (sortKey: SortKey) => sortKey?.startsWith('-');

export const getSortColumnKey = (sortKey: SortKey) =>
  isNegativeSort(sortKey) ? sortKey.slice(1) : sortKey;

export const getCellSortDirectionString = (
  sortKey: SortKey,
  cellKey: string,
  cellInitialSortDirection?: SortDirection
) => {
  if (
    !isNil(cellInitialSortDirection) &&
    getSortColumnKey(sortKey) !== cellKey
  ) {
    return cellInitialSortDirection;
  }

  return isNegativeSort(sortKey) ? 'desc' : 'asc';
};

export const invertSortKey = (sortKey: SortKey) =>
  isNegativeSort(sortKey) ? sortKey.slice(1) : `-${sortKey}`;

export const getSortDirectionPrefix = (sortDirection: SortDirection) =>
  sortDirection === 'asc' ? '' : '-';

export const createRowKey = (
  keyProp: string,
  row: RowData,
  columns: ColumnData[],
  index: number
) =>
  keyProp
    ? row[keyProp]
    : columns.reduce(
        (acc: string, columnData: ColumnData) =>
          acc.concat(row[columnData.key], `${index}`),
        ''
      );
