import {
  DEFAULT_MIN_AMOUNT,
  DEFAULT_MAX_AMOUNT,
  DEFAULT_MIN_CV,
  DEFAULT_MAX_CV,
  DEFAULT_CUT_OFF,
  DEFAULT_CURRENCY,
  PLACEHOLDER_PRECISION,
  Slugs,
  Labels,
  CVSchemes,
} from './constants';

import {InputFieldConfig} from './types';

type FormFields = Omit<
  Record<CVSchemes, InputFieldConfig[]>,
  CVSchemes.FacebookAdrevenue
>;
export const fields: FormFields = {
  [CVSchemes.AdRevenue]: [
    {
      id: Slugs.MinRevenue,
      label: Labels.MinRevenue,
      placeholder: DEFAULT_MIN_AMOUNT.toFixed(PLACEHOLDER_PRECISION),
      isDecimal: true,
    },
    {
      id: Slugs.MaxRevenue,
      label: Labels.MaxRevenue,
      placeholder: DEFAULT_MAX_AMOUNT.toFixed(PLACEHOLDER_PRECISION),
      isDecimal: true,
    },
    {
      id: Slugs.MinCV,
      label: Labels.MinCV,
      placeholder: DEFAULT_MIN_CV.toString(),
    },
    {
      id: Slugs.MaxCV,
      label: Labels.MaxCV,
      placeholder: DEFAULT_MAX_CV.toString(),
    },
    {
      id: Slugs.CapRange,
      label: Labels.CapRange,
      placeholder: 'Populate to apply',
    },
  ],
  [CVSchemes.Purchases]: [
    {
      id: Slugs.MinRevenue,
      label: Labels.MinPurchase,
      placeholder: DEFAULT_MIN_AMOUNT.toFixed(PLACEHOLDER_PRECISION),
      isDecimal: true,
    },
    {
      id: Slugs.MaxRevenue,
      label: Labels.MaxPurchase,
      placeholder: DEFAULT_MAX_AMOUNT.toFixed(PLACEHOLDER_PRECISION),
      isDecimal: true,
    },
    {
      id: Slugs.MinCV,
      label: Labels.MinCV,
      placeholder: DEFAULT_MIN_CV.toString(),
    },
    {
      id: Slugs.MaxCV,
      label: Labels.MaxCV,
      placeholder: DEFAULT_MAX_CV.toString(),
    },
  ],
  [CVSchemes.Facebook]: [
    {
      id: Slugs.MinRevenue,
      label: Labels.MinRevenue,
      placeholder: DEFAULT_MIN_AMOUNT.toFixed(PLACEHOLDER_PRECISION),
      isDecimal: true,
    },
    {
      id: Slugs.MaxRevenue,
      label: Labels.MaxRevenue,
      placeholder: DEFAULT_MAX_AMOUNT.toFixed(PLACEHOLDER_PRECISION),
      isDecimal: true,
    },
    {
      id: Slugs.MinCV,
      label: Labels.MinCV,
      placeholder: DEFAULT_MIN_CV.toString(),
    },
    {
      id: Slugs.MaxCV,
      label: Labels.MaxCV,
      placeholder: DEFAULT_MAX_CV.toString(),
    },
    {
      id: Slugs.CapRange,
      label: Labels.CapRange,
      placeholder: 'Populate to apply',
    },
    {
      id: Slugs.AppStoreId,
      label: Labels.AppStoreId,
      type: 'text',
      placeholder: '',
    },
    {
      id: Slugs.CutOffPeriod,
      label: Labels.CutOffPeriod,
      placeholder: DEFAULT_CUT_OFF.toString(),
    },
    {
      id: Slugs.Currency,
      label: Labels.Currency,
      type: 'text',
      placeholder: DEFAULT_CURRENCY,
    },
  ],
};
