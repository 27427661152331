import {
  at,
  curry,
  curryN,
  every,
  flip,
  flow,
  head,
  includes,
  isEmpty,
  isEqual,
  flatMap,
  mapKeys,
  mapValues,
  omit,
  omitBy,
  pick,
  set,
  some,
  values,
  map,
  union,
  reduce,
  negate,
  forEach,
  range,
  ceil,
  flatten,
} from 'lodash/fp';

import type {Dictionary} from 'types/utils';

type Obj<T> = Dictionary<T> | Record<number, T> | null | undefined;
type MapValuesCallback<T, TResult> = (value: T, key: string) => TResult;
interface UncappedLodashMapValues {
  <T, TResult>(callback: MapValuesCallback<T, TResult>): (
    obj: Obj<T>
  ) => Dictionary<TResult>;
  <T, TResult>(callback: MapValuesCallback<T, TResult>, obj: Obj<T>): Record<
    string,
    TResult
  >;
}
/**
 * fp/mapValues with key as a second parameter of callback
 */
// For some reason lodash types doesn't contain convert method
// @ts-expect-error Property 'convert' does not exist on type 'LodashM... Remove this comment to see the full error message
export const uncappedMapValues: UncappedLodashMapValues = mapValues.convert({
  cap: false,
});

type ReduceCallback<Acc, T, I> = (
  acc: Acc,
  value: T,
  key: I extends any[] ? number : string
) => Acc;
interface UncappedLodashReduce {
  <Acc, T, I>(callback: ReduceCallback<Acc, T, I>): (
    acc: Acc
  ) => (iteratee: I) => Acc;
  <Acc, T, I>(callback: ReduceCallback<Acc, T, I>, acc: Acc): (
    iteratee: I
  ) => Acc;
  <Acc, T, I>(callback: ReduceCallback<Acc, T, I>): (
    acc: Acc,
    iteratee: I
  ) => Acc;
  <Acc, T, I>(callback: ReduceCallback<Acc, T, I>, acc: Acc, iteratee: I): Acc;
}
/**
 * fp/reduce with index as a third parameter of callback
 */
// For some reason lodash types doesn't contain convert method
// @ts-expect-error Property 'convert' does not exist on type 'LodashR... Remove this comment to see the full error message
export const uncappedReduce: UncappedLodashReduce = reduce.convert({
  cap: false,
});

/**
 * values + every
 */
export const everyValues = flow(values, every);
/**
 * values + some
 */
export const someValues = flow(values, some);

export const dropEmptyParams = omitBy(isEmpty);

/**
 * Compares two object only by the specified keys
 * @param params array of key names
 */
export const isEqualBy = (params: string[]) => (a: object, b: object) =>
  isEqual(pick(params, a), pick(params, b));

/**
 * Check if element includes in the predefined array
 * flippedIncludes: Array<T> -> T -> boolean
 *
 * flippedIncludes(listToCheck  elementToSearch)
 */
export const flippedIncludes = curryN(2, flip(includes));

/**
 * Useful utility to log intermediate values inside flow
 */
export const log = curry((desc: string = '', val: any) => {
  // eslint-disable-next-line no-console
  console.log(desc, val);
  return val;
});

/**
 * Renames object key
 */
export const renameKey = curry((from: string, to: string, target: object) =>
  flow(set(to, head(at(from, target))), omit(from))(target)
);

/**
 * Our version of _.union supporting more than 2 arrays.
 */
export const unionMulti = reduce(union, []);

/**
 * fp/mapKeys with value as a second parameter of callback
 */
// For some reason lodash types doesn't contain convert method
// @ts-expect-error Property 'convert' does not exist on type 'LodashM... Remove this comment to see the full error message
export const uncappedMapKeys = mapKeys.convert({cap: false});

/**
 * fp/map with index as second param
 */
// For some reason lodash types doesn't contain convert method
// @ts-expect-error Property 'convert' does not exist on type
export const uncappedMap = map.convert({cap: false});

/**
 * fp/flatMap with index as third param
 */
// For some reason lodash types doesn't contain convert method
// @ts-expect-error Property 'convert' does not exist on type 'LodashF... Remove this comment to see the full error message
export const uncappedFlatMap = flatMap.convert({cap: false});

/**
 * fp/forEach with index as a second parameter of callback
 */
// @ts-expect-error: lodash types are not aware of 'convert' method
export const uncappedForEach = forEach.convert({cap: false});

/**
 * debug version of _.flow. Outputs result after each step to the console.
 * @param fns
 */

export const debugFlow = (...fns: Function[]) =>
  flow(
    ...uncappedFlatMap((fn: Function, i: number) => [
      fn,
      log(`Step ${i} (${fn?.name}):`),
    ])(fns)
  );

/**
 * Negated version of lodash isEmpty. Checks if value is a populated object, collection, map, or set.
 */
export const isPopulated = negate(isEmpty);

/**
 * Reorder array by picking items from beginning and end sequentially:
 * i.e.: [arr[0], arr[n], arr[1], arr[n-1], arr[2], ...]
 */
export const shuffleFirstLast = <T>(arr: T[]) => {
  const len = arr.length;
  const newArray: T[] = [];

  // eslint-disable-next-line lodash-fp/no-for-each
  forEach((index: number) => {
    let queryIndex: number;
    if (index % 2 === 0) {
      queryIndex = index / 2;
    } else {
      queryIndex = arr.length - ceil(index / 2);
    }
    newArray.push(arr[queryIndex]);
  })(range(0, len));

  return newArray;
};

/**
 * Extract array values and flatten (merge) them
 */
export const flattenValues: <T>(options: Dictionary<T[]>) => T[] = flow(
  values,
  flatten
);
