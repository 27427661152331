import React, {useState, useCallback, useEffect} from 'react';

import cx from 'classnames';
import {isNil, noop} from 'lodash/fp';

import classes from './Input.css';

type InputProps = {
  value: string;
  placeholder: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  icon: React.ComponentType;
  disabled?: boolean;
  className?: string;
  containerClassName: string;
};
export const Input: React.FC<InputProps> = ({
  className = '',
  containerClassName = '',
  disabled = false,
  icon: Icon,
  ...props
}) => (
  <div className={cx(classes.inputContainer, containerClassName)}>
    {!isNil(Icon) && <Icon />}
    <input
      disabled={disabled}
      className={cx(classes.input, className, {
        [classes.disabled]: disabled,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </div>
);

type CachingInputProps = {
  value: string;
  onChange: () => null;
};
export const CachingInput: React.FC<CachingInputProps> = ({
  value: defaultValue,
  onChange = noop,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleBlur = useCallback(
    () => value !== defaultValue && onChange(value),
    [value, defaultValue, onChange]
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 13 && value !== defaultValue) {
        onChange(value);
      }
    },
    [value, defaultValue, onChange]
  );
  const handleOnChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  return (
    <Input
      // @ts-ignore: blur event function needs weird typing
      onBlur={handleBlur}
      onKeyUp={handleKeyDown}
      onChange={handleOnChange}
      value={value}
      {...props}
    />
  );
};

export default Input;
