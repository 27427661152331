import React, {useCallback, useMemo} from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import {find} from 'lodash/fp';

import {DatePeriodPanel, DateRangePicker} from 'common-components';
import {IntlShape, useIntl} from 'common-components/utils/libs/ReactIntl';
import {
  parseDatePeriod,
  isAbsoluteDatePeriod,
  convertFromApiDate,
  convertToApiDate,
} from 'utils/dateUtils';

import 'common-components/DateRangePicker/DateRangePicker.global.css';
import 'common-components/DateRangePicker/DateRangePicker.adjust.css';

const getAdjustDatePeriodOptions = (intl: IntlShape) => [
  {
    key: 'today',
    label: intl.formatMessage({id: 'aa.time.today', defaultMessage: 'Today'}),
  },
  {
    key: 'yesterday',
    label: intl.formatMessage({
      id: 'aa.time.yesterday',
      defaultMessage: 'Yesterday',
    }),
  },
  {
    key: '-7d:-1d',
    label: intl.formatMessage({
      id: 'aa.time.last7Days',
      defaultMessage: 'Last 7 days',
    }),
  },
  {
    key: '-14d:-1d',
    label: intl.formatMessage({
      id: 'aa.time.last14Days',
      defaultMessage: 'Last 14 days',
    }),
  },
  {
    key: '-30d:-1d',
    label: intl.formatMessage({
      id: 'aa.time.last30Days',
      defaultMessage: 'Last 30 days',
    }),
  },
  {
    key: 'this_week',
    label: intl.formatMessage({
      id: 'aa.time.thisWeek',
      defaultMessage: 'This week',
    }),
  },
  {
    key: 'last_week',
    label: intl.formatMessage({
      id: 'aa.time.lastWeek',
      defaultMessage: 'Last week',
    }),
  },
  {
    key: 'this_month',
    label: intl.formatMessage({
      id: 'aa.time.thisMonth',
      defaultMessage: 'This month',
    }),
  },
  {
    key: 'last_month',
    label: intl.formatMessage({
      id: 'aa.time.lastMonth',
      defaultMessage: 'Last month',
    }),
  },
];

type DatePeriodPanelAdjustThemeProps = {
  datePeriod: string;
  onSelectOption?: Function;
};

const DatePeriodPanelAdjustTheme = ({
  datePeriod,
  onSelectOption,
}: DatePeriodPanelAdjustThemeProps) => {
  const intl = useIntl();
  const adjustDatePeriodOptions = useMemo(
    () => getAdjustDatePeriodOptions(intl),
    [intl]
  );

  const selectedOption = find({key: datePeriod}, adjustDatePeriodOptions);
  const handleSelect = useCallback(
    ({key}) => {
      const [startDateIso, endDateIso] = parseDatePeriod(key);
      onSelectOption?.(
        convertFromApiDate(startDateIso),
        convertFromApiDate(endDateIso),
        key
      );
    },
    [onSelectOption]
  );

  return (
    <DatePeriodPanel
      options={adjustDatePeriodOptions}
      onSelectOption={handleSelect}
      selectedOption={selectedOption}
    />
  );
};

export const AdjustDateRangePicker = (props: any) => {
  const isMobileScreen = useMediaQuery('(max-width: 420px)');
  return (
    <DateRangePicker
      displayFormat='DD MMM YYYY'
      showDateRangeUTCLabel={false}
      allowSelectToday
      withPortal={false}
      centered
      {...props}
      numberOfMonths={isMobileScreen ? 1 : 2}
      DatePeriodPanel={isMobileScreen ? null : DatePeriodPanelAdjustTheme}
    />
  );
};

// Makes DateRangePicker work with one datePeriod field instead of 3 separate fields
export const DatePeriodConnector =
  (DatePickerComponent: React.FC) => (props: any) => {
    const {
      input: {value: datePeriod, onChange},
      /* eslint-disable @typescript-eslint/no-unused-vars */
      // we don't need those in otherProps
      meta,
      children,
      render,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      onDatesChange,
      ...otherProps
    } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleDatesChange = useCallback(
      (startDate, endDate, datePeriodValue) => {
        onChange(
          datePeriodValue ||
            `${convertToApiDate(startDate)}:${convertToApiDate(endDate)}`
        );
        onDatesChange?.(startDate, endDate, datePeriodValue);
      },
      [onChange, onDatesChange]
    );
    const [startDateIso, endDateIso] = parseDatePeriod(datePeriod);

    return (
      <DatePickerComponent
        startDate={convertFromApiDate(startDateIso)}
        endDate={convertFromApiDate(endDateIso)}
        datePeriod={isAbsoluteDatePeriod(datePeriod) ? null : datePeriod}
        onDatesChange={handleDatesChange}
        {...otherProps}
      />
    );
  };
