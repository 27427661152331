import React, {useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {loadCreateChangeGroupRequest} from 'modules/report/actions';
import {selectAttrData, selectAttrLoading} from 'modules/report/reducers';

import {AttributeCell} from 'modules/common/components';

type Props = {
  rowData: any;
  value: string;
  isSelected: boolean;
};

export const ReportAttributeCell = ({rowData, value, isSelected}: Props) => {
  const dispatch = useDispatch();
  const attributes = useSelector(selectAttrData);
  const isLoading = useSelector(selectAttrLoading);
  const attrData = value ? attributes?.[value] : null;
  const {
    attr_dependency: {app_network},
  } = rowData;

  const handleUpdate = useCallback(
    newValue => {
      const change = [
        {
          ...attrData,
          to: newValue,
          app_network,
        },
      ];
      dispatch(
        loadCreateChangeGroupRequest({
          attrUpdates: change,
          shouldApprove: true,
        })
      );
    },
    [attrData, app_network, dispatch]
  );

  return (
    <AttributeCell
      attrData={attrData}
      isLoading={isLoading}
      onChange={handleUpdate}
      isSelected={isSelected}
    />
  );
};
