import React from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';
import {includes} from 'lodash/fp';

import {MAX_HEIGHT_LEGEND} from './constants';

const cssLegend = css`
  padding: 5px;
  max-height: ${MAX_HEIGHT_LEGEND}px;
  width: 100%;
  overflow: auto;
`;
const cssLegendItem = css`
  display: flex;
  margin-bottom: 4px;

  svg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    margin-left: 6px;
    margin-right: 6px;
  }
  input {
    margin-top: 4px;
  }
`;
const cssClickable = css`
  cursor: pointer;
`;

const Circle = ({color}: {color: string}) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <circle cx={10} cy={10} r={10} fill={color} />
  </svg>
);

type Props = {
  series: {
    groupId: string;
    color: string;
  }[];
  onSeriesClick: (groupId: string) => void;
  hiddenGroupIds: string[];
};
export const Legend = ({series, onSeriesClick, hiddenGroupIds}: Props) => {
  const isSingleSeriesActive = hiddenGroupIds.length + 1 === series.length;

  return (
    <div className={cssLegend}>
      {series.map(({groupId, color}) => {
        const isSelected = !includes(groupId, hiddenGroupIds);
        const isNotSelectable = isSingleSeriesActive && isSelected;

        return (
          <div
            className={cx(cssLegendItem, {[cssClickable]: !isNotSelectable})}
            key={groupId}
            onClick={() => {
              if (isNotSelectable) {
                return;
              }
              onSeriesClick(groupId);
            }}
            role='button'
            tabIndex={0}
          >
            <input
              readOnly
              type='checkbox'
              checked={isSelected}
              disabled={isNotSelectable}
            />
            <Circle color={color} />
            <span>{groupId}</span>
          </div>
        );
      })}
    </div>
  );
};
