import {createReducer} from '@reduxjs/toolkit';
import {find} from 'lodash/fp';
import {createSelector} from 'reselect';

import * as ReportActions from 'modules/report/actions/ReportActions';

import {
  loadCustomerConfigFailure,
  loadCustomerConfigRequest,
  loadCustomerConfigSuccess,
  loadOpsStatsFailure,
  loadOpsStatsSuccess,
} from '../actions';

import type {CustomerConfig, RootState} from 'modules/common/types';
import {CustomerConfigReport} from 'modules/report/types';

type CustomerConfigState = {
  isLoading: boolean;
  isLoaded: boolean;
  error?: any;
  value: CustomerConfig;
  pendingOperations: number;
  operationErrors: number;
};
const initialCustomerConfigState: CustomerConfigState = {
  isLoading: false,
  isLoaded: false,
  value: <CustomerConfig>{},
  pendingOperations: 0,
  operationErrors: 0,
};
export const customerConfig = createReducer<CustomerConfigState>(
  initialCustomerConfigState,
  builder =>
    builder
      .addCase(loadCustomerConfigRequest, state => ({
        ...state,
        isLoading: true,
      }))
      .addCase(loadCustomerConfigSuccess, (state, {payload}) => ({
        ...state,
        isLoaded: true,
        isLoading: false,
        value: {
          ...payload,
          features: payload.features,
        },
      }))
      .addCase(loadCustomerConfigFailure, (state, {payload: error}) => ({
        ...state,
        isLoading: false,
        isLoaded: false,
        error,
      }))
      .addCase(loadOpsStatsSuccess, (state, {payload}) => ({
        ...state,
        pendingOperations: payload.pending,
        operationErrors: payload.errors,
      }))
      .addCase(loadOpsStatsFailure, (state, {payload: error}) => ({
        ...state,
        error,
      }))
      .addCase(ReportActions.loadDeleteReportRequest, (state, {payload}) => ({
        ...state,
        value: {
          ...state.value,
          reports: state.value.reports.map(report => {
            if (report.id === payload) {
              return {
                ...report,
                isDeleted: true,
              };
            }
            return report;
          }),
        },
      }))
      .addCase(
        ReportActions.loadRestoreDeletedReportRequest,
        (state, {payload}) => ({
          ...state,
          value: {
            ...state.value,
            reports: state.value.reports.map(report => {
              if (report.id === payload) {
                return {
                  ...report,
                  isDeleted: false,
                };
              }
              return report;
            }),
          },
        })
      )
      .addCase(ReportActions.removeDeletedReports, state => ({
        ...state,
        value: {
          ...state.value,
          reports: state.value.reports.filter(report => !report.isDeleted),
        },
      }))
      .addCase(
        ReportActions.loadSaveReportAsDefaultRequest,
        (state, {payload}) => ({
          ...state,
          value: {
            ...state.value,
            default_report_id: payload,
          },
        })
      )
);

export const selectCustomerConfig = (state: RootState) =>
  state.common.customerConfig.value;
export const selectAdjustAccountId = createSelector(
  selectCustomerConfig,
  ({adjust_account_id}) => adjust_account_id
);
export const selectCustomerAutomateFeatures = createSelector(
  selectCustomerConfig,
  ({features}) => features
);
export const selectIsProductionMode = createSelector(
  selectCustomerAutomateFeatures,
  ({production}) => production
);
export const selectAutomationEnabled = createSelector(
  selectCustomerAutomateFeatures,
  ({automation}) => automation
);
export const selectHasChartsEnabled = createSelector(
  selectCustomerAutomateFeatures,
  ({fe_display_graphs}) => fe_display_graphs
);
export const selectIsSuperAdmin = createSelector(
  selectCustomerConfig,
  ({adjust_superadmin}) => adjust_superadmin
);
export const selectShouldShowChangePopup = createSelector(
  selectIsProductionMode,
  selectIsSuperAdmin,
  (isProductionMode, isSuperAdmin) => isProductionMode && isSuperAdmin
);

export const selectIsCustomerConfigLoaded = (state: RootState) =>
  state.common.customerConfig.isLoaded;
export const selectCustomerReports = (state: RootState) =>
  state.common.customerConfig.value.reports;
export const selectDefaultReportId = createSelector(
  selectCustomerConfig,
  ({default_report_id}) => default_report_id
);
export const selectDefaultUrl = createSelector(
  selectCustomerConfig,
  config =>
    (
      find(
        {id: config?.default_report_id},
        config?.reports
      ) as CustomerConfigReport
    )?.url
);
export const selectCanViewAttr = (state: RootState) =>
  state.common.customerConfig.value.permissions.view_channel_data;
export const selectCanEditAttr = (state: RootState) =>
  state.common.customerConfig.value.permissions.edit_channel_data;
export const selectCanViewAndEditAttr = createSelector(
  selectCanEditAttr,
  selectCanViewAttr,
  (canEdit, canView) => canEdit && canView
);
export const selectUnapprovedCount = (state: RootState) =>
  state.common.customerConfig.pendingOperations;
export const selectOperationErrors = (state: RootState) =>
  state.common.customerConfig.operationErrors;
