import React, {ReactElement} from 'react';

import {css} from '@linaria/core';
import {IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';

import {SpaceFiller} from 'common-components';
import {useIntl} from 'common-components/utils/libs/ReactIntl';

import {ActionPanel} from 'modules/common/components';

const cssForm = css`
  display: inline-flex;
  align-items: flex-end;

  @media all and (max-width: 420px) {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
  }
`;
const cssActionPanelHeader = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
const cssIconPosition = css`
  margin-left: 10px;

  @media (max-width: 420px) : {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
const cssCloseIcon = css`
  &.MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`;

export const reportActionPanelClasses = {
  cssControls: css`
    display: flex;
    flex-direction: row;
  `,
  cssMobileViewAttributes: css`
    display: flex;
    flex-direction: row;

    @media all and (max-width: 420px) {
      flex-direction: column;
    }
  `,
};

type Props = {
  headerLabel?: ReactElement;
  closeLabel?: string;
  onClose: () => void;
};
export const ReportActionPanel: React.FC<Props> = ({
  onClose,
  headerLabel,
  closeLabel,
  children,
}) => {
  const intl = useIntl();
  const handleClosePanel = () => {
    onClose();
  };

  const closeButtonTitle =
    closeLabel ||
    intl.formatMessage({
      id: 'aa.label.close',
      defaultMessage: 'Close',
    });

  return (
    <ActionPanel>
      <div className={cssActionPanelHeader}>
        <span>{headerLabel}</span>
        <SpaceFiller />
        <IconButton
          color='primary'
          title={closeButtonTitle}
          className={cssIconPosition}
          onClick={handleClosePanel}
        >
          <CloseIcon className={cssCloseIcon} />
        </IconButton>
      </div>
      <div className={cssForm}>{children}</div>
    </ActionPanel>
  );
};
