import React, {useEffect, useMemo, useState} from 'react';

import {css} from '@linaria/core';
import {filter, includes, isEmpty, toLower} from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';

import {ModernGrid} from 'common-components';
import {reportListScreenUnmounted} from 'modules/common/actions';
import {loadReportFiltersRequest} from 'modules/report/actions';
import {
  selectFiltersOptions,
  selectEnrichedReports,
} from 'modules/report/reducers';
import {isPopulated} from 'utils/lodash+';
import {extractChangeActions} from 'utils/reportAutomationActions';

import {reportListColumns} from './columns';
import {ReportListFilters} from './ReportListFilters';
import {ReportListNullState} from './ReportListNullState';
import {craftAutomationStatus} from './utils';

import {ReportListFiltersValues} from './types';
import type {EnrichedReport} from 'modules/report/types';

const cssRoot = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ReportList = () => {
  const dispatch = useDispatch();

  const [reportNameSearch, setReportNameSearch] = useState('');
  const [selModifiedByUsers, setSelModifiedByUsers] = useState<string[]>([]);
  const [selAutomationStatus, setSelAutomationStatus] = useState('');

  const allReports = useSelector(selectEnrichedReports) as EnrichedReport[];
  const filtersOptions = useSelector(selectFiltersOptions);

  useEffect(() => {
    if (isEmpty(filtersOptions)) {
      dispatch(loadReportFiltersRequest());
    }
  }, [filtersOptions, dispatch]);

  useEffect(
    () => () => {
      dispatch(reportListScreenUnmounted());
    },
    [dispatch]
  );

  const tableData = useMemo(
    () =>
      filter(
        ({
          title,
          modified_by,
          actions,
          are_actions_enabled: hasAutomationEnabled,
        }) => {
          const hasChangeActions = isPopulated(extractChangeActions(actions));

          return (
            includes(toLower(reportNameSearch), toLower(title)) &&
            (isEmpty(selModifiedByUsers) ||
              includes(modified_by, selModifiedByUsers)) &&
            (isEmpty(selAutomationStatus) ||
              selAutomationStatus ===
                craftAutomationStatus(hasChangeActions, hasAutomationEnabled))
          );
        },
        allReports
      ),
    [allReports, reportNameSearch, selModifiedByUsers, selAutomationStatus]
  );

  const handleReportNameChange = (
    newValue: ReportListFiltersValues['reportName']
  ) => setReportNameSearch(newValue);
  const handleModifiedByChange = (
    newValue: ReportListFiltersValues['modifiedBy']
  ) => setSelModifiedByUsers(newValue);
  const handleAutomationStatusChange = (
    newValue: ReportListFiltersValues['automationStatus']
  ) => setSelAutomationStatus(newValue);

  return (
    <div className={cssRoot}>
      <ReportListFilters
        onAutomationStatusChange={handleAutomationStatusChange}
        onModifiedByChange={handleModifiedByChange}
        onReportNameChange={handleReportNameChange}
        reports={allReports}
        values={{
          reportName: reportNameSearch,
          modifiedBy: selModifiedByUsers,
          automationStatus: selAutomationStatus,
        }}
      />
      <ModernGrid
        columns={reportListColumns}
        data={tableData}
        keyProp='id'
        headerHeight={43}
        rowHeight={52}
        showHeaderInNullState
        nullState={ReportListNullState}
      />
    </div>
  );
};
