import React, {useState, useEffect, useMemo, useContext} from 'react';

import {
  every,
  find,
  flatMap,
  flow,
  groupBy,
  intersection,
  isEmpty,
  keys,
  map,
  mapValues,
  omit,
  pick,
  pickBy,
  get,
  reject,
  some,
  values,
} from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';

import {SelectableRowsContext} from 'common-components/ModernGrid/extensions/SelectableRows';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {showInformationDialog} from 'modules/common/actions';
import {selectShouldShowChangePopup} from 'modules/common/reducers/customerConfig';
import {loadCreateChangeGroupRequest} from 'modules/report/actions/ReportActions';
import {
  selectAttrData,
  selectFilteredReportData,
  selectIsGroupChangeInProgress,
  selectIsAutomationPanelOpen,
  selectGeneralParams,
  selectAttributesFormattings,
  selectAttrsNameBySlug,
  selectQuickAttributesParam,
} from 'modules/report/reducers';
import {arrFromOrder} from 'modules/report/utils';
import {getAttrChangeDialogParams} from 'utils/dialog';
import {flippedIncludes} from 'utils/lodash+';

import {AttributeField} from '../AttributeField';
import {ReportActionPanel, reportActionPanelClasses} from './ReportActionPanel';
import {getStatusOptionsBySlugFromAttrs} from './utils';

import {SubmitButton} from 'modules/common/components';

import type {AttributeValue} from 'modules/common/types';
import type {Attributes, AttrUpdate} from 'modules/report/types';
import type {BE_types} from 'types/backendServicesTypes';
import type {Dictionary} from 'types/utils';

export const AttrChangesActionPanel = () => {
  const dispatch = useDispatch();
  const shouldShowPopup = useSelector(selectShouldShowChangePopup);
  const attributes = useSelector(selectAttrData) as Attributes;
  const report = useSelector(selectFilteredReportData);
  const changeInProgress = useSelector(selectIsGroupChangeInProgress);
  const quickParamsAttributes = useSelector(selectQuickAttributesParam);
  const {order} = useSelector(selectGeneralParams);
  const isAutomationPanelOpen = useSelector(selectIsAutomationPanelOpen);
  const attributesFormattings = useSelector(selectAttributesFormattings);
  const attrSlugToReadableName = useSelector(selectAttrsNameBySlug);
  const {selectedRows, discardSelection} = useContext(SelectableRowsContext);

  const initialEmptyValues = useMemo(
    () =>
      quickParamsAttributes.reduce(
        (acc: Dictionary<string>, attr: string) => ({...acc, [attr]: ''}),
        {}
      ),
    [quickParamsAttributes]
  );
  const [inputValues, setInputValues] = useState(initialEmptyValues);
  const someInputChanged = some(Boolean, values(inputValues));

  useEffect(() => {
    if (isAutomationPanelOpen || isEmpty(selectedRows)) {
      setInputValues(initialEmptyValues);
    }
  }, [isAutomationPanelOpen, selectedRows, initialEmptyValues]);

  if (isEmpty(selectedRows) || isAutomationPanelOpen) {
    return null;
  }

  const pickSelectedRows = pick(selectedRows);
  const pickTokenAndHashes = mapValues(
    pick(['attr_dependency', ...quickParamsAttributes])
  );
  const appHashesMapping: any[] = flow(
    pickSelectedRows,
    pickTokenAndHashes,
    values
  )(report);
  const getAppNetworkForAttr = (attr: AttributeValue) =>
    (find({[attr.slug]: attr.hash})(appHashesMapping) as any)?.attr_dependency
      .app_network;
  const selectedHashes = flatMap(flow(omit('attr_dependency'), values))(
    appHashesMapping
  );
  const selectedAttrs = pick(selectedHashes)(attributes) as Attributes;

  const inputs = flow(
    arrFromOrder,
    intersection(quickParamsAttributes)
  )(order) as BE_types['AttributeSlug'][];
  const statusOptionsByAttr = getStatusOptionsBySlugFromAttrs(selectedAttrs);

  const checkIfAllReadonly = flow(map('readonly'), every(Boolean));
  const readOnlyParams = flow(
    groupBy('slug'),
    mapValues(checkIfAllReadonly)
  )(selectedAttrs);

  const createClickHandler =
    ({shouldApprove}: {shouldApprove: boolean}) =>
    () => {
      const changeCallback = () => {
        const unchangedTypes = flow(pickBy(isEmpty), keys)(inputValues);
        const rejectUnchanged = reject(
          flow(get('slug'), flippedIncludes(unchangedTypes))
        );
        const rejectReadonly = reject(get('readonly'));
        const addToValues = map((attr: AttributeValue) => ({
          ...attr,
          to: inputValues[attr.slug],
          app_network: getAppNetworkForAttr(attr),
        }));
        const attrUpdates: AttrUpdate[] = flow(
          values,
          rejectUnchanged,
          rejectReadonly,
          addToValues
        )(selectedAttrs);
        dispatch(
          loadCreateChangeGroupRequest({
            attrUpdates,
            shouldApprove,
            onChangeGroupCreated: discardSelection,
          })
        );
      };

      if (shouldShowPopup) {
        dispatch(
          showInformationDialog(getAttrChangeDialogParams(changeCallback))
        );
      } else {
        changeCallback();
      }
    };

  const handleAttributeInputChange =
    (inputType: string) => (newValue: string) => {
      setInputValues((oldInputValues: Dictionary<string>) => ({
        ...oldInputValues,
        [inputType]: newValue,
      }));
    };

  const actionPanelHeaderLabel = (
    <FormattedMessage
      id='aa.reportActionPanel.selectedRows'
      defaultMessage='Change {numSelectedRows} selected row(s)'
      values={{
        numSelectedRows: selectedRows.length,
      }}
    />
  );

  return (
    <ReportActionPanel
      headerLabel={actionPanelHeaderLabel}
      onClose={discardSelection}
    >
      <div className={reportActionPanelClasses.cssMobileViewAttributes}>
        {inputs.map(attrSlug => (
          <AttributeField
            pendoId={`actionPanel-${attrSlug}`}
            key={attrSlug}
            id={attrSlug}
            name={attrSlugToReadableName[attrSlug]}
            formatting={attributesFormattings[attrSlug]}
            readonly={readOnlyParams[attrSlug]}
            value={inputValues[attrSlug]}
            setValue={handleAttributeInputChange(attrSlug)}
            statusOptions={statusOptionsByAttr[attrSlug]}
            isClearable
          />
        ))}
      </div>
      <div className={reportActionPanelClasses.cssControls}>
        <SubmitButton
          data-pendoid='actionPanelSaveForReview'
          color='default'
          type='button'
          disabled={!someInputChanged || changeInProgress}
          onClick={createClickHandler({shouldApprove: false})}
        >
          <FormattedMessage
            id='aa.reportActionPanel.saveBtn'
            defaultMessage='Save for review'
          />
        </SubmitButton>
        <SubmitButton
          data-pendoid='actionPanelSubmitNow'
          color='default'
          variant='contained'
          type='button'
          disabled={!someInputChanged || changeInProgress}
          onClick={createClickHandler({shouldApprove: true})}
        >
          <FormattedMessage
            id='aa.reportActionPanel.submitBtn'
            defaultMessage='Submit now'
          />
        </SubmitButton>
      </div>
    </ReportActionPanel>
  );
};
