import React from 'react';

import {css} from '@linaria/core';
import {SvgIcon} from '@material-ui/core';
import DiscardedIcon from '@material-ui/icons/CancelOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/ErrorOutline';
import SyncIcon from '@material-ui/icons/Sync';
import ProblemIcon from '@material-ui/icons/SyncProblem';
import cx from 'classnames';

import {styleVariables} from 'constants/globalVariables';

import {FormattedTooltip} from './FormattedTooltip';

import {BE_types} from 'types/backendServicesTypes';
import {Dictionary} from 'types/utils';

const STATUS_ICON_ID = 'status-icon';

const cssIcon = css`
  max-height: 16px;
  max-width: 16px;
  margin-right: 4px;
`;
const cssCell = css`
  min-width: 16px;
`;

const icons: Dictionary<typeof SvgIcon> = {
  queued: SyncIcon,
  is_loading: SyncIcon,
  is_error: ProblemIcon,
  internal_failure: ProblemIcon,
  network_failure: ProblemIcon,
  permission_failure: ProblemIcon,
  success: SuccessIcon,
  info: InfoIcon,
  discarded: DiscardedIcon,
};

const colors: Record<BE_types['FetchResponseModel']['status'], string> = {
  is_error: styleVariables.colorLipstickTwo,
  internal_failure: styleVariables.colorLipstickTwo,
  network_failure: styleVariables.colorLipstickTwo,
  permission_failure: styleVariables.colorLipstickTwo,
  success: styleVariables.colorMidGreen,
  info: styleVariables.colorWarmGreyTwo,
  discarded: styleVariables.colorWarmGreyTwo,
};

type Props = {
  statusSlug: string;
  tooltipText?: string;
};

export const CellStatus = ({statusSlug, tooltipText = ''}: Props) => {
  let infoFallback = false;
  if (!Object.keys(icons).includes(statusSlug)) {
    if (tooltipText) {
      infoFallback = true;
    } else {
      return <div className={cx(cssIcon, cssCell)} />;
    }
  }

  const Icon = infoFallback ? icons.info : icons[statusSlug];
  const color = infoFallback ? colors.info : colors[statusSlug];
  return (
    <FormattedTooltip title={tooltipText}>
      <Icon
        className={cssIcon}
        style={{color}}
        data-pendoid={STATUS_ICON_ID}
        data-testid={STATUS_ICON_ID}
      />
    </FormattedTooltip>
  );
};
