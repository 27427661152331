import {flow, values, flatten, sortBy, indexOf, compact, set} from 'lodash/fp';

import {orderFromArr} from 'modules/report/utils';

import type {Dictionary} from 'types/utils';

const getOrderedMetrics = (metrics: string[]) =>
  flow(
    values,
    flatten,
    sortBy(key => indexOf(key, metrics))
  )(metrics);
const getOrder = flow(flatten, compact, orderFromArr);

const setOrder = (params: Dictionary<any>) => {
  const {dimensions, attributes, metrics} = params.quick;
  const orderedMetrics = getOrderedMetrics(metrics);
  const order = getOrder([dimensions, attributes, orderedMetrics]);

  return set('general.order', order, params);
};

export default setOrder;
