import React from 'react';

import {css} from '@linaria/core';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import {components} from 'react-select';

import {cssTwoLinesMaxClassName} from 'common-components/TwoLinesMax';
import {useIntl} from 'common-components/utils/libs/ReactIntl';

const cssOptionsComponentWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const cssOptionsInput = css`
  margin-right: 10px;
`;

const cssOptionsComponentLabels = css`
  text-overflow: ellipsis;
`;

export const Option = ({children: longOptionName, ...props}) => {
  const intl = useIntl();
  const defaultLockedLabel = intl.formatMessage({
    id: 'aa.label.locked',
    defaultMessage: 'locked',
  });

  const {
    isSelected,
    data: {
      locked = false,
      showCheckbox = true,
      lockedOptionLabel = defaultLockedLabel,
      shortName,
    },
    selectProps: {isCompact, showOptionCheckbox},
  } = props;

  const isChecked = locked || isSelected;
  const longLabelThreshold = isCompact ? 58 : 50;
  const showLongOptionName =
    (shortName && shortName !== longOptionName) ||
    longOptionName.length > longLabelThreshold
      ? longOptionName
      : '';

  return (
    <components.Option {...props}>
      <div
        className={cssOptionsComponentWrapper}
        role='option'
        aria-selected={isChecked}
      >
        {showOptionCheckbox && showCheckbox && (
          <input
            className={cssOptionsInput}
            type='checkbox'
            checked={isChecked}
            readOnly
          />
        )}
        <Tooltip arrow title={showLongOptionName}>
          <div
            className={cx(cssOptionsComponentLabels, cssTwoLinesMaxClassName)}
          >
            {shortName || longOptionName}
            {locked ? ` (${lockedOptionLabel})` : ''}
          </div>
        </Tooltip>
      </div>
    </components.Option>
  );
};
