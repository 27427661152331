import {uncappedMapValues} from 'utils/lodash+';

import {isDimensionFilter} from '../postfix';

const stringifyDimensionParam = (value: string | string[]) =>
  JSON.stringify(value).slice(1, -1);

const stringifyValue = (value: string | string[], key: string) => {
  if (isDimensionFilter(key)) {
    return stringifyDimensionParam(value);
  }

  if (Array.isArray(value)) {
    return value.join(',');
  }

  return value;
};

const stringifyValues = uncappedMapValues(stringifyValue);

export default stringifyValues;
