import {Account, User} from '@adjust/dashboard-components';

import {createModuleAction} from 'utils/reduxUtils';

import {ColumnSizesState} from '../reducers/customerPreferences';

import {
  BrowserHistoryChange,
  InformationDialogParams,
  InputDialogParams,
  ToastParams,
} from '../types';

const {createAction} = createModuleAction('COMMON');

export const showInformationDialog = createAction<InformationDialogParams>(
  'SHOW_INFORMATION_DIALOG'
);
export const hideInformationDialog = createAction('HIDE_INFORMATION_DIALOG');
export const approveInformationDialog = createAction(
  'APPROVE_INFORMATION_DIALOG'
);
export const rejectInformationDialog = createAction(
  'REJECT_INFORMATION_DIALOG'
);

export const showMainMenu = createAction('SHOW_MAIN_MENU');
export const hideMainMenu = createAction('HIDE_MAIN_MENU');
export const setIsMainMenuReady = createAction<boolean>(
  'SET_IS_MAIN_MENU_READY'
);

export const showInputDialog =
  createAction<InputDialogParams>('SHOW_INPUT_DIALOG');
export const hideInputDialog = createAction('HIDE_INPUT_DIALOG');

export const showToast = createAction<ToastParams>('SHOW_TOAST_DIALOG');
export const hideToast = createAction<void>('HIDE_TOAST_DIALOG');

export const loadTargetingLabelsRequest = createAction<any>(
  'LOAD_TARGETING_LABELS_REQUEST'
);
export const loadTargetingLabelsSuccess = createAction<any>(
  'LOAD_TARGETING_LABELS_SUCCESS'
);

export const loadDrilldownLabelsRequest = createAction(
  'LOAD_DRILLDOWN_LABELS_REQUEST'
);
export const loadDrilldownLabelsSuccess = createAction<any>(
  'LOAD_DRILLDOWN_LABELS_SUCCESS'
);

// customerConfig Actions
export const loadOpsStatsRequest = createAction('LOAD_OPS_STATS_REQUEST');
export const initRepeatingLoadOpsStatsRequest = createAction(
  'INIT_REPEATING_LOAD_OPS_STATS_REQUEST'
);
export const loadOpsStatsSuccess = createAction<any>('LOAD_OPS_STATS_SUCCESS');
export const loadOpsStatsFailure = createAction<any>('LOAD_OPS_STATS_FAILURE');

export const loadAdjustCustomerDataSuccess = createAction<{
  currentAccount?: Account;
  currentUser?: User;
}>('LOAD_ADJUST_CUSTOMER_DATA_SUCCESS');
export const loadAdjustCustomerAccountsSuccess = createAction<{
  accounts: Account[];
}>('LOAD_ADJUST_CUSTOMER_ACCOUNTS_SUCCESS');

// @deprecated
export const loadCustomerConfigRequest = createAction(
  'LOAD_CUSTOMER_CONFIG_REQUEST'
);
export const loadCustomerConfigSuccess = createAction<any>(
  'LOAD_CUSTOMER_CONFIG_SUCCESS'
);
export const loadCustomerConfigFailure = createAction<any>(
  'LOAD_CUSTOMER_CONFIG_FAILURE'
);

// customerPreferences
export const updateCustomerColumnSizeRequest = createAction<any>(
  'UPDATE_CUSTOMER_COLUMN_SIZE_REQUEST'
);
export const loadCustomerColumnSizes = createAction<ColumnSizesState>(
  'LOAD_CUSTOMER_COLUMN_SIZES'
);

export const reportListScreenUnmounted = createAction(
  'REPORT_LIST_SCREEN_UNMOUNTED'
);

export const browserHistoryUpdated = createAction<BrowserHistoryChange>(
  'BROWSER_HISTORY_UPDATED'
);
