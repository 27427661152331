import React, {useCallback, useMemo} from 'react';

import {css} from '@linaria/core';
import Button from '@material-ui/core/Button';
import {
  identity,
  isEqual,
  isEmpty,
  find,
  flow,
  forEach,
  map,
  mapValues,
} from 'lodash/fp';
import {Field, Form, useFormState} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  getOptions,
  Title,
  Label,
  SpaceFiller,
  FormConnector,
  LoadingIndicator,
  GenericMetricSelect,
} from 'common-components';
import CheckboxMultiSelect from 'common-components/CheckboxMultiSelect';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  NO_DIMENSIONS_ERROR_MESSAGE,
  NO_METRICS_ERROR_MESSAGE,
} from 'constants/globalVariables';
import {selectCanViewAttr} from 'modules/common/reducers/customerConfig';
import {applyReportParams} from 'modules/report/actions/ReportActions';
import {
  selectBackendEnforcedDimensions,
  selectFiltersWithOptions,
} from 'modules/report/reducers';
import {normalizeForm} from 'modules/report/utils';
import inputComponents from 'utils/filtersInputs';
import {isPopulated} from 'utils/lodash+';
import {
  METRIC_FILTER_PARAMS,
  pickMetricFilterOptionsNames,
  extractMetricFilterParams,
} from 'utils/reportFilters';

import {Section} from 'modules/common/components';
import type {SectionProps} from 'modules/common/components/Section';

import type {FiltersOptions} from 'modules/common/types';
import type {ReportParams} from 'modules/report/types';
import type {Dictionary} from 'types/utils';

const MetricSelectFormConnector = FormConnector(GenericMetricSelect);
const FormSelect = FormConnector(CheckboxMultiSelect);

const cssSection = css`
  margin-top: 30px;
`;
const cssButton = css`
  margin-right: 8px;
`;
const cssTitleContainer = css`
  display: flex;
  margin-bottom: 20px;
`;
const cssTitle = css`
  color: #4b577e;
`;
const cssButtonsBlockWrapper = css`
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
`;
const cssMetricSelectProp = css`
  margin-top: 20px;
  min-width: 300px;
`;
const cssLabel = css`
  margin-top: 0px;
  font-size: 16px;
`;
const cssFieldWidth = css`
  min-width: 300px;
`;

const BigLabel: React.FC = ({children}) => (
  <Label className={cssLabel}>{children}</Label>
);

type PaddedSectionProps = SectionProps & {
  pendoId?: string;
};
const PaddedSection: React.FC<PaddedSectionProps> = ({
  pendoId,
  label,
  children,
  ...props
}) => (
  <Section
    {...props}
    pendoId={pendoId}
    className={cssSection}
    label={<BigLabel>{label}</BigLabel>}
  >
    {children}
  </Section>
);

const EnhancedMetricSelect = ({
  title,
  allMetrics,
  ...props
}: {
  title: string;
  allMetrics: string[];
  input: unknown;
}) =>
  isEmpty(allMetrics) ? (
    <PaddedSection label={title}>
      <LoadingIndicator />
    </PaddedSection>
  ) : (
    <MetricSelectFormConnector
      pendoId={title}
      className={cssMetricSelectProp}
      title={<Label className={cssLabel}>{title}</Label>}
      showTrailingSelectAll={false}
      allMetrics={allMetrics}
      {...props}
    />
  );

const ButtonsBlock = ({pendoId}: {pendoId?: string}) => {
  const intl = useIntl();
  const {invalid, submitting, pristine} = useFormState();
  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    history.goBack();
  }, [history]);
  const firstPage = !history.location.key;
  const cancelButtonPendoTag = pendoId
    ? `builder-cancelButton${pendoId}`
    : null;
  const applyButtonPendoTag = pendoId ? `builder-applyButton${pendoId}` : null;
  return (
    <>
      {!firstPage && (
        <Button
          data-pendoid={cancelButtonPendoTag}
          className={cssButton}
          color='primary'
          variant='text'
          title={intl.formatMessage({
            id: 'aa.form.submitBtn.cancel',
            defaultMessage: 'Cancel',
          })}
          onClick={handleCancelClick}
        >
          <FormattedMessage
            id='aa.form.submitBtn.cancel'
            defaultMessage='Cancel'
          />
        </Button>
      )}

      <Button
        data-pendoid={applyButtonPendoTag}
        type='submit'
        color='primary'
        variant='contained'
        disabled={invalid || submitting || pristine}
        title={intl.formatMessage({
          id: 'aa.form.submitBtn.apply',
          defaultMessage: 'Apply',
        })}
      >
        <FormattedMessage id='aa.form.submitBtn.apply' defaultMessage='Apply' />
      </Button>
    </>
  );
};

export const validateBuilderForm = (values: ReportParams) => {
  if (isEmpty(values)) {
    return undefined;
  }
  const errors: Dictionary<string> = {};
  const {dimensions} = values.quick;
  const metrics = extractMetricFilterParams(values.quick);

  if (isEmpty(dimensions)) {
    errors.dimensions = NO_DIMENSIONS_ERROR_MESSAGE;
  }
  if (isEmpty(metrics)) {
    // eslint-disable-next-line lodash-fp/no-for-each
    forEach(field => {
      errors[field] = NO_METRICS_ERROR_MESSAGE;
    }, METRIC_FILTER_PARAMS);
  }
  return errors;
};

type BuilderFormProps = {
  showTitle?: boolean;
  onSubmit?: (data: unknown) => void;
  initialValues: ReportParams | null;
  filtersOptions?: FiltersOptions;
};
export const BuilderForm = ({
  showTitle = true,
  onSubmit,
  initialValues,
  filtersOptions: {
    dimensions: allDimensions = [],
    apps: allApps = [],
    countries: allCountries = [],
    networks: allNetworks = [],
    partners: allPartners = [],
    attributes: allAttributes = [],
    ...restFiltersOptions
  } = {},
}: BuilderFormProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const canViewAttr = useSelector(selectCanViewAttr);
  const backendDimensions = useSelector(selectBackendEnforcedDimensions);
  const filtersWithOptions = useSelector(selectFiltersWithOptions);

  const drilldownLabel = intl.formatMessage({
    id: 'aa.label.drilldown',
    defaultMessage: 'drilldown',
  });
  const indexLabel = intl.formatMessage({
    id: 'aa.label.index',
    defaultMessage: 'index',
  });

  const dimensionOptions = useMemo(() => {
    const addLockedLabel = (option: ReturnType<typeof getOptions>[number]) => {
      const matchedBackendDimension = find(
        ['slug', option.value],
        backendDimensions
      );
      const isLocked = Boolean(matchedBackendDimension);
      const lockedLabel = matchedBackendDimension?.isHidden
        ? indexLabel
        : drilldownLabel;

      return {
        ...option,
        label: isLocked ? `${option.label} (${lockedLabel})` : option.label,
        disabled: isLocked,
      };
    };

    return flow(getOptions, map(addLockedLabel))(allDimensions);
  }, [allDimensions, backendDimensions, drilldownLabel, indexLabel]);

  const metricsOptions = useMemo(
    () =>
      flow(
        pickMetricFilterOptionsNames,
        mapValues(getOptions)
      )(restFiltersOptions),
    [restFiltersOptions]
  );
  const appOptions = useMemo(() => getOptions(allApps), [allApps]);
  const countryOptions = useMemo(
    () => getOptions(allCountries),
    [allCountries]
  );
  const networkOptions = useMemo(() => getOptions(allNetworks), [allNetworks]);
  const partnerOptions = useMemo(() => getOptions(allPartners), [allPartners]);
  const attributeOptions = useMemo(
    () =>
      getOptions(allAttributes).map(attribute => ({
        ...attribute,
        label: intl.formatMessage({id: attribute.label}),
      })),
    [allAttributes, intl]
  );

  const cohortsFilterWithOptions = useMemo(() => {
    const cohortsFilter = find({filter_param: 'cohorts'}, filtersWithOptions);

    if (!cohortsFilter) {
      return undefined;
    }

    return {
      ...cohortsFilter,
      input: inputComponents[cohortsFilter.component](intl, cohortsFilter),
    };
  }, [filtersWithOptions, intl]);

  const skadsFilterWithOptions = useMemo(() => {
    const skadsFilter = find({filter_param: 'skads'}, filtersWithOptions);

    if (!skadsFilter) {
      return undefined;
    }

    return {
      ...skadsFilter,
      input: inputComponents[skadsFilter.component](intl, skadsFilter),
    };
  }, [filtersWithOptions, intl]);

  const handleFormSubmit = useCallback(
    data => {
      const validData = normalizeForm(data);
      dispatch(
        applyReportParams({
          params: validData,
          skipDataReloading: isEqual(validData, initialValues),
        })
      );

      onSubmit?.(data);
    },
    [dispatch, initialValues, onSubmit]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateBuilderForm}
      subscription={{
        submitting: true,
        pristine: true,
        invalid: true,
        errors: true,
      }}
    >
      {({handleSubmit, errors}) => (
        <form onSubmit={handleSubmit}>
          {showTitle && (
            <div className={cssTitleContainer}>
              <Title className={cssTitle}>
                <FormattedMessage
                  id='aa.builder.title'
                  defaultMessage='Configure report'
                />
              </Title>

              <SpaceFiller />

              <ButtonsBlock pendoId='Top' />
            </div>
          )}

          <Field
            name='quick.app_token__in'
            // @ts-expect-error wrong type of component
            component={EnhancedMetricSelect}
            title={intl.formatMessage({
              id: 'aa.builder.section.apps',
              defaultMessage: 'Apps',
            })}
            multiline
            smallPadding={false}
            allowSelectNew
            allowNoSelection
            format={identity}
            parse={identity}
            allMetrics={appOptions}
          />

          <PaddedSection
            pendoId='builder-countries'
            label={intl.formatMessage({
              id: 'aa.builder.section.countries',
              defaultMessage: 'Countries',
            })}
          >
            <Field
              className={cssFieldWidth}
              name='quick.country_code__in'
              component={FormSelect}
              limitOptionsTo={200}
              inputName='countries'
              plainValue
              isMulti
              options={countryOptions}
              placeholder={intl.formatMessage({
                id: 'aa.label.select',
                defaultMessage: 'Select',
              })}
            />
          </PaddedSection>

          <Field
            name='quick.partner_id__in'
            // @ts-expect-error wrong type of component
            component={EnhancedMetricSelect}
            title={intl.formatMessage({
              id: 'aa.builder.section.partners',
              defaultMessage: 'Partners',
            })}
            multiline
            smallPadding
            allowSelectNew
            allowNoSelection
            format={identity}
            parse={identity}
            allMetrics={partnerOptions}
          />

          <PaddedSection
            pendoId='builder-network'
            label={intl.formatMessage({
              id: 'aa.builder.section.networks',
              defaultMessage: 'Networks',
            })}
          >
            <Field
              className={cssFieldWidth}
              name='quick.network__in'
              component={FormSelect}
              limitOptionsTo={200}
              inputName='networks'
              plainValue
              isMulti
              options={networkOptions}
              placeholder={intl.formatMessage({
                id: 'aa.label.select',
                defaultMessage: 'Select',
              })}
            />
          </PaddedSection>

          <Field
            name='quick.dimensions'
            // @ts-expect-error wrong type of component
            component={EnhancedMetricSelect}
            multiline
            smallPadding
            allowSelectNew
            format={identity}
            parse={identity}
            title={intl.formatMessage({
              id: 'aa.builder.section.dimensions',
              defaultMessage: 'Dimensions',
            })}
            errorMessage={
              errors?.dimensions
                ? intl.formatMessage({
                    id: errors.dimensions,
                  })
                : ''
            }
            allMetrics={dimensionOptions}
          />

          {/* // TODO: Render metric sections dynamically */}
          {canViewAttr && (
            <PaddedSection
              pendoId='builder-attributes'
              label={intl.formatMessage({
                id: 'aa.builder.section.attributes',
                defaultMessage: 'Attributes',
              })}
            >
              <Field
                className={cssFieldWidth}
                name='quick.attributes'
                component={FormSelect}
                limitOptionsTo={200}
                inputName='attributes'
                plainValue
                isMulti
                options={attributeOptions}
                placeholder={intl.formatMessage({
                  id: 'aa.label.select',
                  defaultMessage: 'Select',
                })}
              />
            </PaddedSection>
          )}

          <Field
            name='quick.overviews'
            // @ts-expect-error wrong type of component
            component={EnhancedMetricSelect}
            title={intl.formatMessage({
              id: 'aa.builder.section.deliverableKPIs',
              defaultMessage: 'Deliverable KPIs',
            })}
            multiline
            smallPadding
            allowSelectNew
            allowNoSelection
            format={identity}
            parse={identity}
            errorMessage={
              errors?.overviews
                ? intl.formatMessage({id: errors.overviews})
                : ''
            }
            allMetrics={metricsOptions.overview_metrics}
          />

          {isPopulated(metricsOptions.skad_metrics) && (
            <PaddedSection
              pendoId='builder-skads'
              label={intl.formatMessage({
                id: 'aa.filters.report.skads.label',
                defaultMessage: 'SKAdNetwork KPIs',
              })}
              errorMessage={
                errors?.skads ? intl.formatMessage({id: errors.skads}) : ''
              }
            >
              <Field
                className={cssFieldWidth}
                name='quick.skads'
                component={skadsFilterWithOptions?.input.component}
                {...skadsFilterWithOptions?.input.componentProps}
              />
            </PaddedSection>
          )}

          {cohortsFilterWithOptions && (
            <PaddedSection
              pendoId='builder-cohorts'
              label={intl.formatMessage({
                id: 'aa.builder.section.cohortsKPIs.label',
                defaultMessage: 'Cohorted KPIs',
              })}
              errorMessage={
                errors?.cohorts ? intl.formatMessage({id: errors.cohorts}) : ''
              }
            >
              <Field
                className={cssFieldWidth}
                name='quick.cohorts'
                component={cohortsFilterWithOptions.input.component}
                placeholder={intl.formatMessage({
                  id: 'aa.label.select',
                  defaultMessage: 'Select',
                })}
                {...cohortsFilterWithOptions.input.componentProps}
              />
            </PaddedSection>
          )}

          <PaddedSection
            pendoId='builder-event'
            label={intl.formatMessage({
              id: 'aa.builder.section.eventKPIs',
              defaultMessage: 'Event KPIs',
            })}
            errorMessage={
              errors?.events ? intl.formatMessage({id: errors.events}) : ''
            }
          >
            <Field
              className={cssFieldWidth}
              name='quick.events'
              component={FormSelect}
              limitOptionsTo={200}
              inputName='event_metrics'
              plainValue
              isMulti
              options={metricsOptions.event_metrics}
              placeholder={intl.formatMessage({
                id: 'aa.label.select',
                defaultMessage: 'Select',
              })}
            />
          </PaddedSection>

          <div className={cssButtonsBlockWrapper}>
            <ButtonsBlock pendoId='Bottom' />
          </div>
        </form>
      )}
    </Form>
  );
};
