import React from 'react';

import MuiBackdrop from '@material-ui/core/Backdrop';

import {cssBackdrop} from './Backdrop.styles';

export const BACKDROP_Z_INDEX = 1200;

export const BACKDROP_TEST_ID = 'BACKDROP_TEST_ID';

type BackdropProps = {
  onClick: (args: any) => void;
  open?: boolean;
};
export const Backdrop = ({onClick, open = true}: BackdropProps) => (
  <MuiBackdrop
    onClick={onClick}
    open={open}
    className={cssBackdrop}
    data-testid={BACKDROP_TEST_ID}
  />
);
