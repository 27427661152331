import {useCallback} from 'react';

import {find, pick, curry, isEmpty, trim} from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {
  showInformationDialog,
  showInputDialog,
  showToast,
} from 'modules/common/actions';
import {
  loadDuplicateReportRequest,
  loadDeleteReportRequest,
  removeDeletedReports,
  loadUpdateReportRequest,
} from 'modules/report/actions';
import {selectEnrichedReports} from 'modules/report/reducers';

import {
  createDeleteReportDialogParams,
  createSaveReportDialogParams,
  REPORT_DELETED_TOAST_PARAMS,
  REPORT_RENAMED_TOAST_PARAMS,
  REPORT_SAVED_TOAST_PARAMS,
} from './dialog';

import {EnrichedReport} from 'modules/report/types';

const validateReportName = curry(
  (allReports: EnrichedReport[], reportName: string) =>
    !find(
      ({title, isDeleted}) => !isDeleted && title === reportName,
      allReports
    )
);

export const useNameValidator = () => {
  const intl = useIntl();
  const allReports = useSelector(selectEnrichedReports);

  const validator = useCallback(
    name => {
      const sanitizedName = trim(name);
      let error;

      if (!sanitizedName || isEmpty(sanitizedName)) {
        error = intl.formatMessage({
          id: 'aa.label.errorNoEmptyValue',
          defaultMessage: 'Name is required',
        });
      }

      if (!validateReportName(allReports, sanitizedName)) {
        error = intl.formatMessage({
          id: 'aa.saveReportDialog.reportAlreadyExists',
        });
      }

      return error;
    },
    [intl, allReports]
  );

  return validator;
};

export const useRenameReportModal = (report?: EnrichedReport) => {
  const dispatch = useDispatch();
  const validate = useNameValidator();

  const handleRename = useCallback(() => {
    if (report) {
      const renameReport = (newReportName: string) => {
        dispatch(loadUpdateReportRequest({...report, title: newReportName}));
        dispatch(showToast(REPORT_RENAMED_TOAST_PARAMS));
      };

      dispatch(
        showInputDialog(
          createSaveReportDialogParams({
            pendoId: 'rename',
            headerLabelKey: 'aa.renameReportDialog.header',
            onSubmit: renameReport,
            validate,
            initialInputValue: report.title,
          })
        )
      );
    }
  }, [report, dispatch, validate]);

  return handleRename;
};

export const useDeleteReportModal = (report?: EnrichedReport) => {
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    if (report) {
      const deleteReport = () => {
        dispatch(loadDeleteReportRequest(report.id));
        dispatch(removeDeletedReports());
        dispatch(showToast(REPORT_DELETED_TOAST_PARAMS));
      };

      dispatch(
        showInformationDialog({
          ...createDeleteReportDialogParams(report, deleteReport),
          pendoId: 'delete',
        })
      );
    }
  }, [dispatch, report]);

  return handleDelete;
};

const transformToCreateReportPayload: (
  report: EnrichedReport
) => Pick<EnrichedReport, 'url' | 'title'> = pick(['url', 'title']);

export const useDuplicateReportModal = (report?: EnrichedReport) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const validate = useNameValidator();

  const handleDuplicate = useCallback(() => {
    if (report) {
      const duplicateReport = (newReportName: string) => {
        const newReportPayload = transformToCreateReportPayload({
          ...report,
          title: newReportName,
        });

        dispatch(loadDuplicateReportRequest(newReportPayload));
        dispatch(showToast(REPORT_SAVED_TOAST_PARAMS));
      };

      const initialDuplicateReportName = intl.formatMessage(
        {
          id: 'aa.placeholder.copyOf',
          defaultMessage: 'Copy of { item }',
        },
        {
          item: report.title,
        }
      );

      dispatch(
        showInputDialog(
          createSaveReportDialogParams({
            pendoId: 'duplicate',
            onSubmit: duplicateReport,
            validate,
            initialInputValue: initialDuplicateReportName,
          })
        )
      );
    }
  }, [report, dispatch, intl, validate]);

  return handleDuplicate;
};
