import React, {memo} from 'react';

import {isEqual, upperFirst} from 'lodash/fp';

import {AttributeValue} from '../types';

type Props = {
  // We met false-positive case. Eslint doesn't see that we use attrData in getValue function, so it thinks that we don't use attrData at all
  // eslint-disable-next-line react/no-unused-prop-types
  attrData: AttributeValue;
};

const getValue = ({attrData}: Props) => attrData.value.value ?? '';

const TextCell = (props: Props) => {
  const value = getValue(props);
  return <span>{upperFirst(value)}</span>;
};

const isSameValue = (prevProps: Props, nextProps: Props) =>
  isEqual(getValue(prevProps), getValue(nextProps));

export default memo(TextCell, isSameValue);
