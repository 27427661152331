import moment from 'moment';

import {DASHED_DATE_FORMAT} from 'utils/dateUtils';

export const today = () => moment.utc().startOf('day');

export const convertToUtc = date =>
  moment.utc(date.format(DASHED_DATE_FORMAT), DASHED_DATE_FORMAT);

export function convertFromApiDate(date, utc = true) {
  if (!date) {
    return null;
  }

  if (utc) {
    return moment.utc(date, DASHED_DATE_FORMAT);
  }

  return moment(date, DASHED_DATE_FORMAT);
}

export function convertToApiDate(date, utc = true) {
  if (!date) {
    return null;
  }

  if (utc) {
    return moment.utc(date).format(DASHED_DATE_FORMAT);
  }

  return moment(date).format(DASHED_DATE_FORMAT);
}
