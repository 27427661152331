import {createContext} from 'react';

import {noop} from 'lodash/fp';

import {CVSchemes} from './constants';

export const SchemeContext = createContext({
  scheme: CVSchemes.Purchases,
  setScheme: noop,
});
