import React, {useEffect} from 'react';

import {css} from '@linaria/core';
import {useDispatch, useSelector} from 'react-redux';

import {LoadingIndicator} from 'common-components';
import {useExpandableGrid} from 'common-components/ModernGrid/extensions/ExpandableGrid/useExpandableGrid';
import {
  initReportRequest,
  closeAutomationPanel,
  reportScreenUnmounted,
} from 'modules/report/actions/ReportActions';
import {selectIsReportInitialized} from 'modules/report/reducers';
import {selectIsDebugMode} from 'modules/report/reducers/debugInfo';

import {AutomationSidePanel} from './AutomationSidePanel/AutomationSidePanel';
import {ReportTable} from './Table';

import {ReportSettings} from 'modules/report/components/ReportSettings';
import ReportSandbox from 'modules/report/components/Sandbox/ReportSandbox';

const cssWrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const cssLoadingIndicator = css`
  margin-top: 20px;
`;

const MINIMUM_VISIBLE_ROWS_THRESHOLD = 5;

export const TableScreen = () => {
  const dispatch = useDispatch();

  const isReportInitialized = useSelector(selectIsReportInitialized);
  const debug = useSelector(selectIsDebugMode);

  const {ResizingContainer} = useExpandableGrid({
    minimumVisibleRowsThreshold: MINIMUM_VISIBLE_ROWS_THRESHOLD,
  });

  useEffect(() => {
    // Error prone, bug counter: 1
    if (!isReportInitialized) {
      dispatch(initReportRequest());
    }
  }, [dispatch, isReportInitialized]);

  // Close panel on unmount
  useEffect(
    () => () => {
      dispatch(closeAutomationPanel());
    },
    [dispatch]
  );

  useEffect(
    () => () => {
      dispatch(reportScreenUnmounted());
    },
    [dispatch]
  );

  return (
    <div className={cssWrapper}>
      {debug && <ReportSandbox />}

      {!isReportInitialized && (
        <LoadingIndicator className={cssLoadingIndicator} />
      )}

      {isReportInitialized && (
        <ResizingContainer>
          <ReportSettings />
          <ReportTable />
          <AutomationSidePanel />
        </ResizingContainer>
      )}
    </div>
  );
};
