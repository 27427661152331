import React, {useCallback, useMemo, useRef} from 'react';

import {css} from '@linaria/core';
import classnames from 'classnames';
import {
  filter,
  compact,
  find,
  identity,
  map,
  noop,
  omit,
  uniq,
  flow,
} from 'lodash/fp';
import Select, {components} from 'react-select';
import AsyncSelect from 'react-select/async';

import {useIsRenderedOutsideScreen} from 'common-components/hooks';
import {getOptions} from 'common-components/utils/getOptions';
import {
  useIntl,
  FormattedMessage,
} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';
import {debouncedCancellableFetchFiltersOptions} from 'modules/common/api';

import {
  craftFuzzyMatchScorer,
  getDistinctCollidingLabelsOptions,
  shortNameFilterOption,
} from './utils';

import {Option} from './components/Options';

const cssRightAlignedMenu = css`
  right: 0;
`;

export const ASYNC_SEARCH_QUERY_LENGTH_THRESHOLD = 10;
export const ASYNC_SEARCH_OPTIONS_LENGTH_THRESHOLD = 500;
const COMPACT_MENU_WIDTH = 290;

export const selectNewTheme = {
  borderRadius: 0,
  spacing: {
    baseUnit: 4,
    controlHeight: 34,
    menuGutter: 0,
  },
};
const compactStyles = {
  multiValue: {paddingRight: 3},
};
const cellStyle = {
  control: {borderBottom: 'none'},
  indicatorSeparator: {display: 'none'},
  dropdownIndicator: {
    color: '#96a1bd',
    position: 'absolute',
    marginLeft: 55,
    '&:hover': {
      color: '#4b577e',
    },
  },
  singleValue: {marginLeft: 0, fontSize: 13},
  valueContainer: {position: 'absolute', width: 80},
  menu: {width: 100, fontSize: 13, zIndex: 1},
};
const smallStyle = {
  container: {fontSize: 12},
  control: {minHeight: null, height: 18, borderBottom: 'solid 1px #b5bdd1'},
  indicatorSeparator: {display: 'none'},
  dropdownIndicator: {display: 'none', padding: '8px 4px'},
  clearIndicator: {
    padding: 0,
    transform: 'scale(0.8)',
    transformOrigin: 'right',
  },
  valueContainer: {height: 18, position: 'none'},
};
const disabledStyle = {
  placeholder: {
    color: styleVariables.disabledColor,
  },
  control: {
    borderBottomColor: styleVariables.disabledColor,
  },
  dropdownIndicator: {
    color: styleVariables.disabledColor,
  },
  indicatorSeparator: {
    backgroundColor: styleVariables.disabledColor,
  },
};

const disabledStyleInDarkBackground = {
  placeholder: {
    color: styleVariables.colorLightGrey,
  },
  control: {
    borderBottomColor: styleVariables.colorLightGrey,
  },
};

const darkBackgroundContrastStyles = {
  indicatorSeparator: {display: 'none'},
  dropdownIndicator: {color: '#96a1bd'},
  clearIndicator: {color: '#96a1bd'},
  singleValue: {color: 'white'},
};

export const selectNewStylesOverride = {
  container: (style, {selectProps: {isSmall}}) => ({
    ...style,
    fontSize: 14,
    minWidth: 100,
    ...(isSmall ? smallStyle.container : {}),
  }),
  control: (
    style,
    {selectProps: {isSmall, isCell, isDisabled, hasDarkBackground}}
  ) => ({
    ...style,
    background: 'transparent',
    borderWidth: 0,
    borderBottom: 'solid 2px #b5bdd1',
    cursor: 'pointer',
    boxShadow: 'none',
    color: '#96a1bd',
    '&:hover': {
      borderBottomColor: '#4b577e',
    },
    ...(isCell ? cellStyle.control : {}),
    ...(isSmall ? smallStyle.control : {}),
    ...(isDisabled ? disabledStyle.control : {}),
    ...(isDisabled && hasDarkBackground
      ? disabledStyleInDarkBackground.control
      : {}),
    /* ,
    '&:hover .placeholder': {
      backgroundColor: 'red',
      color: '#4b577e'
    }
    */
  }),
  indicatorSeparator: (
    style,
    {selectProps: {isSmall, isCell, isDisabled, hasDarkBackground}}
  ) => ({
    ...style,
    ...(isCell ? cellStyle.indicatorSeparator : {}),
    ...(isSmall ? smallStyle.indicatorSeparator : {}),
    ...(isDisabled ? disabledStyle.indicatorSeparator : {}),
    ...(hasDarkBackground
      ? darkBackgroundContrastStyles.indicatorSeparator
      : {}),
  }),
  dropdownIndicator: (
    style,
    {selectProps: {isSmall, isCell, isDisabled, hasDarkBackground}}
  ) => ({
    ...style,
    ...(isSmall ? smallStyle.dropdownIndicator : {}),
    ...(isCell ? cellStyle.dropdownIndicator : {}),
    ...(isDisabled ? disabledStyle.dropdownIndicator : {}),
    ...(hasDarkBackground
      ? darkBackgroundContrastStyles.dropdownIndicator
      : {}),
  }),
  singleValue: (style, {selectProps: {isCell, hasDarkBackground}}) => ({
    ...style,
    ...(isCell ? cellStyle.singleValue : {}),
    ...(hasDarkBackground ? darkBackgroundContrastStyles.singleValue : {}),
  }),
  clearIndicator: (style, {selectProps: {isSmall, hasDarkBackground}}) => ({
    ...style,
    paddingLeft: 0,
    paddingRight: 4,
    ...(isSmall ? smallStyle.clearIndicator : {}),
    ...(hasDarkBackground ? darkBackgroundContrastStyles.clearIndicator : {}),
  }),
  placeholder: (
    style,
    {selectProps: {menuIsOpen, isDisabled, hasDarkBackground}}
  ) => ({
    ...style,
    transition: 'all .3s ease-in-out',
    color: menuIsOpen ? '#4b577e' : '#96a1bd',
    marginLeft: 0,
    ...(isDisabled ? disabledStyle.placeholder : {}),
    ...(isDisabled && hasDarkBackground
      ? disabledStyleInDarkBackground.placeholder
      : {}),
  }),
  input: style => ({
    ...style,
    marginLeft: 0,
  }),
  valueContainer: (
    style,
    {selectProps: {isSmall, isCell, noWrap = false}}
  ) => ({
    ...style,
    ...(noWrap ? {flexWrap: 'nowrap'} : {}),
    padding: '2px 0',
    ...(isSmall ? smallStyle.valueContainer : {}),
    ...(isCell ? cellStyle.valueContainer : {}),
  }),
  multiValue: (style, {selectProps: {index, isCompact}}) => ({
    ...style,
    ...(isCompact ? compactStyles.multiValue : {}),
    ...(index === 0 ? {flexShrink: 0} : {}),
  }),
  menu: (style, {selectProps: {isCell, isCompact, isMulti}}) => ({
    ...style,
    color: '#fff',
    backgroundColor: '#4b577e',
    zIndex: 1500,
    ...(isCompact
      ? {
          position: 'absolute',
        }
      : {}),
    ...(isCompact && isMulti
      ? {
          width: `${COMPACT_MENU_WIDTH}px`,
        }
      : {}),
    ...(isCell ? cellStyle.menu : {}),
  }),
  option: (style, {isFocused, isDisabled}) => ({
    ...style,
    color: 'inherit',
    cursor: 'pointer',
    backgroundColor: 'inherit',
    ...(!isDisabled
      ? {
          '&:hover, &:active, &:focus': {
            backgroundColor: '#5d6a8c',
          },
        }
      : {}),
    ...(isFocused && !isDisabled ? {backgroundColor: '#5d6a8c'} : {}),
  }),
  noOptionsMessage: style => ({
    ...style,
    color: 'inherit',
  }),
};

const ShortNameAwareMultiValueLabel = ({data, children, ...props}) => (
  <components.MultiValueLabel {...props}>
    {data?.shortName || children}
  </components.MultiValueLabel>
);
const ShortNameAwareSingleValueLabel = ({data, children, ...props}) => (
  <components.SingleValue {...props}>
    {data?.shortName || children}
  </components.SingleValue>
);

const Menu = ({children, ...props}) => {
  const {
    selectProps: {isCompact},
  } = props;
  const ref = useRef();
  const isOutside = useIsRenderedOutsideScreen(ref);

  return (
    <components.Menu
      className={classnames({[cssRightAlignedMenu]: isOutside && isCompact})}
      {...props}
    >
      <div ref={ref}>{children}</div>
    </components.Menu>
  );
};

// react-select uses AutoSizeInput by default which is VERY slow and not really needed
const NONASSIGNABLE_INPUT_PROPS = [
  'clearValue',
  'getValue',
  'hasValue',
  'isMulti',
  'isRtl',
  'selectOption',
  'setValue',
  'onLoadOptions',
  'loadedOptionsEnhancer',
];
const Input = ({
  className,
  cx,
  innerRef,
  isDisabled,
  isHidden,
  getStyles,
  theme,
  selectProps: {menuIsOpen, hasDarkBackground},
  ...props
}) => {
  const sanitizedProps = omit(NONASSIGNABLE_INPUT_PROPS, props);

  return (
    <input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...sanitizedProps}
      ref={innerRef}
      className={cx({input: true}, className)}
      style={{
        ...getStyles('input', {theme, ...props}),
        background: 'transparent',
        border: 0,
        fontSize: 'inherit',
        opacity: isHidden ? 0 : 1,
        outline: 0,
        padding: 0,
        ...(menuIsOpen ? {flex: 1} : {}),
        ...(hasDarkBackground ? {color: 'white'} : {}),
      }}
      size={menuIsOpen ? undefined : 1}
      disabled={isDisabled}
    />
  );
};

export const CLEAR_INDICATOR_TEST_ID = 'CLEAR_INDICATOR_TEST_ID';
const CustomClearIndicator = props => (
  <div data-testid={CLEAR_INDICATOR_TEST_ID}>
    <components.ClearIndicator {...props} />
  </div>
);

const limitOptions = (
  results,
  limit,
  intl,
  mustAppendLimitedMessageOption = false
) => {
  if (!limit || (!mustAppendLimitedMessageOption && results.length <= limit)) {
    return results;
  }

  const message = intl.formatMessage(
    {
      id: 'aa.select.resultsLimitedTo',
      defaultMessage: 'Results are limited to {limit} items.',
    },
    {limit}
  );

  const resultsLimitedOption = {
    label: message,
    title: message,
    value: '*results_limited',
    isDisabled: true,
    showCheckbox: false,
  };
  const trimmedResults = [...results.slice(0, limit), resultsLimitedOption];

  return trimmedResults;
};

const SelectNew = ({
  style = {},
  styles: stylesProp = {},
  components: componentsOverride = {},
  theme: themeOverride = {},
  plainValue = false,
  value,
  onChange,
  onLoadOptions = noop,
  loadedOptionsEnhancer = identity,
  allowInconsistentValues = false,
  options,
  limitOptionsTo = null,
  pendoId = null,
  isMulti = false,
  isClearable = false,
  inputName = '',
  ...otherProps
}) => {
  const intl = useIntl();

  const enhancedStyles = useMemo(
    () => ({
      ...selectNewStylesOverride,
      container: (...args) => ({
        ...selectNewStylesOverride?.container?.(...args),
        ...style,
      }),
      ...stylesProp,
    }),
    [style, stylesProp]
  );

  const distinctLabelsOptions = getDistinctCollidingLabelsOptions(options);
  const enhancedOptions = useMemo(() => {
    if (!allowInconsistentValues || value === '') return distinctLabelsOptions;

    const unrecognizedValues = isMulti ? value : [value];
    const additionalValues = filter(
      val => !find({value: val}, distinctLabelsOptions),
      unrecognizedValues
    );
    const additionalOptions = map(
      val => ({
        label: val,
        value: val,
      }),
      additionalValues
    );

    return [...distinctLabelsOptions, ...additionalOptions];
  }, [distinctLabelsOptions, allowInconsistentValues, isMulti, value]);

  const modifiedValue = useMemo(() => {
    if (!plainValue) {
      return value;
    }

    if (plainValue && !value) {
      return null;
    }

    if (!isMulti) {
      // null is crucial here. If value is undefined react-select
      // will be using internal state to store value.
      return find({value}, enhancedOptions) || null;
    }

    return compact(
      map(selValue => find({value: selValue}, enhancedOptions), value)
    );
  }, [enhancedOptions, value, plainValue, isMulti]);

  const optionsFuzzySearch = useMemo(
    () => craftFuzzyMatchScorer(enhancedOptions),
    [enhancedOptions]
  );

  const loadOptions = useCallback(
    async (query, cb) => {
      const shouldUseAsyncSearch =
        query.length >= ASYNC_SEARCH_QUERY_LENGTH_THRESHOLD ||
        enhancedOptions.length >= ASYNC_SEARCH_OPTIONS_LENGTH_THRESHOLD;

      let foundOptions;
      let mustAppendLimitedMessageOption = false;

      if (!shouldUseAsyncSearch) {
        const optionMatchedByExactValue =
          isMulti && find({value: query}, enhancedOptions);

        const optionsMatchedByLabelQuery = query
          ? optionsFuzzySearch(query)
          : enhancedOptions;

        const rawFoundOptions = [
          optionMatchedByExactValue,
          ...optionsMatchedByLabelQuery,
        ];

        foundOptions = flow(uniq, compact)(rawFoundOptions);
      } else {
        const filterName = inputName;
        const {[filterName]: asyncOptions} =
          await debouncedCancellableFetchFiltersOptions({
            filters: [filterName],
            filtersQueries: {[filterName]: query},
          });

        foundOptions = getOptions(asyncOptions);
        mustAppendLimitedMessageOption = asyncOptions.length === limitOptionsTo;
      }

      const enhancedFoundOptions = loadedOptionsEnhancer(foundOptions);

      onLoadOptions(enhancedFoundOptions);

      cb(
        limitOptions(
          enhancedFoundOptions,
          limitOptionsTo,
          intl,
          mustAppendLimitedMessageOption
        )
      );
    },
    [
      loadedOptionsEnhancer,
      onLoadOptions,
      limitOptionsTo,
      intl,
      isMulti,
      enhancedOptions,
      optionsFuzzySearch,
      inputName,
    ]
  );

  const defaultOptions = useMemo(
    () => limitOptions(enhancedOptions, limitOptionsTo, intl),
    [enhancedOptions, limitOptionsTo, intl]
  );

  const handleOnChange = useCallback(
    (option, ...args) => {
      onChange(isMulti ? map('value', option) : option?.value, ...args);
    },
    [onChange, isMulti]
  );
  const asyncMode = !!limitOptionsTo;
  const SelectComponent = asyncMode ? AsyncSelect : Select;

  return (
    <div data-pendoid={pendoId}>
      <SelectComponent
        menuPlacement='auto'
        {...otherProps}
        theme={{
          ...selectNewTheme,
          ...themeOverride,
        }}
        styles={enhancedStyles}
        components={{
          MultiValueLabel: ShortNameAwareMultiValueLabel,
          SingleValue: ShortNameAwareSingleValueLabel,
          Input,
          ClearIndicator: CustomClearIndicator,
          Menu,
          Option,
          ...componentsOverride,
        }}
        noOptionsMessage={() => (
          <FormattedMessage
            id='aa.label.noMatchingOptions'
            defaultMessage='No matching options'
          />
        )}
        options={enhancedOptions}
        // enhancedOptions are sometimes empty when component is mounted
        // so we need to always provide defaultOptions. Otherwise select will
        // have no options when query is empty.
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        value={modifiedValue}
        onChange={plainValue ? handleOnChange : onChange}
        isMulti={isMulti}
        isClearable={isClearable || isMulti}
        filterOption={asyncMode ? undefined : shortNameFilterOption}
      />
    </div>
  );
};

export default SelectNew;
