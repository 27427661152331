import {createReducer} from '@reduxjs/toolkit';
import {
  assign,
  constant,
  difference,
  filter,
  find,
  findIndex,
  findIndexFrom,
  flatMap,
  flow,
  get,
  includes,
  isEmpty,
  isEqual,
  keyBy,
  map,
  mapValues,
  merge,
  omit,
  overEvery,
  pick,
  set,
  slice,
  sortBy,
  toString,
  unary,
  union,
  update,
} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';

import {history} from 'browserHistory';
import {
  selectCanViewAttr,
  selectCustomerReports,
} from 'modules/common/reducers/customerConfig';
import * as ReportActions from 'modules/report/actions/ReportActions';
import {chartsOpen} from 'modules/report/reducers/chartsOpen';
import {debugInfo} from 'modules/report/reducers/debugInfo';
import {quickFilterOpen} from 'modules/report/reducers/quickFilterOpen';
import {flattenAttributeResponse, isMoneyAttribute} from 'utils/attributes';
import {processFilters} from 'utils/filters';
import {someValues, uncappedMapValues} from 'utils/lodash+';
import {prepareOpsForAttrs} from 'utils/operations';
import {PARENT_REPORT_ID} from 'utils/parentReportId';
import {combineActions} from 'utils/reduxUtils';
import {extractMetricFilterOptionsNames} from 'utils/reportFilters';
import {
  allWarningsSelectorFactory,
  extractAttributesFormattingsFromFiltersOptions,
} from 'utils/selectors';

import {
  params,
  selectQuickParams,
  selectColumnFiltersInputValues,
} from './params';

import type {ReportSpec, CustomerConfigReport, EnrichedReport} from '../types';
import type {
  RootState,
  FiltersOptions,
  FiltersWithOptions,
  Filters,
  RowData,
  AttributeValue,
  Filter,
} from 'modules/common/types';
import type {BE_types} from 'types/backendServicesTypes';

type ReportState = {
  data: RowData[] | null;
  warnings: BE_types['ReportResponseModel']['data_warnings'];
  total: {[field: string]: any} | null;
  isLoading: boolean;
  isLoaded: boolean;
  error?: boolean | string | Error | null;
};
const initialReportState: ReportState = {
  data: null,
  warnings: [],
  total: null,
  isLoading: false,
  isLoaded: false,
  error: null,
};
const table = createReducer<ReportState>(initialReportState, builder =>
  builder
    .addCase(
      ReportActions.applyReportParams,
      (state, {payload: {skipDataReloading}}) =>
        skipDataReloading ? state : initialReportState
    )
    .addCase(ReportActions.loadReportDataSuccess, (state, {payload}: any) => ({
      ...state,
      data: payload.rows,
      warnings: payload.data_warnings,
      total: payload.totals,
      isLoading: false,
      isLoaded: true,
    }))
    .addCase(
      ReportActions.loadAddPivotReportDataRequest,
      (state, {payload: hash}: {payload: string}) => {
        const rowDataIndex = findIndex({hash}, state.data);

        return update(
          `data[${rowDataIndex}]`,
          (row: RowData) => ({
            ...row,
            isLoading: true,
            indexStatus: 'opened',
          }),
          state
        );
      }
    )
    .addCase(
      ReportActions.loadAddPivotReportDataSuccess,
      (
        state,
        {
          payload: {hash, newPivotData},
        }: {
          payload: {
            hash: string;
            newPivotData: BE_types['ReportResponseModel'];
          };
        }
      ) =>
        update(
          'data',
          flatMap((row: RowData) =>
            row.hash === hash
              ? [
                  {
                    ...row,
                    isLoading: false,
                  },
                  ...newPivotData.rows!,
                ]
              : row
          ),
          state
        )
    )
    .addCase(
      ReportActions.hidePivotReportData,
      (state, {payload: mainParentHash}: {payload: string}) => {
        const {data} = state;

        const parentRowIndex = findIndex(['hash', mainParentHash], data);
        const parentRow = data![parentRowIndex];
        const nextElderRowIndex = findIndexFrom(
          ({indexLevel}: any) => indexLevel <= parentRow.indexLevel,
          parentRowIndex + 1,
          data
        );

        const endOfDataIndex = data!.length;
        const removeFrom = parentRowIndex + 1;
        const removeTo =
          nextElderRowIndex !== -1 ? nextElderRowIndex : endOfDataIndex;

        const updatedData = flow(
          set(`[${parentRowIndex}]`, {...parentRow, indexStatus: 'closed'}),
          (currentData: any[]) => [
            ...slice(0, removeFrom, currentData),
            ...slice(removeTo, endOfDataIndex, currentData),
          ]
        )(data);

        return set('data', updatedData, state);
      }
    )
    .addCase(
      ReportActions.loadReportDataFailure,
      (state, {payload: {error}}) => ({
        ...state,
        data: null,
        warnings: [],
        total: null,
        isLoading: false,
        isLoaded: true,
        error,
      })
    )
    .addCase(ReportActions.loadMoreReportAttrsSuccess, (state, {payload}) => {
      const answerHashes = Object.keys(
        payload[Object.keys(payload)[0]].mapping
      );

      const changedHashes = answerHashes.filter(hash => {
        const isAttrChanged = uncappedMapValues(
          (attribute: any, attrSlug) =>
            find({hash}, state.data)?.[attrSlug] !== attribute.mapping[hash],
          payload
        );
        return someValues(isAttrChanged);
      });

      if (isEmpty(changedHashes)) {
        // state stays unchanged, exactly same object
        return state;
      }

      const newData = state.data!.map(row => {
        if (answerHashes.includes(row.hash!)) {
          const rowAttributes = mapValues(
            attribute => attribute.mapping[row.hash!],
            payload
          );
          return {...row, ...rowAttributes};
        }

        return row;
      });

      return {
        ...state,
        data: newData,
      };
    })
    .addMatcher(
      combineActions([
        ReportActions.loadReportAttrsSuccess,
        ReportActions.reloadAttrsSuccess,
      ]),
      (state, {payload}) => {
        if (!state.isLoaded || state.data === null) {
          return state;
        }

        const answerRows = Object.keys(
          payload[Object.keys(payload)[0]].mapping
        ).map(unary(parseInt));

        const changedRows = answerRows.filter(rowNum => {
          const isAttrChanged = uncappedMapValues(
            (attribute: any, attrName) =>
              state.data?.[rowNum]?.[attrName] !== attribute.mapping[rowNum],
            payload
          );
          return someValues(isAttrChanged);
        });

        if (isEmpty(changedRows)) {
          // state stays unchanged, exactly same object
          return state;
        }

        const newData = state.data.map((row, idx) => {
          if (answerRows.includes(idx)) {
            const rowAttributes = mapValues(
              attribute => attribute.mapping[idx],
              payload
            );
            return {...row, ...rowAttributes};
          }

          return row;
        });
        return {
          ...state,
          data: newData,
        };
      }
    )
    .addMatcher(
      combineActions([
        ReportActions.loadReportDataRequest,
        ReportActions.navigateToReport,
      ]),
      () => ({
        ...initialReportState,
        isLoading: true,
      })
    )
);

const extractUpdatedAttrAmount =
  (attrUpdates: any) => (attr: AttributeValue) => {
    const {hash, type} = attr;
    if (isMoneyAttribute(type)) {
      const newAmount = find({hash}, attrUpdates)?.to;
      return set('value.amount', newAmount)(attr);
    }
    return attr;
  };

type AttributesState = {
  data: {
    [attr: string]: any;
  } | null;
  isLoading: boolean;
  isLoaded: boolean;
  error?: boolean | string | Error | null;
};
const initialAttributesState: AttributesState = {
  data: null,
  isLoading: false,
  isLoaded: false,
  error: null,
};
const attributesReducer = createReducer<AttributesState>(
  initialAttributesState,
  builder =>
    builder
      .addCase(ReportActions.loadReportAttrsRequest, state => ({
        ...state,
        isLoading: true,
        isLoaded: false,
      }))
      .addCase(ReportActions.loadReportAttrsSuccess, (_, {payload}) => ({
        data: flattenAttributeResponse(payload),
        isLoading: false,
        isLoaded: true,
      }))
      .addCase(
        ReportActions.loadCreateChangeGroupSuccess,
        (state, {payload}) => ({
          ...state,
          data: merge(state.data, prepareOpsForAttrs(payload)),
        })
      )
      .addCase(
        ReportActions.loadOpsForReportAttrsSuccess,
        (state, {payload}) => ({
          ...state,
          data: merge(state.data, payload),
        })
      )
      .addCase(
        ReportActions.loadReportAttrsFailure,
        (state, {payload: error}) => ({
          ...state,
          isLoading: false,
          isLoaded: true,
          error,
        })
      )
      .addCase(
        ReportActions.applyReportParams,
        (state, {payload: {skipDataReloading}}) =>
          skipDataReloading ? state : initialAttributesState
      )
      // optimistic update of status (to queued) for created changes
      .addCase(
        ReportActions.loadCreateChangeGroupApprove,
        (state, {payload: {attrUpdates}}) => {
          const changedHashes = map('hash')(attrUpdates);
          const updateAmount = mapValues(extractUpdatedAttrAmount(attrUpdates));
          const pickChangedAndSetQueued = flow(
            pick(changedHashes),
            mapValues(set('status', 'queued')),
            updateAmount
          );
          const changedAttrs = pickChangedAndSetQueued(state.data);
          const newData = merge(state.data, changedAttrs);
          return {...state, data: newData};
        }
      )
      .addCase(
        ReportActions.loadCreateChangeGroupRevertFailedUpdates,
        (state, {payload: {oldAttributeValues}}) => ({
          ...state,
          data: {
            ...state.data,
            ...oldAttributeValues,
          },
        })
      )
      .addMatcher(
        combineActions([
          ReportActions.reloadAttrsSuccess,
          ReportActions.loadMoreReportAttrsSuccess,
        ]),
        (state, {payload}) => {
          if (!state.data) {
            return state;
          }

          const payloadData = flattenAttributeResponse(payload);
          const newData = assign(state.data, payloadData);
          if (!isEqual(state.data, newData)) {
            return {...state, data: newData};
          }
          return state;
        }
      )
      .addMatcher(
        combineActions([
          ReportActions.loadReportDataRequest,
          ReportActions.navigateToReport,
        ]),
        () => initialAttributesState
      )
);

const filtersOptionsReducer = createReducer<FiltersOptions>({}, builder =>
  builder.addCase(ReportActions.setFiltersOptions, (state, {payload}) => ({
    ...state,
    ...payload,
  }))
);

const initialized = createReducer<boolean>(false, builder =>
  builder.addCase(ReportActions.initReportSuccess, constant(true))
);

export const initialReportSpecState: ReportSpec = {
  sort: '',
  dimensions: [],
  metrics: [],
};
export const reportSpec = createReducer<ReportSpec>(
  initialReportSpecState,
  builder =>
    builder
      .addCase(ReportActions.loadReportSpecSuccess, (_, {payload}) => payload)
      .addCase(
        ReportActions.applyReportParams,
        (state, {payload: {skipDataReloading}}) =>
          skipDataReloading ? state : initialReportSpecState
      )
      .addMatcher(
        combineActions([
          ReportActions.navigateToReport,
          ReportActions.setReportParams,
        ]),
        () => initialReportSpecState
      )
);

const changeInProgress = createReducer<boolean>(false, builder =>
  builder
    .addCase(ReportActions.loadCreateChangeGroupRequest, constant(true))
    .addMatcher(
      combineActions([
        ReportActions.loadCreateChangeGroupSuccess,
        ReportActions.loadCreateChangeGroupFailure,
      ]),
      constant(false)
    )
);

const isAutomationPanelOpen = createReducer<boolean>(false, builder =>
  builder
    // @deprecated
    .addCase(ReportActions.openAutomationPanel, constant(true))
    .addCase(ReportActions.closeAutomationPanel, constant(false))
    .addCase(ReportActions.toggleAutomationPanel, state => !state)
);

const filtersReducer = createReducer<Filters>([], builder =>
  builder.addCase(ReportActions.setFilters, (_, {payload}) => payload)
);

export default combineReducers({
  initialized,
  table,
  attributes: attributesReducer,
  params,
  debugInfo,
  filters: filtersReducer,
  filtersOptions: filtersOptionsReducer,
  reportSpec,
  changeInProgress,
  isAutomationPanelOpen,
  quickFilterOpen,
  chartsOpen,
});

export const selectAttrData = (state: RootState) =>
  state.report.attributes.data;
export const selectAttrLoading = (state: RootState) =>
  state.report.attributes.isLoading || !state.report.attributes.isLoaded;

export const selectReportData = (state: RootState) => state.report.table.data;
export const selectReportDataByHash = (state: RootState, hash: string) =>
  find({hash}, selectReportData(state));

// Filter by column header filters on the FE
export const selectFilteredReportData = createSelector(
  (state: RootState) => selectQuickParams(state)?.attributes,
  selectReportData,
  selectAttrData,
  selectColumnFiltersInputValues,
  (selectedAttrs, data, attrData, columnFilters) => {
    if (!data) {
      return [];
    }

    if (!attrData) {
      return data;
    }

    const filterDataByAttr =
      (attrSlug: BE_types['AttributeSlug']) =>
      (rowData: {[field: string]: string}) => {
        const filterValue = columnFilters[attrSlug];
        if (!filterValue) {
          return true;
        }

        const attrHash = rowData[attrSlug];
        const attr = attrData[attrHash];

        return (
          attr &&
          ((filterValue === 'editable' && !attr.readonly) ||
            (filterValue === 'known' && attr.type !== 'unknown') ||
            (filterValue === 'unknown' && attr.type === 'unknown'))
        );
      };

    const filteredData = filter(
      overEvery(map(filterDataByAttr, selectedAttrs))
    )(data);

    return filteredData;
  }
);
export const selectReportWarnings = (state: RootState) =>
  state.report.table.warnings;
export const selectReportTotalData = (state: RootState) =>
  state.report.table.total;
export const selectReportIsLoading = (state: RootState) =>
  state.report.table.isLoading || !state.report.table.isLoaded;

export const selectReportSpec = (state: RootState) => state.report.reportSpec;
export const selectDrilldownDimensionsSlugs = createSelector(
  selectReportSpec,
  ({dimensions}) => map('slug', dimensions)
);
export const selectReportSpecDimensions = createSelector(
  selectReportSpec,
  get('dimensions')
);
export const selectReportSpecMetrics = createSelector(
  selectReportSpec,
  ({metrics}) => metrics
);

export const selectRawFilters = (state: RootState) => state.report.filters;
const selectEnabledFilters = createSelector(
  selectRawFilters,
  filter<Filter>('enabled')
);

export const selectAllReportWarnings =
  allWarningsSelectorFactory(selectReportWarnings);

const selectFilters = createSelector(
  selectEnabledFilters,
  selectCanViewAttr,
  processFilters
);

const selectAllDimensions = createSelector(
  selectDrilldownDimensionsSlugs,
  selectQuickParams,
  (drilldownDimensionsSlugs, reportParams) =>
    union(drilldownDimensionsSlugs, reportParams?.dimensions)
);
const selectDrilldownHiddenDimensionsSlugs = createSelector(
  selectReportSpec,
  ({dimensions}) => flow(filter('isHidden'), map('slug'))(dimensions)
);
export const selectAllVisibleDimensions = createSelector(
  selectAllDimensions,
  selectDrilldownHiddenDimensionsSlugs,
  (allDimensionSlugs, hiddenDimensionSlugs) =>
    difference(allDimensionSlugs, hiddenDimensionSlugs)
);
export const selectBackendEnforcedDimensions = createSelector(
  selectReportSpecDimensions,
  selectQuickParams,
  (specDimensions, {dimensions: quickDimensions}) =>
    filter(({slug}) => !includes(slug, quickDimensions), specDimensions)
);
export const selectBackendEnforcedDimensionsSlugs = createSelector(
  selectBackendEnforcedDimensions,
  map('slug')
);
// filters values
export const selectFiltersOptions = (state: RootState) =>
  state.report.filtersOptions;

export const selectAllMetricFiltersOptions = createSelector(
  selectFiltersOptions,
  extractMetricFilterOptionsNames
);

export const selectAttributesFormattings = createSelector(
  selectFiltersOptions,
  extractAttributesFormattingsFromFiltersOptions
);

export const selectFiltersOptionsAttributes = (state: RootState) =>
  state.report.filtersOptions.attributes;
export const selectAttrsNameBySlug = createSelector(
  selectFiltersOptionsAttributes,
  options =>
    flow(keyBy('id'), mapValues('name'))(options) as {
      [key in BE_types['AttributeSlug']]: string;
    }
);

export const selectFiltersWithOptions = createSelector(
  selectFilters,
  selectFiltersOptions,
  (filters, filtersOptions): FiltersWithOptions =>
    map(
      filterObj => ({
        ...filterObj,
        options: filterObj.options_name
          ? pick(filterObj.options_name, filtersOptions)
          : null,
      }),
      filters
    )
);
export const selectIsReportInitialized = (state: RootState) =>
  state.report.initialized;

export const selectIsGroupChangeInProgress = (state: RootState) =>
  state.report.changeInProgress;
export const selectIsAutomationPanelOpen = (state: RootState) =>
  state.report.isAutomationPanelOpen;

const selectOrderedReports = createSelector(
  selectCustomerReports,
  sortBy<CustomerConfigReport>('order')
);
const selectQueryFromLocation = createSelector(
  () => history.location,
  location => parseSearch(location.search)
);
// only allow usual reports in CC (i.e. "pulse" reports are not expected to appear)
const ALLOWED_REPORT_TYPE = 'report';
export const selectEnrichedReports = createSelector(
  [selectOrderedReports, selectQueryFromLocation],
  (reports, query) => {
    const omitParentId = omit(PARENT_REPORT_ID);
    const testParamsMatch = isEqual(omitParentId(query));
    const testParentReportIdMatch = flow(
      toString,
      isEqual(query[PARENT_REPORT_ID])
    );
    const testCurrentQueryMatch = flow(
      parseSearch,
      omitParentId,
      testParamsMatch
    );

    return flow(
      map<CustomerConfigReport, EnrichedReport>(report => ({
        ...report,
        isCurrentQueryMatched: testCurrentQueryMatch(report.url),
        isParentReportMatched: testParentReportIdMatch(report.id),
        urlWithParentReportId: `${report.url}&parent_report_id=${report.id}`,
      })),
      filter(({report_type}) => report_type === ALLOWED_REPORT_TYPE)
    )(reports);
  }
);

const selectCurrentReport = createSelector(
  selectEnrichedReports,
  find<EnrichedReport>('isCurrentQueryMatched')
);
export const selectReportMatchedByParentReportId = createSelector(
  selectEnrichedReports,
  find<EnrichedReport>('isParentReportMatched')
);
export const selectActiveReport = createSelector(
  selectCurrentReport,
  selectReportMatchedByParentReportId,
  (currentReport, parentReport) => parentReport ?? currentReport
);
export const selectActiveReportActions = createSelector(
  selectActiveReport,
  get('actions')
);

// Module exports
export * from './params';
