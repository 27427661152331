import {createContext} from 'react';

import {
  compact,
  filter,
  flatten,
  flow,
  identity,
  isNaN,
  isNil,
  keys,
  map,
  noop,
  parseInt,
  pick,
  sortBy,
  startCase,
  without,
} from 'lodash/fp';
import {IntlShape} from 'react-intl';

import {DEMO_URL} from 'constants/globalVariables';
import {uncappedMapValues, uncappedReduce} from 'utils/lodash+';

import {PRESENCE_VALUES} from './constants';

import {useColumnFilters} from './components/ReportTable/useColumnFilters';

import {ColumnParam, CustomerConfigReport, Order} from './types';
import type {ColumnData} from 'common-components/ModernGrid/types';
import type {Dictionary} from 'types/utils';

export const getPresenceOptions = (intl: IntlShape) =>
  map(
    val => ({
      label: intl.formatMessage({
        id: `aa.filters.attributes.${val}`,
        defaultMessage: startCase(val),
      }),
      value: val,
    }),
    PRESENCE_VALUES
  );

export function normalizeForm(data: Dictionary<any>) {
  const {dimensions = []} = data;

  const validators = (key: string) => {
    switch (key) {
      case 'campaignName':
        return (campaignName: string) =>
          !dimensions.includes('campaign') ? '' : campaignName;
      case 'adgroupName':
        return (adgroupName: string) =>
          !dimensions.includes('adgroup') ? '' : adgroupName;
      default:
        return identity;
    }
  };

  return uncappedMapValues((value, key) => validators(key)(value), data);
}

export function updateColumnOrder(order: Order, from: number, to: number) {
  const [bottom, upper] = sortBy(identity, [from, to]);
  const step = from < to ? -1 : +1;

  const newOrder = uncappedReduce<Order, number, Order>(
    (result, colOrder, colName) => {
      let newColOrder;
      if (colOrder === from) {
        newColOrder = to;
      } else if (colOrder < bottom || colOrder > upper) {
        newColOrder = colOrder;
      } else {
        newColOrder = colOrder + step;
      }
      return {...result, [colName]: newColOrder};
    },
    {},
    order
  );

  return newOrder;
}

export function updateArrOrder(arr: any[], from: number, to: number) {
  return arr
    .slice()
    .splice(to < 0 ? arr.length + to : to, 0, arr.splice(from, 1)[0]);
}

export const dropOutdatedColumnFilters = (
  columns: string[],
  columnParams: ColumnParam[]
) => filter(({column}) => columns.includes(column), columnParams);

export function sanitizeColumnOrder(
  columnOrder: Order,
  dimensions: string[],
  attributes: string[],
  ...otherKpis: string[][]
) {
  const allFields = [...dimensions, ...attributes, ...flatten(otherKpis)];
  const newlyAddedFields = without(keys(columnOrder), allFields);

  return flow(
    pick<Order, string>(allFields),
    uncappedReduce<string[], number, Order>((acc, val, key) => {
      acc[val] = key;
      return acc;
    }, []),
    // Throw out empty places
    compact,
    // Add missing fields
    d => [...d, ...newlyAddedFields],
    sortBy(field => {
      if (dimensions.includes(field)) return 0;
      if (attributes.includes(field)) return 1;
      return 2;
    }),
    uncappedReduce<Order, string, string[]>(
      (acc, val, order) => ({
        ...acc,
        [val]: order,
      }),
      {}
    )
  )(columnOrder);
}

export const orderFromArr = <T extends string[] | undefined | null>(
  columns?: T
): Dictionary<number> => {
  if (isNil(columns)) {
    return {};
  }

  return columns.reduce((acc, val, key) => ({...acc, [val]: key}), {});
};

export const arrFromOrder = (order: Order) => {
  if (isNil(order)) {
    return undefined;
  }
  return compact(
    uncappedReduce<string[], number, Order>(
      (acc, val, key) => {
        acc[val] = key;
        return acc;
      },
      [],
      order
    )
  );
};

export const ColumnFiltersContext = createContext<
  ReturnType<typeof useColumnFilters>
>({
  columnFilterDrafts: {},
  setColumnFilterDraft: () => noop,
  setColumnFilterDrafts: noop,
  applyColumnFilterDrafts: noop,
});

export const createAttrValueRegExp = (accuracy = 6) =>
  new RegExp(
    `^([+-]\\d*\\.?\\d{0,${accuracy}}%?|\\d*\\.?\\d{0,${accuracy}})?$`,
    'm'
  );

export const isDemo = () => window.location.hostname.includes(DEMO_URL);

export const getColumnsWithSizes = (
  columns: ColumnData[],
  sizes: Dictionary<number>
) =>
  map(
    column =>
      isNil(sizes[column.key]) ? column : {...column, width: sizes[column.key]},
    columns
  );

// template reports are not parsable to number as they contain slugs (e.g. "skad_report")
export const isTemplateReportId = (id: CustomerConfigReport['id']) =>
  isNaN(parseInt(10, id));
