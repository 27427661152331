import React from 'react';

import {css} from '@linaria/core';
import {useSelector} from 'react-redux';

import {LoadingIndicator} from 'common-components';
import {selectIsCustomerConfigLoaded} from 'modules/common/reducers/customerConfig';

const cssLoadingIndicator = css`
  height: 100%;
`;

export const AuthorizedArea: React.FC = ({children}) => {
  const isConfigLoaded = useSelector(selectIsCustomerConfigLoaded);

  if (!isConfigLoaded) {
    return (
      <LoadingIndicator className={cssLoadingIndicator} verticallyCentered />
    );
  }

  return <>{children}</>;
};
