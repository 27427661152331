import {safeJsonParse} from './jsonUtils';

import {TableKey} from 'modules/common/types';

const LOCAL_STORAGE_COLUMN_SIZES_KEY = 'COLUMN_SIZES';
export const getLocalStorageColumnSizesKey = (
  adjustAccountId: number,
  tableKey: TableKey
) => `${adjustAccountId}@@${LOCAL_STORAGE_COLUMN_SIZES_KEY}@@${tableKey}`;

export const getLocalStorageItem = (localStorageItemKey: string) => {
  const stringifiedItem = window.localStorage.getItem(localStorageItemKey);

  return safeJsonParse(`${stringifiedItem}`);
};

export const setLocalStorageItem = (localStorageItemKey: string, item: any) =>
  window.localStorage.setItem(localStorageItemKey, JSON.stringify(item));
