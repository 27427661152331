import React, {memo} from 'react';

import cx from 'classnames';

import {
  cssRow,
  cssTotalRow,
} from 'common-components/ModernGrid/ModernGrid.styles';

import {MODERNGRID_TOTAL_ROW_TEST_ID} from '../../../constants';
import TotalCell from './TotalCell';

import {ExtendedColumnData, RowData} from 'common-components/ModernGrid/types';

type Props = {
  row: RowData;
  columns: ExtendedColumnData[];
};
const TotalRow = ({row, columns}: Props) => (
  <div
    className={cx(cssRow, cssTotalRow)}
    data-testid={MODERNGRID_TOTAL_ROW_TEST_ID}
  >
    {columns.map(columnData => (
      <TotalCell key={columnData.key} column={columnData} row={row} />
    ))}
  </div>
);

export default memo(TotalRow);
