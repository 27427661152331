import React from 'react';

import cx from 'classnames';

import {useIntl} from 'common-components/utils/libs/ReactIntl';

import classes from './DateRangePicker.css';

export const DatePeriodPanel = ({
  showCustomOption = false,
  options = [],
  selectedOption,
  onSelectOption,
}) => {
  const intl = useIntl();

  const customLabel = intl.formatMessage({
    id: 'aa.label.custom',
    defaultMessage: 'Custom',
  });

  return (
    <div className={classes.lastDaysPanel}>
      {options.map(option => {
        const {key, label} = option;

        return (
          <div
            key={key}
            className={cx(classes.option, {
              [classes.optionSelected]: option.key === selectedOption?.key,
            })}
            title={label}
            onClick={e => {
              e.stopPropagation(); // prevent modal-styled picker from closing
              onSelectOption?.(option);
            }}
            role='button'
            tabIndex={0}
          >
            {label}
          </div>
        );
      })}

      {showCustomOption && (
        <div
          className={cx(classes.option, classes.customOption, {
            [classes.optionSelected]: selectedOption?.key === 'custom',
          })}
          title={customLabel}
        >
          {customLabel}
        </div>
      )}
    </div>
  );
};
