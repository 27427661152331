import React from 'react';

import {css} from '@linaria/core';
import CalendarIcon from '@material-ui/icons/InsertInvitation';
import moment from 'moment';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';

const cssRoot = css`
  color: ${styleVariables.colorFiltersTitles};
  cursor: pointer;
  font-size: 14px;
`;
const cssCalendarIcon = css`
  &.MuiSvgIcon-root {
    display: none;
  }
`;
const cssDateText = css`
  border: none;
  background: transparent;
  cursor: inherit;
  width: 85px;
  height: 24px;
  line-height: 24px;
`;
const cssDash = css`
  margin-right: 4px;
`;

const DRPMock = ({startDate, endDate, handleAppear}) => {
  const intl = useIntl();

  return (
    <div onMouseOver={handleAppear} onFocus={handleAppear} className={cssRoot}>
      <CalendarIcon className={cssCalendarIcon} />
      <input
        className={cssDateText}
        value={
          startDate
            ? moment(startDate).format('DD MMM YYYY')
            : intl.formatMessage({
                id: 'aa.dateRangePicker.startDate',
                defaultMessage: 'Start Date',
              })
        }
        readOnly
      />
      <span className={cssDash}>–</span>
      <input
        className={cssDateText}
        value={
          endDate
            ? moment(endDate).format('DD MMM YYYY')
            : intl.formatMessage({
                id: 'aa.dateRangePicker.endDate',
                defaultMessage: 'End Date',
              })
        }
        readOnly
      />
    </div>
  );
};

export default DRPMock;
