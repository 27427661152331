import React, {useCallback, useContext} from 'react';

import {useDispatch} from 'react-redux';

import {SelectableRowsContext} from 'common-components/ModernGrid/extensions/SelectableRows';
import {loadUpdateHistoryAttrRequest} from 'modules/history/actions/HistoryActions';
import {isMoneyAttribute} from 'utils/attributes';

import AttributeCell from 'modules/common/components/AttributeCell';

import type {AttributeValue} from 'modules/common/types';

type Props = {
  isSelected?: boolean;
  rowData: {
    report_url: string;
    attribute: AttributeValue;
    entity_name: string;
    app_network: string[];
    targeting: Record<string, string>;
  };
};

export const CurrentValueColumn = ({
  rowData: {
    app_network,
    entity_name: name,
    report_url,
    attribute,
    targeting: rowDataTargeting,
  },
  isSelected,
}: Props) => {
  const dispatch = useDispatch();
  const {discardSelection} = useContext(SelectableRowsContext);

  const handleUpdate = useCallback(
    newValue => {
      const value_to = isMoneyAttribute(attribute.type)
        ? {
            ...attribute.value,
            amount: newValue,
          }
        : {value: newValue};
      dispatch(
        loadUpdateHistoryAttrRequest({
          attribute_slug: attribute.slug,
          app_network,
          name: name || 'entity',
          targeting: attribute.targeting,
          value_from: attribute.value,
          report_url,
          value_to,
          onChangeGroupUpdated: discardSelection,
        })
      );
    },
    [dispatch, discardSelection, attribute, name, app_network, report_url]
  );

  return (
    <AttributeCell
      pendoId='history-currentValue'
      isLoading={!attribute}
      attrData={attribute}
      onChange={handleUpdate}
      isSelected={isSelected}
      rowDataTargeting={rowDataTargeting}
    />
  );
};
