import React from 'react';

import {css} from '@linaria/core';
import {Tooltip, IconButton} from '@material-ui/core';
import DuplicateIcon from '@material-ui/icons/AddToPhotosOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import cx from 'classnames';

import {OneLine} from 'common-components/OneLine';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {isTemplateReportId} from 'modules/report/utils';
import {
  useDeleteReportModal,
  useDuplicateReportModal,
  useRenameReportModal,
} from 'utils/reportActions';

import type {EnrichedReport} from 'modules/report/types';

const cssDuplicateIcon = css`
  transform: rotate(90deg);
`;

const cssDisabledIcon = css`
  svg {
    color: #d4dae7;
  }
`;

export const REPORT_RENAME_BUTTON_ID = 'report-editButton';
export const REPORT_DUPLICATE_BUTTON_ID = 'report-duplicateButton';
export const REPORT_DELETE_BUTTON_ID = 'report-deleteButton';

type Props = {
  report: EnrichedReport;
};
export const ActionButtons = ({report}: Props) => {
  const intl = useIntl();

  const openRenameReportModal = useRenameReportModal(report);
  const openDeleteReportModal = useDeleteReportModal(report);
  const openDuplicateReportModal = useDuplicateReportModal(report);

  const isTemplateReport = isTemplateReportId(report.id);

  return (
    <OneLine>
      <Tooltip
        title={
          isTemplateReport
            ? ''
            : intl.formatMessage({
                id: 'aa.label.rename',
                defaultMessage: 'Rename',
              })
        }
      >
        <IconButton
          data-pendoid={REPORT_RENAME_BUTTON_ID}
          data-testid={REPORT_RENAME_BUTTON_ID}
          color='primary'
          onClick={openRenameReportModal}
          disabled={isTemplateReport}
          className={cx({
            [cssDisabledIcon]: isTemplateReport,
          })}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={intl.formatMessage({
          id: 'aa.label.duplicate',
          defaultMessage: 'Duplicate',
        })}
      >
        <IconButton
          data-pendoid={REPORT_DUPLICATE_BUTTON_ID}
          color='primary'
          onClick={openDuplicateReportModal}
        >
          <DuplicateIcon className={cssDuplicateIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          isTemplateReport
            ? ''
            : intl.formatMessage({
                id: 'aa.label.delete',
                defaultMessage: 'Delete',
              })
        }
      >
        <IconButton
          data-pendoid={REPORT_DELETE_BUTTON_ID}
          data-testid={REPORT_DELETE_BUTTON_ID}
          color='primary'
          onClick={openDeleteReportModal}
          disabled={isTemplateReport}
          className={cx({
            [cssDisabledIcon]: isTemplateReport,
          })}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </OneLine>
  );
};
