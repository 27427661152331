import React, {memo} from 'react';

import {AttributeValue} from '../../../types';

type Props = {
  attrData: AttributeValue;
};

export const MultiplePercentageCell = memo(
  ({
    attrData: {
      value: {value_from, value_to},
    },
  }: Props) => (
    <span>
      {value_from}%&nbsp;—%&nbsp;{value_to}%
    </span>
  )
);
