import {css} from '@linaria/core';

import {styleVariables} from 'constants/globalVariables';

export const cssErrorMessage = css`
  display: block;
  margin-top: 4px;
  color: ${styleVariables.colorFieldError};
`;
export const composeChipInputStyles = (isValid: boolean) => ({
  container: (style: any) => ({
    ...style,
    display: 'flex',
    minHeight: '72px',
  }),
  control: (style: any) => ({
    ...style,
    backgroundColor: 'transparent',
    borderWidth: 0,
    outline: 0,
    borderRadius: 0,
    borderBottom: `2px solid ${styleVariables.colorLightGrey}`,
    boxShadow: 'none',
    alignSelf: 'flex-end',
    width: '100%',

    '&:hover': {
      borderColor: isValid
        ? styleVariables.colorLightGrey
        : styleVariables.colorFieldError,
    },
    ...(!isValid && {
      borderBottomColor: styleVariables.colorFieldError,
    }),
  }),
  multiValue: (style: any) => ({
    ...style,
    height: '24px',
    borderRadius: 0,
    background: '#8a95b3',
    padding: '4px',
    paddingLeft: '2px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: '8px',
    marginBottom: '8px',
  }),
  multiValueLabel: (style: any) => ({
    ...style,
    color: 'white',
    fontSize: '13px',
    lineHeight: '14px',
    padding: 0,
  }),
  multiValueRemove: (style: any) => ({
    ...style,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white',
      cursor: 'pointer',
    },
  }),
  input: (style: any) => ({
    ...style,
    color: 'white',
  }),
  valueContainer: (style: any) => ({
    ...style,
    paddingLeft: 0,
  }),
});
