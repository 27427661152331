import {createReducer} from '@reduxjs/toolkit';
import {noop} from 'lodash/fp';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';

import * as CommonActions from 'modules/common/actions';
import {adjustConfig} from 'modules/common/reducers/adjustConfig';
import {customerConfig} from 'modules/common/reducers/customerConfig';
import {customerPreferences} from 'modules/common/reducers/customerPreferences';

import type {
  InformationDialogParams,
  InputDialogParams,
  ToastParams,
  RootState,
} from 'modules/common/types';

interface InformationDialogState {
  params: InformationDialogParams;
  isOpen: boolean;
}
const initialInformationDialogState: InformationDialogState = {
  params: {body: ''},
  isOpen: false,
};
const informationDialog = createReducer<InformationDialogState>(
  initialInformationDialogState,
  builder =>
    builder
      .addCase(CommonActions.showInformationDialog, (_, {payload}) => ({
        isOpen: true,
        params: payload,
      }))
      .addCase(
        CommonActions.hideInformationDialog,
        () => initialInformationDialogState
      )
);

interface MainMenuState {
  isOpen: boolean;
  isReady: boolean;
}
const initialMainMenuState: MainMenuState = {
  isOpen: false,
  isReady: false,
};
const mainMenu = createReducer<MainMenuState>(initialMainMenuState, builder =>
  builder
    .addCase(CommonActions.showMainMenu, state => ({
      ...state,
      isOpen: true,
    }))
    .addCase(CommonActions.hideMainMenu, state => ({
      ...state,
      isOpen: false,
    }))
    .addCase(CommonActions.setIsMainMenuReady, (state, {payload}) => ({
      ...state,
      isReady: payload,
    }))
);

interface InputDialogState {
  params: InputDialogParams;
  isOpen: boolean;
}
const initialInputDialogState: InputDialogState = {
  params: {headerLabelKey: '', onSubmit: noop},
  isOpen: false,
};
const inputDialog = createReducer(initialInputDialogState, builder =>
  builder
    .addCase(CommonActions.showInputDialog, (_, {payload}) => ({
      isOpen: true,
      params: payload,
    }))
    .addCase(CommonActions.hideInputDialog, () => initialInputDialogState)
);

interface ToastState {
  params: ToastParams;
  isOpen: boolean;
}
const initialToastState: ToastState = {
  params: {category: 'informational'},
  isOpen: false,
};
const toast = createReducer(initialToastState, builder =>
  builder
    .addCase(CommonActions.showToast, (_, {payload}) => ({
      isOpen: true,
      params: payload,
    }))
    .addCase(CommonActions.hideToast, () => initialToastState)
);

interface LabelsState {
  drilldown: {[drilldownDimensionSlug: string]: string};
  attrTargeting: {
    [attrHash: string]: {
      [targetingParam: string]: string;
    };
  };
}
const initialLabelsState: LabelsState = {
  drilldown: {},
  attrTargeting: {},
};
const labels = createReducer<LabelsState>(initialLabelsState, builder =>
  builder
    .addCase(CommonActions.loadDrilldownLabelsSuccess, (state, {payload}) => ({
      ...state,
      drilldown: payload,
    }))
    .addCase(CommonActions.loadTargetingLabelsSuccess, (state, {payload}) => ({
      ...state,
      attrTargeting: {
        ...state.attrTargeting,
        ...payload,
      },
    }))
);

export default combineReducers({
  inputDialog,
  informationDialog,
  mainMenu,
  toast,
  labels,
  customerConfig,
  customerPreferences,
  adjustConfig,
});

export const selectLabels = (state: RootState) => state.common.labels;
export const selectDrilldownLabels = createSelector(
  selectLabels,
  ({drilldown}) => drilldown
);
export const selectAttrTargetingLabels = createSelector(
  selectLabels,
  ({attrTargeting}) => attrTargeting
);

export const selectInformationDialogParams = (state: RootState) =>
  state.common.informationDialog.params;
export const selectIsInformationDialogOpen = (state: RootState) =>
  state.common.informationDialog.isOpen;

export const selectIsMainMenuOpen = (state: RootState) =>
  state.common.mainMenu.isOpen;
export const selectIsMainMenuReady = (state: RootState) =>
  state.common.mainMenu.isReady;

export const selectInputDialogParams = (state: RootState) =>
  state.common.inputDialog.params;
export const selectIsInputDialogOpen = (state: RootState) =>
  state.common.inputDialog.isOpen;

export const selectToastParams = (state: RootState) =>
  state.common.toast.params;
export const selectIsToastOpen = (state: RootState) =>
  state.common.toast.isOpen;
