import {Account, User} from '@adjust/dashboard-components';
import * as Sentry from '@sentry/browser';
import {
  flow,
  get,
  isString,
  keys,
  mapValues,
  negate,
  omit,
  omitBy,
  set,
  isNil,
  unset,
  map,
  fromPairs,
  isNumber,
  partialRight,
  pickBy,
} from 'lodash/fp';
import {all, put, takeLatest, take, race, takeEvery} from 'redux-saga/effects';

import {
  DEFAULT_MAX_COLUMN_WIDTH,
  DEFAULT_MIN_COLUMN_WIDTH,
} from 'common-components/ModernGrid/constants';
import {
  EXTERNAL_LOGIN,
  PATHNAME_HISTORY,
  PATHNAME_REPORT,
  TABLE_KEYS,
} from 'constants/globalVariables';
import {
  loadTargetingLabelsRequest,
  loadTargetingLabelsSuccess,
  showInformationDialog,
  approveInformationDialog,
  rejectInformationDialog,
  loadCustomerConfigRequest,
  loadCustomerConfigSuccess,
  initRepeatingLoadOpsStatsRequest,
  updateCustomerColumnSizeRequest,
  loadCustomerColumnSizes,
  loadCustomerConfigFailure,
  browserHistoryUpdated,
  loadAdjustCustomerDataSuccess,
  loadAdjustCustomerAccountsSuccess,
  setIsMainMenuReady,
} from 'modules/common/actions';
import {
  fetchNetworkEntitiesLabels,
  fetchCustomerConfig,
  fetchCurrentAccount,
  fetchCurrentUser,
  fetchAccounts,
} from 'modules/common/api';
import {selectAttrTargetingLabels} from 'modules/common/reducers';
import {
  selectCustomerConfig,
  selectAdjustAccountId,
  selectCanViewAttr,
} from 'modules/common/reducers/customerConfig';
import {
  selectCustomerPreferencesColumnSizes,
  ColumnSizesState,
} from 'modules/common/reducers/customerPreferences';
import {parseNewHistoryQuery} from 'modules/history/actions/HistoryActions';
import {navigateToReport} from 'modules/report/actions';
import {safe} from 'sagas/utils';
import {flattenAttributeResponse} from 'utils/attributes';
import {call, select} from 'utils/libs/TypedReduxSaga';
import {
  getLocalStorageColumnSizesKey,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/localStorage';
import {isPopulated} from 'utils/lodash+';

import {trimToLimits} from 'common-components/ModernGrid/components/ColumnResizer/ColumnResizer';

import type {
  AttributesResponse,
  BrowserHistoryChange,
  InformationDialogParams,
  TableKey,
} from 'modules/common/types';

export function* handleNavigationSideEffects({
  payload: {
    location: {pathname, search, state},
    action,
  },
}: {
  payload: BrowserHistoryChange;
}) {
  if (action === 'POP' || state?.fromTabClick) {
    if (pathname === PATHNAME_REPORT) {
      yield put(navigateToReport(search));
    } else if (pathname === PATHNAME_HISTORY) {
      yield put(parseNewHistoryQuery(search));
    }
  }
}

export function* watchHandleNavigationSideEffects() {
  yield takeEvery(browserHistoryUpdated, safe(handleNavigationSideEffects));
}

export function* loadAuthSaga() {
  try {
    yield put(loadCustomerConfigRequest());
    yield take(loadCustomerConfigSuccess);

    const canViewAttr = yield* select(selectCanViewAttr);
    const userConfig = yield* select(selectCustomerConfig);

    if (canViewAttr) {
      yield put(initRepeatingLoadOpsStatsRequest());
    }

    if (process.env.NODE_ENV === 'production') {
      Sentry.configureScope(scope => scope.setUser(userConfig));

      if (!isNil(window.pendo)) {
        window.pendo.initialize({
          visitor: {
            screen_size: window.innerWidth,
            id: userConfig.adjust_user_id.toString(),
            email: userConfig.adjust_user_email,
            full_name: userConfig.adjust_user_name || '',
            role: userConfig.role,
            timezone_id: userConfig.timezone_id,
            language: userConfig.language,
            creationDate: userConfig.adjust_user_created_at,
            super_admin: userConfig.adjust_superadmin,
            whitelisted: userConfig.whitelisted,
          },
          account: {
            id: userConfig.adjust_account_id.toString(),
            name: userConfig.adjust_account_name,
            is_paying: userConfig.is_paying,
            monthly_value: userConfig.monthly_value ?? '',
            planLevel: userConfig.plan_level ?? '',
            planPrice: userConfig.plan_price ?? '',
            creationDate: userConfig.adjust_account_created_at,
            status: userConfig.status,
            manager: userConfig.manager || '',
            sales_manager: userConfig.sales_manager || '',
            billing_day: userConfig.billing_day,
            contract_length: userConfig.contract_length || '',
            contract_ends_on: userConfig.contract_ends_on || '',
          },
        });
      }
    }
  } catch (error) {
    console.error(error);
    window.location.href = EXTERNAL_LOGIN;
  }
}

function* loadCustomerConfigFlow(): unknown {
  try {
    const config = yield call(fetchCustomerConfig);
    if (config) {
      yield put(loadCustomerConfigSuccess(config));
    }
  } catch (error) {
    console.error(error);
    yield put(loadCustomerConfigFailure(error));
  }
}

export function* watchLoadCustomerConfigFlow() {
  yield takeEvery(loadCustomerConfigRequest, safe(loadCustomerConfigFlow));
}

const getStoredColumnSizesForUser = (adjustAccountId: number) =>
  fromPairs(
    map(
      tableKey => [
        tableKey,
        getLocalStorageItem(
          getLocalStorageColumnSizesKey(adjustAccountId, tableKey)
        ) ?? {},
      ],
      TABLE_KEYS
    )
  );

const columnSizeLimits = {
  minLimit: DEFAULT_MIN_COLUMN_WIDTH,
  maxLimit: DEFAULT_MAX_COLUMN_WIDTH,
};
export const sanitizeColumnSizes = flow(
  pickBy(isNumber),
  mapValues(partialRight(trimToLimits, [columnSizeLimits]))
);

export function* loadCustomerColumnSizesFlow({
  payload: {adjust_account_id},
}: {
  payload: {
    adjust_account_id: number;
  };
}) {
  const storedColumnSizes = getStoredColumnSizesForUser(
    adjust_account_id
  ) as ColumnSizesState;

  const sanitizedColumnSizes = mapValues(
    sanitizeColumnSizes,
    storedColumnSizes
  ) as ColumnSizesState;

  yield put(loadCustomerColumnSizes(sanitizedColumnSizes));
}
export function* watchLoadCustomerColumnSizesFlow() {
  yield takeLatest(
    loadCustomerConfigSuccess,
    safe(loadCustomerColumnSizesFlow)
  );
}

export function* updateCustomerColumnSizeFlow({
  payload: {tableKey, columnKey, columnSize},
}: {
  payload: {
    tableKey: TableKey;
    columnKey: string;
    columnSize: number | undefined;
  };
}) {
  const currentColumnSizes = yield* select(
    selectCustomerPreferencesColumnSizes
  );
  const adjustAccountId = yield* select(selectAdjustAccountId);

  const pathToUpdate = `${tableKey}.${columnKey}`;
  const updateColumnSizesMethod = isNil(columnSize)
    ? unset(pathToUpdate)
    : set(pathToUpdate, columnSize);

  const newColumnSizes = updateColumnSizesMethod(currentColumnSizes);

  setLocalStorageItem(
    getLocalStorageColumnSizesKey(adjustAccountId, tableKey),
    newColumnSizes[tableKey]
  );

  yield put(loadCustomerColumnSizes(newColumnSizes));
}
export function* watchUpdateCustomerColumnSizeFlow() {
  yield takeLatest(
    updateCustomerColumnSizeRequest,
    safe(updateCustomerColumnSizeFlow)
  );
}

export function* loadTargetingLabelsFlow({
  payload,
}: {
  payload: AttributesResponse;
}) {
  const attributes = flattenAttributeResponse(payload);
  const currentTargetingLabels = yield* select(selectAttrTargetingLabels);
  const mappedAttrTargetings = flow(
    omit(keys(currentTargetingLabels)),
    mapValues(get('targeting')),
    mapValues(omitBy(negate(isString)))
  )(attributes);
  if (isPopulated(mappedAttrTargetings)) {
    const attrTargetingsLabels = yield* call(
      fetchNetworkEntitiesLabels,
      mappedAttrTargetings
    );
    yield put(loadTargetingLabelsSuccess(attrTargetingsLabels));
  }
}

export function* watchLoadTargetingLabelsFlow() {
  yield takeLatest(loadTargetingLabelsRequest, safe(loadTargetingLabelsFlow));
}

export function* showConfirmationDialog(dialogParams: InformationDialogParams) {
  yield put(showInformationDialog(dialogParams));
  const {yes} = yield race({
    yes: take(approveInformationDialog),
    no: take(rejectInformationDialog),
  });
  return !!yes;
}

export function* loadDashboardCustomerData() {
  const [currentAccount, currentUser]: [Account, User] = yield all([
    call(fetchCurrentAccount),
    call(fetchCurrentUser),
  ]);

  if (!currentAccount || !currentUser) {
    return;
  }

  yield put(
    loadAdjustCustomerDataSuccess({
      currentAccount,
      currentUser,
    })
  );

  yield put(setIsMainMenuReady(!!currentAccount && !!currentUser));
}

export function* loadCustomerAccountsFlow({
  payload: {currentUser},
}: {
  payload: {
    currentUser?: User;
  };
}) {
  /**
   * We must skip requesting accounts for super admin, because
   * 1. It returns too many options for super admin (all existed accounts).
   * 2. `MainMenu` ignores this for super admin and `searchAccount` callback is used instead.
   */
  if (currentUser && !currentUser.super_admin) {
    const accounts: Account[] = yield call(fetchAccounts);

    if (!accounts) {
      return;
    }

    yield put(loadAdjustCustomerAccountsSuccess({accounts}));
  }
}

export function* watchLoadAdjustCustomerDataFlow() {
  yield takeLatest(
    loadAdjustCustomerDataSuccess,
    safe(loadCustomerAccountsFlow)
  );
}
