import {createReducer} from '@reduxjs/toolkit';
import {flow, groupBy, mapValues} from 'lodash/fp';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';

import {selectCanViewAttr} from 'modules/common/reducers/customerConfig';
import {
  updateReportParams,
  setReportParams,
  loadReportSpecSuccess,
} from 'modules/report/actions';
import {stringifyColumnParams} from 'utils/columns';

import {ReportParams, ColumnParam, GeneralParam} from '../types';
import type {RootState} from 'modules/common/types';

const initialParams = {
  quick: {
    attributes: [],
    overviews: [],
    events: [],
    cohorts: [],
    dimensions: [],
    skads: [],
    index: [],
  },
  general: {
    order: {},
    date_period: '-21d:-14d',
    sort: '',
    drilldown: '',
    focus: '',
  },
  column: [],
  builder: {},
  unknown: {},
};

type ParamsParts = 'quick' | 'general' | 'builder' | 'unknown';

const columnReducer = createReducer<ColumnParam[]>(
  initialParams.column,
  builder =>
    builder
      .addCase(
        updateReportParams,
        (state, {payload}: {payload: ReportParams}) => payload.column ?? state
      )
      .addCase(
        setReportParams,
        (_, {payload}: {payload: ReportParams}) => payload.column || []
      )
);

const generalReducer = createReducer<GeneralParam>(
  initialParams.general,
  builder =>
    builder
      .addCase(updateReportParams, (state, {payload}) => ({
        ...state,
        ...payload.general,
      }))
      .addCase(setReportParams, (_, {payload}) => ({
        ...initialParams.general,
        ...payload.general,
      }))
      .addCase(loadReportSpecSuccess, (state, {payload}) => ({
        ...state,
        sort: payload.sort,
      }))
);

const createReducerForParamsPart = <T extends ParamsParts>(key: T) =>
  createReducer<ReportParams[T]>(initialParams[key], builder =>
    builder
      .addCase(
        updateReportParams,
        (state, {payload}: {payload: ReportParams}) => ({
          ...state,
          ...payload[key],
        })
      )
      .addCase(setReportParams, (_, {payload}: {payload: ReportParams}) => ({
        ...initialParams[key],
        ...payload[key],
      }))
  );

export const params = combineReducers({
  quick: createReducerForParamsPart('quick'),
  builder: createReducerForParamsPart('builder'),
  unknown: createReducerForParamsPart('unknown'),
  general: generalReducer,
  column: columnReducer,
});

export const selectParams = (state: RootState) => state.report.params;

export const selectGeneralParams = createSelector(
  selectParams,
  ({general}) => general
);

export const selectColumnParams = createSelector(
  selectParams,
  ({column}) => column
);

export const toInputsValues = flow(
  groupBy('column'),
  mapValues(stringifyColumnParams)
);

export const selectColumnFiltersInputValues = createSelector(
  selectColumnParams,
  toInputsValues
);
const filterWithAttributesViewPermission = (
  quickParams: any,
  hasViewPermission: boolean
) => ({
  ...quickParams,
  attributes: hasViewPermission ? quickParams?.attributes : [],
});

// use selectQuickParam instead
const selectRootQuickParams = (state: RootState) => state.report.params.quick;

export const selectQuickParams = createSelector(
  selectRootQuickParams,
  selectCanViewAttr,
  filterWithAttributesViewPermission
);
export const selectQuickIndexParam = createSelector(
  selectQuickParams,
  ({index}) => index
);
export const selectQuickDimensionParam = createSelector(
  selectQuickParams,
  ({dimensions}) => dimensions
);
export const selectQuickAttributesParam = createSelector(
  selectQuickParams,
  ({attributes}) => attributes || []
);
export const selectBuilderParams = createSelector(
  selectParams,
  ({builder}) => builder
);
export const selectUnknownParams = createSelector(
  selectParams,
  ({unknown}) => unknown
);
export const selectDrilldownParam = createSelector(
  selectGeneralParams,
  ({drilldown}) => drilldown
);
export const selectFocusParam = createSelector(
  selectGeneralParams,
  ({focus}) => focus
);
export const selectFlatReportParams = createSelector(
  selectParams,
  ({builder, column, general, quick, unknown}) => ({
    ...unknown,
    ...builder,
    ...column,
    ...general,
    ...quick,
  })
);
