import React, {
  memo,
  useCallback,
  CSSProperties,
  useRef,
  useContext,
} from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';
import {isFunction, isNil, noop} from 'lodash/fp';
import {SortableElement} from 'react-sortable-hoc';

import {useNodeSize} from 'common-components/hooks';
import {
  defaultRenderHeader,
  DEFAULT_ALIGN,
  DEFAULT_INITIAL_SORT_DIRECTION,
  DEFAULT_IS_ARRANGEABLE,
  DEFAULT_IS_FIXED,
  DEFAULT_IS_RESIZABLE,
  DEFAULT_IS_SORTABLE,
  MODERNGRID_HEADER_CELL_ROOT_TEST_ID,
} from 'common-components/ModernGrid/constants';
import {MovableColumnContext} from 'common-components/ModernGrid/contexts';
import {ResizableColumnContext} from 'common-components/ModernGrid/contexts/ResizableColumnContext';
import {SortableColumnContext} from 'common-components/ModernGrid/contexts/SortableColumnContext';
import {SELECTABLE_COLUMN_KEY} from 'common-components/ModernGrid/extensions/SelectableRows';
import {
  cssCell,
  cssHeader,
  cssHeaderCell,
} from 'common-components/ModernGrid/ModernGrid.styles';
import {
  getCellSortDirectionString,
  getSortColumnKey,
} from 'common-components/ModernGrid/utils';
import SpaceFiller from 'common-components/SpaceFiller';

import {DivAlign} from '../../DivAlign';
import SortIcon from './SortIcon';

import ColumnResizer from 'common-components/ModernGrid/components/ColumnResizer';

import {
  ExtendedColumnData,
  HeaderData,
} from 'common-components/ModernGrid/types';

const cssDivAlign = css`
  white-space: nowrap;
`;

export type HeaderCellProps = {
  column: ExtendedColumnData;
  isArrangeableColumn?: boolean;
  headerData: HeaderData;
};
const BaseHeaderCell = ({
  column: {
    key: columnKey,
    width: cellWidth,
    maxWidth,
    align = DEFAULT_ALIGN,
    headerAlign = align,
    isSortable: isColumnSortable = DEFAULT_IS_SORTABLE,
    isResizable: isColumnResizable = DEFAULT_IS_RESIZABLE,
    initialSortDirection = DEFAULT_INITIAL_SORT_DIRECTION,
    renderHeader = defaultRenderHeader,
    title,
  },
  isArrangeableColumn: isMovableColumn = false,
  headerData,
}: HeaderCellProps) => {
  const {sortKey, handleColumnSortChange} = useContext(SortableColumnContext);
  const {onColumnResize} = useContext(ResizableColumnContext);

  const hasActiveSort = getSortColumnKey(sortKey) === columnKey;
  const sortDirection = getCellSortDirectionString(
    sortKey,
    columnKey,
    initialSortDirection
  );
  const cellWrapperRef = useRef<HTMLDivElement>(null);
  const {width: cellWrapperWidth} = useNodeSize(cellWrapperRef);

  const currentColumnWidth = cellWidth || cellWrapperWidth;
  const isResizable =
    isColumnResizable &&
    isFunction(onColumnResize) &&
    !isNil(currentColumnWidth);

  const isSortable = isColumnSortable && isFunction(handleColumnSortChange);

  const handleSortChange = useCallback(
    () =>
      isSortable
        ? handleColumnSortChange?.({
            key: columnKey,
            initialSortDirection,
          })
        : noop,
    [handleColumnSortChange, isSortable, columnKey, initialSortDirection]
  );

  const cellContent = renderHeader!({
    key: columnKey,
    headerAlign,
    value: title,
    headerClickHandler: handleSortChange,
    isMovableColumn,
    headerData,
  });

  return (
    <DivAlign
      data-testid={MODERNGRID_HEADER_CELL_ROOT_TEST_ID}
      className={cx(cssDivAlign, cssCell, cssHeader)}
      align={align}
      style={
        {
          '--headerLevel': 0,
        } as CSSProperties
      }
      ref={cellWrapperRef}
    >
      <div className={cssHeaderCell}>
        {isSortable && (
          <SortIcon
            sortDirection={sortDirection}
            hasActiveSort={hasActiveSort}
            onClick={handleSortChange}
          />
        )}
        {headerAlign === 'right' && <SpaceFiller />}
        {cellContent}
        {isResizable && (
          <ColumnResizer
            columnKey={columnKey}
            currentWidth={currentColumnWidth!}
            maxWidth={maxWidth}
            onColumnResize={onColumnResize!}
          />
        )}
      </div>
    </DivAlign>
  );
};

const ArrangeableCell = SortableElement(BaseHeaderCell);

const HeaderCell = (props: Omit<HeaderCellProps, 'isMovableColumn'>) => {
  const {
    column: {
      order,
      isFixed = DEFAULT_IS_FIXED,
      isArrangeable = DEFAULT_IS_ARRANGEABLE,
      orderCollection,
      key: columnKey,
    },
  } = props;

  const {onMoveColumn} = useContext(MovableColumnContext);
  const isArrangeableColumn =
    isArrangeable &&
    isFunction(onMoveColumn) &&
    columnKey !== SELECTABLE_COLUMN_KEY;

  if (!isArrangeableColumn) {
    return <BaseHeaderCell {...props} />;
  }

  const collection = orderCollection ?? Number(isFixed);

  return (
    <ArrangeableCell
      index={order!}
      collection={collection}
      isArrangeableColumn
      {...props}
    />
  );
};

export default memo(HeaderCell);
