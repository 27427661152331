import {fork} from 'redux-saga/effects';

import {
  watchLoadApproveChangesFlow,
  watchLoadDiscardChangesFlow,
  watchLoadRevertChangesFlow,
  watchConfirmChangesFlow,
  watchLoadEditChangesFlow,
  watchLoadRetryChangesFlow,
} from './changes';
import {
  watchLoadHistoryFiltersFlow,
  watchLoadHistoryDataFlow,
  watchUpdateUrlQueryFlow,
  watchInitHistoryFlow,
  watchReloadHistoryDataFlow,
  watchParseNewHistoryQueryFlow,
  watchLoadHistoryAttrsFlow,
  watchLoadUpdateHistoryAttrFlow,
  watchLoadOpsForHistoryAttrsFlow,
} from './history';

export default function* mainHistorySaga() {
  yield fork(watchLoadHistoryFiltersFlow);
  yield fork(watchLoadHistoryDataFlow);
  yield fork(watchUpdateUrlQueryFlow);
  yield fork(watchInitHistoryFlow);
  yield fork(watchReloadHistoryDataFlow);
  yield fork(watchParseNewHistoryQueryFlow);
  yield fork(watchLoadApproveChangesFlow);
  yield fork(watchLoadDiscardChangesFlow);
  yield fork(watchConfirmChangesFlow);
  yield fork(watchLoadRevertChangesFlow);
  yield fork(watchLoadHistoryAttrsFlow);
  yield fork(watchLoadUpdateHistoryAttrFlow);
  yield fork(watchLoadOpsForHistoryAttrsFlow);
  yield fork(watchLoadEditChangesFlow);
  yield fork(watchLoadRetryChangesFlow);
}
