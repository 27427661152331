import {PATHNAME_HISTORY} from 'constants/globalVariables';
import {cancelOnDispatched, callOnlyWithinPathnames, Fn} from 'sagas/utils';

import {historyScreenUnmounted} from '../actions/HistoryActions';

export const cancelOnHistoryUnmount = cancelOnDispatched(
  historyScreenUnmounted
);

export const withinHistoryPathnames = callOnlyWithinPathnames([
  PATHNAME_HISTORY,
]);

export const cancelOnStaleHistory = (saga: Fn) =>
  withinHistoryPathnames(cancelOnHistoryUnmount(saga));
