import axios from 'axios';
import debounce from 'debounce-promise';
import {map, mapKeys} from 'lodash/fp';

import {authorizedRequest, dashboardRequest} from 'utils/axios';
import {stringifyQuery} from 'utils/query';

import type {
  AttributesResponse,
  Filters,
  FiltersOptions,
  AttrForOps,
  UpdateAttributeModel,
  TableKey,
  Filter,
} from 'modules/common/types';
import {BE_types} from 'types/backendServicesTypes';
import type {Dictionary} from 'types/utils';

export function fetchCustomerConfig() {
  return authorizedRequest.get('configs-service/config');
}

export function fetchEnrichAttributes({reqBody}: {reqBody: object}) {
  return authorizedRequest.post<any, AttributesResponse>(
    'attributes-service/enrich',
    {
      ...reqBody,
      get_operations: false,
    }
  );
}

const getFiltersOptionsEndpoint = (
  isFetchingHistoryOptions: boolean = false
) => {
  if (isFetchingHistoryOptions) {
    return 'operations-service/filters_data';
  }

  return 'reports-service/filters_data';
};

export const fetchFiltersOptions = ({
  filters,
  isFetchingHistoryOptions = false,
}: {
  filters: string[];
  isFetchingHistoryOptions?: boolean;
}) => {
  const filtersOptionsEndpoint = getFiltersOptionsEndpoint(
    isFetchingHistoryOptions
  );

  return authorizedRequest.get<any, FiltersOptions>(filtersOptionsEndpoint, {
    params: {
      required_filters: filters.join(','),
    },
  });
};

let cancelTokenSourceFetchFilterOptions: any;
const cancellableFetchFiltersOptions = ({
  filters,
  filtersQueries = {},
}: {
  filters: string[];
  filtersQueries?: Dictionary<string>;
}) => {
  if (typeof cancelTokenSourceFetchFilterOptions !== typeof undefined) {
    cancelTokenSourceFetchFilterOptions.cancel(
      'Operation canceled due to new request.'
    );
  }

  cancelTokenSourceFetchFilterOptions = axios.CancelToken.source();

  return authorizedRequest.get<any, FiltersOptions>(
    'reports-service/filters_data',
    {
      params: {
        required_filters: filters.join(','),
        ...mapKeys(
          (filterName: string) => `${filterName}__contains`,
          filtersQueries
        ),
      },
      cancelToken: cancelTokenSourceFetchFilterOptions.token,
    }
  );
};
const LOAD_OPTIONS_DEBOUNCE_TIME_MS = 300;
export const debouncedCancellableFetchFiltersOptions = debounce(
  cancellableFetchFiltersOptions,
  LOAD_OPTIONS_DEBOUNCE_TIME_MS
);

type CreateChangeGroupParams = {
  attr_changes: UpdateAttributeModel[];
  report_url: string;
  approve?: boolean;
  ignore_same_values?: boolean;
};
export function createChangeGroup({
  attr_changes,
  report_url,
  approve = false,
  ignore_same_values = false,
}: CreateChangeGroupParams): Promise<BE_types['HistoryResponseModel']> {
  return authorizedRequest.post<any, any>('/operations-service/create', {
    attr_changes,
    report_url,
    approve,
    ignore_same_values,
  });
}
export const fetchOperations = (attributes: AttrForOps[]) =>
  authorizedRequest.post<any, any>('/operations-service/fetch', {attributes});

// TODO: remove in scope of https://adjustcom.atlassian.net/browse/DEX-2871
export const changeEnabled = (f: Filter) => {
  switch (f.filter_param) {
    case 'cost_mode':
    case 'ad_revenue_mode':
      return {...f, enabled: false};
    case 'ad_spend_mode':
    case 'iap_revenue_mode':
      return {...f, enabled: true};
    default:
      return f;
  }
};
export const fetchFiltersFor = (source: TableKey) =>
  authorizedRequest
    .get<any, Filters>(`configs-service/filters${stringifyQuery({source})}`)
    .then(map(changeEnabled));

interface FetchNetworkEntitiesRequest {
  [mappingKey: string]: Dictionary<string>;
}
interface FetchNetworkEntitiesResponse {
  [mappingKey: string]: Dictionary<string | null>;
}
export function fetchNetworkEntitiesLabels(
  mappedNetworkEntities: FetchNetworkEntitiesRequest
) {
  return authorizedRequest.post<any, FetchNetworkEntitiesResponse>(
    'reports-service/fetch_network_entities',
    {
      data: mappedNetworkEntities,
    }
  );
}
export function fetchDrilldownLabels(drilldownParams: Dictionary<string>) {
  return authorizedRequest.post<any, FetchNetworkEntitiesResponse>(
    'reports-service/fetch_network_entities',
    {
      data: {
        drilldown: drilldownParams,
      },
    }
  );
}

export const fetchCurrentAccount = () =>
  dashboardRequest.get('accounts/current_account');

export const fetchCurrentUser = () =>
  dashboardRequest.get('accounts/current_user');

export const fetchAccounts = () => dashboardRequest.get('accounts/accounts');

export const searchAccounts = (search: string) =>
  dashboardRequest.get('accounts/accounts/switch.json', {
    params: {
      search,
    },
  });

export const switchAccount = (accountId: number) =>
  dashboardRequest.post('accounts/accounts/switch', {
    account_id: accountId,
    skip_redirect: true,
  });

export const logoutAccount = () =>
  dashboardRequest.delete('accounts/users/sign_out');
