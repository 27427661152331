import {flow, uniq, map, noop} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {take, put, putResolve, takeLatest, takeEvery} from 'redux-saga/effects';

import {history} from 'browserHistory';
import {PATHNAME_HISTORY} from 'constants/globalVariables';
import {
  fetchEnrichAttributes,
  fetchFiltersFor,
  fetchFiltersOptions,
  createChangeGroup,
  fetchOperations,
} from 'modules/common/api';
import {showConfirmationDialog} from 'modules/common/sagas/common';
import {
  initHistoryRequest,
  initHistorySuccess,
  loadHistoryDataRequest,
  loadHistoryDataSuccess,
  loadHistoryDataFailure,
  setHistoryParams,
  setDefaultHistoryParams,
  parseNewHistoryQuery,
  setFilters,
  setFiltersOptions,
  loadHistoryAttrsRequest,
  loadHistoryAttrsSuccess,
  loadHistoryAttrsFailure,
  loadOpsForHistoryAttrsSuccess,
  loadOpsForHistoryAttrsFailure,
  loadUpdateHistoryAttrRequest,
  loadUpdateHistoryAttrFailure,
  loadUpdateHistoryAttrSuccess,
  loadHistoryFiltersRequest,
  loadHistoryFiltersFailure,
  updateUrlQueryRequest,
} from 'modules/history/actions/HistoryActions';
import {fetchHistoryData} from 'modules/history/api';
import {
  selectRawFilters,
  selectHistoryParams,
} from 'modules/history/reducers/history';
import {safe} from 'sagas/utils';
import {validateASResponse, reshapeRowsToEnrich} from 'utils/attributes';
import {
  getEnabledFilterDefaultParams,
  extractEnabledFilterOptionsNames,
} from 'utils/filters';
import {call, select} from 'utils/libs/TypedReduxSaga';
import {isPopulated} from 'utils/lodash+';
import {prepareAttrsForOps} from 'utils/operations';
import {
  buildHistoryQuery,
  parseHistoryQuery,
  stringifyQuery,
} from 'utils/query';

import {cancelOnHistoryUnmount, cancelOnStaleHistory} from './utils';

import type {
  RowData,
  AttributesResponse,
  AttrForOps,
  UpdateAttributeModel,
} from 'modules/common/types';

export function* loadHistoryFiltersFlow() {
  try {
    const filters = yield* call(fetchFiltersFor, 'history');
    yield put(setFilters(filters));

    const defaultParams = getEnabledFilterDefaultParams(filters);
    yield put(setDefaultHistoryParams(defaultParams));

    const optionsNames = extractEnabledFilterOptionsNames(filters);
    const filtersOptions = yield* call(fetchFiltersOptions, {
      filters: optionsNames,
      isFetchingHistoryOptions: true,
    });
    yield put(setFiltersOptions(filtersOptions));
  } catch (e) {
    console.error(e);
    yield put(loadHistoryFiltersFailure(e));
  }
}
export function* watchLoadHistoryFiltersFlow() {
  yield takeLatest(loadHistoryFiltersRequest, safe(loadHistoryFiltersFlow));
}

export function* initHistoryFlow() {
  yield put(loadHistoryFiltersRequest());
  yield take(setFiltersOptions);

  yield put(initHistorySuccess());
}

export function* watchInitHistoryFlow() {
  yield takeLatest(initHistoryRequest, safe(initHistoryFlow));
}

export function* loadHistoryDataFlow() {
  try {
    const params = yield* select(selectHistoryParams);
    const result = yield* call(fetchHistoryData, params);

    yield put(loadHistoryDataSuccess(result));

    if (isPopulated(result.rows)) {
      yield put(loadHistoryAttrsRequest(result.rows));
    }
  } catch (e) {
    console.error(e);
    yield put(loadHistoryDataFailure(e));
  }
}

export function* watchLoadHistoryDataFlow() {
  yield takeLatest(
    loadHistoryDataRequest,
    cancelOnHistoryUnmount(safe(loadHistoryDataFlow))
  );
}

export function* loadHistoryAttrsFlow({
  payload: reportRows,
}: {
  payload: RowData[];
}) {
  try {
    const getUniqAttributeSlugs = flow(map('attribute_slug'), uniq);
    const attributes = getUniqAttributeSlugs(reportRows);
    const rows = reshapeRowsToEnrich(reportRows, 'targeting');
    const attrData = yield* call(fetchEnrichAttributes, {
      reqBody: {
        rows,
        attributes,
      },
    });
    if (attrData) {
      validateASResponse(rows, attrData);
      yield put(loadHistoryAttrsSuccess(attrData));
    }
  } catch (error) {
    console.error(error);
    yield put(loadHistoryAttrsFailure(error));
  }
}

export function* watchLoadHistoryAttrsFlow() {
  yield takeLatest(loadHistoryAttrsRequest, safe(loadHistoryAttrsFlow));
}

export function* loadOpsForHistoryAttrsFlow({
  payload: attributes,
}: {
  payload: AttributesResponse;
}) {
  try {
    const reshapedAttributes: AttrForOps[] = prepareAttrsForOps(attributes);
    const operations = yield* call(fetchOperations, reshapedAttributes);
    if (isPopulated(operations.rows)) {
      yield put(loadOpsForHistoryAttrsSuccess(operations));
    }
  } catch (error) {
    console.error(error);
    yield put(loadOpsForHistoryAttrsFailure(error));
  }
}

export function* watchLoadOpsForHistoryAttrsFlow() {
  yield takeLatest(loadHistoryAttrsSuccess, safe(loadOpsForHistoryAttrsFlow));
}

export function* updateUrlQueryFlow({
  payload: {isSameEntry},
}: {
  payload: {isSameEntry: boolean};
}) {
  try {
    const params = yield* select(selectHistoryParams);
    const newSearch = flow(buildHistoryQuery, stringifyQuery)(params);

    const navigationMethod = isSameEntry ? 'replace' : 'push';
    yield* call(history[navigationMethod], {
      pathname: PATHNAME_HISTORY,
      search: newSearch,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* watchUpdateUrlQueryFlow() {
  yield takeLatest(
    updateUrlQueryRequest,
    cancelOnStaleHistory(safe(updateUrlQueryFlow))
  );
}

function* reloadHistoryDataFlow() {
  yield put(loadHistoryDataRequest());
}

export function* watchReloadHistoryDataFlow() {
  yield takeLatest([setHistoryParams], safe(reloadHistoryDataFlow));
}

function* getHistoryFiltersAsync() {
  const filters = yield* select(selectRawFilters);

  if (isPopulated(filters)) return filters;

  yield take(setFiltersOptions);
  return yield* select(selectRawFilters);
}

export function* parseNewHistoryQueryFlow({
  payload: search,
}: {
  payload: string;
}) {
  try {
    const filters = yield* call(getHistoryFiltersAsync);
    const newQuery = parseSearch(search);

    const newParams = parseHistoryQuery(filters, newQuery);

    yield putResolve(setHistoryParams(newParams));
    yield put(updateUrlQueryRequest({isSameEntry: true}));
  } catch (e) {
    console.error(e);
  }
}

export function* watchParseNewHistoryQueryFlow() {
  yield takeLatest(parseNewHistoryQuery, safe(parseNewHistoryQueryFlow));
}

// attributes
export function* loadUpdateHistoryAttrFlow({
  payload,
}: {
  payload: UpdateAttributeModel & {
    report_url: string;
    onChangeGroupUpdated?: () => void;
  };
}) {
  try {
    const {
      attribute_slug,
      name,
      value_from,
      value_to,
      report_url,
      onChangeGroupUpdated = noop,
    } = payload;

    const confirmed: boolean = yield showConfirmationDialog({
      header: 'aa.popup.confirmation.header',
      body: 'aa.popup.updateAttribute.body',
      confirmText: 'aa.popup.confirmation.confirm',
      cancelText: 'aa.popup.confirmation.cancel',
      values: {
        slug: attribute_slug,
        valueFrom: value_from?.amount,
        valueTo: value_to?.amount,
        name,
      },
    });

    if (confirmed) {
      const updatedAttribute = yield* call(createChangeGroup, {
        attr_changes: [payload],
        report_url: report_url ?? history.location.search,
        approve: true,
        ignore_same_values: false,
      });
      yield put(loadHistoryDataRequest());

      yield put(loadUpdateHistoryAttrSuccess(updatedAttribute));

      onChangeGroupUpdated();
    }
  } catch (error) {
    yield put(loadUpdateHistoryAttrFailure(error));
  }
}

export function* watchLoadUpdateHistoryAttrFlow() {
  yield takeEvery(
    loadUpdateHistoryAttrRequest,
    safe(loadUpdateHistoryAttrFlow)
  );
}
