import {useCallback, useState} from 'react';

import {isEqual} from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';

import {applyReportParams} from 'modules/report/actions/ReportActions';
import {
  selectAllMetricFiltersOptions,
  selectColumnParams,
} from 'modules/report/reducers';
import {buildNewColumnParams} from 'utils/columns';

import type {ColumnFilterDraft, ColumnDraftType} from 'modules/report/types';

export const useColumnFilters = () => {
  const [columnFilterDrafts, setColumnFilterDrafts] =
    useState<ColumnFilterDraft>({});
  const columnParams = useSelector(selectColumnParams);
  const metricsData = useSelector(selectAllMetricFiltersOptions);

  const dispatch = useDispatch();

  const setColumnFilterDraft = useCallback(
    (columnType: ColumnDraftType, columnKey: string) => (value: string) => {
      setColumnFilterDrafts(drafts => ({
        ...drafts,
        [columnKey]: {value, columnType},
      }));
    },
    [setColumnFilterDrafts]
  );

  const applyColumnFilterDrafts = useCallback(() => {
    const newColumnParams = buildNewColumnParams(
      columnFilterDrafts,
      columnParams,
      metricsData
    );

    const isOnlyAttributeColumnsChanged = Object.values(
      columnFilterDrafts
    ).every(({columnType}) => columnType === 'attribute');

    setColumnFilterDrafts({});
    if (isEqual(newColumnParams, columnParams)) {
      return;
    }
    dispatch(
      applyReportParams({
        params: {column: newColumnParams},
        skipDataReloading: isOnlyAttributeColumnsChanged,
      })
    );
  }, [columnFilterDrafts, columnParams, dispatch, metricsData]);

  return {
    columnFilterDrafts,
    setColumnFilterDraft,
    setColumnFilterDrafts,
    applyColumnFilterDrafts,
  };
};
