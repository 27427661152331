import React from 'react';

import {css} from '@linaria/core';
import ChevronRight from '@material-ui/icons/ChevronRight';

import {TwoLinesMax} from 'common-components/TwoLinesMax';
import {isTemplateReportId} from 'modules/report/utils';

import {ReportLink} from 'modules/report/components/ReportLink';

import {EnrichedReport} from 'modules/report/types';

const cssTitleLink = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--rowHeight);
  padding: 15px 10px;
`;
const cssChevron = css`
  color: #96a1bd;
`;
const cssCellBreakout = css`
  --marginDiff: -10px;
  --widthDiff: calc(var(--marginDiff) * -2);
  width: calc(100% + var(--widthDiff));
  margin: 0 var(--marginDiff);

  &:hover {
    background-color: #e9ecf0;

    & .${cssChevron} {
      color: #4d567e;
    }
  }

  & a.${cssTitleLink}, & a.${cssTitleLink}:hover {
    color: #4d567e;
  }
`;

type Props = {
  report: EnrichedReport;
};
export const ReportTitle = ({
  report: {title, urlWithParentReportId, id: reportId},
}: Props) => {
  const isTemplateReport = isTemplateReportId(reportId);

  return (
    <div
      className={cssCellBreakout}
      data-pendoid={
        isTemplateReport
          ? `report-list-template-report-${reportId}`
          : 'report-title'
      }
    >
      <ReportLink url={urlWithParentReportId} className={cssTitleLink}>
        <TwoLinesMax>
          <span>{title}</span>
        </TwoLinesMax>
        <ChevronRight className={cssChevron} />
      </ReportLink>
    </div>
  );
};
