import React, {
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

// eslint-disable-next-line import/no-unassigned-import
import 'react-dates/initialize';
import Button from '@material-ui/core/Button';
import CalendarIcon from '@material-ui/icons/InsertInvitation';
import cx from 'classnames';
import {noop} from 'lodash/fp';
import moment from 'moment';
import {START_DATE, END_DATE} from 'react-dates/constants';

import {ThemeContext} from 'common-components/Contexts';
import {today, convertToUtc} from 'common-components/utils/date';
import {useIntl} from 'common-components/utils/libs/ReactIntl';

import classes from './DateRangePicker.css';
import DateRangePicker from './react-dates-default';

function swallowEvent(e) {
  e.stopPropagation();
  e.preventDefault();
}

const DatePicker = props => {
  const {
    DatePeriodPanel,
    className,
    style,
    centered,
    showLastDays = true,
    allowSelectToday,
    zIndex = 1000,
    onDatesChange = noop,
    showDateRangeUTCLabel = true,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    datePeriod: defaultDatePeriod,
    ...otherProps
  } = props;

  const intl = useIntl();

  const defaultValue = useMemo(
    () => ({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      datePeriod: defaultDatePeriod,
    }),
    [defaultStartDate, defaultEndDate, defaultDatePeriod]
  );

  const [startDateId, setStartDateId] = useState('startDate');
  const [endDateId, setEndDateId] = useState('endDate');
  const [focusedInput, setFocusedInput] = useState(null);
  const [{startDate, endDate, datePeriod}, setValue] = useState(defaultValue);

  const theme = useContext(ThemeContext);
  useEffect(() => {
    const randomId = new Date().getTime();

    setStartDateId(`startDate${randomId}`);
    setEndDateId(`endDate${randomId}`);
  }, []);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const getInitialMonth = useCallback(
    () =>
      startDate
        ? moment(startDate).startOf('month')
        : moment().subtract(1, 'month').startOf('month'),
    [startDate]
  );
  const handleDatesChange = useCallback(
    ({startDate: updatedStartDate, endDate: updatedEndDate}) => {
      setFocusedInput(
        focusedInput === END_DATE &&
          endDate !== updatedEndDate &&
          updatedEndDate
          ? START_DATE
          : END_DATE
      );
      setValue({
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        datePeriod: null,
      });
    },
    [endDate, focusedInput]
  );
  const handleFocusChange = useCallback(newFocus => {
    if (newFocus) {
      setFocusedInput(newFocus);
    }
  }, []);
  const handleSelectPeriod = (newStartDate, newEndDate, newDatePeriod) => {
    setValue({
      startDate: newStartDate,
      endDate: newEndDate,
      datePeriod: newDatePeriod,
    });
  };
  const handleApply = useCallback(() => {
    if (startDate && endDate) {
      onDatesChange(convertToUtc(startDate), convertToUtc(endDate), datePeriod);
    }

    setFocusedInput(null);
  }, [startDate, endDate, datePeriod, onDatesChange]);

  const handleCancel = useCallback(() => {
    setFocusedInput(null);
    setValue(defaultValue);
  }, [defaultValue]);

  const dateFormat = 'MMM D, YYYY';
  const startDateLabel = moment.utc(startDate).format(dateFormat);
  const endDateLabel = moment.utc(endDate).format(dateFormat);

  const UTCLabel = intl.formatMessage({
    id: 'aa.label.utc',
    defaultMessage: 'UTC',
  });

  const DateRangeUTCLabel = showDateRangeUTCLabel ? ` (${UTCLabel})` : '';
  const selDateRangeLabel = `${startDateLabel}–${endDateLabel}${DateRangeUTCLabel}`;

  const cancelLabel = intl.formatMessage({
    id: 'aa.form.submitBtn.cancel',
    defaultMessage: 'Cancel',
  });
  const applyLabel = intl.formatMessage({
    id: 'aa.form.submitBtn.apply',
    defaultMessage: 'Apply',
  });

  const renderActionButtons = useCallback(
    () => (
      <div
        className={cx(classes.buttonContainer, {
          [classes.themeAdjust]: theme === 'adjust',
        })}
        onClick={swallowEvent}
        role='button'
        tabIndex={0}
      >
        {DatePeriodPanel && (
          <DatePeriodPanel
            datePeriod={datePeriod}
            onSelectOption={handleSelectPeriod}
          />
        )}

        {startDate && endDate && (
          <span className={classes.selectedDatesNotice}>
            {selDateRangeLabel}
          </span>
        )}

        <Button
          style={{
            marginRight: 3,
          }}
          color='primary'
          title={cancelLabel}
          variant={theme === 'adjust' ? 'contained' : 'text'}
          onClick={handleCancel}
        >
          {cancelLabel}
        </Button>

        <Button
          color='primary'
          title={applyLabel}
          variant={theme === 'adjust' ? 'contained' : 'text'}
          disabled={!startDate || !endDate}
          onClick={handleApply}
        >
          {applyLabel}
        </Button>
      </div>
    ),
    [
      startDate,
      endDate,
      datePeriod,
      handleCancel,
      handleApply,
      DatePeriodPanel,
      applyLabel,
      cancelLabel,
      selDateRangeLabel,
      theme,
    ]
  );

  return (
    <div
      className={cx(
        classes.root,
        {
          'DateRangePicker--lastDays': showLastDays,
          'DateRangePicker--centered': centered,
          [classes.themeAdjust]: theme === 'adjust',
        },
        className
      )}
      style={{
        ...style,
        '--date-range-picker-z-index': zIndex,
      }}
    >
      <DateRangePicker
        verticalHeight={34}
        customArrowIcon={<span>–</span>}
        customInputIcon={theme !== 'adjust' ? <CalendarIcon /> : null}
        hideKeyboardShortcutsPanel
        displayFormat='DD MMM YYYY'
        minimumNights={0}
        isOutsideRange={date => convertToUtc(date).isAfter(today(), 'day')}
        keepOpenOnDateSelect
        isDayBlocked={day =>
          !allowSelectToday && convertToUtc(day).isSame(today(), 'day')
        }
        initialVisibleMonth={getInitialMonth}
        withPortal={window?.innerHeight < 850}
        {...otherProps}
        onDatesChange={handleDatesChange}
        onFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        startDateId={startDateId}
        endDateId={endDateId}
        startDate={startDate || null}
        endDate={endDate || null}
        renderCalendarInfo={renderActionButtons}
        onClose={handleCancel}
      />
    </div>
  );
};

export default React.memo(DatePicker);
