import {createReducer} from '@reduxjs/toolkit';

import {
  setReportDebugMode,
  setReportDebugData,
} from 'modules/report/actions/ReportActions';

import type {RootState} from 'modules/common/types';

interface DebugInfoState {
  debug: boolean;
  rsData: any;
  asData: any;
}
const initialDebugInfoState: DebugInfoState = {
  debug: false,
  rsData: null,
  asData: null,
};
export const debugInfo = createReducer<DebugInfoState>(
  initialDebugInfoState,
  builder =>
    builder
      .addCase(setReportDebugMode, (state, {payload: debug}) => ({
        ...state,
        debug,
      }))
      .addCase(setReportDebugData, (state, {payload: {rsData, asData}}) => ({
        ...state,
        rsData,
        asData,
      }))
);

export const selectIsDebugMode = (state: RootState) =>
  state.report.debugInfo.debug;
export const selectRsData = (state: RootState) => state.report.debugInfo.rsData;
export const selectAsData = (state: RootState) => state.report.debugInfo.asData;
