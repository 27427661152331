import {EnhancedAxiosRequestConfig, authorizedRequest} from 'utils/axios';
import {buildReportQuery} from 'utils/query';

import type {
  ReportParams,
  CustomerConfigReport,
  CreateReportParams,
} from 'modules/report/types';
import {BE_types} from 'types/backendServicesTypes';

const RS_REPORT_ROWS_LIMIT = 500;
export function fetchReportData(
  params: ReportParams,
  config?: EnhancedAxiosRequestConfig
) {
  return authorizedRequest.get<any, BE_types['ReportResponseModel']>(
    '/reports-service/report',
    {
      params: {
        ...buildReportQuery(params),
        limit: RS_REPORT_ROWS_LIMIT,
      },
      ...config,
    }
  );
}

export function fetchOperationsStats() {
  return authorizedRequest.get('operations-service/stats');
}

export function createReport(params: CreateReportParams) {
  return authorizedRequest.post<any, BE_types['ReportModel']>(
    'configs-service/report',
    params
  );
}

export function updateReport({
  id,
  ...params
}: {id: CustomerConfigReport['id']} & Partial<CustomerConfigReport>) {
  return authorizedRequest.post(`configs-service/report/${id}`, params);
}

export function deleteReport(id: CustomerConfigReport['id']) {
  return authorizedRequest.delete(`configs-service/report/${id}`);
}

export function restoreReport(id: CustomerConfigReport['id']) {
  return authorizedRequest.post(`configs-service/report/${id}/restore`);
}

export function changeDefaultReport(id: CustomerConfigReport['id']) {
  return authorizedRequest.post('configs-service/default_report', {id});
}

export function fetchReportSpec(params: ReportParams) {
  return authorizedRequest.get('/reports-service/spec', {
    params: buildReportQuery(params),
  });
}
