import React from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {Box, Button} from '@material-ui/core';

const cssWrapper = css`
  display: flex;
  justify-content: center;
`;

const cssContainer = css`
  position: absolute;
  display: flex;
  bottom: 5em;
  justify-content: center;
  z-index: 1510;
`;

const cssPanel = css`
  background-color: white;
  color: #96a1bd;
  border-radius: 6px;
  z-index: 2;
  padding: 1em 2em;
`;

export const ActionPanel: React.FC = ({children}) => (
  <div className={cssWrapper}>
    <div className={cssContainer}>
      <Box className={cssPanel} boxShadow={8}>
        {children}
      </Box>
    </div>
  </div>
);

export const SubmitButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 2em;
  }
`;
