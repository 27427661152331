import React, {useState, useCallback, useMemo, useEffect} from 'react';

import {css} from '@linaria/core';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import cx from 'classnames';
import {flatMap, flow, groupBy, includes, map, toArray} from 'lodash/fp';

import {ModernGrid, SpaceFiller} from 'common-components';
import {authorizedRequest} from 'utils/axios';
import {isValidJson, safeJsonParse} from 'utils/jsonUtils';

import {AttributeCell} from 'modules/common/components';

import {ColumnData} from 'common-components/ModernGrid/types';

const ATTRIBUTE_DEMO_DATA_URL = 'attributes-service/attributes_demo';
// const DEMO_MONEY_ATTR_SLUGS = ['bid', 'daily_budget'];
const DEMO_SELECT_ATTR_SLUGS = [
  'campaign_status',
  'adgroup_status',
  'ad_status',
];
const MONEY_SLUG_CATEGORY = 'money';
const SELECT_SLUG_CATEGORY = 'select';

const cssTrimmedCell = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  width: 100%;
  overflow: hidden;
`;
const cssWrapper = css`
  height: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 1fr 50px;
  column-gap: 10px;
  row-gap: 10px;
  background-color: white;
`;
const cssTextArea = css`
  grid-column: 1;
  grid-row: 1;
  border: solid 1px #c2c2c2;
  vertical-align: bottom;
`;
const cssJsonButton = css`
  grid-column: 1;
  grid-row: 2;
`;
const cssTable = css`
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const cssTabsContainer = css`
  display: flex;
`;
const cssExpandedTable = css`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
`;
const cssToggleEditDataBtn = css`
  margin: 5px;
`;
const cssHidden = css`
  display: none;
`;

const getDebugColumns = () => [
  {
    key: 'index',
    title: '#',
    align: 'left',
    headerAlign: 'left',
    render: ({rowData}: {rowData: {[field: string]: any}}) => (
      <span className={cssTrimmedCell}>{rowData.attribute.hash}</span>
    ),
    isSortable: false,
    width: 100,
  } as ColumnData,
  ...['message', 'type', 'value'].map(
    field =>
      ({
        key: `${field}`,
        title: `${field}`,
        isSortable: false,
        defaultWidth: 180,
        headerAlign: 'left',
        render: ({rowData}: {rowData: {[field: string]: any}}) =>
          rowData?.attribute?.[field] ? (
            <span className={cssTrimmedCell}>
              {JSON.stringify(rowData.attribute[field])}
            </span>
          ) : null,
      } as ColumnData)
  ),
];

const moneyColumns = [
  ...getDebugColumns(),
  {
    key: 'attribute',
    title: 'Attribute (money)',
    align: 'right',
    headerAlign: 'left',
    render: ({value, isSelected}: any) => (
      <AttributeCell isSelected={isSelected} attrData={value} />
    ),
    isSortable: false,
    defaultWidth: 220,
  } as ColumnData,
];
const selectColumns = [
  ...getDebugColumns(),
  {
    key: 'attribute',
    title: 'Attribute (select)',
    align: 'left',
    headerAlign: 'left',
    render: ({value, isSelected}: any) => (
      <AttributeCell isSelected={isSelected} attrData={value} />
    ),
    isSortable: false,
    defaultWidth: 220,
  } as ColumnData,
];

const craftDemoAttrsData = flow(
  map('attr_values'),
  flatMap(toArray),
  groupBy(({slug}) => {
    if (includes(slug, DEMO_SELECT_ATTR_SLUGS)) {
      return SELECT_SLUG_CATEGORY;
    }

    return MONEY_SLUG_CATEGORY;
  })
);

const useASDemoData = () => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const resData = await authorizedRequest.get<any, {}>(
        ATTRIBUTE_DEMO_DATA_URL
      );

      const demoAttrsByType = craftDemoAttrsData(resData);
      setData(demoAttrsByType);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return {data, isLoading};
};

// This demo should be changed on any attribute shape or logic change
const SandboxScreen = () => {
  const [demoAttrs, setDemoAttrs] = useState<{}[]>([]);
  const [demoAttrsJson, setDemoAttrsJson] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [isEditDataContainerVisible, setIsEditDataContainerVisible] =
    useState(false);

  const {data: demoAttrsBySlugCategory, isLoading} = useASDemoData();

  useEffect(() => {
    const selAttrsSlugCategory =
      tabIndex === 0 ? MONEY_SLUG_CATEGORY : SELECT_SLUG_CATEGORY;
    const selDemoAttrs = demoAttrsBySlugCategory[selAttrsSlugCategory];
    setDemoAttrs(selDemoAttrs);
    setDemoAttrsJson(JSON.stringify(selDemoAttrs, null, 2));
  }, [demoAttrsBySlugCategory, tabIndex]);

  const columns = useMemo(
    () => (tabIndex === 0 ? moneyColumns : selectColumns),
    [tabIndex]
  );

  const handleTabChange = (_: any, newTabIndex: number) =>
    setTabIndex(newTabIndex);
  const handleJsonChange = useCallback(
    e => setDemoAttrsJson(e.target.value),
    [setDemoAttrsJson]
  );
  const handleJsonSubmit = useCallback(
    () => setDemoAttrs(safeJsonParse(demoAttrsJson)),
    [demoAttrsJson, setDemoAttrs]
  );

  const tableData = useMemo(
    () =>
      map((attribute: any) => ({
        campaign: 'Fake campaign',
        adgroup: 'Fake adgroup',
        attr_dependency: {app_network: ['store:fake_app']},
        attribute,
      }))(demoAttrs),
    [demoAttrs]
  );

  const toggleEditDataContainer = () => {
    setIsEditDataContainerVisible(!isEditDataContainerVisible);
  };

  const toggleEditDataBtnLabel = isEditDataContainerVisible
    ? 'Hide editing'
    : 'Open editing';

  return (
    <div className={cssWrapper}>
      <textarea
        className={cx(cssTextArea, {
          [cssHidden]: !isEditDataContainerVisible,
        })}
        value={demoAttrsJson}
        onChange={handleJsonChange}
      />

      <Button
        className={cx(cssJsonButton, {
          [cssHidden]: !isEditDataContainerVisible,
        })}
        color='primary'
        variant='contained'
        disabled={!isValidJson(demoAttrsJson)}
        onClick={handleJsonSubmit}
      >
        Apply
      </Button>

      <div
        className={cx(cssTable, {
          [cssExpandedTable]: !isEditDataContainerVisible,
        })}
      >
        <div className={cssTabsContainer}>
          <Button
            color='primary'
            variant='contained'
            onClick={toggleEditDataContainer}
            className={cssToggleEditDataBtn}
          >
            {toggleEditDataBtnLabel}
          </Button>
          <SpaceFiller />
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor='primary'
            aria-label='simple tabs example'
          >
            <Tab label='Money' />
            <Tab label='Select' />
          </Tabs>
        </div>

        <ModernGrid
          columns={columns}
          data={tableData}
          shouldHideFixedColumns
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SandboxScreen;
