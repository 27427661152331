import React from 'react';

import {defaults, map} from 'lodash/fp';

import {TwoLinesMax} from 'common-components/TwoLinesMax';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';

import {ActionButtons} from './ActionButtons';
import {AppsLabel} from './AppLabels';
import {AutomationLabel} from './AutomationLabel';
import {DashedDateColumn} from './DashedDateColumn';
import {ReportTitle} from './ReportTitle';

import {Alignment, ColumnData} from 'common-components/ModernGrid/types';
import type {EnrichedReport} from 'modules/report/types';

const columnSettings = [
  {
    key: 'report_name',
    renderHeader: () => (
      <FormattedMessage
        id='aa.reportList.header.reportName'
        defaultMessage='Report'
      />
    ),
    render: ({rowData: report}: {rowData: EnrichedReport}) => (
      <ReportTitle report={report} />
    ),
    defaultWidth: 300,
  },
  {
    key: 'apps',
    renderHeader: () => (
      <FormattedMessage id='aa.reportList.header.apps' defaultMessage='Apps' />
    ),
    render: ({rowData: report}: {rowData: EnrichedReport}) => (
      <AppsLabel report={report} />
    ),
    defaultWidth: 120,
  },
  {
    key: 'automation',
    renderHeader: () => (
      <FormattedMessage
        id='aa.reportList.header.automation'
        defaultMessage='Automation'
      />
    ),
    render: ({rowData: report}: {rowData: EnrichedReport}) => (
      <AutomationLabel report={report} />
    ),
    defaultWidth: 180,
  },
  {
    key: 'modified_by',
    renderHeader: () => (
      <FormattedMessage
        id='aa.reportList.header.modifiedBy'
        defaultMessage='Modified by'
      />
    ),
    defaultWidth: 150,
  },
  {
    key: 'updated_at',
    renderHeader: () => (
      <FormattedMessage
        id='aa.reportList.header.updatedAt'
        defaultMessage='Last modified'
      />
    ),
    render: DashedDateColumn,
  },
  {
    key: 'actions',
    renderHeader: () => (
      <FormattedMessage
        id='aa.reportList.header.actions'
        defaultMessage='Actions'
      />
    ),
    render: ({rowData: report}: {rowData: EnrichedReport}) => (
      <ActionButtons report={report} />
    ),
    gridWidth: 'min-content',
  },
];

const columSettingsDefaults = {
  align: 'left' as Alignment,
  render: ({value}: {value?: any}) =>
    value ? <TwoLinesMax>{value}</TwoLinesMax> : <>—</>,
  isSortable: false,
};

export const reportListColumns: ColumnData[] = map(
  defaults(columSettingsDefaults)
)(columnSettings);
