import {AxiosInstance, AxiosPromise} from 'axios';
import {v4 as uuidv4} from 'uuid';

import {CONTROL_CENTER} from 'constants/globalVariables';
import {showInformationDialog} from 'modules/common/actions';
import {dispatchAction} from 'store';
import {copyAxiosInstance, request} from 'utils/apiUtils';

import type {EnhancedAxiosRequestConfig} from '.';

type EnhancedAxiosInstance = AxiosInstance & {
  get<T = any>(
    url: string,
    config?: EnhancedAxiosRequestConfig
  ): AxiosPromise<T>;
};

const AUTOMATE_SOURCE_HEADER = 'cc_frontend';

const authorizedRequest: EnhancedAxiosInstance = copyAxiosInstance(request);
// add proper address to request url
authorizedRequest.defaults.baseURL = `${authorizedRequest.defaults.baseURL}/${CONTROL_CENTER}`;
// set headers for requests
authorizedRequest.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
    'Sandbox-Mode': true,
    'correlation-id': uuidv4(),
    'X-Source': AUTOMATE_SOURCE_HEADER,
  },
}));

// show dialogs for errors
authorizedRequest.interceptors.response.use(undefined, error => {
  // process BE error
  if (error?.response?.data?.error?.error_desc) {
    dispatchAction(
      showInformationDialog({
        header: 'aa.popup.error.errorOccurred.header',
        body: error.response.data.error.error_desc,
        isPreformatted: true,
      })
    );
    return Promise.reject(error.response.data);
  }

  // Important. Do not remove! It's crucial for redux-saga call() effect
  // to function properly on axios error
  return Promise.reject(error);
});

export default authorizedRequest;
