import React, {SyntheticEvent, useCallback} from 'react';

import {css} from '@linaria/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import cx from 'classnames';
import {capitalize, noop} from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  hideInformationDialog,
  approveInformationDialog,
  rejectInformationDialog,
} from 'modules/common/actions';
import {
  selectIsInformationDialogOpen,
  selectInformationDialogParams,
} from 'modules/common/reducers';

const cssDialog = css`
  & .MuiPaper-root.MuiDialog-paper {
    background-color: #4b577e;
    color: white;
  }
`;
const cssContentWrapPreformatted = css`
  white-space: pre-wrap;
`;
const cssContentWrap = css`
  overflow-wrap: break-word;
`;
const cssDialogAction = css`
  padding: 16px 8px;
`;

export const InformationDialog = () => {
  const isOpen = useSelector(selectIsInformationDialogOpen);
  const params = useSelector(selectInformationDialogParams);
  const dispatch = useDispatch();

  const intl = useIntl();

  const {
    header,
    body,
    confirmText = capitalize(
      intl.formatMessage({id: 'aa.label.ok', defaultMessage: 'ok'})
    ),
    confirmCallback = noop,
    isPreformatted,
    cancelText,
    cancelCallback = noop,
    closeCallback = noop,
    isWhiteDialog = false,
    values = {},
    pendoId,
  } = params;

  const handleConfirm = useCallback(() => {
    confirmCallback();
    dispatch(approveInformationDialog());
    dispatch(hideInformationDialog());
  }, [confirmCallback, dispatch]);

  const handleCancel = useCallback(() => {
    cancelCallback();
    dispatch(rejectInformationDialog());
    dispatch(hideInformationDialog());
  }, [cancelCallback, dispatch]);

  const handleClose = useCallback(
    (event: SyntheticEvent) => {
      dispatch(hideInformationDialog());
      event.preventDefault();
      closeCallback();
    },
    [closeCallback, dispatch]
  );

  const confirmButtonPendoTag = pendoId ? `modal-${pendoId}Confirm` : null;
  const ConfirmButton = () => (
    <>
      {confirmText && (
        <Button
          data-pendoid={confirmButtonPendoTag}
          color='primary'
          variant='contained'
          onClick={handleConfirm}
        >
          <FormattedMessage id={confirmText} values={values} />
        </Button>
      )}
    </>
  );

  const cancelButtonPendoTag = pendoId ? `modal-${pendoId}Cancel` : null;
  const CancelButton = () => (
    <>
      {cancelText && (
        <Button
          data-pendoid={cancelButtonPendoTag}
          color='secondary'
          variant='contained'
          onClick={handleCancel}
        >
          <FormattedMessage id={cancelText} values={values} />
        </Button>
      )}
    </>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={cx({[cssDialog]: !isWhiteDialog})}
      data-testid='information-dialog'
    >
      {header && (
        <DialogTitle>
          <FormattedMessage id={header} values={values} />
        </DialogTitle>
      )}
      {body && (
        <DialogContent>
          <div
            className={
              isPreformatted ? cssContentWrapPreformatted : cssContentWrap
            }
          >
            <FormattedMessage id={body} values={values} />
          </div>
        </DialogContent>
      )}
      <DialogActions className={cssDialogAction}>
        <>
          <CancelButton />
          <ConfirmButton />
        </>
      </DialogActions>
    </Dialog>
  );
};
