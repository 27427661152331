import React from 'react';

import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {EXTERNAL_CC_CREDENTIALS_LINK} from 'constants/globalVariables';

export const CredentialManagementTab = () => (
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={EXTERNAL_CC_CREDENTIALS_LINK}
  >
    <FormattedMessage
      id='aa.reportTabs.credentialsManager'
      defaultMessage='Credential Management'
    />
  </a>
);
