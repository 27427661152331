import {css} from '@linaria/core';

import {styleVariables} from 'constants/globalVariables';

export const cssWrapper = css`
  color: white;
`;
export const cssContent = css`
  padding: 20px;
`;
export const cssText = css`
  margin-bottom: 16px;
`;
export const cssSection = css`
  margin-bottom: 24px;
`;
export const cssSectionHeader = css`
  font-size: 16px;
  margin-bottom: 8px;
`;
export const cssArrowBack = css`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  cursor: pointer;
`;
export const cssEmailsSection = css`
  margin-bottom: 16px;
  color: white;
`;
export const cssEmailAddressLabel = css`
  color: #96a1bd;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
`;
export const cssRemoveAllButton = css`
  display: flex;
  justify-content: flex-end;
`;
export const cssEmailAlertsButtons = css`
  width: 360px;
  gap: 8px;
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: #4b577e;
  height: fit-content;
  padding: 12px 20px;
  margin-left: -20px;
  border-top: solid 1px #5c6590;
`;
export const cssTitleSection = css`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #4b577e;
  height: 50px;
  align-items: center;
  border-bottom: solid 1px #5c6590;
`;
export const cssTitle = css`
  display: flex;
  color: white;
  font-size: 18px;
`;
export const cssWarning = css`
  color: ${styleVariables.colorFieldError};
`;
