import React, {useCallback, useContext, useMemo} from 'react';

import {css} from '@linaria/core';
import {Button} from '@material-ui/core';
import {map, update, join, compact} from 'lodash/fp';
import {Field, Form} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';

import {Filter, SpaceFiller} from 'common-components';
import {SelectableRowsContext} from 'common-components/ModernGrid/extensions/SelectableRows';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  setHistoryParams,
  updateUrlQueryRequest,
} from 'modules/history/actions/HistoryActions';
import {
  selectHistoryParams,
  getAllHistoryWarnings,
  selectFiltersWithOptions,
} from 'modules/history/reducers/history';
import inputComponents from 'utils/filtersInputs';

import {DateRangePicker, DatePeriodConnector} from 'modules/common/components';
import {
  cssFilterBar,
  cssWarningsContainer,
  cssDateFilterContainer,
  cssDateFilter,
} from 'modules/common/components/QuickFilters.styles';

import {FiltersWithOptions, FilterWithOptions} from 'modules/common/types';

const cssFilters = css`
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
`;
const cssApplyButton = css`
  align-self: flex-end;
  margin-top: 15px;
`;
const cssPaddedFilter = css`
  margin-top: 15px;
  min-width: 230px;
  flex: 1px;
`;
const cssWarningTitle = css`
  font-weight: 500;
`;

const DatePicker = DatePeriodConnector(DateRangePicker);

const PaddedFilter = (props: any) => (
  <Filter className={cssPaddedFilter} {...props} />
);

const HistoryFilters = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const warnings = useSelector(getAllHistoryWarnings);
  const filters: FiltersWithOptions = useSelector(selectFiltersWithOptions);
  const historyParams = useSelector(selectHistoryParams);
  const {discardSelection} = useContext(SelectableRowsContext);

  const onSubmit = useCallback(
    data => {
      // input supports only string, but we store all dimension metrics as arrays
      const sanitizedData = update(
        'entity_name__contains',
        str => compact([str]),
        data
      );
      dispatch(setHistoryParams(sanitizedData));
      dispatch(updateUrlQueryRequest({isSameEntry: false}));
      discardSelection();
    },
    [dispatch, discardSelection]
  );

  const sanitizedHistoryParams = update(
    'entity_name__contains',
    join(''),
    historyParams
  );

  const memoFilters = useMemo(
    () =>
      map(
        (filter: FilterWithOptions) => ({
          ...filter,
          input: inputComponents[filter.component](intl, filter),
        }),
        filters
      ),
    [filters, intl]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={sanitizedHistoryParams}
      subscription={{
        submitting: true,
        pristine: true,
        invalid: true,
      }}
    >
      {({handleSubmit, pristine, invalid, submitting}) => (
        <form onSubmit={handleSubmit}>
          <div className={cssFilterBar}>
            <div className={cssWarningsContainer}>
              {Array.isArray(warnings) &&
                warnings.map(warning => (
                  <div key={warning.body}>
                    <p className={cssWarningTitle}>
                      {intl.formatMessage({id: warning.title})}
                    </p>
                    <p>{intl.formatMessage({id: warning.body})}</p>
                  </div>
                ))}
            </div>

            <div className={cssDateFilterContainer}>
              <Filter className={cssDateFilter}>
                <Field
                  name='date_period'
                  component={DatePicker}
                  onDatesChange={handleSubmit}
                />
              </Filter>
            </div>

            <SpaceFiller />
          </div>

          <div className={cssFilters}>
            {memoFilters.map(
              ({label, input: {component, componentProps}, filter_param}) => (
                <PaddedFilter
                  pendoId={`history-${filter_param}`}
                  label={intl.formatMessage({id: label})}
                  key={filter_param}
                >
                  <Field
                    name={filter_param}
                    placeholder={intl.formatMessage({
                      id: 'aa.label.select',
                      defaultMessage: 'Select',
                    })}
                    component={component}
                    {...componentProps}
                  />
                </PaddedFilter>
              )
            )}

            {!pristine && (
              <Button
                className={cssApplyButton}
                variant='contained'
                color='primary'
                type='submit'
                title={intl.formatMessage({
                  id: 'aa.form.submitBtn.apply',
                  defaultMessage: 'Apply',
                })}
                disabled={invalid || submitting}
              >
                <FormattedMessage
                  id='aa.form.submitBtn.apply'
                  defaultMessage='Apply'
                />
              </Button>
            )}
          </div>
        </form>
      )}
    </Form>
  );
};

export default HistoryFilters;
