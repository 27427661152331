import {
  flow,
  update,
  values,
  mapKeys,
  map,
  groupBy,
  mapValues,
  fromPairs,
} from 'lodash/fp';

import {addColumnPostfix, addBuilderPostfix, DIVIDER} from '../postfix';

import {ReportParams} from 'modules/report/types';

const stringifyColumnFilter = (params: ReportParams['column']) => {
  const {type, column} = params[0];
  const columnValues = map('value', params);
  const key = `${column}${DIVIDER}${type}`;

  return [key, columnValues];
};

const transformColumnParams = flow(
  groupBy(({column, type}) => `${column}_${type}`),
  mapValues(stringifyColumnFilter),
  values,
  fromPairs,
  mapKeys(addColumnPostfix)
);
const transformBuilderParams = mapKeys(addBuilderPostfix);

export const transformParams = flow(
  update('column', transformColumnParams),
  update('builder', transformBuilderParams)
);
