import React, {lazy} from 'react';

import {useDeprecationMiddleware} from '@adjust/dashboard-components/build/deprecation/reactDeprecationMiddleware';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import {
  PATHNAME_BUILDER,
  PATHNAME_HISTORY,
  PATHNAME_REPORT_LIST,
  PATHNAME_ATTRIBUTES_DEMO,
  PATHNAME_REPORT,
  PATHNAME_CV2_BUILDER,
} from 'constants/globalVariables';
import {CVBuilderScreen} from 'cv2-builder/screen';
import {
  selectMainMenuCurrentAccount,
  selectMainMenuCurrentUser,
} from 'modules/common/reducers/adjustConfig';

import {
  App,
  NotFoundPage,
  DefaultRedirect,
  GlobalScreen,
} from 'modules/common/components';
import {ReportList} from 'modules/common/components/ReportList/ReportList';
import HistoryScreen from 'modules/history/components/HistoryScreen';
import {AuthorizedArea} from 'modules/report/components/AuthorizedArea';
import {BuilderScreen} from 'modules/report/components/Builder/BuilderScreen';
import {TableScreen} from 'modules/report/components/ReportTable';

const AttributesDemoScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "SandboxScreen" */ 'modules/report/components/Sandbox/AttributesDemoScreen'
    )
);

export const Routes = () => {
  const currentAccount = useSelector(selectMainMenuCurrentAccount);
  const currentUser = useSelector(selectMainMenuCurrentUser);

  useDeprecationMiddleware(currentAccount, currentUser, {
    projectName: 'fe-control-center',
  });

  return (
    <>
      <App>
        <Route exact path='/'>
          <DefaultRedirect />
        </Route>

        <AuthorizedArea>
          <Switch>
            <Route exact path={PATHNAME_CV2_BUILDER}>
              <CVBuilderScreen />
            </Route>
            <Route>
              <GlobalScreen>
                <Switch>
                  <Route exact path={PATHNAME_REPORT}>
                    <TableScreen />
                  </Route>
                  <Route exact path={PATHNAME_REPORT_LIST}>
                    <ReportList />
                  </Route>
                  <Route path={PATHNAME_BUILDER}>
                    <BuilderScreen />
                  </Route>
                  <Route path={PATHNAME_HISTORY}>
                    <HistoryScreen />
                  </Route>
                  <Route path={PATHNAME_ATTRIBUTES_DEMO}>
                    <AttributesDemoScreen />
                  </Route>

                  <Route>
                    <NotFoundPage />
                  </Route>
                </Switch>
              </GlobalScreen>
            </Route>
          </Switch>
        </AuthorizedArea>
      </App>
    </>
  );
};
