import React, {useCallback} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LoadingIcon from '@material-ui/icons/Sync';
import cx from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

import {styleVariables} from 'constants/globalVariables';
import {
  loadAddPivotReportDataRequest,
  hidePivotReportData,
} from 'modules/report/actions';
import {selectQuickIndexParam} from 'modules/report/reducers';

import {RowIndexStatus} from 'modules/report/types';

const cssIcon = css`
  width: 24px;
  max-width: 24px;
  margin-right: 4px;
  color: ${styleVariables.colorMenuBackground};
`;
const cssLoadingIcon = css`
  max-width: 16px;
  margin-left: 4px;
  color: ${styleVariables.colorMenuBackground};
`;
const cssWrapper = css`
  display: contents;
`;
const cssBoldFont = css`
  font-weight: bold;
`;
const cssClickable = css`
  cursor: pointer;
`;

const IndexStatus = ({status}: {status: RowIndexStatus}) => {
  if (status === 'last') {
    // to preserve icon width even if no icon is rendered
    return <div className={cssIcon} />;
  }

  const Icon = status === 'closed' ? ArrowRight : ArrowDown;

  return <Icon className={cssIcon} />;
};

const IndexIndentation = styled.div<{level: number}>`
  min-width: ${props => props.level * 10}px;
`;

export const IndexCell = ({rowData}: any) => {
  const dispatch = useDispatch();
  const indexParam: string[] = useSelector(selectQuickIndexParam);

  const {hash, indexStatus, indexLevel, isLoading} = rowData;

  const currentIndexDimension = indexParam[indexLevel];
  const content = rowData[currentIndexDimension];

  const isTopLevelRow = indexLevel === 0;
  const isLastLevelRow = indexStatus === 'last';

  const handleClick = useCallback(() => {
    if (isLastLevelRow || isLoading) {
      return;
    }

    if (indexStatus === 'closed') {
      dispatch(loadAddPivotReportDataRequest(hash));
    } else {
      dispatch(hidePivotReportData(hash));
    }
  }, [hash, isLastLevelRow, isLoading, indexStatus, dispatch]);

  return (
    <div
      onClick={handleClick}
      role='button'
      tabIndex={0}
      className={cx(cssWrapper, {
        [cssClickable]: !isLastLevelRow,
        [cssBoldFont]: isTopLevelRow,
      })}
    >
      <IndexIndentation level={indexLevel} />
      <IndexStatus status={indexStatus} />
      <span>{content}</span>
      {isLoading && <LoadingIcon className={cssLoadingIcon} />}
    </div>
  );
};
