import React, {useMemo} from 'react';

import {update} from 'lodash/fp';
import {FormattedMessage, IntlShape, useIntl} from 'react-intl';

type CustomFormattedMessageProps = {
  [propName: string]: any;
  id?: string;
  defaultMessage?: string;
};
const CustomFormattedMessage = (props: CustomFormattedMessageProps) => {
  const {id, defaultMessage} = props;

  if (!id) {
    return <>{defaultMessage ?? ''}</>;
  }

  return <FormattedMessage {...props} />;
};

const createCustomFormatMessage =
  (originalFormatMessage: Function) =>
  (messageDescriptor: {id?: string; defaultMessage?: string}, values?: any) => {
    const {id, defaultMessage} = messageDescriptor;

    if (!id) return defaultMessage ?? '';

    return originalFormatMessage(messageDescriptor, values);
  };
const useCustomIntl = (): IntlShape => {
  const intl = useIntl();

  return useMemo(
    () => update('formatMessage', createCustomFormatMessage, intl),
    [intl]
  );
};

export * from 'react-intl';
export {CustomFormattedMessage as FormattedMessage, useCustomIntl as useIntl};
