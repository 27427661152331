import React, {useState} from 'react';

import {Tooltip} from '@material-ui/core';
import {stringify as stringifyQuery} from 'query-string';
import {useLocation} from 'react-router-dom';

import {SWITCHER, CONTROL_CENTER} from 'constants/globalVariables';

export const ShareableLink = ({
  accountId,
  className,
}: {
  accountId: number;
  className: string;
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const {pathname, search} = useLocation();

  const shareLinkParams = {
    account: accountId,
    url: `${pathname}${search}`,
  };
  const shareableLink = `/${CONTROL_CENTER}${SWITCHER}?${stringifyQuery(
    shareLinkParams
  )}`;
  const shareHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(`${window.location.origin}${shareableLink}`);
    setIsTooltipOpen(true);
    const TOOLTIP_CLOSE_DELAY = 1500;
    setTimeout(() => setIsTooltipOpen(false), TOOLTIP_CLOSE_DELAY);
  };
  return (
    <Tooltip
      open={isTooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title='Copied!'
    >
      <a className={className} onClick={shareHandler} href={shareableLink}>
        Share with Adjust
      </a>
    </Tooltip>
  );
};
