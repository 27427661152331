import {flow, map} from 'lodash/fp';
import moment from 'moment';

import type {Moment} from 'moment';

export const DATE_FORMAT = 'MMM D, YYYY';
export const WEEK_FORMAT = 'Y-[w]ww';
export const DASHED_DATE_FORMAT = 'YYYY-MM-DD';

export const today = () => moment.utc().startOf('day');
export const yesterday = () => moment.utc().subtract(1, 'day').startOf('day');

export function convertToUtc(date: Moment) {
  return moment.utc(date.format(DASHED_DATE_FORMAT), DASHED_DATE_FORMAT);
}

export function convertToApiDate(date: string | Moment, utc = true) {
  if (!date) {
    return null;
  }

  if (utc) {
    return moment.utc(date).format(DASHED_DATE_FORMAT);
  }

  return moment(date).format(DASHED_DATE_FORMAT);
}

export function convertFromApiDate(date: string, utc = true) {
  if (!date) {
    return null;
  }

  if (utc) {
    return moment.utc(date, DASHED_DATE_FORMAT);
  }

  return moment(date, DASHED_DATE_FORMAT);
}

export function getMomentsFromDateRange(
  dateRange: [string, string]
): [Moment | null, Moment | null] {
  return dateRange
    ? (map((date: string) => convertFromApiDate(date, false), dateRange) as [
        Moment | null,
        Moment | null
      ])
    : [null, null];
}

export function convertMomentsToDateRange(dates: [Moment, Moment]) {
  return dates
    ? map((date: Moment) => convertToApiDate(date, false))(dates)
    : null;
}

export function getRangeOfTheSameLengthInThePast(dateRange: [string, string]) {
  if (!Array.isArray(dateRange) || dateRange.length !== 2) {
    return null;
  }
  const moments = getMomentsFromDateRange(dateRange);
  if (
    !moments[0]?.isValid?.() ||
    !moments[1]?.isValid?.() ||
    moments[0].isAfter(moments[1])
  ) {
    throw new Error('invalid date range');
  }

  const datePeriodLength = Math.abs(moments[1].diff(moments[0], 'days'));
  const dateRangePrevious = [
    convertToApiDate(
      moment(moments[0])
        .subtract(datePeriodLength + 1, 'days')
        .startOf('day'),
      false
    ),
    convertToApiDate(
      moment(moments[0]).subtract(1, 'day').startOf('day'),
      false
    ),
  ];

  return dateRangePrevious;
}

const ABSOLUTE_DATE_REGEXP = /^(\d{4}-\d{2}-\d{2})$/;
const RELATIVE_DATE_REGEXP = /^(-?\d+)([mwd])$/;

const DATE_PERIOD_REGEXP =
  /^(\d{4}-\d{2}-\d{2}|-?\d+[mwd]):(\d{4}-\d{2}-\d{2}|-?\d+[mwd])$/m;
const isValidDatePeriod = (str?: string) => str && DATE_PERIOD_REGEXP.test(str);

const ABSOLUTE_DATE_PERIOD_REGEXP =
  /^(\d{4}-\d{2}-\d{2}):(\d{4}-\d{2}-\d{2})$/m;
export const isAbsoluteDatePeriod = (str: string) =>
  ABSOLUTE_DATE_PERIOD_REGEXP.test(str);

const LOGICAL_LAST_N_DATE_PERIOD_REGEXP = /^last_(\d+)_(day|week|month)s$/m;
export const isLogicalDatePeriod = (str: string) =>
  [
    'today',
    'yesterday',
    'this_week',
    'last_week',
    'this_month',
    'last_month',
    'this_year',
    'all_time',
  ].includes(str) || LOGICAL_LAST_N_DATE_PERIOD_REGEXP.test(str);
const unitOfTimeDict = {
  day: 'day',
  week: 'isoWeek',
  month: 'month',
};

type Unit = 'w' | 'm' | 'd';
function parseRelativeDate(str: string) {
  const [, rawValue, rawUnit] = RELATIVE_DATE_REGEXP.exec(str) || [];

  const value = Number(rawValue);
  const unit = rawUnit === 'm' ? 'M' : (rawUnit as Unit);

  return moment.utc().add(value, unit);
}

function parseLogicalDatePeriod(str: string): [Moment, Moment] {
  const m = moment.utc.bind(moment);

  if (str === 'today') {
    return [m(), m()];
  }
  if (str === 'yesterday') {
    return [m().subtract(1, 'day'), m().subtract(1, 'day')];
  }
  if (str === 'this_week') {
    return [m().startOf('isoWeek'), m()];
  }
  if (str === 'this_month') {
    return [m().startOf('month'), m()];
  }
  if (str === 'this_year') {
    return [m().startOf('year'), m()];
  }
  if (str === 'all_time') {
    return [m('2020-01-01').startOf('year'), m()];
  }
  if (str === 'last_week') {
    return [
      // isoWeek always starts on monday
      m().subtract(1, 'week').startOf('isoWeek'),
      m().subtract(1, 'week').endOf('isoWeek'),
    ];
  }
  if (str === 'last_month') {
    return [
      m().subtract(1, 'month').startOf('month'),
      m().subtract(1, 'month').endOf('month'),
    ];
  }
  // last_N_period
  if (LOGICAL_LAST_N_DATE_PERIOD_REGEXP.test(str)) {
    const [, amountOfUnits, durationUnit] =
      LOGICAL_LAST_N_DATE_PERIOD_REGEXP.exec(str) || [];

    // @ts-expect-error: ensured by regexp
    const unitOfTime = unitOfTimeDict[
      durationUnit
    ] as moment.unitOfTime.StartOf;

    return [
      m()
        .subtract(
          Number(amountOfUnits),
          durationUnit as moment.unitOfTime.DurationConstructor
        )
        .startOf(unitOfTime),
      m()
        .subtract(1, durationUnit as moment.unitOfTime.DurationConstructor)
        .endOf(unitOfTime),
    ];
  }

  throw new Error('parseLogicalDatePeriod: incorrect input');
}

const parseAbsoluteOrRelativeDate = (date: string): Moment | undefined => {
  let result: Moment | undefined;

  if (ABSOLUTE_DATE_REGEXP.test(date)) {
    result = moment.utc(date);
  } else if (RELATIVE_DATE_REGEXP.test(date)) {
    result = parseRelativeDate(date);
  }

  return result;
};

export function parseDatePeriod(datePeriodString: string): [string, string] {
  if (isLogicalDatePeriod(datePeriodString)) {
    return flow(
      parseLogicalDatePeriod,
      map(convertToApiDate)
    )(datePeriodString) as [string, string];
  }

  if (!isValidDatePeriod(datePeriodString)) {
    throw new Error(`Invalid date_period format: ${datePeriodString}`);
  }

  const [, startDateString, endDateString] =
    DATE_PERIOD_REGEXP.exec(datePeriodString)!;

  const datePeriod = [
    parseAbsoluteOrRelativeDate(startDateString),
    parseAbsoluteOrRelativeDate(endDateString),
  ] as [Moment, Moment];

  if (datePeriod[0].isAfter(datePeriod[1])) {
    throw new Error(
      `Invalid date order. start_date: ${datePeriod[0].toISOString}; end_date: Start date: ${datePeriod[1].toISOString}`
    );
  }

  return map(convertToApiDate, datePeriod) as [string, string];
}

export const parseDatePeriodTimestamps: (
  datePeriodString: string
) => number[] | never = flow(
  parseDatePeriod,
  map((dateIso: string) => convertFromApiDate(dateIso)?.valueOf() as number)
);

const diffDays = (date: string) =>
  Math.abs(moment.utc(date).diff(moment.utc(), 'days'));
const stringifyDate = (date: string) => `-${diffDays(date)}d`;

export function convertAbsolutePeriodToRelative(datePeriod: string): string {
  if (!isAbsoluteDatePeriod(datePeriod)) {
    return datePeriod;
  }

  const period = parseDatePeriod(datePeriod);

  // BE fails for 0d so we forced to add -0d
  return period.map(stringifyDate).join(':');
}

export function convertLogicalPeriodToAbsolute(datePeriod: string): string {
  if (!isLogicalDatePeriod(datePeriod)) {
    return datePeriod;
  }

  const period = parseDatePeriod(datePeriod);

  return period.join(':');
}
