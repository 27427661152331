import React, {useMemo} from 'react';

import {Button, Paper} from '@material-ui/core';
import {isEmpty, map} from 'lodash/fp';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';

import {OptionWithSection} from './SectionSelect';

import {
  cssEmptyValuesContainer,
  cssLineBreak,
  cssTextAlignCenter,
  cssPanel,
  cssLeftTile,
  cssFormLabel,
  cssFormButtonContainer,
  cssRightTile,
} from 'modules/common/components/CombiSelect/CombiSelect.styles';
import {
  CombiSelectValue,
  PickerSelect,
} from 'modules/common/components/CombiSelect/CombiSelectMenu';

import {Option} from 'modules/common/types';

export const SECTION_SELECT_MENU_TEST_ID = 'SECTION_SELECT_MENU_TEST_ID';
export const SECTION_SELECT_METRIC_PICKER_TEST_ID =
  'SECTION_SELECT_METRIC_PICKER_TEST_ID';
export const SECTION_SELECT_SELECTED_VALUES_TEST_ID =
  'SECTION_SELECT_SELECTED_VALUES_TEST_ID';

const PENDO_ID_TEMPLATE = (tag: string) => `quick-filters-skad-network-${tag}`;

type SectionSelectMenuProps = {
  filterLabel?: string;
  isFormButtonDisabled?: boolean;
  onDeleteValue: (value: OptionWithSection) => void;
  onSelectSection: (value: Option) => void;
  onSelectOption: (value: OptionWithSection) => void;
  onCreateValue: () => void;
  selectedOption: OptionWithSection | null;
  selectedSection: Option | null;
  selectedValues: OptionWithSection[];
  sectionsOptions: Option[];
  optionsGroupedBySection: Record<string, Option[]>;
};
export const SectionSelectMenu = ({
  isFormButtonDisabled,
  filterLabel,
  onCreateValue,
  onDeleteValue,
  onSelectSection,
  onSelectOption,
  selectedSection,
  selectedOption,
  selectedValues,
  sectionsOptions,
  optionsGroupedBySection,
}: SectionSelectMenuProps) => {
  const intl = useIntl();
  const emptyValuesContent = useMemo(
    () => (
      <div className={cssEmptyValuesContainer}>
        <span className={cssTextAlignCenter}>
          <FormattedMessage
            id='aa.label.noKPIAdded'
            defaultMessage='No { item } added'
            values={{item: intl.formatMessage({id: filterLabel})}}
          />
        </span>
        <div className={cssLineBreak} />
        <span className={cssTextAlignCenter}>
          <FormattedMessage
            id='aa.filters.placeholder.message.addKPI'
            defaultMessage='To add a KPI, select a KPI from a group and additional parameters if required.'
          />
        </span>
      </div>
    ),
    [filterLabel, intl]
  );

  const renderedValues = map((option: OptionWithSection) => (
    <div key={option.value}>
      <CombiSelectValue
        option={option}
        onClick={value => onDeleteValue(value as OptionWithSection)}
        pendoId={PENDO_ID_TEMPLATE('remove_kpi')}
      />
    </div>
  ))(selectedValues);

  const selectedGroupValue = selectedSection?.value;
  return (
    <Paper
      style={{
        background: styleVariables.colorMenuBackground,
      }}
      className={cssPanel}
      data-testid={SECTION_SELECT_MENU_TEST_ID}
    >
      <div className={cssLeftTile}>
        <span className={cssFormLabel}>
          <FormattedMessage
            id='aa.placeholder.addItem'
            defaultMessage='Add { item }'
            values={{item: intl.formatMessage({id: filterLabel})}}
          />
        </span>
        <div>
          <PickerSelect
            id='group'
            data-pendoid={PENDO_ID_TEMPLATE('select_1')}
            value={selectedSection}
            onChange={(value: Option) => onSelectSection(value)}
            isRequired
            label={intl.formatMessage({
              id: 'aa.label.group',
              defaultMessage: 'Group',
            })}
            options={sectionsOptions}
          />
        </div>
        {selectedGroupValue && (
          <div
            key={selectedGroupValue}
            data-testid={SECTION_SELECT_METRIC_PICKER_TEST_ID}
          >
            <PickerSelect
              data-pendoid={PENDO_ID_TEMPLATE('select_2')}
              id={selectedGroupValue}
              value={selectedOption}
              onChange={value => onSelectOption(value as OptionWithSection)}
              isRequired
              label={selectedSection.label}
              options={optionsGroupedBySection[selectedGroupValue] || []}
            />
          </div>
        )}
        <div className={cssFormButtonContainer}>
          <Button
            data-pendoid={PENDO_ID_TEMPLATE('add_kpi')}
            size='small'
            color='primary'
            variant='contained'
            type='button'
            onClick={onCreateValue}
            disabled={isFormButtonDisabled}
          >
            <FormattedMessage id='aa.label.add' defaultMessage='Add' />
          </Button>
        </div>
      </div>
      <div
        className={cssRightTile}
        data-testid={SECTION_SELECT_SELECTED_VALUES_TEST_ID}
      >
        {isEmpty(selectedValues) ? emptyValuesContent : renderedValues}
      </div>
    </Paper>
  );
};
