import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {history} from 'browserHistory';
import {
  EXTERNAL_CT_ADMIN_LINK_TEMPLATE,
  EXTERNAL_CC_ADMIN_LINK_TEMPLATE,
  PATHNAME_REPORT_LIST,
} from 'constants/globalVariables';
import {browserHistoryUpdated} from 'modules/common/actions';
import {
  selectIsCustomerConfigLoaded,
  selectCustomerConfig,
} from 'modules/common/reducers/customerConfig';
import {createExternalReportLink} from 'utils/createExternalReportLink';

import {MainMenu} from '../MainMenu/MainMenu';
import classes from './GlobalScreen.css';
import {LogoContainer} from './LogoContainer';
import {ReportDropdown} from './ReportDropdown';
import {ShareableLink} from './ShareableLink';

import {DocumentTitle} from 'modules/common/components/DocumentTitle';
import {Tabs} from 'modules/common/components/Tabs';
import {Drilldown} from 'modules/report/components/Drilldown';

import {BrowserHistoryChange} from 'modules/common/types';

export const GlobalScreen: React.FC = ({children}) => {
  const dispatch = useDispatch();
  const isCustomerConfigLoaded = useSelector(selectIsCustomerConfigLoaded);
  const {adjust_account_id, adjust_superadmin} =
    useSelector(selectCustomerConfig);
  const {pathname: currentPathname = '', search} = useLocation();
  const isReportListActive = currentPathname.endsWith(PATHNAME_REPORT_LIST);

  const externalReportLink = createExternalReportLink(search);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      dispatch(
        browserHistoryUpdated({
          location: location as BrowserHistoryChange['location'],
          action,
        })
      );
    });

    return unlisten;
  }, [dispatch]);

  return (
    <>
      <DocumentTitle />
      <div className={classes.root}>
        <div className={classes.header}>
          <LogoContainer />

          <MainMenu />

          {!isReportListActive && (
            <div className={classes.headerContainer}>
              <ReportDropdown />
              <Drilldown />
            </div>
          )}

          {adjust_superadmin && (
            <div className={classes.adminLinksContainer}>
              <a
                className={classes.adminLink}
                target='_blank'
                rel='noopener noreferrer'
                href={EXTERNAL_CC_ADMIN_LINK_TEMPLATE(adjust_account_id)}
              >
                CC Admin
              </a>
              <a
                className={classes.adminLink}
                target='_blank'
                rel='noopener noreferrer'
                href={EXTERNAL_CT_ADMIN_LINK_TEMPLATE(adjust_account_id)}
              >
                CT Admin
              </a>
              <a
                className={classes.adminLink}
                target='_blank'
                rel='noopener noreferrer'
                href={externalReportLink}
              >
                external report
              </a>
              <ShareableLink
                accountId={adjust_account_id}
                className={classes.visibleLink}
              />
            </div>
          )}
        </div>

        {isCustomerConfigLoaded && <Tabs />}

        <div className={classes.body}>{children}</div>
      </div>
    </>
  );
};
