import {css} from '@linaria/core';

import {BACKDROP_Z_INDEX} from './Backdrop';

export const cssBackdrop = css`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  &.MuiBackdrop-root {
    background-color: transparent;
    z-index: ${BACKDROP_Z_INDEX};
  }
`;
