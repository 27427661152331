import React from 'react';

import {flow, isNil, join, map, uniq} from 'lodash/fp';

import {TwoLinesMax} from 'common-components/TwoLinesMax';

type Props = {value?: string[]; rowData: {apps?: Record<string, string>}};

export const AppColumn = ({
  value: storeIds,
  rowData: {apps: storeIdToAppName},
}: Props) => {
  if (isNil(storeIds) || storeIds.length === 0) {
    return <>—</>;
  }
  const appNames = flow(
    uniq,
    map((storeId: string) => storeIdToAppName?.[storeId] || storeId),
    join(', ')
  )(storeIds);

  return <TwoLinesMax>{appNames}</TwoLinesMax>;
};
