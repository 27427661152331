import {Account, User} from '@adjust/dashboard-components';
import {createReducer} from '@reduxjs/toolkit';

import * as CommonActions from '../actions';

import type {RootState} from 'modules/common/types';

interface AdjustConfigState {
  currentAccount?: Account;
  currentUser?: User;
  accounts: Account[];
}
const initialAdjustConfigState: AdjustConfigState = {
  accounts: [],
};
export const adjustConfig = createReducer<AdjustConfigState>(
  initialAdjustConfigState,
  builder =>
    builder
      .addCase(
        CommonActions.loadAdjustCustomerDataSuccess,
        (state, {payload}) => ({
          ...state,
          currentAccount: payload.currentAccount,
          currentUser: payload.currentUser,
        })
      )
      .addCase(
        CommonActions.loadAdjustCustomerAccountsSuccess,
        (state, {payload}) => ({
          ...state,
          accounts: payload.accounts,
        })
      )
);

export const selectMainMenuCurrentAccount = (state: RootState) =>
  state.common.adjustConfig.currentAccount;
export const selectMainMenuCurrentUser = (state: RootState) =>
  state.common.adjustConfig.currentUser;
export const selectMainMenuAccounts = (state: RootState) =>
  state.common.adjustConfig.accounts;
