import React, {useCallback, useMemo} from 'react';

import {css} from '@linaria/core';
import Button from '@material-ui/core/Button';
import cx from 'classnames';
import {FORM_ERROR} from 'final-form';
import {
  map,
  isEqual,
  isEmpty,
  update,
  difference,
  flow,
  keys,
  fromPairs,
  find,
} from 'lodash/fp';
import {Field, Form} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';

import {Filter} from 'common-components';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  NO_DIMENSIONS_OR_METRICS_ERROR_MESSAGE,
  NO_DIMENSIONS_ERROR_MESSAGE,
  NO_METRICS_ERROR_MESSAGE,
} from 'constants/globalVariables';
import {applyReportParams} from 'modules/report/actions/ReportActions';
import {
  selectQuickParams,
  selectAllReportWarnings,
  selectFiltersWithOptions,
  selectGeneralParams,
  selectBackendEnforcedDimensions,
  selectBackendEnforcedDimensionsSlugs,
} from 'modules/report/reducers';
import {selectIsQuickFiltersOpen} from 'modules/report/reducers/quickFilterOpen';
import {normalizeForm} from 'modules/report/utils';
import inputComponents from 'utils/filtersInputs';
import {
  extractMetricFilterParams,
  METRIC_FILTER_PARAMS,
} from 'utils/reportFilters';

import {DateRangePicker, DatePeriodConnector} from 'modules/common/components';
import {
  cssFilterBar,
  cssWarningsContainer,
  cssDateFilterContainer,
  cssDateFilter,
} from 'modules/common/components/QuickFilters.styles';

import {FilterWithOptions} from 'modules/common/types';
import type {Dictionary} from 'types/utils';

const cssFilters = css`
  margin-bottom: 12px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;
const cssValidationMessage = css`
  position: absolute;
  margin-left: 80px;
  white-space: pre-wrap;
  font-size: 13px;
  color: #454f76;
  width: 130px;
`;
const cssMetricsAndDimensionsMessage = css`
  margin-top: -12px;
`;
const cssButtonValidation = css`
  display: flex;
  align-self: end;
  padding-top: 25px;
`;
const cssApplyButton = css`
  &.MuiButton-root {
    align-self: end;
    justify-self: start;
  }
`;
const cssWarningTitle = css`
  font-weight: 500;
`;

const DatePicker = DatePeriodConnector(DateRangePicker);

const PaddedFilter: React.FC<{label: string}> = ({label, children}) => (
  <Filter label={label}>{children}</Filter>
);

const createInvalidFilterErrors = (
  message: string,
  invalidFields: string[]
) => ({
  [FORM_ERROR]: message,
  ...fromPairs(map(field => [field, 'error'], invalidFields)),
});

export const extractFiltersValidationErrors = ({
  dimensions,
  ...restFilters
}: Dictionary<string[]>): Dictionary<string> => {
  const metrics = extractMetricFilterParams(restFilters);

  if (isEmpty(metrics)) {
    if (isEmpty(dimensions)) {
      return createInvalidFilterErrors(NO_DIMENSIONS_OR_METRICS_ERROR_MESSAGE, [
        'dimensions',
        ...METRIC_FILTER_PARAMS,
      ]);
    }
    return createInvalidFilterErrors(
      NO_METRICS_ERROR_MESSAGE,
      METRIC_FILTER_PARAMS
    );
  }
  if (isEmpty(dimensions)) {
    return createInvalidFilterErrors(NO_DIMENSIONS_ERROR_MESSAGE, [
      'dimensions',
    ]);
  }
  return {};
};

const SettingsComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const quickParams = useSelector(selectQuickParams);
  const {date_period: initDatePeriod} = useSelector(selectGeneralParams);

  const initialValues = useMemo(
    () => ({
      ...quickParams,
      date_period: initDatePeriod,
    }),
    [quickParams, initDatePeriod]
  );

  const filters = useSelector(selectFiltersWithOptions);
  const warnings = useSelector(selectAllReportWarnings);
  const backendDimensions = useSelector(selectBackendEnforcedDimensions);
  const backendDimensionsSlugs = useSelector(
    selectBackendEnforcedDimensionsSlugs
  );

  const isOpen = useSelector(selectIsQuickFiltersOpen);
  const handleApply = useCallback(
    data => {
      const {date_period, ...quick} = normalizeForm(data);

      // As we only update defined fields in applyReportParams
      // we need to assign empty values for those filters that were 'cleared'
      const clearedFiltersNames = difference(keys(quickParams), keys(quick));
      const clearedFiltersWithEmptyValues = flow(
        map((filterParam: string) => {
          const filter = find({filterParam}, filters) as FilterWithOptions;
          const emptyValue = filter?.component === 'multiselect' ? [] : '';

          return [filterParam, emptyValue];
        }),
        fromPairs
      )(clearedFiltersNames);

      // remove drilldown / index dimensions from form dimensions
      const quickParamWithoutBackendEnforcedDimensions = update(
        'dimensions',
        (formDimensions: string[]) =>
          difference(formDimensions, backendDimensionsSlugs),
        quick
      );
      const updatedValues = {
        ...quickParamWithoutBackendEnforcedDimensions,
        date_period,
      };

      const isParamsEqual = isEqual(updatedValues, initialValues);
      dispatch(
        applyReportParams({
          params: {
            quick: {
              ...quickParamWithoutBackendEnforcedDimensions,
              ...clearedFiltersWithEmptyValues,
            },
            general: {date_period},
          },
          skipDataReloading: isParamsEqual,
        })
      );
    },
    [quickParams, initialValues, dispatch, filters, backendDimensionsSlugs]
  );

  const memoFilters = useMemo(
    () =>
      map(
        filter => ({
          ...filter,
          input: inputComponents[filter.component](
            intl,
            filter,
            backendDimensions
          ),
        }),
        filters
      ),
    [filters, intl, backendDimensions]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleApply}
      subscription={{
        submitting: true,
        pristine: true,
        invalid: true,
        error: true,
        errors: true,
      }}
      validate={extractFiltersValidationErrors}
    >
      {({
        invalid,
        submitting,
        pristine,
        handleSubmit,
        error: errorMessageKey,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={cssFilterBar}>
            <div className={cssWarningsContainer}>
              {Array.isArray(warnings) &&
                warnings.map(warning => (
                  <div key={warning.body}>
                    <p className={cssWarningTitle}>
                      {intl.formatMessage({id: warning.title})}
                    </p>
                    <p>{intl.formatMessage({id: warning.body})}</p>
                  </div>
                ))}
            </div>
            <div className={cssDateFilterContainer}>
              <Filter className={cssDateFilter}>
                <Field
                  name='date_period'
                  component={DatePicker}
                  onDatesChange={handleSubmit}
                />
              </Filter>
            </div>
          </div>
          {isOpen && (
            <div className={cssFilters}>
              {map(
                ({label, filter_param, input: {component, componentProps}}) => (
                  <PaddedFilter
                    label={intl.formatMessage({id: label})}
                    key={filter_param}
                  >
                    <Field
                      isCompact
                      name={filter_param}
                      pendoId={`quickFilters-${filter_param}`}
                      component={component}
                      placeholder={intl.formatMessage({
                        id: 'aa.label.select',
                        defaultMessage: 'Select',
                      })}
                      showWarning={Boolean(errors?.[filter_param])}
                      {...componentProps}
                    />
                  </PaddedFilter>
                )
              )(memoFilters)}
              <div className={cssButtonValidation}>
                <Button
                  data-pendoid='quickFilters-applyButton'
                  className={cssApplyButton}
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={handleSubmit}
                  disabled={invalid || submitting || pristine}
                  title={intl.formatMessage({
                    id: 'aa.form.submitBtn.apply',
                    defaultMessage: 'Apply',
                  })}
                >
                  <FormattedMessage
                    id='aa.form.submitBtn.apply'
                    defaultMessage='Apply'
                  />
                </Button>
                {errorMessageKey && (
                  <span
                    className={cx(cssValidationMessage, {
                      [cssMetricsAndDimensionsMessage]:
                        errorMessageKey ===
                        NO_DIMENSIONS_OR_METRICS_ERROR_MESSAGE,
                    })}
                  >
                    {intl.formatMessage({
                      id: errorMessageKey,
                    })}
                  </span>
                )}
              </div>
            </div>
          )}
        </form>
      )}
    </Form>
  );
};
export const ReportSettings = React.memo(SettingsComponent);
