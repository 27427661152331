import {createTheme} from '@material-ui/core/styles';

import {styleVariables} from 'constants/globalVariables';

export const adjustTheme = createTheme({
  typography: {
    fontFamily: ['Maven Pro', 'Sans', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      light: '#96a1bd',
      main: '#96a1bd',
      contrastText: '#fff',
    },
    secondary: {
      light: '#96a1bd',
      main: '#96a1bd',
      contrastText: '#fff',
    },
    error: {
      main: styleVariables.colorLipstickTwo,
    },
    text: {
      primary: '#252525',
      secondary: '#96a1bd',
    },
    action: {
      active: styleVariables.colorWhite,
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 'normal',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          color: 'white',
          backgroundColor: '#96a1bd',
          opacity: 0.4,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#8693b3',
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#8693b3',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 6,
      },
      colorPrimary: {
        '&:hover': {
          color: '#4b577e',
          backgroundColor: 'none',
        },
      },
      colorSecondary: {
        '&:hover': {
          color: '#ab003c',
          backgroundColor: 'none',
        },
      },
    },
    MuiTextField: {
      root: {
        '& .MuiFormLabel-root.Mui-focused': {
          color: '#96a1bd',
        },
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
      },
      input: {
        height: '1.3125rem',
        '&&::-webkit-input-placeholder': {
          color: '#96a1bd',
          opacity: 1,
        },
      },
      underline: {
        '&&:before': {
          borderBottom: 'solid 2px #b5bdd1',
        },
        '&&:hover:before': {
          borderBottomColor: '#4b577e',
        },
      },
      colorSecondary: {
        color: 'white',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  } /* ,
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  } */,
});
