import React from 'react';

import {css} from '@linaria/core';
import SettingsIcon from '@material-ui/icons/Settings';
import {Link, useLocation} from 'react-router-dom';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {PATHNAME_BUILDER} from 'constants/globalVariables';

import {StyledReportButton} from './StyledReportButton';

const cssWrapper = css`
  display: contents;
`;
const cssIcon = css`
  &.MuiSvgIcon-root {
    margin-right: 4px;
    height: 20px;
    width: 20px;
  }
`;

export const BuilderButton = () => {
  const {search} = useLocation();
  const intl = useIntl();

  const PATH_TO_BUILDER_PAGE = {
    pathname: PATHNAME_BUILDER,
    search,
  };

  const title = intl.formatMessage({
    id: 'aa.label.settings',
    defaultMessage: 'Settings',
  });

  const label = intl.formatMessage({
    id: 'aa.label.builder',
    defaultMessage: 'Builder',
  });

  return (
    <Link to={PATH_TO_BUILDER_PAGE} className={cssWrapper}>
      <StyledReportButton
        data-pendoid='tabs-builderButton'
        title={title}
        color='primary'
      >
        <SettingsIcon className={cssIcon} />
        <span>{label}</span>
      </StyledReportButton>
    </Link>
  );
};
