import {endsWith, flow, keys, pick, pickBy} from 'lodash/fp';

import {flattenValues} from './lodash+';

// constants
export const METRIC_FILTER_PARAM_TO_OPTIONS_NAME = {
  events: 'event_metrics',
  overviews: 'overview_metrics',
  cohorts: 'cohort_metrics',
  skads: 'skad_metrics',
  etls: 'etl_metrics',
} as const;

export const METRIC_FILTER_PARAMS = keys(METRIC_FILTER_PARAM_TO_OPTIONS_NAME);

const METRIC_FILTER_SUFFIX = '_metrics';

// utils
const pickMetricFilterParams = pick(METRIC_FILTER_PARAMS);
export const extractMetricFilterParams = flow(
  pickMetricFilterParams,
  flattenValues
);

export const pickMetricFilterOptionsNames = pickBy((_: any, key: string) =>
  endsWith(METRIC_FILTER_SUFFIX, key)
);
export const extractMetricFilterOptionsNames = flow(
  pickMetricFilterOptionsNames,
  flattenValues
);
