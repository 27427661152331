import React from 'react';

import {styleVariables} from 'constants/globalVariables';

const styles = {
  title: {
    fontSize: styleVariables.titleFontSize,
    fontWeight: styleVariables.fontWeightMedium,
  },
};

// type Props = BasicComponent & {
//   capitalize?: boolean
// };

const Title = ({
  className = '',
  style = {},
  children,
  capitalize = true,
  ...props
}) => (
  <div
    className={className}
    style={{
      ...styles.title,
      ...(capitalize ? {textTransform: 'capitalize'} : {}),
      ...style,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </div>
);

export default Title;
