import moment from 'moment';

import type {Dictionary} from 'types/utils';

type Change = Dictionary<unknown>;

export const daysNumberUntilExpiredDate = 2;

export const checkIsExpired = ({created_at}: Change) => {
  const changeExpireDate = moment
    .utc(created_at as string)
    .add(daysNumberUntilExpiredDate, 'days');

  const now = moment.utc();

  const isExpired = changeExpireDate.isBefore(now);

  return isExpired;
};
