import {createModuleAction} from 'utils/reduxUtils';

const {createAction} = createModuleAction('HISTORY');

export const initHistoryRequest = createAction('INIT_HISTORY_REQUEST');
export const initHistorySuccess = createAction('INIT_HISTORY_SUCCESS');
// @deprecated
export const initHistoryFailure = createAction('INIT_HISTORY_FAILURE');

export const setFilters = createAction<any>('SET_FILTERS');
export const setFiltersOptions = createAction<any>('SET_FILTERS_OPTIONS');

export const setDefaultHistoryParams = createAction<any>('SET_DEFAULT_PARAMS');
export const setHistoryParams = createAction<any>('SET_HISTORY_PARAMS');

export const updateUrlQueryRequest = createAction<any>(
  'UPDATE_URL_QUERY_REQUEST'
);

export const parseNewHistoryQuery = createAction<any>(
  'PARSE_NEW_HISTORY_QUERY'
);

export const loadHistoryDataRequest = createAction('LOAD_HISTORY_DATA_REQUEST');
export const loadHistoryDataSuccess = createAction<any>(
  'LOAD_HISTORY_DATA_SUCCESS'
);
export const loadHistoryDataFailure = createAction<any>(
  'LOAD_HISTORY_DATA_FAILURE'
);

export const confirmChanges = createAction<any>('CONFIRM_CHANGES');
export const loadApproveChangesRequest = createAction<any>(
  'LOAD_APPROVE_CHANGES_REQUEST'
);
export const loadApproveChangesSuccess = createAction<any>(
  'LOAD_APPROVE_CHANGES_SUCCESS'
);
export const loadApproveChangesFailure = createAction<any>(
  'LOAD_APPROVE_CHANGES_FAILURE'
);

export const loadDiscardChangesRequest = createAction<any>(
  'LOAD_DISCARD_CHANGES_REQUEST'
);
export const loadDiscardChangesSuccess = createAction<any>(
  'LOAD_DISCARD_CHANGES_SUCCESS'
);
export const loadDiscardChangesFailure = createAction<any>(
  'LOAD_DISCARD_CHANGES_FAILURE'
);

export const loadRevertChangesRequest = createAction<any>(
  'LOAD_REVERT_CHANGES_REQUEST'
);
export const loadRevertChangesSuccess = createAction<any>(
  'LOAD_REVERT_CHANGES_SUCCESS'
);
export const loadRevertChangesFailure = createAction<any>(
  'LOAD_REVERT_CHANGES_FAILURE'
);

export const loadRetryChangesRequest = createAction<any>(
  'LOAD_RETRY_CHANGES_REQUEST'
);
export const loadRetryChangesSuccess = createAction<any>(
  'LOAD_RETRY_CHANGES_SUCCESS'
);
export const loadRetryChangesFailure = createAction<any>(
  'LOAD_RETRY_CHANGES_FAILURE'
);

export const loadHistoryAttrsRequest = createAction<any>(
  'LOAD_HISTORY_ATTRIBUTES_REQUEST'
);
export const loadHistoryAttrsSuccess = createAction<any>(
  'LOAD_HISTORY_ATTRIBUTES_SUCCESS'
);
export const loadHistoryAttrsFailure = createAction<any>(
  'LOAD_HISTORY_ATTRIBUTES_FAILURE'
);

export const loadUpdateHistoryAttrRequest = createAction<any>(
  'LOAD_UPDATE_HISTORY_ATTR_REQUEST'
);
// @deprecated
export const loadUpdateHistoryAttrSuccess = createAction<any>(
  'LOAD_UPDATE_HISTORY_ATTR_SUCCESS'
);
export const loadUpdateHistoryAttrFailure = createAction<any>(
  'LOAD_UPDATE_HISTORY_ATTR_FAILURE'
);

// @deprecated
export const loadOpsForHistoryAttrsRequest = createAction(
  'LOAD_OPS_FOR_HISTORY_ATTRS_REQUEST'
);
export const loadOpsForHistoryAttrsSuccess = createAction<any>(
  'LOAD_OPS_FOR_HISTORY_ATTRS_SUCCESS'
);
export const loadOpsForHistoryAttrsFailure = createAction<any>(
  'LOAD_OPS_FOR_HISTORY_ATTRS_FAILURE'
);

export const loadEditChangesRequest = createAction<any>(
  'LOAD_EDIT_CHANGES_REQUEST'
);
export const loadEditChangesOptimisticSuccess = createAction<any>(
  'LOAD_EDIT_CHANGES_OPTIMISTIC_SUCCESS'
);
export const loadEditChangesSuccess = createAction<any>(
  'LOAD_EDIT_CHANGES_SUCCESS'
);
export const loadEditChangesFailure = createAction<any>(
  'LOAD_EDIT_CHANGES_FAILURE'
);

export const loadHistoryFiltersRequest = createAction(
  'LOAD_HISTORY_FILTERS_REQUEST'
);
export const loadHistoryFiltersFailure = createAction<any>(
  'LOAD_HISTORY_FILTERS_FAILURE'
);

export const historyScreenUnmounted = createAction('HISTORY_SCREEN_UNMOUNTED');
