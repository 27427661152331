import {flow, head, isEmpty, join, map} from 'lodash/fp';
import {IntlShape} from 'react-intl';

import {AutomationStatus, AutomationStatusOption} from './types';
import {BE_types} from 'types/backendServicesTypes';

const craftHumanReadableActionOperation = (
  intl: IntlShape,
  {attributeName, operation}: {attributeName: string; operation: string}
) => {
  const isRelative = ['+', '-'].includes(head(operation.toString()) || '');

  if (isRelative) {
    return `${attributeName} ${operation}`;
  }

  return intl.formatMessage(
    {
      id: 'aa.label.to',
      defaultMessage: '{attributeName} to {operation}',
    },
    {
      attributeName,
      operation,
    }
  );
};

export const craftHumanReadableRules = (
  intl: IntlShape,
  actions?: BE_types['ActionModel']['value'][]
): string =>
  isEmpty(actions)
    ? ''
    : flow(
        map((action: any) => craftHumanReadableActionOperation(intl, action)),
        join(', ')
      )(actions);

export const craftAutomationStatus = (
  hasAutomationSetup: boolean,
  hasAutomationEnabled?: boolean
): AutomationStatus => {
  if (!hasAutomationSetup) {
    return 'none';
  }

  if (hasAutomationEnabled) {
    return 'enabled';
  }

  return 'disabled';
};

export const getAutomationStatusOption = (
  intl: IntlShape
): AutomationStatusOption[] => [
  {
    label: intl.formatMessage({
      id: 'aa.label.all',
      defaultMessage: 'All',
    }),
    value: '',
  },
  {
    label: intl.formatMessage({
      id: 'aa.reportList.header.empty',
      defaultMessage: 'Empty',
    }),
    value: 'none',
  },
  {
    label: intl.formatMessage({
      id: 'aa.reportList.header.enabled',
      defaultMessage: 'Enabled',
    }),
    value: 'enabled',
  },
  {
    label: intl.formatMessage({
      id: 'aa.reportList.header.disabled',
      defaultMessage: 'Disabled',
    }),
    value: 'disabled',
  },
];
