import React from 'react';

import {css} from '@linaria/core';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import {startCase, isNil} from 'lodash/fp';

import {OneLine} from 'common-components/OneLine/OneLine';
import {styleVariables} from 'constants/globalVariables';

const cssWrapper = css`
  flex: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const cssIconWrapper = css`
  margin-left: 4px;
  margin-right: -8px;
  height: 24px;
`;

type SlugIconProps = {
  value_from?: {amount: number};
  value_to: {amount: number};
  formatting: string;
};

const SlugIcon = ({value_from, value_to, formatting}: SlugIconProps) => {
  if (formatting === 'select' || isNil(value_from)) {
    return null;
  }

  if (value_to.amount >= value_from.amount) {
    return <ArrowUp htmlColor={styleVariables.colorDodgerBlue} />;
  }

  return <ArrowDown htmlColor={styleVariables.colorLipstickTwo} />;
};

type Props = {
  value?: string;
  rowData: {
    value_from?: {amount: number};
    value_to: {amount: number};
    formatting: string;
  };
};

export const SlugColumn = ({
  value,
  rowData: {value_from, value_to, formatting},
}: Props) =>
  !isNil(value) ? (
    <div className={cssWrapper}>
      <OneLine>{startCase(value)}</OneLine>
      <span className={cssIconWrapper}>
        <SlugIcon
          value_from={value_from}
          value_to={value_to}
          formatting={formatting}
        />
      </span>
    </div>
  ) : (
    <>—</>
  );
