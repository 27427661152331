import React from 'react';

import {isNil} from 'lodash/fp';

import {OneLine} from 'common-components/OneLine/OneLine';
import {createAttrFormatter} from 'utils/conversions';

import {TextCell} from 'modules/common/components';

import type {AttributeValue} from 'modules/common/types';

type Props = {
  value?: AttributeValue['value'];
  rowData: {
    formatting: string;
  };
};

export const ValueFromColumn = ({value, rowData: {formatting}}: Props) => {
  if (isNil(value)) {
    return <>—</>;
  }

  const relativeOperation = value.relative_operation;
  const formatter = createAttrFormatter({
    accuracy: value.accuracy,
    currency: value.currency,
  });
  const amount = parseFloat(value.amount ?? value.value);
  const formattedValue = !isNil(relativeOperation)
    ? `${relativeOperation} (${formatter.format(amount)})`
    : formatter.format(amount);

  if (formatting === 'select') {
    // @ts-expect-error Type '{ value: { [key: string]: any; } & { accurac... Remove this comment to see the full error message
    return <TextCell attrData={{value}} />;
  }
  return <OneLine>{formattedValue}</OneLine>;
};
