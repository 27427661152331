import React, {memo} from 'react';

import {css} from '@linaria/core';
import Edit from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InheritedIcon from '@material-ui/icons/Link';

import {FormattedTooltip} from '../FormattedTooltip';

import {BE_types} from 'types/backendServicesTypes';

const cssInheritedIcon = css`
  &.MuiSvgIcon-root {
    height: 14px;
    width: 14px;
    margin-left: 10px;
    color: #a1a1a1;
  }
`;
const cssEditIndicator = css`
  &.MuiSvgIcon-root {
    height: 17px;
    width: 17px;
    color: #e1e6ea;
    margin-left: 10px;
  }
`;
const cssExpandMoreUnknown = css`
  &.MuiSvgIcon-root {
    color: #dee2e6;
    height: 25px;
    width: 25px;
    margin-left: 8px;
  }
`;
const cssExpandMoreMixed = css`
  &.MuiSvgIcon-root {
    color: #dee2e6;
    height: 25px;
    width: 25px;
    margin-left: 28px;
  }
`;

const getAttrIcon = (attrSlug: BE_types['AttributeDataModel']['slug']) => {
  switch (attrSlug) {
    case 'ad_status':
    case 'campaign_status':
    case 'adgroup_status':
      return ExpandMoreIcon;
    case 'bid_target_roas':
    case 'max_bid':
    case 'bid':
    case 'daily_budget':
    default:
      return Edit;
  }
};

type Props = {
  iconPendoId: string | null;
  attr: BE_types['AttributeDataModel'];
  tooltipText: string;
};
export const AttributeIcon = memo(
  ({
    iconPendoId,
    attr: {slug: attrSlug, type: attrType, inherited},
    tooltipText,
  }: Props) => {
    const Icon = getAttrIcon(attrSlug);
    const shouldShowIcon = !['money', 'select', 'percentage'].includes(
      attrType
    );
    const shouldShowInherited =
      !['unknown', 'select'].includes(attrType) && inherited;

    const expandMoreStyles = ['unknown'].includes(attrType)
      ? cssExpandMoreUnknown
      : cssExpandMoreMixed;
    const pencilStyles = cssEditIndicator;
    const styles = Icon === ExpandMoreIcon ? expandMoreStyles : pencilStyles;

    return (
      <FormattedTooltip title={tooltipText} interactive>
        <div>
          {shouldShowIcon && (
            <Icon data-pendoid={iconPendoId} className={styles} />
          )}
          {shouldShowInherited && (
            <InheritedIcon className={cssInheritedIcon} />
          )}
        </div>
      </FormattedTooltip>
    );
  }
);
