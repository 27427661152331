import {createSlice} from '@reduxjs/toolkit';

import type {RootState} from 'modules/common/types';

const chartsOpenSlice = createSlice({
  name: 'REPORT/CHARTS_OPEN',
  initialState: false,
  reducers: {
    toggleChartsOpen: state => !state,
  },
});

export const selectIsChartsOpen = (state: RootState) => state.report.chartsOpen;

export const {toggleChartsOpen} = chartsOpenSlice.actions;
export const chartsOpen = chartsOpenSlice.reducer;
