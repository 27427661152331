import React from 'react';

import {css} from '@linaria/core';

export const oneLineStyle = {
  whiteSpace: 'nowrap',
};

export const sOneLineClassName = css`
  white-space: nowrap;
`;

export const OneLine: React.FC = ({children}) => (
  <span className={sOneLineClassName}>{children}</span>
);

export default OneLine;
