import * as Sentry from '@sentry/browser';
import {
  flow,
  map,
  join,
  compact,
  split,
  invert,
  isEmpty,
  replace,
  update,
  find,
  defaults,
  groupBy,
  sortBy,
  values,
  flatten,
} from 'lodash/fp';

import {uncappedMapValues} from 'utils/lodash+';

import {FilterOptions} from 'modules/common/types';
import {
  ColumnParam,
  ColumnFilterDraft,
  ColumnDraftType,
  AttributeColumnParam,
  DimensionColumnParam,
  MetricColumnParam,
  ColumnMetricType,
} from 'modules/report/types';

const relationMapping: Record<ColumnMetricType, string> = {
  eq: '=',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
};
const attributeColumns = [
  'bid',
  'daily_budget',
  'status',
  'name',
  'campaign_status',
  'adgroup_status',
  'ad_status',
  'max_bid',
  'bid_target_roas',
];
const invertedRelationMapping = invert(relationMapping);
const dimensionParamRegExp = /([=-]?)"(.*?)"/g;

const getRelationSign = (relationType: keyof typeof relationMapping) =>
  relationMapping[relationType];

const stringifyExclude = ({value}: DimensionColumnParam) => `-"${value}"`;
const stringifyContains = ({value}: DimensionColumnParam) => `"${value}"`;
const stringifyIn = ({value}: DimensionColumnParam) => `="${value}"`;
const stringifyMetric = ({type, value}: MetricColumnParam) =>
  `${getRelationSign(type)}${value}`;

const dimensionStringifiers = {
  in: stringifyIn,
  exclude: stringifyExclude,
  contains: stringifyContains,
};

const isDimensionParam = (param: ColumnParam): param is DimensionColumnParam =>
  param.type in dimensionStringifiers;
const isMetricParam = (param: ColumnParam): param is MetricColumnParam =>
  param.type in relationMapping;
const isAttributeParam = (param: ColumnParam): param is AttributeColumnParam =>
  attributeColumns.includes(param.column);

const stringifyColumnParam = (columnParam: ColumnParam) => {
  // Should be first condition, coz attributes have similar type `contains` with dimension param
  if (isAttributeParam(columnParam)) {
    return columnParam.value;
  }
  if (isMetricParam(columnParam)) {
    return stringifyMetric(columnParam);
  }
  if (isDimensionParam(columnParam)) {
    return dimensionStringifiers[columnParam.type](columnParam);
  }

  Sentry.captureMessage(
    `Unknown column param type for columnParam: ${JSON.stringify(columnParam)}`
  );
  // @ts-expect-error Property 'value' does not exist on type 'never'. We covered all cases in previous conditions so TS thinks that columnParam is never, but we still want to have fallback
  return columnParam.value;
};
export const stringifyColumnParams = flow(
  map(stringifyColumnParam),
  join(', ')
);

/**
 * Checks if string is float number with optional percentage sign
 * For example: 17.78%
 */
const isValidMetricValue = (str: string) => /^-?\d*\.?\d*%?$/.test(str);
const findRelationSign = (expression: string) => {
  const numberPosition = expression.search(/-?\.?\d/);
  return expression.slice(0, numberPosition).trim();
};
const parseMetricParam = (param: string) => {
  const relationSign = findRelationSign(param);
  const value = param.replace(relationSign, '').trim();

  if (isValidMetricValue(value)) {
    const relation = invertedRelationMapping[relationSign] ?? 'gte';
    return {value, type: relation};
  }

  return null;
};
const parseDimensionParam = (param: string) => {
  const [, symbol, value] = new RegExp(dimensionParamRegExp).exec(param) || [];

  if (symbol === '=') {
    return {type: 'in', value};
  }

  if (symbol === '-') {
    return {type: 'exclude', value};
  }

  return {type: 'contains', value};
};

const parseDimensionValue = (value: string) => {
  const matches = value.match(dimensionParamRegExp);

  if (!matches) {
    return [{value, type: 'contains'}];
  }

  return matches.map(parseDimensionParam);
};
const parseMetricValue = flow(split(','), map(parseMetricParam), compact);
const parseAttributeValue = (value: string) => [{value, type: 'contains'}];
const parsers = {
  attribute: parseAttributeValue,
  metric: parseMetricValue,
  dimension: parseDimensionValue,
};

const parseColumnFilterValue = (value: string, columnType: ColumnDraftType) => {
  if (isEmpty(value)) return [];
  return parsers[columnType](value);
};

const parseColumnValues = uncappedMapValues(
  // eslint-disable-next-line no-undef
  ({value, columnType}: ValueOf<ColumnFilterDraft>, column: string) => {
    const params = parseColumnFilterValue(value, columnType);

    return params.map(param => ({column, ...param}));
  }
);

const addPercentageSign = (value: string) => {
  if (value.endsWith('%')) {
    return value;
  }

  return `${value}%`;
};
const trimPercentageSign = replace('%', '');

const validatePercentageValue = update('value', addPercentageSign);
const validateNonPercentageValue = update('value', trimPercentageSign);

const validateMetricValues = (metricsData: FilterOptions) =>
  uncappedMapValues((columnValues: ColumnParam[], columnName) => {
    const metricData = find({id: columnName}, metricsData);

    if (!metricData) {
      return columnValues;
    }

    if (metricData.formatting === 'percent') {
      return map<ColumnParam, ColumnParam>(
        validatePercentageValue,
        columnValues
      );
    }

    return map<ColumnParam, ColumnParam>(
      validateNonPercentageValue,
      columnValues
    );
  });

export const buildNewColumnParams = (
  draftParams: ColumnFilterDraft,
  currentParams: ColumnParam[],
  metricsData: FilterOptions
) =>
  flow(
    parseColumnValues,
    validateMetricValues(metricsData),
    defaults(groupBy('column', currentParams)),
    values,
    flatten,
    sortBy<ColumnParam>(['column', 'type', 'value'])
  )(draftParams);
