import React from 'react';

import cx from 'classnames';
import {Link, useLocation} from 'react-router-dom';

import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {PATHNAME_REPORT_LIST} from 'constants/globalVariables';

import classes from './Tabs.css';

export const AllReportsTab = () => {
  const {pathname = ''} = useLocation();
  const isAllReportsActive = pathname.endsWith(PATHNAME_REPORT_LIST);

  return (
    <Link
      className={cx({
        [classes.active]: isAllReportsActive,
      })}
      data-pendoid='dropdownMenuFixedReportList'
      to={{
        pathname: PATHNAME_REPORT_LIST,
      }}
    >
      <FormattedMessage
        id='aa.reportTabs.allReports'
        defaultMessage='All reports'
      />
    </Link>
  );
};
