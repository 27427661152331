import {range, map, assign, set, flow, isEmpty} from 'lodash/fp';

import {STANDARD_PRECISION, CVSchemes} from '../constants';
import {roundDecimal, mapWithIndices} from '../utils';

import {CVInputs} from '../types';

const appendCV = (cv: number) => (bucket: {}) =>
  assign({conversion_value: cv})(bucket);
const appendMinRevenue = (revenue_min: number, name: string) => (bucket: {}) =>
  set(`${name}.revenue_min`, revenue_min)(bucket);
const appendMaxRevenue = (revenue_max: number, name: string) => (bucket: {}) =>
  set(`${name}.revenue_max`, revenue_max)(bucket);
const appendCap =
  (name: string, precision: number, cap_range?: number) => (bucket: {}) => {
    if (!cap_range) {
      return bucket;
    }
    const revenue_max = roundDecimal(cap_range, precision);
    return set(`${name}.revenue_max`, revenue_max)(bucket);
  };

const generateBuckets = (
  bucketsRange: number[],
  bucketsCount: number,
  bucketSize: number,
  min: number,
  precision: number,
  name: string,
  cap_range?: number
) =>
  mapWithIndices((cv: number, index: number) => {
    const isLast = index === bucketsCount;

    const revenue_min = roundDecimal(min + index * bucketSize, precision);
    const revenue_max = roundDecimal(min + (index + 1) * bucketSize, precision);

    return flow(
      appendCV(cv),
      appendMinRevenue(revenue_min, name),
      isLast
        ? appendCap(name, precision, cap_range)
        : appendMaxRevenue(revenue_max, name)
    )({});
  })(bucketsRange);

export const generateSimpleCV =
  ({schemeType, precision}: {schemeType: CVSchemes; precision: number}) =>
  ({revenue_min, revenue_max, cv_min, cv_max, cap_range, errors}: CVInputs) => {
    if (!isEmpty(errors)) {
      return errors;
    }

    const bucketsRange = range(cv_min)(cv_max + 1);
    const bucketsCount = Math.max(bucketsRange.length - 1, 1);
    const bucketSize = (revenue_max - revenue_min) / bucketsCount;

    return {
      conversion_values: generateBuckets(
        bucketsRange,
        bucketsCount,
        bucketSize,
        revenue_min,
        precision,
        schemeType,
        cap_range
      ),
    };
  };

export const generateFacebookCV =
  (bucketName: string = 'purchases') =>
  ({
    revenue_min,
    revenue_max,
    cv_min,
    cv_max,
    cap_range,
    app_store_id,
    cut_off_period,
    reporting_currency,
    errors,
  }: CVInputs) => {
    if (!isEmpty(errors)) {
      return errors;
    }

    const bucketsRange = range(cv_min)(cv_max + 1);
    const bucketsCount = Math.max(bucketsRange.length - 1, 1);
    const bucketSize = (revenue_max - revenue_min) / bucketsCount;

    const conversion_values = generateBuckets(
      bucketsRange,
      bucketsCount,
      bucketSize,
      revenue_min,
      STANDARD_PRECISION,
      bucketName,
      cap_range
    );

    return {
      conversion_values,
      partner_schemas: {
        facebook: {
          app_store_id,
          updated_at: Math.floor(Date.now() / 1000),
          cut_off_period,
          reporting_currency,
          conversion_values: map(({conversion_value, ...rest}) => {
            const bucket = rest[bucketName];

            return {
              conversion_value,
              events: [{event_name: 'fb_mobile_purchase', ...bucket}],
            };
          })(conversion_values),
        },
      },
    };
  };
