import {ReactElement} from 'react';

import * as Sentry from '@sentry/browser';
import {
  find,
  flatten,
  flow,
  get,
  head,
  identity,
  isArray,
  isEqual,
  isNil,
  map,
  values,
} from 'lodash/fp';

import {
  getOptions as getOptionsOriginal,
  MultiSelect,
  SelectNew,
  Input,
  FormConnector,
  DateRangePicker,
} from 'common-components';
import {IntlShape} from 'common-components/utils/libs/ReactIntl';
import {isPopulated} from 'utils/lodash+';

import {
  CombiSelect,
  CombiSelectProps,
} from 'modules/common/components/CombiSelect/CombiSelect';
import {
  createCombinePickersValuesFn,
  createCraftOptionFromValueStringFn,
  createEmptyValuesLabels,
  createPickersData,
} from 'modules/common/components/CombiSelect/utils';
import {
  SectionSelect,
  SectionSelectProps,
} from 'modules/common/components/SectionSelect/SectionSelect';
import {INDEX_FILTER_PARAM_NAME} from 'modules/report/components/ReportTable/columns/IndexColumn/constants';
import {IndexSortableMultiSelect} from 'modules/report/components/ReportTable/columns/IndexColumn/IndexSortableMultiSelect';

import {
  ComponentType,
  FilterOption,
  FilterWithOptions,
  Option,
} from 'modules/common/types';
import {ReportSpec} from 'modules/report/types';
import type {Dictionary} from 'types/utils';

const FormSelect = FormConnector(SelectNew);
const FormInput = FormConnector(Input);
const FormMultiSelect = FormConnector(MultiSelect);
const FormIndexSortableMultiSelect = FormConnector(IndexSortableMultiSelect);
const FormCombiSelect = FormConnector(CombiSelect);
const FormSectionSelect = FormConnector(SectionSelect);

type ComponentData = {
  name: ComponentType;
  component: (...args: any[]) => ReactElement;
  componentProps: Dictionary<any>;
};

const styles = {
  textInput: {
    width: '100%',
  },
};

const craftOptions = (data: any[] | null): Option[] =>
  getOptionsOriginal(data, {
    shortNameField: 'short_name',
  });

const extractFirstFilterOptions = (filter: FilterWithOptions) =>
  // 'select' and 'multiselect' only contain a single (first) set of options
  get(head(filter.options_name)!)(filter.options);

const buildDimensionsSelectProps = (
  intl: IntlShape,
  filter: FilterWithOptions,
  backendDimensions: ReportSpec['dimensions']
) => {
  const drilldownLabel = intl.formatMessage({
    id: 'aa.label.drilldown',
    defaultMessage: 'drilldown',
  });
  const indexLabel = intl.formatMessage({
    id: 'aa.label.index',
    defaultMessage: 'index',
  });

  const selectOptions = flow(
    extractFirstFilterOptions,
    craftOptions,
    map((option: Option) => {
      const matchedBackendDimension = find(
        ['slug', option.value],
        backendDimensions
      );
      const isLocked = Boolean(matchedBackendDimension);
      const lockedLabel = matchedBackendDimension?.isHidden
        ? indexLabel
        : drilldownLabel;

      return {
        ...option,
        locked: isLocked,
        lockedOptionLabel: lockedLabel,
      };
    })
  )(filter);

  return {
    options: selectOptions,
    isDisabled: !isArray(selectOptions),
  };
};

const isDimensionsOptions = ({options_name}: FilterWithOptions): boolean =>
  isEqual(options_name, ['dimensions']);

const buildSelectProps = (
  intl: IntlShape,
  filter: FilterWithOptions,
  backendDimensions?: ReportSpec['dimensions']
) => {
  if (
    isDimensionsOptions(filter) &&
    backendDimensions &&
    isPopulated(backendDimensions)
  ) {
    return buildDimensionsSelectProps(intl, filter, backendDimensions);
  }

  const selectOptions = flow(extractFirstFilterOptions, craftOptions)(filter);

  // If BE allows a 'empty' filter.default value for 'select' input,
  // we enable isClearable to return value to default (null)
  const isClearable = filter.component === 'select' && isNil(filter.default);

  return {
    options: selectOptions,
    isClearable,
    isDisabled: !isArray(selectOptions),
    // https://app.asana.com/0/260834613116794/1180110013296743
    allowInconsistentValues: true,
    inputName: filter.options_name,
  };
};

const buildCombiSelectProps = (
  intl: IntlShape,
  filter: FilterWithOptions
): Omit<CombiSelectProps, 'onChange'> => ({
  pickers: createPickersData(filter, intl),
  combinePickersValues: createCombinePickersValuesFn(filter),
  craftOptionFromValueString: createCraftOptionFromValueStringFn(filter),
  ...createEmptyValuesLabels(filter, intl),
});

const buildSectionSelectProps = (
  filter: FilterWithOptions
): Omit<SectionSelectProps, 'onChange'> => {
  const options = filter?.options
    ? flow(
        values,
        flatten,
        map(({section, id, name, short_name}: FilterOption) => ({
          label: name,
          shortName: short_name,
          title: name,
          value: id,
          section,
        }))
      )(filter?.options)
    : [];
  return {
    options,
    filterLabel: filter.label,
  };
};

type InputComponents = Record<
  ComponentType,
  (
    intl: IntlShape,
    filterWithOptions: FilterWithOptions,
    ...args: any[]
  ) => ComponentData
>;

const inputComponents: InputComponents = {
  combiselect: (intl, filterWithOptions) => ({
    name: 'combiselect',
    component: FormCombiSelect,
    componentProps: buildCombiSelectProps(intl, filterWithOptions),
  }),
  multiselect: (...args) => {
    const filterParamName = args[1].filter_param;
    // TODO: Address index filter properly
    const isIndexFilterParam = filterParamName === INDEX_FILTER_PARAM_NAME;

    return {
      name: 'multiselect',
      component: isIndexFilterParam
        ? FormIndexSortableMultiSelect
        : FormMultiSelect,
      componentProps: {
        ...buildSelectProps(...args),
        limitOptionsTo: 300,
      },
    };
  },
  text: () => ({
    name: 'text',
    component: FormInput,
    componentProps: {
      style: styles.textInput,
      parse: identity,
    },
  }),
  select: (...args) => ({
    name: 'select',
    component: FormSelect,
    componentProps: {
      ...buildSelectProps(...args),
      plainValue: true,
    },
  }),
  daterangepicker: () => ({
    name: 'daterangepicker',
    component: DateRangePicker,
    componentProps: {},
  }),
  radio: (...args) => {
    const {filter_param} = args?.[1] ?? {};
    // We're not expecting 'radio' input in CC Automate (this is only supported in DashExp)
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Warning);
      scope.setTag('filterInput component', 'radio');
      Sentry.captureMessage(
        `Unsupported filterInput component: radio. Filter param: ${filter_param}`
      );
    });

    // falling back to select
    return {
      name: 'select',
      component: FormSelect,
      componentProps: {
        ...buildSelectProps(...args),
        plainValue: true,
      },
    };
  },
  section_select: (...args) => ({
    name: 'section_select',
    component: FormSectionSelect,
    componentProps: buildSectionSelectProps(args[1]),
  }),
};

export default inputComponents;
