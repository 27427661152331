import React from 'react';

import {css} from '@linaria/core';
import ActionIcon from '@material-ui/icons/FlashOn';
import cx from 'classnames';

const cssDefaultIcon = css`
  &.MuiSvgIcon-root {
    height: 20px;
    width: 20px;
    margin-bottom: -2px;
    fill: currentColor;
  }
`;
const cssActiveIcon = css`
  &.MuiSvgIcon-root {
    fill: gold;
    stroke: currentColor;
    stroke-width: 1.3px;
  }
`;
const cssDisabledIcon = css`
  &.MuiSvgIcon-root {
    fill: #dce0e8;
    stroke-width: 1px;
  }
`;

type IconProps = {
  isActive?: boolean;
  isDisabled?: boolean;
  iconStyles?: React.CSSProperties;
};
export const ActionsIcon = ({
  isActive = false,
  isDisabled = false,
  iconStyles,
}: IconProps) => (
  <ActionIcon
    className={cx(cssDefaultIcon, {
      [cssActiveIcon]: isActive,
      [cssDisabledIcon]: isDisabled,
    })}
    style={iconStyles}
  />
);
