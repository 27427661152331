import {useState, useEffect} from 'react';

import {
  filter,
  flow,
  fromPairs,
  groupBy,
  identity,
  keys,
  map,
  startCase,
  take,
  update,
  zipObject,
} from 'lodash/fp';
import {useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {fetchReportData} from 'modules/report/api';
import {selectAllMetricFiltersOptions} from 'modules/report/reducers';
import {parseDatePeriodTimestamps} from 'utils/dateUtils';
import {flippedIncludes, uncappedMap} from 'utils/lodash+';
import {reorderArr} from 'utils/query';
import {
  extractMetricFilterParams,
  METRIC_FILTER_PARAMS,
} from 'utils/reportFilters';

import {
  CONTRASTED_COLORS,
  MAX_LINES_AMOUNT,
  METRIC_VALUE_DATA_KEY,
  PARSED_DATE_DATA_KEY,
} from './constants';
import {
  craftHumanDateParser,
  formatYAxis,
  getFieldLabel,
  getFieldType,
  orderDataByDate,
  reportTransformerMapping,
} from './utils';

import {ChartDataFetcher, DataFetcherReturn, Axes} from './types';
import type {RowData} from 'modules/common/types';
import {Dictionary} from 'types/utils';

export const initialChartDataResult: DataFetcherReturn = {
  chartTitle: '',
  isLoading: true,
  series: [],
  xAxisDomain: [0, 0],
  xAxisGroupIdField: '',
  xAxisId: PARSED_DATE_DATA_KEY,
  xAxisTickFormatter: identity,
  yAxisId: '',
  yAxisTickFormatter: identity,
};

export const useChartData: ChartDataFetcher = ({reportParams, chartType}) => {
  const intl = useIntl();

  const allMetricFilterOptions = useSelector(selectAllMetricFiltersOptions);

  const [result, setResult] = useState<DataFetcherReturn>(
    initialChartDataResult
  );

  const isDimensionChart = chartType === 'dimension';

  useEffect(() => {
    const fetchChartData = async () => {
      setResult(initialChartDataResult);

      // prepare data
      const {
        quick: {dimensions},
        general: {date_period, order},
      } = reportParams;
      const allQuickMetrics: string[] = extractMetricFilterParams(
        reportParams.quick
      );

      const orderedMetrics = reorderArr(allQuickMetrics, order);
      const firstMetric = orderedMetrics[0];

      const firstMetricName = getFieldLabel(
        allMetricFilterOptions,
        firstMetric
      );
      const firstDimension = reorderArr(dimensions, order)[0];

      const firstMetricType = getFieldType(allMetricFilterOptions, firstMetric);
      const sameTypeMetrics = filter(
        metric =>
          getFieldType(allMetricFilterOptions, metric) === firstMetricType,
        orderedMetrics
      );

      const metricsToFetch = isDimensionChart ? [firstMetric] : sameTypeMetrics;
      const filterInMetrics = filter(flippedIncludes(metricsToFetch));

      const filteredMetricsReportParams = flow(
        ...map(
          key => update(`quick.${key}`, filterInMetrics),
          METRIC_FILTER_PARAMS
        )
      )(reportParams);

      const chartParams = reportTransformerMapping[chartType](
        filteredMetricsReportParams
      );

      // fetch
      const {rows} = await fetchReportData(chartParams);

      // calculate series
      const datedRows: RowData[] = map(
        row => ({...row, [PARSED_DATE_DATA_KEY]: new Date(row.day)}),
        rows
      );

      let groupedData: Dictionary<RowData[]>;
      if (isDimensionChart) {
        // group by first dimension
        groupedData = groupBy(firstDimension, datedRows);
      } else {
        // group by same metric type
        groupedData = flow(
          map((metric: string) => [
            metric,
            map(
              row => ({
                ...row,
                [METRIC_VALUE_DATA_KEY]: row[metric],
                selMetric: metric,
              }),
              datedRows
            ),
          ]),
          fromPairs
        )(sameTypeMetrics);
      }

      const groupColorMapping = zipObject(keys(groupedData), CONTRASTED_COLORS);

      const enhancedCappedSeries = flow(
        uncappedMap((groupData: any, groupId: string) => ({
          groupData,
          groupId,
          color: groupColorMapping[groupId],
        })),
        map(orderDataByDate),
        take(MAX_LINES_AMOUNT)
      )(groupedData);

      // other chart props
      const chartTitle = isDimensionChart
        ? intl.formatMessage(
            {
              id: 'aa.placeholder.itemByOtherItem',
              defaultMessage: '{firstItem} by {secondItem}',
            },
            {
              firstItem: startCase(firstMetricName),
              secondItem: startCase(firstDimension),
            }
          )
        : intl.formatMessage({
            id: 'aa.charts.totalMetrics.title',
            defaultMessage: 'Totals',
          });

      const xAxisDomain = parseDatePeriodTimestamps(date_period) as Axes;

      const xAxisGroupIdField = isDimensionChart ? firstDimension : '';

      const xAxisTickFormatter = craftHumanDateParser(intl.locale);

      const yAxisId = isDimensionChart ? firstMetric : METRIC_VALUE_DATA_KEY;

      const yAxisTickFormatter = formatYAxis(firstMetricType);

      // result
      setResult({
        chartTitle,
        isLoading: false,
        series: enhancedCappedSeries,
        xAxisDomain,
        xAxisGroupIdField,
        xAxisId: PARSED_DATE_DATA_KEY,
        xAxisTickFormatter,
        yAxisId,
        yAxisTickFormatter,
      });
    };

    fetchChartData();
  }, [allMetricFilterOptions, chartType, intl, isDimensionChart, reportParams]);

  return result;
};
