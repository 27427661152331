import {flow} from 'lodash/fp';

import {STANDARD_PRECISION, HIGH_PRECISION, CVSchemes} from './constants';
import {fields} from './fields';
import {generateFacebookCV, generateSimpleCV} from './lib/generateCVData';
import {transformInputs} from './lib/transformInputs';

import {SchemeConfig} from './types';

export const schemes: Record<CVSchemes, SchemeConfig> = {
  [CVSchemes.AdRevenue]: {
    schemeGenerator: flow(
      transformInputs,
      generateSimpleCV({
        schemeType: CVSchemes.AdRevenue,
        precision: HIGH_PRECISION,
      })
    ),
    fields: fields[CVSchemes.AdRevenue],
    precision: HIGH_PRECISION,
  },
  [CVSchemes.Purchases]: {
    schemeGenerator: flow(
      transformInputs,
      generateSimpleCV({
        schemeType: CVSchemes.Purchases,
        precision: STANDARD_PRECISION,
      })
    ),
    fields: fields[CVSchemes.Purchases],
    precision: STANDARD_PRECISION,
  },
  [CVSchemes.Facebook]: {
    schemeGenerator: flow(transformInputs, generateFacebookCV('purchases')),
    fields: fields[CVSchemes.Facebook],
    precision: STANDARD_PRECISION,
  },
  [CVSchemes.FacebookAdrevenue]: {
    schemeGenerator: flow(transformInputs, generateFacebookCV('adrevenue')),
    // Same fields as for Facebook scheme
    fields: fields[CVSchemes.Facebook],
    precision: STANDARD_PRECISION,
  },
};
