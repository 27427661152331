import React from 'react';

import {css} from '@linaria/core';

import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';

const cssWrapper = css`
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const NotFoundPage = () => (
  <div className={cssWrapper}>
    <FormattedMessage
      id='aa.label.notFoundPage'
      defaultMessage='Oops, something went wrong...'
    />
  </div>
);
