import React from 'react';

import ArrowDownAdjust from '@material-ui/icons/ArrowDropDown';
import ArrowUpAdjust from '@material-ui/icons/ArrowDropUp';
import cx from 'classnames';

import {MODERNGRID_SORT_ICON_TEST_ID} from 'common-components/ModernGrid/constants';
import {
  cssAdjustSortArrowStyle,
  cssSortArrow,
  cssSortArrowActive,
} from 'common-components/ModernGrid/ModernGrid.styles';

import {SortDirection} from 'common-components/ModernGrid/types';

type Props = {
  sortDirection: SortDirection;
  hasActiveSort: boolean;
  onClick: () => void;
};
const SortIcon = ({sortDirection, hasActiveSort, onClick}: Props) => {
  const ArrowIcon = sortDirection === 'asc' ? ArrowUpAdjust : ArrowDownAdjust;

  return (
    <ArrowIcon
      data-testid={MODERNGRID_SORT_ICON_TEST_ID}
      className={cx(cssSortArrow, cssAdjustSortArrowStyle, {
        [cssSortArrowActive]: hasActiveSort,
      })}
      preserveAspectRatio='none'
      viewBox='7 7 10 10'
      onClick={onClick}
      data-direction={sortDirection}
    />
  );
};

export default SortIcon;
