import * as Sentry from '@sentry/browser';
import Axios from 'axios';
import {mapValues, flow, omitBy, isNil} from 'lodash/fp';

import {uncappedMapValues} from './lodash+';
import wait from './wait';

export const removeCurlyBracesDuplication = (str: string) =>
  str.replace(/{{\s?(\S+)\s?}}/g, '{$1}');

export const replaceEscapedCharacters = (str: string) =>
  str
    .replace(/\\gt\\/g, '>')
    .replace(/\\lt\\/g, '<')
    .replace(/\\nl\\/g, '\n');

const sanitizeTranslations = flow(
  omitBy(isNil),
  mapValues(removeCurlyBracesDuplication),
  mapValues(replaceEscapedCharacters)
);

type Manifest = Record<string, string>;
const fetchTranslations = async (locale: string) => {
  const {data: translationsLocaleHashes} = await Axios.get<Manifest>(
    `/translations/manifest.json?t=${Date.now()}`
  );

  const localeHash = translationsLocaleHashes[locale];
  const {data: rawTranslations} = await Axios.get(
    `/translations/${locale}.${localeHash}.json`
  );

  return sanitizeTranslations(rawTranslations);
};

export const DEBUG_LOCALE = 'debug';
const createDebugTranslations = uncappedMapValues((_, key) => key);

export const initTranslationCatalogs = async (locale: string) => {
  const translations = await Promise.race([
    fetchTranslations(locale).catch(err => {
      Sentry.captureException(err);
      return {};
    }),
    wait(5000).then(() => ({})),
  ]);

  const debugTranslations = createDebugTranslations(translations);

  const translationCatalogs = {
    [locale]: translations,
    [DEBUG_LOCALE]: debugTranslations,
  };

  return translationCatalogs;
};
