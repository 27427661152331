import {
  difference,
  flow,
  min,
  minBy,
  max,
  range,
  values,
  union,
  includes,
  filter,
  map,
} from 'lodash/fp';

import {SELECTABLE_COLUMN_KEY} from './constants';

import {RowData} from 'common-components/ModernGrid/types';

/**
 * Split using as separator missing number in series of numbers
 * @example splitByGaps([1,2,4]) => [[1,2], [4]]
 */
export function splitByGaps(data: number[]) {
  // _.groupBy unfortunately doesn't provide index to the iteratee
  const groups = data.reduce(
    (acc: Record<number, number[]>, val, i) => ({
      ...acc,
      [val - i]: [...(acc?.[val - i] ?? []), val],
    }),
    {}
  );

  return values(groups);
}

export const buildNewSelectedRangesFrom = (
  currentRows: number[],
  selectedRow: number,
  disabledRows: number[]
) => {
  const selectedRanges = splitByGaps(currentRows);
  const closestRangeToTriggeredRow = minBy(
    selectedRange =>
      Math.min(
        Math.abs(selectedRow - min(selectedRange)!),
        Math.abs(selectedRow - max(selectedRange)!)
      ),
    selectedRanges
  );

  const intermediateRange = [selectedRow, ...closestRangeToTriggeredRow!];
  const intermediateRows = range(
    min(intermediateRange)!,
    max(intermediateRange)! + 1
  );

  return flow(union(currentRows), rows => difference(rows, disabledRows))(
    intermediateRows
  );
};

export const checkIsAllSelected = (
  srcList: RowData[],
  selectedList: number[],
  disabledList: number[]
) =>
  srcList.length > 0 &&
  selectedList.length > 0 &&
  selectedList.length + disabledList.length === srcList.length;

const getSelectableRow = (row: RowData, isSelected: boolean) => ({
  ...row,
  [SELECTABLE_COLUMN_KEY]: isSelected,
});

const selectRow = (row: RowData, index: number, selectedRows: number[]) => {
  const isSelected = row?.[SELECTABLE_COLUMN_KEY];

  if (includes(index, selectedRows) && !isSelected) {
    return getSelectableRow(row, true);
  }

  if (!includes(index, selectedRows) && isSelected) {
    return getSelectableRow(row, false);
  }

  return SELECTABLE_COLUMN_KEY in row ? row : getSelectableRow(row, false);
};

export const selectRows = (rows: RowData[], selectedRows: number[]) =>
  rows.map((row, index) => selectRow(row, index, selectedRows));

export const extractSelectedRows = (rows: RowData[]): number[] =>
  flow(
    list =>
      list.map((rowData: RowData, index: number) => ({
        isSelected: rowData?.[SELECTABLE_COLUMN_KEY],
        index,
      })),
    filter('isSelected'),
    map('index')
  )(rows);
