import moment from 'moment';

import {LOCALE} from 'utils/conversions';

const numberFormat = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const decimalFormat = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const preciseDecimalFormat = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});
const percentFormat = new Intl.NumberFormat(LOCALE, {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const numberFormatter = (val: number) => numberFormat.format(val);

export const decimalFormatter = (val: number) => decimalFormat.format(val);

export const percentFormatter = (val: number) => percentFormat.format(val);

const currencySymbolAwareFormatter = (val: number, format: Intl.NumberFormat) =>
  format.format(val);

export const currencyFormatter = (val: number, isPrecise: boolean = false) =>
  currencySymbolAwareFormatter(
    val,
    isPrecise ? preciseDecimalFormat : decimalFormat
  );

export const timePeriodFormatter = (durationInSeconds: number) => {
  const timeperiod = moment.duration(durationInSeconds, 'seconds');
  const calculateYear = (seconds: number) =>
    Math.floor(seconds / (86400 * 365));
  const calculateDay = (seconds: number) => Math.floor((seconds / 86400) % 365);

  const years = calculateYear(durationInSeconds);
  const days = calculateDay(durationInSeconds);
  const hours = timeperiod.hours();
  const minutes = timeperiod.minutes();
  const seconds = timeperiod.seconds();

  let str = '';
  if (years > 0) {
    str += `${years}y `;
  }
  if (days > 0 && years < 10) {
    str += `${days}d `;
  }
  if (hours > 0 && years < 1 && days < 10) {
    str += `${hours}H `;
  }
  if (minutes > 0 && years < 1 && days < 1) {
    str += `${minutes}m `;
  }
  if (seconds > 0 && years < 1 && days < 1 && hours < 1) {
    str += `${seconds}s `;
  }
  if (!str) {
    str = 'n/a';
  }

  return str.trim();
};

export const formattersByType = {
  money: currencyFormatter,
  percent: percentFormatter,
  integer: numberFormatter,
  decimal: decimalFormatter,
};
