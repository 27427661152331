import React, {useCallback, useMemo, useReducer} from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';
import cx from 'classnames';
import {every, isEmpty, isEqual} from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {showInformationDialog} from 'modules/common/actions';
import {loadUpdateEmailAlertsRequest} from 'modules/report/actions';
import {selectActiveReportActions} from 'modules/report/reducers';
import {getUnsavedChangesDialogParams} from 'utils/dialog';
import {isPopulated} from 'utils/lodash+';
import {extractEmailAction} from 'utils/reportAutomationActions';

import {RulesPanelButton, RemoveButton} from './buttons';
import {ChipInput} from './ChipInput';
import {
  cssArrowBack,
  cssContent,
  cssEmailAddressLabel,
  cssEmailAlertsButtons,
  cssEmailsSection,
  cssRemoveAllButton,
  cssSection,
  cssSectionHeader,
  cssText,
  cssTitle,
  cssTitleSection,
  cssWarning,
  cssWrapper,
} from './EmailAlerts.styles';
import {
  extractEmails,
  validateEmail,
  composeToEmailsErrorMessage,
} from './utils';

import type {BE_types} from 'types/backendServicesTypes';

const emptyEmailsState: BE_types['EmailAlertActionModel'] = {
  to: [],
  cc: [],
  bcc: [],
};
type EmailRecipientsUpdateAction = {
  type: 'to' | 'cc' | 'bcc' | 'reset';
  payload?: string[];
};
function emailAlertsReducer(
  state: BE_types['EmailAlertActionModel'],
  action: EmailRecipientsUpdateAction
): BE_types['EmailAlertActionModel'] {
  const {type, payload = []} = action;

  switch (type) {
    case 'to':
    case 'cc':
    case 'bcc':
      return {...state, [type]: payload};
    case 'reset':
      return emptyEmailsState;
    default:
      throw new Error();
  }
}

type EmailAlertsProps = {
  onClose: () => void;
};
export const EmailAlerts = ({onClose}: EmailAlertsProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const reportActions = useSelector(selectActiveReportActions);

  const emailAction = extractEmailAction(reportActions);
  const initialEmails = useMemo(
    () => emailAction?.value ?? emptyEmailsState,
    [emailAction]
  );
  const [emails, localDispatch] = useReducer<
    React.Reducer<any, EmailRecipientsUpdateAction>
  >(emailAlertsReducer, initialEmails);

  const hasAnyInputChanged = !isEqual(initialEmails, emails);
  const isEmptyForm = every(isEmpty, emails);
  const isValidForm = isPopulated(emails.to) || isEmptyForm;

  const handleSaveEmailAlerts = useCallback(() => {
    const newEmailActions = [
      {
        type: 'email',
        value: {
          to: emails.to,
          cc: emails.cc,
          bcc: emails.bcc,
        },
      },
    ];

    dispatch(
      loadUpdateEmailAlertsRequest({
        actions: isEmptyForm ? [] : newEmailActions,
      })
    );
    onClose();
  }, [emails, dispatch, onClose, isEmptyForm]);

  const onUnsavedChanges = () => {
    dispatch(showInformationDialog(getUnsavedChangesDialogParams(onClose)));
  };
  const handleOnCancel = () => {
    if (hasAnyInputChanged) {
      onUnsavedChanges();
    } else {
      onClose();
    }
  };

  const toEmailsValid = useMemo(
    () => every(validateEmail, emails.to),
    [emails.to]
  );
  const ccEmailsValid = useMemo(
    () => every(validateEmail, emails.cc),
    [emails.cc]
  );
  const bccEmailsValid = useMemo(
    () => every(validateEmail, emails.bcc),
    [emails.bcc]
  );

  const invalidEmailErrorMessage = intl.formatMessage({
    id: 'aa.alerts.containsInvalidEmail',
    defaultMessage: 'One or more emails are invalid',
  });
  const toEmailsErrorMessage = composeToEmailsErrorMessage(
    isValidForm,
    toEmailsValid,
    intl
  );
  const ccEmailsErrorMessage = ccEmailsValid ? '' : invalidEmailErrorMessage;
  const bccEmailsErrorMessage = bccEmailsValid ? '' : invalidEmailErrorMessage;
  const hasNoErrors = every(isEmpty, [
    toEmailsErrorMessage,
    ccEmailsErrorMessage,
    bccEmailsErrorMessage,
  ]);

  const isSaveDisabled = !hasAnyInputChanged || !isValidForm || !hasNoErrors;

  return (
    <div className={cssWrapper}>
      <div className={cssTitleSection}>
        <div className={cssTitle}>
          <ArrowBack
            role='navigation'
            className={cssArrowBack}
            onClick={handleOnCancel}
            aria-hidden={false}
          />
          {intl.formatMessage({
            id: 'aa.automationPanel.emailReports',
            defaultMessage: 'Email reports',
          })}
        </div>
      </div>

      <div className={cssContent}>
        <div className={cssText}>
          <FormattedMessage
            id='aa.automationPanel.emailReports.alertsPurpose'
            defaultMessage='Set up email reports to notify recipients when your report’s rule conditions are met. If you have no rules set up, your full report is exported as a CSV.'
          />
        </div>

        <div className={cssSection}>
          <div className={cssSectionHeader}>
            <FormattedMessage
              id='aa.automationPanel.emailReports.addRecipients'
              defaultMessage='Add recipients'
            />
          </div>

          <FormattedMessage
            id='aa.automationPanel.emailReports.commaHint'
            defaultMessage='Use a comma to separate email addresses.'
          />
        </div>

        <div className={cssEmailsSection}>
          <div
            className={cx(cssEmailAddressLabel, {[cssWarning]: !isValidForm})}
          >
            <FormattedMessage
              id='aa.automationPanel.emailReports.address'
              defaultMessage='EMAIL ADDRESS'
            />
          </div>

          <ChipInput
            chips={emails.to}
            setChips={toEmails =>
              localDispatch({type: 'to', payload: toEmails})
            }
            errorMessage={toEmailsErrorMessage}
            handlePasteFormatter={extractEmails}
            checkChipValidity={validateEmail}
            pendoId='automation-alerts-emails-to'
          />
        </div>

        <div className={cssEmailsSection}>
          <div className={cssEmailAddressLabel}>
            <FormattedMessage
              id='aa.automationPanel.emailReports.cc'
              defaultMessage='CC'
            />
          </div>

          <ChipInput
            chips={emails.cc}
            setChips={ccEmails =>
              localDispatch({type: 'cc', payload: ccEmails})
            }
            errorMessage={ccEmailsErrorMessage}
            handlePasteFormatter={extractEmails}
            checkChipValidity={validateEmail}
            pendoId='automation-alerts-emails-cc'
          />
        </div>

        <div className={cssEmailsSection}>
          <div className={cssEmailAddressLabel}>
            <FormattedMessage
              id='aa.automationPanel.emailReports.bcc'
              defaultMessage='BCC'
            />
          </div>

          <ChipInput
            chips={emails.bcc}
            setChips={bccEmails =>
              localDispatch({type: 'bcc', payload: bccEmails})
            }
            errorMessage={bccEmailsErrorMessage}
            handlePasteFormatter={extractEmails}
            checkChipValidity={validateEmail}
            pendoId='automation-alerts-emails-bcc'
          />
        </div>

        <div className={cssRemoveAllButton}>
          <RemoveButton
            onClick={() => localDispatch({type: 'reset'})}
            disabled={isEmptyForm}
            pendoId='automation-email-alerts-clear'
          >
            <FormattedMessage
              id='aa.automationPanel.emailReports.removeAll'
              defaultMessage='Remove all'
            />
          </RemoveButton>
        </div>

        <div className={cssEmailAlertsButtons}>
          <RulesPanelButton
            onClick={handleOnCancel}
            pendoId='automation-email-alerts-cancel'
          >
            <FormattedMessage id='aa.label.cancel' defaultMessage='CANCEL' />
          </RulesPanelButton>

          <RulesPanelButton
            disabled={isSaveDisabled}
            onClick={handleSaveEmailAlerts}
            pendoId='automation-email-alerts-save'
          >
            <FormattedMessage id='aa.label.save' defaultMessage='SAVE' />
          </RulesPanelButton>
        </div>
      </div>
    </div>
  );
};
