import {isString, flow} from 'lodash/fp';
import {
  parse as parseSearch,
  ParsedQuery,
  stringify as stringifyQuery,
} from 'query-string';

import {NEW_EXTERNAL_REPORT_LINK} from 'constants/globalVariables';
import {convertLogicalPeriodToAbsolute as convertLogicalPeriodToRelativeUtil} from 'utils/dateUtils';

const convertLogicalPeriodToAbsolute = (params: ParsedQuery<string>) => {
  const convertedDatePeriod =
    isString(params.date_period) &&
    convertLogicalPeriodToRelativeUtil(params.date_period);
  return convertedDatePeriod
    ? {...params, date_period: convertedDatePeriod}
    : params;
};
const transformSearch = flow(
  parseSearch,
  convertLogicalPeriodToAbsolute,
  stringifyQuery
);

export const createExternalReportLink = (search: string) =>
  `${NEW_EXTERNAL_REPORT_LINK}?${transformSearch(search)}`;
