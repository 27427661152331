import React, {Suspense} from 'react';

import DRPMock from './DRPMock';

export {DatePeriodPanel} from './DatePeriodPanel';

const DateRangePicker = React.lazy(() =>
  import(/* webpackChunkName: "DateRangePicker" */ './DateRangePicker')
);
const LazyDateRangePicker = props => (
  <Suspense fallback={<DRPMock {...props} />}>
    <DateRangePicker {...props} />
  </Suspense>
);

export default LazyDateRangePicker;
