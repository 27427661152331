import React from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';

export const cssTwoLinesMaxClassName = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

export const cssBreakOnWord = css`
  word-break: break-word;
`;

type Props = {
  breakOnWord?: boolean;
};
export const TwoLinesMax: React.FC<Props> = ({
  breakOnWord = false,
  children,
}) => (
  <span
    className={cx(cssTwoLinesMaxClassName, {[cssBreakOnWord]: breakOnWord})}
  >
    {children}
  </span>
);

export default TwoLinesMax;
