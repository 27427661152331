import React from 'react';

import {css} from '@linaria/core';
import {Tooltip} from '@material-ui/core';
import cx from 'classnames';
import {isNil, noop} from 'lodash/fp';

import {cssTwoLinesMaxClassName} from 'common-components/TwoLinesMax';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';

import type {Dictionary} from 'types/utils';

const cssClickable = css`
  cursor: pointer;
  &:hover {
    color: ${styleVariables.colorAdjustBlue};
  }
`;
const cssTrimmedCell = css`
  word-break: break-word;
  width: 100%;
`;

type Props = {
  value?: string;
  columnKey: string;
  drilldownKey?: string | null;
  attr_dependency: Dictionary<string | string[]>;
  handleAddDrilldown: (
    columnKey: string,
    attr_dependency: Dictionary<string | string[]>,
    drilldownKey: string
  ) => void;
};
export const DrillableDimension = ({
  value,
  columnKey,
  drilldownKey,
  attr_dependency,
  handleAddDrilldown,
}: Props) => {
  const intl = useIntl();
  const drilldownValue = drilldownKey && attr_dependency?.[drilldownKey];
  const isDrilldownValueExist = Boolean(drilldownValue);

  return !isNil(value) ? (
    <Tooltip title={value} interactive arrow>
      <span
        className={cx(cssTwoLinesMaxClassName, cssTrimmedCell, {
          [cssClickable]: isDrilldownValueExist,
        })}
        role='button'
        tabIndex={0}
        onClick={
          isDrilldownValueExist
            ? () =>
                handleAddDrilldown(
                  columnKey,
                  attr_dependency,
                  drilldownKey as string
                )
            : noop
        }
      >
        {value ||
          intl.formatMessage({
            id: 'aa.label.unknown',
            defaultMessage: 'Unkown',
          })}
      </span>
    </Tooltip>
  ) : (
    <>—</>
  );
};
