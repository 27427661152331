import {compact} from 'lodash/fp';
import {createStore, applyMiddleware, compose, Store, Action} from 'redux';
import reduxFreeze from 'redux-freeze';
import {SagaMiddleware} from 'redux-saga';

import {sentryMiddleware} from 'utils/errorReportingUtils';

import reducer from './reducers';

import type {Dictionary} from 'types/utils';

let store: Store | undefined;
const DEV = process.env.NODE_ENV === 'development';
const PROD = process.env.NODE_ENV === 'production';
const DEV_SERVER = DEV || process.env.RELEASE_ENV !== 'production';

export function configureStore(
  initialState: Dictionary<any>,
  sagaMiddleware: SagaMiddleware,
  routingMiddleware?: any
) {
  const middlewares = compact([
    routingMiddleware,
    sagaMiddleware,
    PROD ? sentryMiddleware : null,
  ]);

  if (DEV) {
    middlewares.push(reduxFreeze);
  }

  store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      DEV_SERVER && window.devToolsExtension
        ? // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__({
            maxAge: 1500 /* , trace: true */,
          })
        : (f: any) => f
    )
  );

  return store;
}

export const getStore = () => store;
export const dispatchAction = (action: Action) => {
  if (!store) {
    console.error(
      'Trying to dispatch an action when store is not yet initialize',
      action
    );
    return undefined;
  }

  return store.dispatch(action);
};
