import React from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';

import AdjustSpinner from './AdjustSpinner';

const cssWrapper = css`
  display: flex;
  justify-content: center;
`;
const cssVerticallyCentered = css`
  height: 100%;
  align-items: center;
`;

type Props = {
  className?: string;
  verticallyCentered?: boolean;
};

export default ({className = '', verticallyCentered = false}: Props) => (
  <div
    className={cx(
      cssWrapper,
      {
        [cssVerticallyCentered]: verticallyCentered,
      },
      className
    )}
  >
    <AdjustSpinner />
  </div>
);
