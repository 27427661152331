import React, {useState, useCallback} from 'react';

import Button from '@material-ui/core/Button';
import {useDispatch} from 'react-redux';

import {
  loadReportDataRequest,
  setReportDebugData,
} from 'modules/report/actions/ReportActions';
import {isValidJson, safeJsonParse} from 'utils/jsonUtils';

const styles = {
  textArea: {
    border: 'solid 1px #c2c2c2',
    width: 400,
    height: 200,
    marginRight: 20,
  },
};

const ReportSandbox = () => {
  const [reportJson, setReportJson] = useState('');
  const [attrJson, setAttrJson] = useState('');
  const dispatch = useDispatch();
  const handleApply = useCallback(() => {
    dispatch(
      setReportDebugData({
        rsData: safeJsonParse(reportJson),
        asData: safeJsonParse(attrJson),
      })
    );
    dispatch(loadReportDataRequest());
  }, [dispatch, reportJson, attrJson]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <div>Report service JSON</div>

          <textarea
            style={styles.textArea}
            value={reportJson}
            onChange={useCallback(
              e => setReportJson(e.target.value),
              [setReportJson]
            )}
          />
        </div>
        <div>
          <div>Attribute service JSON</div>

          <textarea
            style={styles.textArea}
            value={attrJson}
            onChange={useCallback(
              e => setAttrJson(e.target.value),
              [setAttrJson]
            )}
          />
        </div>
      </div>

      <Button
        color='primary'
        variant='contained'
        disabled={
          !isValidJson(attrJson || 'null') || !isValidJson(reportJson || 'null')
        }
        onClick={handleApply}
      >
        Apply
      </Button>
    </div>
  );
};

export default ReportSandbox;
