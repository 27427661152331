import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  CSSProperties,
} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import cx from 'classnames';
import {startCase} from 'lodash/fp';
import {useSelector} from 'react-redux';

import {SelectNew} from 'common-components';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {selectColumnFiltersInputValues} from 'modules/report/reducers';
import {ColumnFiltersContext, getPresenceOptions} from 'modules/report/utils';

import {ColumnDraftType} from 'modules/report/types';

const cssWrapper = css`
  display: flex;
  flex-direction: column;
  margin: -8px 0;
  width: 100%;
  padding-inline-end: 4px;
`;
const StyledLabel = styled.label<{align: string}>`
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;

  text-align: ${props => props.align};
`;
const cssCursor = css`
  cursor: pointer;
`;

type Props = {
  labelText: string;
  align?: CSSProperties['textAlign'];
  headerClickHandler?: (event: any) => void;
  columnType: ColumnDraftType;
  columnKey: string;
  isMovableColumn: boolean;
};

export const SelectableHeader = ({
  labelText,
  align = 'right',
  headerClickHandler,
  columnType,
  columnKey,
  isMovableColumn,
}: Props) => {
  const intl = useIntl();
  const {applyColumnFilterDrafts, setColumnFilterDraft} =
    useContext(ColumnFiltersContext);
  const inputsValues = useSelector(selectColumnFiltersInputValues);
  const currentFilterValue = inputsValues[columnKey];
  const applyRef = useRef(applyColumnFilterDrafts);

  useEffect(() => {
    applyRef.current = applyColumnFilterDrafts;
  }, [applyColumnFilterDrafts]);

  const handleInput = setColumnFilterDraft(columnType, columnKey);
  const handleChange = useCallback(
    option => {
      handleInput(option?.value);
      setTimeout(() => applyRef.current(), 0);
    },
    [handleInput]
  );

  const selectedValue = currentFilterValue
    ? {value: currentFilterValue, label: startCase(currentFilterValue)}
    : null;

  const presenceOptions = useMemo(() => getPresenceOptions(intl), [intl]);

  return (
    <div className={cssWrapper}>
      <StyledLabel
        align={align}
        className={cx({
          [cssCursor]: headerClickHandler || isMovableColumn,
        })}
        htmlFor={labelText}
        onClick={headerClickHandler}
      >
        {labelText}
      </StyledLabel>
      <SelectNew
        inputId={labelText}
        value={selectedValue}
        onChange={handleChange}
        options={presenceOptions}
        placeholder=''
        isClearable
        isSmall
        autosize={false}
      />
    </div>
  );
};
