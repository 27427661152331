import React, {useCallback, useMemo} from 'react';

import {isNil, uniq} from 'lodash/fp';
import {useDispatch} from 'react-redux';

import {loadEditChangesRequest} from 'modules/history/actions/HistoryActions';
import {isMoneyAttribute, isStatusAttribute} from 'utils/attributes';

import AttributeCell from 'modules/common/components/AttributeCell';

import type {AttributeValue} from 'modules/common/types';

const getOptions = (attr: any, value: {value: string}) => {
  const {
    value: {value: currentValue, options: currentOptions = []},
  } = attr;
  const valueTo = value.value;

  const options = uniq([currentValue, valueTo, ...currentOptions]);

  return options;
};

const getValueTo = (newValue: string, value: any, isMoneyChange: boolean) => {
  if (isMoneyChange) {
    return {
      amount: newValue,
      currency: value.currency,
      accuracy: value.accuracy,
    };
  }

  return {
    value: newValue,
  };
};

const getAttrData = (value: any, attr: any) => {
  if (!value || !attr) return null;

  if (isMoneyAttribute(attr.type)) {
    return {...attr, value};
  }

  if (isStatusAttribute(attr.type)) {
    const options = getOptions(attr, value);
    return {...attr, value: {...value, options}};
  }

  return {value, showValue: true};
};

type Props = {
  value?: any; // AttributeValue['value'];
  isSelected?: boolean;
  rowData: {
    attribute: AttributeValue;
    id: number;
    status: string;
    formatting: 'select' | 'money';
    targeting: Record<string, string>;
  };
};
export const ValueToColumn = ({
  value,
  rowData: {id, status, attribute, targeting: rowDataTargeting},
  isSelected,
}: Props) => {
  const dispatch = useDispatch();
  const attrData = useMemo(
    () => getAttrData(value, attribute),
    [value, attribute]
  );

  const handleUpdate = useCallback(
    newValue => {
      const value_to = getValueTo(
        newValue,
        value,
        isMoneyAttribute(attribute.type)
      );

      dispatch(
        loadEditChangesRequest([
          {
            attr_change_id: id,
            value_from: attribute.value,
            value_to,
          },
        ])
      );
    },
    [dispatch, id, attribute, value]
  );

  return !isNil(value) ? (
    <AttributeCell
      pendoId='history-newValue'
      isLoading={!attrData}
      isEditable={status === 'pending'}
      attrData={attrData}
      isSelected={isSelected}
      onChange={handleUpdate}
      rowDataTargeting={rowDataTargeting}
    />
  ) : (
    <>—</>
  );
};
