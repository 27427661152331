import React, {memo, ReactNode} from 'react';

import cx from 'classnames';
import {isFunction} from 'lodash/fp';

import {
  defaultRenderTotal,
  DEFAULT_ALIGN,
} from 'common-components/ModernGrid/constants';
import {
  cssCell,
  cssTotalCell,
} from 'common-components/ModernGrid/ModernGrid.styles';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';

import {DivAlign} from '../../DivAlign';

import type {
  ExtendedColumnData,
  RowData,
} from 'common-components/ModernGrid/types';

type Props = {
  column: ExtendedColumnData;
  row: RowData;
};
const TotalCell = ({
  column: {renderTotal = defaultRenderTotal, align = DEFAULT_ALIGN, key, order},
  row,
}: Props) => {
  let cellContent: ReactNode;

  if (order === 0) {
    cellContent = (
      <FormattedMessage id='aa.label.total' defaultMessage='Total' />
    );
  } else {
    cellContent = isFunction(renderTotal) ? renderTotal({value: row[key]}) : '';
  }

  return (
    <DivAlign className={cx(cssCell, cssTotalCell)} align={align}>
      {cellContent}
    </DivAlign>
  );
};

export default memo(TotalCell);
