import React, {memo, useContext} from 'react';

import {isFunction, includes} from 'lodash/fp';
import {SortableContainer} from 'react-sortable-hoc';

import {MODERNGRID_HEADER_ROW_TEST_ID} from 'common-components/ModernGrid/constants';
import {MovableColumnContext} from 'common-components/ModernGrid/contexts/MovableColumnContext';
import {
  cssRow,
  cssSortableHelper,
} from 'common-components/ModernGrid/ModernGrid.styles';

import HeaderCell from './HeaderCell';

import columnResizerClasses from 'common-components/ModernGrid/components/ColumnResizer/ColumnResizer.css';

import {
  ExtendedColumnData,
  HeaderData,
} from 'common-components/ModernGrid/types';

type BaseHeaderRowProps = {
  columns: ExtendedColumnData[];
  headerData: HeaderData;
};
const BaseHeaderRow = ({columns, headerData}: BaseHeaderRowProps) => (
  <div className={cssRow} data-testid={MODERNGRID_HEADER_ROW_TEST_ID}>
    {columns.map(columnData => (
      <HeaderCell
        key={columnData.key}
        column={columnData}
        headerData={headerData}
      />
    ))}
  </div>
);

const ArrangeableHeaderRow = SortableContainer(BaseHeaderRow);

const HeaderRow = (props: BaseHeaderRowProps) => {
  const {onMoveColumn} = useContext(MovableColumnContext);
  const isArrangeableColumns = isFunction(onMoveColumn);

  if (isArrangeableColumns) {
    return (
      <ArrangeableHeaderRow
        lockToContainerEdges
        axis='x'
        lockAxis='x'
        distance={1}
        lockOffset='50%'
        helperClass={cssSortableHelper}
        onSortEnd={onMoveColumn}
        // @ts-expect-error Type '(e: MouseEvent<any, MouseEvent> | TouchEvent... Remove this comment to see the full error message
        shouldCancelStart={e =>
          // @ts-expect-error Property 'className' does not exist on type 'Event... Remove this comment to see the full error message
          includes(columnResizerClasses.resizeHandler, e.target.className)
        }
        {...props}
      />
    );
  }

  return <BaseHeaderRow {...props} />;
};

export default memo(HeaderRow);
