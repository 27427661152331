import React, {useCallback} from 'react';

import {Language, MainMenu as AdjustMenu} from '@adjust/dashboard-components';
import {useDispatch, useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {BASE_URL} from 'config';
import {CONTROL_CENTER} from 'constants/globalVariables';
import {hideMainMenu, showMainMenu} from 'modules/common/actions';
import {selectIsMainMenuOpen} from 'modules/common/reducers';
import {
  selectMainMenuAccounts,
  selectMainMenuCurrentAccount,
  selectMainMenuCurrentUser,
} from 'modules/common/reducers/adjustConfig';

import {Backdrop} from '../Backdrop/Backdrop';
import {SidePanel} from '../SidePanel';
import {handleLogout, handleSearchAccount, handleSwitchAccount} from './utils';

export const MainMenu = () => {
  const isOpen = useSelector(selectIsMainMenuOpen);
  const currentAccount = useSelector(selectMainMenuCurrentAccount);
  const currentUser = useSelector(selectMainMenuCurrentUser);
  const accounts = useSelector(selectMainMenuAccounts);
  const dispatch = useDispatch();
  const intl = useIntl();

  const translate = useCallback(
    (id: string) => intl.formatMessage({id}),
    [intl]
  );

  const handleOnClose = useCallback(() => {
    dispatch(hideMainMenu());
  }, [dispatch]);

  const handleToggleSidebar = useCallback(() => {
    if (isOpen) {
      dispatch(hideMainMenu());
    } else {
      dispatch(showMainMenu());
    }
  }, [dispatch, isOpen]);

  /**
   * `AdjustMenu` uses `searchAccount` ignoring the `accounts` property
   * if a user is super admin where `searchAccount` is an asynchronous callback
   * that takes the search string, does request,
   * and returns the "Promise" to return found options.
   * This is because we can get too many options for the super admin (all existed accounts)
   * so the search is used and the accounts property is ignored.
   * In another case `AdjustMenu` uses the accounts property ignoring `searchAccount`.
   */
  return (
    <>
      <Backdrop onClick={handleOnClose} open={isOpen} />
      <SidePanel open={isOpen} anchorState='left'>
        <AdjustMenu
          currentAccount={currentAccount}
          currentUser={currentUser}
          accounts={accounts}
          activeLink={CONTROL_CENTER}
          baseUrl={BASE_URL}
          language={intl.locale as Language}
          logout={handleLogout}
          searchAccount={handleSearchAccount}
          switchAccount={handleSwitchAccount}
          t={translate}
          toggleSidebar={handleToggleSidebar}
        />
      </SidePanel>
    </>
  );
};
