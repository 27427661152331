import {css} from '@linaria/core';

import {styleVariables} from 'constants/globalVariables';

export const cssFilterBar = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 420px) {
    display: flex;
    flex-direction: column;
  }
`;
export const cssWarningsContainer = css`
  flex: 1px;
  font-size: 12px;
  color: ${styleVariables.colorBlack54};
  margin-top: 8px;

  @media (max-width: 420px) {
    margin-bottom: 8px;
    align-self: flex-start;
  }
`;
export const cssDateFilterContainer = css`
  flex: 1px;
  display: flex;
  justify-content: center;
  align-self: flex-start;

  @media (max-width: 420px) {
    align-self: flex-start;
    margin-bottom: 8px;
  }
`;
export const cssDateFilter = css`
  margin-right: 0px;
`;
