import React from 'react';

import {filter, flow, get, isEmpty, isNil, join, map} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {useSelector} from 'react-redux';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {selectFiltersOptions} from 'modules/report/reducers';
import {safeJsonParse} from 'utils/jsonUtils';

import {FormattedTooltip} from '../../FormattedTooltip';

import type {EnrichedReport} from 'modules/report/types';

const parseAppTokensString = (appTokensString?: string) =>
  !isNil(appTokensString)
    ? safeJsonParse(`[${appTokensString}]`, {
        shouldCaptureSentryException: true,
      })
    : [];
export const extractAppTokensParam = flow(
  parseSearch,
  get('app_token__in'),
  parseAppTokensString
);

type Props = {
  report: EnrichedReport;
};
export const AppsLabel = ({report: {url}}: Props) => {
  const {apps} = useSelector(selectFiltersOptions);
  const intl = useIntl();

  const appTokens = extractAppTokensParam(url);

  const appTokensNamesLabel = flow(
    filter(({id}) => appTokens?.includes(id)),
    map('name'),
    join('\n')
  )(apps);
  const loadingLabel = intl.formatMessage({
    id: 'aa.label.loading',
    defaultMessage: 'Loading',
  });
  const tooltipLabel = appTokensNamesLabel || loadingLabel;

  if (isEmpty(appTokens)) {
    return (
      <span>
        <FormattedMessage id='aa.label.all' defaultMessage='All' />
      </span>
    );
  }

  return (
    <FormattedTooltip title={tooltipLabel}>
      <span>
        <FormattedMessage
          id='aa.placeholder.countSelected'
          defaultMessage='{ count } selected'
          values={{count: appTokens.length}}
        />
      </span>
    </FormattedTooltip>
  );
};
