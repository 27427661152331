import {
  isNil,
  merge,
  keyBy,
  flow,
  mapValues,
  constant,
  values,
  includes,
} from 'lodash/fp';
import {parse} from 'query-string';

import {history} from '../browserHistory';
import {CVSchemes} from '../constants';
import {schemes} from '../schemes';

import {FormState} from '../types';

export const getDefaultSchemeState = flow(keyBy('id'), mapValues(constant('')));
export const isExistingScheme = (scheme: string | string[]) =>
  flow(values, includes(scheme))(CVSchemes);

export const getInitialSchemeParams = (
  defaultState: FormState,
  URLQuery: string = history.location.search
) => {
  const {scheme: schemeType, ...params} = parse(URLQuery);

  if (isNil(schemeType) || !isExistingScheme(schemeType)) {
    return {initialScheme: CVSchemes.Purchases, initialState: defaultState};
  }

  const schemeFields = schemes[schemeType as CVSchemes].fields;
  const schemeDefaultState = getDefaultSchemeState(schemeFields);

  return {
    initialScheme: schemeType,
    initialState: merge(schemeDefaultState)(params),
  };
};
