import {
  every,
  find,
  findIndex,
  flow,
  head,
  includes,
  isEqual,
  join,
  keys,
  map,
  pickBy,
  reduce,
  replace,
  tail,
} from 'lodash/fp';

import {getOptions} from 'common-components';
import {IntlShape} from 'common-components/utils/libs/ReactIntl';
import {isPopulated, uncappedMap} from 'utils/lodash+';
import {wrapWithBrackets} from 'utils/stringUtils';

import {CombiSelectProps} from './CombiSelect';

import {Picker} from './types';
import {FilterWithOptions, Option} from 'modules/common/types';

export const craftInitialPickersValues = reduce(
  (acc, {id}: Picker) => ({...acc, [id]: null}),
  {}
);

export const craftOptions = (data: any[] | null): Option[] =>
  getOptions(data, {
    shortNameField: 'short_name',
  });

export const createEmptyValuesLabels = (
  filter: FilterWithOptions,
  intl: IntlShape
): {emptyValuesMessage: string; formMessage: string} => {
  const paramLabel = intl.formatMessage({id: filter.label});
  const formMessage = intl.formatMessage(
    {
      id: 'aa.placeholder.addItem',
      defaultMessage: 'Add { item }',
    },
    {
      item: paramLabel,
    }
  );

  const commaSeparatedPickerLabels = flow(
    map((labelId: string) => intl.formatMessage({id: labelId})),
    join(', ')
  )(filter.options_label!);
  const emptyValuesMessage = intl.formatMessage(
    {
      id: 'aa.filters.placeholder.message.combineKpiValues',
      defaultMessage: 'To add { kpi }, combine { commaSeparatedValues }',
    },
    {
      kpi: paramLabel,
      commaSeparatedValues: commaSeparatedPickerLabels,
    }
  );

  return {emptyValuesMessage, formMessage};
};

export const createPickersData = (
  filter: FilterWithOptions,
  intl: IntlShape
) => {
  // First options_name corresponds to all possible combinations options,
  // and the rest to the pickers split options
  const pickersOptionsNames = tail(filter.options_name!);

  const pickersData = uncappedMap((pickerId: string, index: number): Picker => {
    const pickerLabel = intl.formatMessage({id: filter.options_label![index]});

    const isRequired = every(includes(pickerId))(
      filter.combinations_validation
    );

    const pickerOptions = craftOptions(filter.options![pickerId]);

    return {
      id: pickerId,
      label: pickerLabel,
      isRequired,
      options: pickerOptions,
    };
  })(pickersOptionsNames);

  return pickersData;
};

export const createCraftOptionFromValueStringFn = (
  filter: FilterWithOptions
): CombiSelectProps['craftOptionFromValueString'] => {
  // First options_name corresponds to all possible combinations options,
  // and the rest to the pickers split options
  const allCombinationsOptionsName = head(filter.options_name!) as string;
  const allCombinationsOptions = craftOptions(
    filter.options![allCombinationsOptionsName]
  );
  const craftOptionFromValueString: CombiSelectProps['craftOptionFromValueString'] =
    value => find({value})(allCombinationsOptions)! as Option;

  return craftOptionFromValueString;
};

export const createCombinePickersValuesFn: (
  filter: FilterWithOptions
) => CombiSelectProps['combinePickersValues'] = filter => {
  // Obtains valid combination index or -1 on none found
  const obtainValidCombinationIndex = (populatedPickersIds: string[]): number =>
    findIndex(isEqual(populatedPickersIds))(filter.combinations_validation!);

  const combinePickersValues: CombiSelectProps['combinePickersValues'] =
    pickersValues => {
      const populatedPickers = flow(pickBy(isPopulated), keys)(pickersValues);

      const combinationIndex = obtainValidCombinationIndex(populatedPickers);
      if (combinationIndex === -1) {
        return null;
      }

      const combinedLabel = reduce(
        (acc: string, pickerId: string) =>
          replace(
            wrapWithBrackets(pickerId),
            pickersValues[pickerId]!.label,
            acc
          ),
        filter.combinations_label_template![combinationIndex]
      )(populatedPickers);

      const combinedValue = reduce(
        (acc: string, pickerId: string) =>
          replace(
            wrapWithBrackets(pickerId),
            pickersValues[pickerId]!.value as string,
            acc
          ),
        filter.combinations_value_template![combinationIndex]
      )(populatedPickers);

      const combinedOption: Option = {
        label: combinedLabel,
        title: combinedLabel,
        shortName: '', // TODO: provide a way to combine shortNames
        value: combinedValue,
      };

      return combinedOption;
    };

  return combinePickersValues;
};
