import {
  constant,
  filter,
  flow,
  groupBy,
  identity,
  keyBy,
  map,
  mapValues,
  startCase,
} from 'lodash/fp';

import {IntlShape} from 'common-components/utils/libs/ReactIntl';
import {unionMulti} from 'utils/lodash+';

import type {Attributes, AttributeStatusOption} from 'modules/report/types';
import type {BE_types} from 'types/backendServicesTypes';

export const getStatusOptionsBySlugFromAttrs = (attrData: Attributes) =>
  flow(
    filter({type: 'select'}),
    groupBy('slug'),
    mapValues(
      map(({value: {value, options}}: BE_types['AttributeDataModel']) => [
        value,
        ...options,
      ])
    ),
    mapValues(unionMulti),
    mapValues(
      map((status: string) => ({
        label: startCase(status),
        value: status,
      }))
    )
  )(attrData) as {[slug: string]: string[]};

export const getFixedStatusOptions = (intl: IntlShape) => {
  const runningStatusOption = {
    label: intl.formatMessage({
      id: 'aa.status.running',
      defaultMessage: 'Running',
    }),
    value: 'running',
  };
  const pausedStatusOption = {
    label: intl.formatMessage({
      id: 'aa.status.paused',
      defaultMessage: 'Paused',
    }),
    value: 'paused',
  };

  const statusOptions = [runningStatusOption, pausedStatusOption];
  const statusSlugs = ['ad_status', 'adgroup_status', 'campaign_status'];

  return flow(
    keyBy(identity),
    mapValues(constant(statusOptions))
  )(statusSlugs) as {
    [slug: string]: AttributeStatusOption[];
  };
};
