import {authorizedRequest} from 'utils/axios';
import {parseDatePeriod} from 'utils/dateUtils';
import {buildHistoryQuery} from 'utils/query';

import type {EditAttributeModel} from 'modules/common/types';

interface FetchHistoryDataParams {
  app_network__in?: Array<String>;
  partner_id__in?: Array<String>;
  status__in?: Array<string>;
  attribute_slug__in?: Array<string>;
  date_period: string;
  target__contains?: string;
  attr_change_id?: Array<string>;
  attr_change_group_id?: Array<string>;
}
export function fetchHistoryData({
  date_period,
  ...params
}: FetchHistoryDataParams) {
  const [startDate, endDate] = date_period
    ? parseDatePeriod(date_period)
    : [null, null];

  return authorizedRequest.get<any, any>('/operations-service/history', {
    params: {
      created_at__gte: startDate,
      created_at__lte: endDate,
      // @ts-expect-error Argument of type '{ app_network__in?: String[] | u... Remove this comment to see the full error message
      ...buildHistoryQuery(params),
    },
  });
}

export function editChanges(attr_changes: EditAttributeModel[]) {
  return authorizedRequest.post('/operations-service/update_values', {
    attr_changes,
    ignore_same_values: true,
  });
}

export function approveChanges(attr_change_ids: number[]) {
  return authorizedRequest.post('/operations-service/approve', {
    attr_change_ids,
  });
}

export function discardChanges(attr_change_ids: number[]) {
  return authorizedRequest.post('/operations-service/discard', {
    attr_change_ids,
  });
}

export function retryChanges(attr_change_ids: number[]) {
  return authorizedRequest.post('/operations-service/retry', {
    attr_change_ids,
  });
}

export function revertChanges(
  payload: {attr_change_id: number; current_value: object}[]
) {
  return authorizedRequest.post('/operations-service/revert', {
    attr_changes: payload,
    approve: true,
  });
}
