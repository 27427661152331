import React from 'react';

import {css} from '@linaria/core';
import BarChart from '@material-ui/icons/BarChart';
import {useDispatch, useSelector} from 'react-redux';

import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {selectHasChartsEnabled} from 'modules/common/reducers/customerConfig';
import {toggleChartsOpen} from 'modules/report/reducers/chartsOpen';

import {StyledReportButton} from './StyledReportButton';

const cssIcon = css`
  width: 16px;
  fill: currentColor;
`;

export const ChartsButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hasChartsFeature = useSelector(selectHasChartsEnabled);

  if (!hasChartsFeature) {
    return null;
  }

  const handleButtonClick = () => dispatch(toggleChartsOpen());
  const label = intl.formatMessage({
    id: 'aa.reportSettings.buttons.charts',
    defaultMessage: 'Charts',
  });
  return (
    <StyledReportButton
      data-pendoid='tabs-chartsButton'
      title={label}
      color='primary'
      onClick={handleButtonClick}
    >
      <BarChart className={cssIcon} />
      <span>{label}</span>
    </StyledReportButton>
  );
};
