import React, {useCallback, useMemo, useState, useRef} from 'react';

import Popper from '@material-ui/core/Popper';
import {constant, groupBy, intersectionBy, map, pull} from 'lodash/fp';
import Select, {components} from 'react-select';

import {MultiSelectValueContainer} from 'common-components/CheckboxMultiSelect/CheckboxMultiSelect';
import {
  CLEAR_INDICATOR_TEST_ID,
  selectNewTheme,
} from 'common-components/SelectNew/SelectNew';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {isPopulated} from 'utils/lodash+';

import {BACKDROP_Z_INDEX, Backdrop} from '../Backdrop/Backdrop';
import {CombiSelectStylesOverride} from '../CombiSelect/CombiSelect';
import {SectionSelectMenu} from './SectionSelectMenu';

import {Option} from 'modules/common/types';

export const SECTION_SELECT_ARIA_LABEL = 'Section select';

export type OptionWithSection = Option & {section: string};
export type SectionSelectProps = {
  options: OptionWithSection[];
  filterLabel: string;
  showWarning?: boolean;
  onChange: (values: string[]) => void;
  value?: string[];
};
export const SectionSelect = ({
  value: valueStrings,
  onChange,
  showWarning,
  filterLabel,
  options,
}: SectionSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedSection, setSelectedSection] = useState<Option | null>(null);
  const [selectedOption, setSelectedOption] =
    useState<OptionWithSection | null>(null);

  const optionsGroupedBySection = groupBy('section', options);

  const sectionsOptions = Object.keys(optionsGroupedBySection).map(section => ({
    label: section,
    shortName: section,
    title: section,
    value: section,
  }));

  const initialSelectedValues: OptionWithSection[] = useMemo(
    () =>
      isPopulated(valueStrings)
        ? intersectionBy(
            'value',
            options,
            valueStrings?.map(value => ({value})) || []
          )
        : [],
    [options, valueStrings]
  );
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

  const anchorRef = useRef(null);

  const isButtonDisabled =
    !selectedOption || valueStrings?.includes(selectedOption.value);

  // handlers
  const handleChange = useCallback(
    (newValues: OptionWithSection[]) => {
      onChange(map('value', newValues));
    },
    [onChange]
  );

  const handleAddValue = useCallback(() => {
    if (isButtonDisabled) {
      return;
    }

    const updatedSelectedValues: OptionWithSection[] = [
      selectedOption!,
      ...selectedValues,
    ];

    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  }, [handleChange, isButtonDisabled, selectedOption, selectedValues]);

  const handleDeleteValue = useCallback(
    (option: OptionWithSection) => {
      const updatedSelectedValues: OptionWithSection[] = pull(
        option,
        selectedValues
      );

      setSelectedValues(updatedSelectedValues);
      handleChange(updatedSelectedValues);
    },
    [handleChange, selectedValues]
  );

  const handleClearValues = useCallback(() => {
    const updatedSelectedValues: OptionWithSection[] = [];

    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  }, [handleChange]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleSelectSection = useCallback((option: Option) => {
    setSelectedSection(option);
    setSelectedOption(null);
  }, []);

  const handleClose = useCallback((event: any) => {
    // @ts-expect-error: ref is not typed
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setSelectedOption(null);
    setSelectedSection(null);
    setIsOpen(false);
  }, []);

  // render
  const ClearIndicatorOverride = useCallback(
    ({children, ...props}) => (
      <div
        role='button'
        tabIndex={0}
        onClick={handleClearValues}
        data-testid={CLEAR_INDICATOR_TEST_ID}
      >
        <components.ClearIndicator {...props}>
          {children}
        </components.ClearIndicator>
      </div>
    ),
    [handleClearValues]
  );

  return (
    <div>
      <div ref={anchorRef}>
        {/* @ts-expect-error: not all props match */}
        <Select
          aria-label={SECTION_SELECT_ARIA_LABEL}
          isMulti
          isCompact
          isClearable
          isSearchable={false}
          showWarning={showWarning}
          menuPlacement='auto'
          placeholder={
            <FormattedMessage id='aa.label.select' defaultMessage='Select' />
          }
          value={selectedValues}
          menuIsOpen={isOpen}
          onMenuOpen={handleOpen}
          components={{
            MultiValueRemove: constant(null),
            Menu: constant(null),
            ValueContainer: MultiSelectValueContainer,
            ClearIndicator: ClearIndicatorOverride,
          }}
          theme={{
            ...selectNewTheme,
          }}
          styles={CombiSelectStylesOverride}
        />
      </div>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: BACKDROP_Z_INDEX + 1,
        }}
      >
        <SectionSelectMenu
          isFormButtonDisabled={isButtonDisabled}
          filterLabel={filterLabel}
          selectedSection={selectedSection}
          selectedOption={selectedOption}
          onSelectSection={handleSelectSection}
          onSelectOption={setSelectedOption}
          onCreateValue={handleAddValue}
          onDeleteValue={handleDeleteValue}
          selectedValues={selectedValues}
          optionsGroupedBySection={optionsGroupedBySection}
          sectionsOptions={sectionsOptions}
        />
      </Popper>
      <Backdrop onClick={handleClose} open={isOpen} />
    </div>
  );
};
