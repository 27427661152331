import {map} from 'lodash/fp';

import {PATHNAME_REPORT} from 'constants/globalVariables';
import {searchAccounts, switchAccount, logoutAccount} from 'modules/common/api';

export const handleLogout = async () => {
  try {
    await logoutAccount();

    window.location.replace('/');
  } catch (e) {
    console.error(e);
  }
};

export const handleSwitchAccount = async (accountId: number) => {
  try {
    await switchAccount(accountId);

    window.location.replace(PATHNAME_REPORT);
  } catch (e) {
    console.error(e);
  }
};

const FALLBACK_SEARCH_ACCOUNT_RESULT: any[] = [];
export const handleSearchAccount = async (term: string) => {
  if (term.length < 2) {
    return FALLBACK_SEARCH_ACCOUNT_RESULT;
  }

  try {
    const options = await searchAccounts(term);

    return map(
      ({id, name}) => ({
        value: id,
        label: name,
      }),
      options
    );
  } catch (e) {
    console.error(e);
    return FALLBACK_SEARCH_ACCOUNT_RESULT;
  }
};
