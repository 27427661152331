import React, {useEffect, useMemo} from 'react';

import {css} from '@linaria/core';
import {eq, flow, keys, map, get, negate, pickBy, parseInt} from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, Redirect} from 'react-router-dom';

import {ModernGrid} from 'common-components';
import {
  useSelectableRows,
  SelectableRowsContext,
} from 'common-components/ModernGrid/extensions/SelectableRows';
import {PATHNAME_REPORT} from 'constants/globalVariables';
import {updateCustomerColumnSizeRequest} from 'modules/common/actions';
import {
  selectCanViewAttr,
  selectDefaultUrl,
} from 'modules/common/reducers/customerConfig';
import {selectHistoryColumnSizes} from 'modules/common/reducers/customerPreferences';
import {
  historyScreenUnmounted,
  initHistoryRequest,
  parseNewHistoryQuery,
} from 'modules/history/actions/HistoryActions';
import {
  selectHistoryData,
  selectIsHistoryDataLoading,
  selectAttributesFormattings,
  selectFiltersOptions,
  selectHistoryAttrData,
} from 'modules/history/reducers/history';
import {getColumnsWithSizes} from 'modules/report/utils';

import {HistoryActionPanel} from './HistoryActionPanel';

import {columns} from 'modules/history/components/columns';
import HistoryFilters from 'modules/history/components/HistoryFilters';

import type {ColumnData} from 'common-components/ModernGrid/types';
import {TableKey} from 'modules/common/types';

const HISTORY_TABLE_KEY: TableKey = 'history';

const cssRoot = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const cssTableContainer = css`
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  margin-top: 4px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  min-height: 0;
  overflow: auto;
  flex: 1;
`;

const HistoryScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const historyData = useSelector(selectHistoryData);
  const canViewAttr = useSelector(selectCanViewAttr);
  const defaultUrl = useSelector(selectDefaultUrl);
  const attributesFormattings = useSelector(selectAttributesFormattings);
  const isDataLoading = useSelector(selectIsHistoryDataLoading);
  const {apps_network: allApps} = useSelector(selectFiltersOptions);
  const attributes = useSelector(selectHistoryAttrData);

  const tableData = useMemo(
    () =>
      historyData.map(row => {
        const formatting = attributesFormattings[row.attribute_slug];
        return {
          ...row,
          attribute: attributes[row.attr],
          formatting,
        };
      }),
    [attributes, attributesFormattings, historyData]
  );

  useEffect(() => {
    dispatch(initHistoryRequest());
    dispatch(parseNewHistoryQuery(history.location.search));
  }, [history.location.search, dispatch]);

  useEffect(
    () => () => {
      dispatch(historyScreenUnmounted());
    },
    [dispatch]
  );

  const nonPendingRows = useMemo(() => {
    const isRowPending = map(flow(get('status'), negate(eq('pending'))))(
      historyData
    );
    const disabledRows = keys(pickBy(Boolean)(isRowPending));
    return map(parseInt(10), disabledRows);
  }, [historyData]);

  const selectableRows = useSelectableRows({
    rows: tableData,
    disabledRows: nonPendingRows,
  });

  const columnSizes = useSelector(selectHistoryColumnSizes);
  const tableColumns = useMemo(
    () => [
      selectableRows.checkboxColumn,
      ...getColumnsWithSizes(columns as ColumnData[], columnSizes),
    ],
    [selectableRows.checkboxColumn, columnSizes]
  );

  const handleColumnResize = (columnKey: string, columnSize: number | null) =>
    dispatch(
      updateCustomerColumnSizeRequest({
        tableKey: HISTORY_TABLE_KEY,
        columnKey,
        columnSize,
      })
    );

  if (!canViewAttr) {
    return <Redirect to={`${PATHNAME_REPORT}${defaultUrl}`} />;
  }

  return (
    <div className={cssRoot}>
      <SelectableRowsContext.Provider value={selectableRows}>
        <HistoryFilters />
        <ModernGrid
          className={cssTableContainer}
          columns={tableColumns}
          keyProp='id'
          data={selectableRows.rows}
          isLoading={isDataLoading || !allApps}
          onColumnResize={handleColumnResize}
        />
        <HistoryActionPanel />
      </SelectableRowsContext.Provider>
    </div>
  );
};

export default HistoryScreen;
