import React from 'react';

import {css} from '@linaria/core';
import {noop, startCase} from 'lodash/fp';

import {styleVariables} from 'constants/globalVariables';
import {createAttrFormatter} from 'utils/conversions';
import {isPopulated} from 'utils/lodash+';

import {UnsupportedTypeCell} from './UnsupportedTypeCell';

const cssWrapper = css`
  color: ${styleVariables.colorWarmGreyTwo};
  text-align: right;
`;

const SingleReference = ({formatter, amount}: any) => (
  <span> ({formatter.format(amount)})</span>
);

const MultipleReference = ({
  formatter,
  value: {amount_from, amount_to},
}: any) => (
  <span>
    {' '}
    ({formatter.format(amount_from)}&nbsp;—&nbsp;{formatter.format(amount_to)})
  </span>
);

const Reference = ({attrData = {}}: any) => {
  const {value, type} = attrData;
  const formatter = createAttrFormatter({
    accuracy: value.accuracy,
    currency: value.currency,
  });

  switch (type) {
    case 'auto':
      return <SingleReference formatter={formatter} amount={value.amount} />;
    case 'multiple_auto':
      return <MultipleReference formatter={formatter} value={value} />;
    default:
      return <UnsupportedTypeCell attrData={attrData} />;
  }
};

export const LiteralCell = ({attrData}: any) => {
  const cellType = attrData?.type;
  const value = attrData?.value;
  const isClickable = attrData?.readonly === false;
  const reportError = () =>
    console.error(`Attribute with type '${cellType}' should not be clickable`);

  return (
    <span
      className={cssWrapper}
      onClick={isClickable ? reportError : noop}
      role='button'
      tabIndex={0}
    >
      {startCase(cellType)}
      {isPopulated(value) && <Reference attrData={attrData} />}
    </span>
  );
};
