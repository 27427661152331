import React, {CSSProperties} from 'react';

import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {PATHNAME_REPORT} from 'constants/globalVariables';
import {navigateToReport} from 'modules/report/actions';

const hasModifierKeyPressed = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) =>
  Boolean(event.altKey) ||
  Boolean(event.ctrlKey) ||
  Boolean(event.shiftKey) ||
  Boolean(event.metaKey);

type Props = {
  url: string;
  style?: CSSProperties;
  className?: string;
  title?: string;
};
export const ReportLink: React.FC<Props> = ({
  url,
  children,
  style,
  className,
  title,
  ...props
}) => {
  const dispatch = useDispatch();

  return (
    <Link
      style={style}
      className={className}
      title={title}
      to={{
        pathname: PATHNAME_REPORT,
        search: url,
      }}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!hasModifierKeyPressed(e)) {
          dispatch(navigateToReport(url));
        }
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
