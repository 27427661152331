import {map, countBy} from 'lodash/fp';
import {QuickScore, quickScore} from 'quick-score';

import type {Option} from 'modules/common/types';

type SelectFilterOption = {
  label: string;
  value: string;
  data: {
    shortName: string;
  };
};

export function getDistinctCollidingLabelsOptions(options: Option[]) {
  const optionsLabelsCount = countBy('label', options);

  return map(option => {
    const hasUniqueName = optionsLabelsCount[option.label] === 1;

    if (hasUniqueName) return option;

    return {
      ...option,
      label: `${option.label} (${option.value})`,
    };
  }, options);
}

const MINIMUM_SCORE = 0;

export const craftFuzzyMatchScorer = (options: Option[]) => {
  const qs = new QuickScore(options, {
    keys: ['label', 'shortName'],
    minimumScore: MINIMUM_SCORE,
  });

  const search = qs.search.bind(qs);
  const transformedSearch = (query: string) => map('item', search(query));

  return transformedSearch;
};

export const SELECT_ALL_OPTION_VALUE = '*';
export const shortNameFilterOption = (
  candidate: SelectFilterOption,
  input: string
) => {
  if (candidate.value === SELECT_ALL_OPTION_VALUE) {
    return true;
  }

  if (input) {
    // Important: make sure that custom filter option logic is aligned with
    // the logic used to match options by input string in the SelectNew component.
    // Otherwise matched options would differ from the displayed options.
    // the logic below based on the QuickScore.search implementation for the closest match:
    // https://github.com/fwextensions/quick-score/blob/master/src/QuickScore.js#L219-L245
    const highestScore = Math.max(
      quickScore(candidate.label, input),
      quickScore(candidate.data.shortName || candidate.value, input)
    );

    return highestScore > MINIMUM_SCORE;
  }

  return true;
};
