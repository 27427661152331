export const API_HOST = process.env.CC_API_URL || '';

export const {RELEASE_ENV, CC_SENTRY_DSN, CC_SENTRY_RELEASE_VERSION} =
  process.env;
export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION_ENV = process.env.NODE_ENV === 'production';

export const BASE_URL = `${window.location.protocol}//${window.location.host}`;

// It calculates ACCOUNT_API_URL based on BASE_URL
// PROD: https://dash.adjust.com -> https://api.adjust.com
// QA2: https://dash-qa-2.adjust.com -> https://api-qa-2.adjust.com
// DEV: proxy is used (webpack-dev-server proxy is used in the development environment)
export const ACCOUNT_API_URL = IS_DEV_ENV
  ? BASE_URL
  : BASE_URL.replace('https://dash', 'https://api');

export const composeEnvAwareURL = (url: string) =>
  `${IS_DEV_ENV ? API_HOST : window.location.origin}/${url}`;
