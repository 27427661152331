import * as Sentry from '@sentry/browser';
import createSagaMiddleware from 'redux-saga';

import {SENTRY_CATEGORY_TAGS} from 'constants/globalVariables';
import {reportError} from 'utils/errorReportingUtils';

const sagaMiddleware = createSagaMiddleware({
  onError: e => {
    Sentry.withScope(scope => {
      scope.setTag('category', SENTRY_CATEGORY_TAGS.REDUX_SAGA_UNHANDLED);
      reportError(e);
    });
  },
});

export default sagaMiddleware;
