import {
  set,
  update,
  find,
  orderBy,
  unset,
  flow,
  remove,
  includes,
  flatMap,
  get,
  map,
} from 'lodash/fp';
import moment from 'moment';

import {METRIC_COLUMN_FILTER_TYPES} from 'modules/report/constants';
import {formattersByType} from 'modules/report/lib/formatters';
import {reorderArr} from 'utils/query';

import {DATE_BREAKDOWN} from './constants';

import type {ChartType, ChartSeries} from './types';
import type {FilterOptions, FilterOption} from 'modules/common/types';
import {ReportParams} from 'modules/report/types';

export const formatYAxis =
  (formatting: FilterOption['formatting']) =>
  (value: any): string =>
    // @ts-expect-error: formattersByType doesn't contain all possible keys in FilterOption['formatting']
    formattersByType[formatting!]?.(value) ?? value;

const removeQuickPoPParam: <T>(arg: T) => T = unset('quick.period_over_period');
const removeGeneralSortParam: <T>(arg: T) => T = unset('general.sort');
const convertDrilldownToFocusParam = update('general', generalParams => ({
  ...generalParams,
  focus: generalParams.drilldown,
  drilldown: '',
}));
const removeMetricColumnFilters = update(
  'column',
  remove(({type}) => includes(type, METRIC_COLUMN_FILTER_TYPES))
);
const commonReportTransformations = flow(
  removeQuickPoPParam,
  removeGeneralSortParam,
  convertDrilldownToFocusParam,
  removeMetricColumnFilters
);
const sortQuickDimensions = (reportParams: ReportParams) =>
  update(
    'quick.dimensions',
    dimensions => reorderArr(dimensions, reportParams.general.order),
    reportParams
  );
export const reportTransformerMapping: Record<
  ChartType,
  (params: ReportParams) => ReportParams
> = {
  total: flow(
    commonReportTransformations,
    set('quick.dimensions', [DATE_BREAKDOWN]) as <T>(arg: T) => T
  ),
  dimension: flow(
    commonReportTransformations,
    sortQuickDimensions,
    update('quick.dimensions', dims => [DATE_BREAKDOWN, dims[0]])
  ),
};

export const craftHumanDateParser = (locale: string) => (date: Date) =>
  moment(date).locale(locale).format('D MMM YYYY');

export const getFieldLabel = (allMetrics: FilterOptions, metric: string) => {
  const metricInfo = find({id: metric}, allMetrics);
  return metricInfo?.short_name || metricInfo?.name || metric;
};
export const getFieldType = (allMetrics: FilterOptions, metric: string) => {
  const metricInfo = find({id: metric}, allMetrics);
  return metricInfo?.formatting;
};

export const orderDataByDate = update(
  'groupData',
  orderBy(['parsedDate'], ['desc'])
);

export const extractYValuesFromSeries = (
  series: ChartSeries[],
  yAxisId: string
): number[] =>
  flatMap(
    flow(
      get('groupData'),
      map((row: any) => Number(row[yAxisId]))
    ),
    series
  );
