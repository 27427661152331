import React, {ReactElement} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import {useDispatch, useSelector} from 'react-redux';

import {hideToast} from '../../actions';
import {selectIsToastOpen, selectToastParams} from '../../reducers';
import {InformationalToast} from './InformationalToast';

import {ToastCategory} from '../../types';

type ToastContentType = (props: any) => ReactElement;

const pickToastContent = (category: ToastCategory): ToastContentType => {
  switch (category) {
    case 'informational':
      return InformationalToast;
    default:
      return InformationalToast;
  }
};

const DEFAULT_SHOW_DURATION = 4000;

export const Toast = () => {
  const isOpen = useSelector(selectIsToastOpen);
  const {
    showDuration = DEFAULT_SHOW_DURATION,
    category,
    ...otherToastParams
  } = useSelector(selectToastParams);
  const dispatch = useDispatch();

  const handleClose = (_: any, reason: string) => {
    // avoid closing toast if user clicks outside of it
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideToast());
  };

  const ToastContent = pickToastContent(category);

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={showDuration}
      onClose={handleClose}
    >
      <ToastContent {...otherToastParams} handleClose={handleClose} />
    </Snackbar>
  );
};
