import React from 'react';

import {styled} from '@linaria/react';

import {ActionsButton} from './ActionsButton';
import {BuilderButton} from './BuilderButton';
import {ChartsButton} from './ChartsButton';
import {CSVReportButton} from './CSVReportButton';
import {FilterButton} from './FilterButton';
import {MoreActionsButton} from './MoreActionsButton';
import {SaveReportButton} from './SaveReportButton';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const ReportButtons = () => (
  <ButtonsContainer>
    <SaveReportButton />
    <ActionsButton />
    <CSVReportButton />
    <FilterButton />
    <ChartsButton />
    <BuilderButton />
    <MoreActionsButton />
  </ButtonsContainer>
);
