/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';

import {IS_DEV_ENV, IS_PRODUCTION_ENV} from 'config';
import {limitPayloadSize} from 'utils/reduxUtils';

export function reportError(error: any, {logToConsole = true} = {}) {
  // console.error is intercepted by Sentry in production
  if (logToConsole) {
    console.log('Error occurred:');
    console[IS_DEV_ENV ? 'error' : 'log'](error);
  }

  if (IS_PRODUCTION_ENV) {
    Sentry.captureException(error);
  }
}

export function sentryMiddleware() {
  // Disable sending redux state
  /* Sentry.configureScope((scope) => {
    scope.addEventProcessor((event) => {
      return {
        ...event,
        extra: {
          ...event.extra,
          'redux:state': state
        }
      }
    })
  }); */

  return (next: any) => (action: any) => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
      /*
       50 breadcrumbs max
       20k characters max per action
       20k * 4bytes (max unicode char size) = 80k total per report
      */
      data: action?.payload
        ? {payload: limitPayloadSize(action.payload, 20000 / 50)}
        : undefined,
    });

    return next(action);
  };
}
