import React, {ChangeEvent} from 'react';

import {css} from '@linaria/core';
import cx from 'classnames';
import {identity} from 'lodash/fp';
import {Field} from 'react-final-form';

import {STANDARD_PRECISION, WARNING_COLOR} from '../constants';
import {roundInputToPrecision} from '../utils';

const cssLabel = css`
  padding: 10px 16px;
  margin-bottom: 12px;
  color: #00bed5;
  font-size: 16px;
`;
const cssInput = css`
  margin-left: 8px;
  padding: 4px;
  color: #02373f;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;

  &::placeholder {
    color: #96a1bd;
  }
`;
const cssInputError = css`
  outline: transparent;
  border-color: ${WARNING_COLOR};
`;
const cssErrorMessage = css`
  height: 20px;
  margin-left: 16px;
  margin-top: -20px;
  color: ${WARNING_COLOR};
`;

type InputFieldProps = {
  id: string;
  label: string;
  type?: 'text' | 'number';
  placeholder?: string;
  isDecimal?: boolean;
  precision?: number;
};
export const InputField = ({
  id,
  label,
  type = 'number',
  placeholder,
  isDecimal,
  precision = STANDARD_PRECISION,
}: InputFieldProps) => (
  // parse specified to prevent weird empty string to undefined converting
  // https://final-form.org/docs/react-final-form/types/FieldProps#parse
  <Field name={id} parse={identity}>
    {({input, meta}) => {
      const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const {
          target: {value: inputValue},
        } = event;

        input.onBlur();
        if (isDecimal && inputValue) {
          input.onChange(roundInputToPrecision(inputValue, precision));
        }
      };

      return (
        <>
          <label htmlFor={id} className={cssLabel}>
            {label}
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...input}
              id={id}
              type={type}
              placeholder={placeholder}
              className={cx(cssInput, {[cssInputError]: meta.error})}
              onBlur={handleBlur}
            />
          </label>
          <span className={cssErrorMessage}>{meta.error}</span>
        </>
      );
    }}
  </Field>
);
