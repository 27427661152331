import {createContext} from 'react';

import {noop} from 'lodash/fp';

import {UseSelectableRowsReturnType} from './useSelectableRows';

type SelectableRowsContextType = Omit<
  UseSelectableRowsReturnType,
  'checkboxColumn'
>;
export const SelectableRowsContext = createContext<SelectableRowsContextType>({
  rows: [],
  isAllSelected: false,
  selectedRows: [],
  disabledRows: [],
  onSelect: noop,
  onSelectAll: noop,
  discardSelection: noop,
});
