import React from 'react';

import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';

import {ActionsColumn} from './ActionsColumn';
import {AppColumn} from './AppColumn';
import {CreatedByColumn} from './CreatedByColumn';
import {CurrentValueColumn} from './CurrentValueColumn';
import {EntityTypeColumn} from './EntityTypeColumn';
import {SlugColumn} from './SlugColumn';
import {StatusColumn} from './StatusColumn';
import {TargetNameColumn} from './TargetNameColumn';
import {ValueFromColumn} from './ValueFromColumn';
import {ValueToColumn} from './ValueToColumn';

export const columns = [
  // Dimensions
  {
    key: 'created_at',
    title: (
      <FormattedMessage id='aa.createdColumnTitle' defaultMessage='Created' />
    ),
    headerAlign: 'left',
    align: 'left',
    gridWidth: 'min-content',
    isSortable: false,
    isFixed: true,
    render: CreatedByColumn,
  },
  {
    key: 'network',
    title: (
      <FormattedMessage id='aa.partnerColumnTitle' defaultMessage='Partner' />
    ),
    headerAlign: 'left',
    align: 'left',
    gridWidth: 'min-content',
    isSortable: false,
    isFixed: true,
  },
  {
    key: 'app_network',
    title: <FormattedMessage id='aa.appColumnTitle' defaultMessage='App' />,
    headerAlign: 'left',
    align: 'left',
    isSortable: false,
    isResizable: true,
    isFixed: true,
    render: AppColumn,
  },
  {
    key: 'entity_type',
    title: (
      <FormattedMessage
        id='aa.targetTypeColumnTitle'
        defaultMessage='Target Type'
      />
    ),
    headerAlign: 'left',
    align: 'left',
    isSortable: false,
    maxWidth: 150,
    render: EntityTypeColumn,
  },
  {
    key: 'entity_name',
    title: (
      <FormattedMessage
        id='aa.targetNameColumnTitle'
        defaultMessage='Target Name'
      />
    ),
    headerAlign: 'left',
    align: 'left',
    isSortable: false,
    isResizable: true,
    maxWidth: 550,
    render: TargetNameColumn,
  },
  // OtherCols
  {
    key: 'attribute_slug',
    title: (
      <FormattedMessage id='aa.slugColumnTitle' defaultMessage='Attribute' />
    ),
    headerAlign: 'left',
    align: 'left',
    isSortable: false,
    gridWidth: 'min-content',
    render: SlugColumn,
  },
  {
    key: 'value_from',
    title: (
      <FormattedMessage
        id='aa.valueFromColumnTitle'
        defaultMessage='Old Value'
      />
    ),
    headerAlign: 'left',
    gridWidth: 'min-content',
    isSortable: false,
    render: ValueFromColumn,
  },
  {
    key: 'value_to',
    title: (
      <FormattedMessage id='aa.valueToColumnTitle' defaultMessage='New Value' />
    ),
    gridWidth: 'min-content',
    isSortable: false,
    headerAlign: 'left',
    render: ValueToColumn,
  },
  {
    key: 'status',
    title: (
      <FormattedMessage id='aa.statusColumnTitle' defaultMessage='Status' />
    ),
    headerAlign: 'left',
    isSortable: false,
    align: 'left',
    render: StatusColumn,
  },
  {
    key: 'current_value',
    title: (
      <FormattedMessage
        id='aa.currentValueColumnTitle'
        defaultMessage='Current Value'
      />
    ),
    headerAlign: 'left',
    gridWidth: 'min-content',
    isSortable: false,
    render: CurrentValueColumn,
  },
  {
    key: 'report_url',
    title: (
      <FormattedMessage id='aa.actionsColumnTitle' defaultMessage='Actions' />
    ),
    headerAlign: 'left',
    gridWidth: 'min-content',
    isSortable: false,
    align: 'right',
    render: ActionsColumn,
  },
];
