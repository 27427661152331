import {uniqBy, concat, isString, update, flow} from 'lodash/fp';
import {parse as parseSearch, stringify as stringifyQuery} from 'query-string';
import {put, takeEvery, delay, take} from 'redux-saga/effects';

import {history} from 'browserHistory';
import {PATHNAME_REPORT} from 'constants/globalVariables';
import {
  loadOpsStatsRequest,
  initRepeatingLoadOpsStatsRequest,
  loadOpsStatsSuccess,
  loadOpsStatsFailure,
  loadCustomerConfigSuccess,
  loadCustomerConfigRequest,
  reportListScreenUnmounted,
  showToast,
} from 'modules/common/actions';
import {fetchCustomerConfig} from 'modules/common/api';
import {selectCustomerConfig} from 'modules/common/reducers/customerConfig';
import {
  loadCreateRuleRequest,
  loadToggleRuleStateRequest,
  loadDeleteRuleRequest,
  loadUpdateRuleRequest,
  updateReportParams,
  loadCreateReportRequest,
  loadDuplicateReportRequest,
  loadCreateReportSuccess,
  loadUpdateReportRequest,
  loadUpdateReportSuccess,
  loadDeleteReportRequest,
  loadSaveReportAsDefaultRequest,
  loadRestoreDeletedReportRequest,
  loadUpdateReportUrlRequest,
  updateReportUrlQuerySuccess,
  navigateToReport,
  loadSaveReportAsNewRequest,
  updateParentReportId,
  loadSaveReportAsDefaultSuccess,
  loadUpdateEmailAlertsRequest,
} from 'modules/report/actions';
import {
  fetchOperationsStats,
  createReport,
  updateReport,
  deleteReport,
  changeDefaultReport,
  restoreReport,
} from 'modules/report/api';
import {selectActiveReport} from 'modules/report/reducers';
import {cancelOnDispatched, safe} from 'sagas/utils';
import {convertAbsolutePeriodToRelative} from 'utils/dateUtils';
import {
  REPORT_RULES_UPDATED_TOAST_PARAMS,
  REPORT_SET_AS_DEFAULT_TOAST_PARAMS,
} from 'utils/dialog';
import {call, select} from 'utils/libs/TypedReduxSaga';
import {trimParentReportId} from 'utils/parentReportId';
import {
  removeChangeActions,
  removeEmailAction,
} from 'utils/reportAutomationActions';

import type {CustomerConfigReport} from 'modules/report/types';

export const cancelOnReportListUnmount = cancelOnDispatched(
  reportListScreenUnmounted
);

export function* repeatingLoadOperationsStatsFlow() {
  while (true) {
    const counter = yield* call(fetchOperationsStats);

    if (counter) {
      yield put(loadOpsStatsSuccess(counter));
    }

    yield delay(15000);
  }
}
export function* watchRepeatingLoadOperationsStatsFlow() {
  yield takeEvery(
    initRepeatingLoadOpsStatsRequest,
    safe(repeatingLoadOperationsStatsFlow)
  );
}

function* loadOpsStatsFlow() {
  try {
    const counter = yield* call(fetchOperationsStats);

    if (counter) {
      yield put(loadOpsStatsSuccess(counter));
    }
  } catch (error) {
    console.error(error);
    yield put(loadOpsStatsFailure(error));
  }
}
export function* watchLoadOpsStatsFlow() {
  yield takeEvery(loadOpsStatsRequest, safe(loadOpsStatsFlow));
}

function* convertDatesToRelative({url, ...rest}: CustomerConfigReport) {
  const {date_period, ...params} = parseSearch(url);

  // To stop TS from complaining about incompatible types
  if (!isString(date_period)) {
    return {url, ...rest};
  }

  const updatedDatePeriod = convertAbsolutePeriodToRelative(date_period);
  if (updatedDatePeriod !== date_period) {
    yield put(updateReportParams({general: {date_period: updatedDatePeriod}}));
    yield put(loadUpdateReportUrlRequest({isSameEntry: true}));
    yield take(updateReportUrlQuerySuccess);
  }

  const updatedQuery = {
    ...params,
    date_period: updatedDatePeriod,
  };

  return {
    ...rest,
    url: `?${stringifyQuery(updatedQuery)}`,
  };
}

function* loadDuplicateReportFlow({payload}: {payload: CustomerConfigReport}) {
  const data = yield* call(convertDatesToRelative, payload);
  const duplicatedReport = yield* call(createReport, data);
  yield put(loadCreateReportSuccess(duplicatedReport));

  const duplicatedReportUrlWithParentId = `${duplicatedReport.url}&parent_report_id=${duplicatedReport.id}`;
  history.push({
    pathname: PATHNAME_REPORT,
    search: duplicatedReportUrlWithParentId,
  });
  yield put(navigateToReport(duplicatedReportUrlWithParentId));
}
export function* watchLoadDuplicateReportFlow() {
  yield takeEvery(
    loadDuplicateReportRequest,
    cancelOnReportListUnmount(safe(loadDuplicateReportFlow))
  );
}

function* loadCreateReportFlow({payload}: {payload: CustomerConfigReport}) {
  const data = yield* call(convertDatesToRelative, payload);
  const newReport = yield* call(createReport, data);
  yield put(loadCreateReportSuccess(newReport));
}
export function* watchLoadCreateReportFlow() {
  yield takeEvery(loadCreateReportRequest, safe(loadCreateReportFlow));
}

export function* loadSaveReportAsNewFlow({
  payload,
}: {
  payload: CustomerConfigReport;
}) {
  yield put(loadCreateReportRequest(payload));
  const {payload: newReport} = yield take(loadCreateReportSuccess);
  yield put(updateParentReportId(newReport.id));
}
export function* watchLoadSaveReportAsNewFlow() {
  yield takeEvery(loadSaveReportAsNewRequest, safe(loadSaveReportAsNewFlow));
}

function* loadUpdateReportFlow({payload}: {payload: CustomerConfigReport}) {
  const data = yield* call(convertDatesToRelative, payload);

  const updateReportPayload = update('url', trimParentReportId, data);

  const updatedReport = yield* call(updateReport, updateReportPayload);
  yield put(loadUpdateReportSuccess(updatedReport));
}
export function* watchLoadUpdateReportFlow() {
  yield takeEvery(loadUpdateReportRequest, safe(loadUpdateReportFlow));
}

export function* loadDeleteReportFlow({
  payload,
}: {
  payload: CustomerConfigReport['id'];
}) {
  yield* call(deleteReport, payload);
  // Merge newConfig with CustomerConfig from state to retain deleted report
  // with isDeleted flag and update defaultReportID if changed.
  // Reports remain in order according to id value, (oldest at the top),
  // even if all are deleted, new default report created from BE will appear at bottom.
  const newConfig = yield* call(fetchCustomerConfig);
  const customerConfig = yield* select(selectCustomerConfig);
  const mergeWithOldReports = flow(
    concat(customerConfig.reports),
    uniqBy('id')
  );
  const config = update('reports', mergeWithOldReports, newConfig);
  yield put(
    loadCustomerConfigSuccess({
      ...config,
      adjustAccount: customerConfig.adjustAccount,
      adjustUser: customerConfig.adjustUser,
    })
  );
}
export function* watchLoadDeleteReportFlow() {
  yield takeEvery(loadDeleteReportRequest, safe(loadDeleteReportFlow));
}

function* loadRestoreDeletedReportFlow({
  payload,
}: {
  payload: CustomerConfigReport['id'];
}) {
  yield* call(restoreReport, payload);
}
export function* watchLoadRestoreDeletedReportFlow() {
  yield takeEvery(
    loadRestoreDeletedReportRequest,
    safe(loadRestoreDeletedReportFlow)
  );
}

function* loadSaveReportAsDefaultFlow({
  payload,
}: {
  payload: CustomerConfigReport['id'];
}) {
  yield* call(changeDefaultReport, payload);
  yield put(loadSaveReportAsDefaultSuccess());
  yield put(showToast(REPORT_SET_AS_DEFAULT_TOAST_PARAMS));
}
export function* watchLoadSaveReportAsDefaultFlow() {
  yield takeEvery(
    loadSaveReportAsDefaultRequest,
    safe(loadSaveReportAsDefaultFlow)
  );
}

function* loadCreateRuleFlow({payload}: {payload: {actions: any[]}}) {
  const activeReport = yield* select(selectActiveReport);

  if (!activeReport) {
    return;
  }

  const updateReportPayload = {
    id: activeReport.id,
    actions: payload.actions,
    are_actions_enabled: true,
  };

  const updatedReport = yield* call(updateReport, updateReportPayload);
  yield put(loadUpdateReportSuccess(updatedReport));
}
export function* watchLoadCreateRuleFlow() {
  yield takeEvery(loadCreateRuleRequest, safe(loadCreateRuleFlow));
}

function* loadUpdateEmailAlertsFlow({
  payload: {actions: newEmailActions},
}: {
  payload: {actions: any};
}) {
  const activeReport = yield* select(selectActiveReport);

  if (!activeReport) {
    return;
  }

  const currentReportActions = activeReport?.actions ?? [];
  // We want to have only one 'email' type action, thus we discard the old 'email' action
  const otherActions = removeEmailAction(currentReportActions);
  const actions = [...otherActions, ...newEmailActions];
  const updateReportPayload = {
    id: activeReport.id,
    actions,
    are_actions_enabled: !!actions.length,
  };

  const updatedReport = yield* call(updateReport, updateReportPayload);
  yield put(loadUpdateReportSuccess(updatedReport));
  yield put(showToast(REPORT_RULES_UPDATED_TOAST_PARAMS));
}
export function* watchUpdateEmailAlertsFlow() {
  yield takeEvery(
    loadUpdateEmailAlertsRequest,
    safe(loadUpdateEmailAlertsFlow)
  );
}

function* loadUpdateRuleFlow({payload}: {payload: {actions: any}}) {
  const activeReport = yield* select(selectActiveReport);

  if (!activeReport) {
    return;
  }

  const currentReportActions = activeReport?.actions ?? [];
  const actionsWithoutChange = removeChangeActions(currentReportActions);

  const updateReportPayload = {
    id: activeReport.id,
    actions: [...actionsWithoutChange, ...payload.actions],
  };

  const updatedReport = yield* call(updateReport, updateReportPayload);
  yield put(loadUpdateReportSuccess(updatedReport));
}
export function* watchLoadUpdateRuleFlow() {
  yield takeEvery(loadUpdateRuleRequest, safe(loadUpdateRuleFlow));
}

function* loadToggleRuleStateFlow() {
  const activeReport = yield* select(selectActiveReport);

  if (!activeReport) {
    return;
  }

  const updateReportPayload = {
    id: activeReport.id,
    are_actions_enabled: !activeReport.are_actions_enabled,
  };
  const updatedReport = yield* call(updateReport, updateReportPayload);
  yield put(loadUpdateReportSuccess(updatedReport));
}
export function* watchLoadToggleRuleStateFlow() {
  yield takeEvery(loadToggleRuleStateRequest, safe(loadToggleRuleStateFlow));
}

function* loadDeleteRuleFlow() {
  const activeReport = yield* select(selectActiveReport);

  if (!activeReport) {
    return;
  }

  const updateReportPayload = {
    id: activeReport.id,
    actions: [],
    are_actions_enabled: false,
  };

  const updatedReport = yield* call(updateReport, updateReportPayload);
  yield put(loadUpdateReportSuccess(updatedReport));
}
export function* watchLoadDeleteRuleFlow() {
  yield takeEvery(loadDeleteRuleRequest, safe(loadDeleteRuleFlow));
}

function* reloadCustomerConfig() {
  yield put(loadCustomerConfigRequest());
}
export function* watchReportListActions() {
  yield takeEvery(
    [
      loadUpdateReportSuccess,
      loadCreateReportSuccess,
      loadSaveReportAsDefaultSuccess,
    ],
    reloadCustomerConfig
  );
}
