import {
  castArray,
  compact,
  curry,
  entries,
  fill,
  find,
  flatMap,
  flow,
  last,
  mapKeys,
  omit,
  some,
  toLower,
  update,
  zipObject,
} from 'lodash/fp';

import {
  METRIC_FILTER_PARAM_TO_OPTIONS_NAME,
  METRIC_FILTER_PARAMS,
} from 'utils/reportFilters';

import {DIVIDER, trimColumnPostfix, trimBuilderPostfix} from '../postfix';

import type {FiltersOptions} from 'modules/common/types';
import type {Dictionary} from 'types/utils';

const parseColumnQueryParam = ([key, values]: [string, string[]]) => {
  const keyParts = key.split(DIVIDER);
  const type = last(keyParts);
  const columnNameParts = keyParts.slice(0, -1);
  const column = columnNameParts.join(DIVIDER);

  const arrayValues = castArray(values);
  return arrayValues.map(value => ({column, type, value}));
};

export const convertUrlToFilters = flow(
  entries,
  flatMap(parseColumnQueryParam),
  compact
);

const constructColumnParams = flow(
  mapKeys(trimColumnPostfix),
  convertUrlToFilters
);

const constructBuilderParams = mapKeys(trimBuilderPostfix);

const FALLBACK_METRIC_TYPE_KEY = 'cohorts';

const splitMetrics = (metrics: string[], filtersOptions: FiltersOptions) =>
  metrics.reduce(
    (filterMetrics: {[metricType: string]: string[]}, metricId: string) => {
      const metricTypeKey = (find(
        (paramName: keyof typeof METRIC_FILTER_PARAM_TO_OPTIONS_NAME) => {
          const optionName = METRIC_FILTER_PARAM_TO_OPTIONS_NAME[paramName];
          return some({id: toLower(metricId)}, filtersOptions[optionName]);
        },
        METRIC_FILTER_PARAMS
      ) || FALLBACK_METRIC_TYPE_KEY) as string;
      return {
        ...filterMetrics,
        [metricTypeKey]: [...filterMetrics[metricTypeKey], toLower(metricId)],
      };
    },
    zipObject(
      METRIC_FILTER_PARAMS,
      fill(
        0,
        METRIC_FILTER_PARAMS.length,
        [],
        Array(METRIC_FILTER_PARAMS.length)
      )
    )
  );

const constructQuickParams = curry(
  (
    filtersOptions: FiltersOptions,
    {metrics = [], ...quick}: Dictionary<any>
  ) => {
    const metricParams = splitMetrics(metrics, filtersOptions);

    return {
      ...quick,
      ...metricParams,
    };
  }
);
const constructGeneralParams = (general: Dictionary<any>) => {
  if (general.drilldown) {
    return omit('focus', general);
  }
  return general;
};

export const constructParams = curry(
  (filtersOptions: FiltersOptions, params: Dictionary<any>) =>
    flow(
      update('column', constructColumnParams),
      update('builder', constructBuilderParams),
      update('quick', constructQuickParams(filtersOptions)),
      update('general', constructGeneralParams)
    )(params)
);
