import React, {useMemo, useEffect} from 'react';

import {css} from '@linaria/core';
import Paper from '@material-ui/core/Paper';
import {isEmpty} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {selectDefaultUrl} from 'modules/common/reducers/customerConfig';
import {loadReportFiltersRequest} from 'modules/report/actions/ReportActions';
import {selectFiltersOptions, selectRawFilters} from 'modules/report/reducers';
import {parseReportQuery} from 'utils/query';

import {BuilderForm} from './BuilderForm';

import {Query} from 'modules/report/types';

const cssPaper = css`
  padding: 24px;
  width: 60%;
  max-width: 1024px;
  min-width: 800px;
  margin: 0 auto;
`;

export const BuilderScreen = () => {
  const {search} = useLocation();
  const dispatch = useDispatch();
  const filters = useSelector(selectRawFilters);
  const filtersOptions = useSelector(selectFiltersOptions);
  const defaultUrl = useSelector(selectDefaultUrl) as string;

  useEffect(() => {
    if (isEmpty(filtersOptions)) {
      dispatch(loadReportFiltersRequest());
    }
  }, [filtersOptions, dispatch]);

  const initialValues = useMemo(() => {
    if (isEmpty(filtersOptions)) {
      return null;
    }

    return parseReportQuery(
      filters,
      filtersOptions,
      parseSearch(search || defaultUrl) as Query
    );
  }, [search, defaultUrl, filters, filtersOptions]);

  return (
    <Paper className={cssPaper}>
      <BuilderForm
        initialValues={initialValues}
        filtersOptions={filtersOptions}
      />
    </Paper>
  );
};
