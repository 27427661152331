import React, {useContext} from 'react';

import {css} from '@linaria/core';
import {useForm} from 'react-final-form';
import Select from 'react-select';

import {CVSchemes, CVSchemeOptions} from '../constants';
import {getDefaultSchemeState} from '../lib/getInitialSchemeParams';
import {SchemeContext} from '../SchemeContext';
import {schemes} from '../schemes';

const cssSelectContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-left: 40px;
  align-self: flex-start;
`;
const cssSelectLabel = css`
  font-size: 16px;
`;
const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    minWidth: 160,
    marginLeft: 12,
  }),
  control: (provided: any) => ({
    ...provided,
    background: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    borderBottom: 'solid 2px #b5bdd1',
    cursor: 'pointer',
    boxShadow: 'none',
    color: '#96a1bd',
    '&:hover': {
      borderBottomColor: '#4b577e',
    },
  }),
  option: (provided: any, {isSelected}: any) => ({
    ...provided,
    backgroundColor: isSelected ? '#00bed5' : null,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    position: 'static',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    maxWidth: 'none',
  }),
};

export type SchemeSelectOption = {
  value: CVSchemes;
  label: string;
};
type SchemeSelectProps = {
  defaultValue?: SchemeSelectOption;
};
export const SchemeSelect = ({
  defaultValue = CVSchemeOptions[0],
}: SchemeSelectProps) => {
  const {setScheme} = useContext(SchemeContext);
  const form = useForm();

  return (
    <div className={cssSelectContainer}>
      <span className={cssSelectLabel}>Scheme type:</span>
      <Select
        defaultValue={defaultValue}
        options={CVSchemeOptions}
        styles={selectStyles}
        onChange={({value}: any) => {
          setScheme(value);
          form.reset(getDefaultSchemeState(schemes[value as CVSchemes].fields));
        }}
      />
    </div>
  );
};
