import React, {memo} from 'react';

import {
  currencyFormatter,
  decimalFormatter,
  numberFormatter,
  percentFormatter,
  timePeriodFormatter,
} from '../lib/formatters';
import {PoPChangeCell} from './PoPChangeCell';

type Props = {
  value: string;
  format: string;
};

const FormatCell = ({value, format}: Props) => {
  const numValue = Number(value);

  let result: number | string = value ?? '—';
  switch (format) {
    case 'money':
      result = currencyFormatter(numValue);
      break;
    case 'money_precise':
      result = currencyFormatter(numValue, true);
      break;
    case 'percent':
    case 'percent_change':
      result = percentFormatter(numValue);
      break;
    case 'timeperiod':
      result = timePeriodFormatter(numValue);
      break;
    case 'integer':
    default:
      result = numberFormatter(numValue);
      break;
    case 'decimal':
      result = decimalFormatter(numValue);
      break;
  }

  if (format === 'percent_change') {
    return <PoPChangeCell numValue={numValue} formattedValue={result} />;
  }

  return <span>{result}</span>;
};

export default memo(FormatCell);
