import React, {useMemo} from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import SearchIcon from '@material-ui/icons/Search';
import {compact, flow, map, uniq} from 'lodash/fp';

import {Input, MultiSelect, SelectNew} from 'common-components';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';

import {getAutomationStatusOption} from './utils';

import {AutomationStatusOption, ReportListFiltersValues} from './types';
import {EnrichedReport} from 'modules/report/types';

const REPORT_NAME_SEARCH_ID = 'report-name-search';

const StyledReportListFilters = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;
const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 4px;
  margin-bottom: 4px;
  color: #96a1bd;
`;
const cssFilter = css`
  margin-right: 15px;
  width: 230px;
`;
const cssLabel = css`
  color: ${styleVariables.colorFiltersTitles};
`;

const createSimpleOption = (value: string | number) => ({
  label: value,
  value,
});

type ReportListFiltersProps = {
  onReportNameChange: (
    newSelReportName: ReportListFiltersValues['reportName']
  ) => void;
  onModifiedByChange: (
    selModifiedByUsers: ReportListFiltersValues['modifiedBy']
  ) => void;
  onAutomationStatusChange: (
    newSelAutomationStatus: ReportListFiltersValues['automationStatus']
  ) => void;
  reports: EnrichedReport[];
  values: ReportListFiltersValues;
};
export const ReportListFilters = ({
  onReportNameChange,
  onModifiedByChange,
  onAutomationStatusChange,
  reports,
  values: {
    reportName: reportNameSearch,
    modifiedBy: selModifiedByUsers,
    automationStatus: selAutomationStatus,
  },
}: ReportListFiltersProps) => {
  const intl = useIntl();

  const allModifiedByUsers = flow(map('modified_by'), compact, uniq)(reports);
  const allModifiedByUsersOptions = map(createSimpleOption, allModifiedByUsers);

  const allAutomationStatusOptions: AutomationStatusOption[] = useMemo(
    () => getAutomationStatusOption(intl),
    [intl]
  );

  const selectLabel = intl.formatMessage({
    id: 'aa.label.select',
    defaultMessage: 'Select',
  });

  return (
    <StyledReportListFilters data-testid='reportList-filters'>
      <div>
        <div className={cssLabel}>
          {intl.formatMessage({
            id: 'aa.reportList.filter.reportName',
            defaultMessage: 'REPORT NAME',
          })}
        </div>
        <Input
          data-testid={REPORT_NAME_SEARCH_ID}
          data-pendoid={REPORT_NAME_SEARCH_ID}
          containerClassName={cssFilter}
          value={reportNameSearch}
          placeholder={intl.formatMessage({
            id: 'aa.filters.history.search.label',
            defaultMessage: 'Search',
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onReportNameChange(e.target.value)
          }
          icon={StyledSearchIcon}
        />
      </div>
      <div>
        <div className={cssLabel}>
          {intl.formatMessage({
            id: 'aa.reportList.filter.modifiedBy',
            defaultMessage: 'MODIFIED BY',
          })}
        </div>
        <MultiSelect
          pendoId='report-modifyBy-filter'
          className={cssFilter}
          limitOptionsTo={200}
          value={selModifiedByUsers}
          options={allModifiedByUsersOptions}
          onChange={onModifiedByChange}
          placeholder={selectLabel}
        />
      </div>
      <div>
        <div className={cssLabel}>
          {intl.formatMessage({
            id: 'aa.reportList.filter.automation',
            defaultMessage: 'AUTOMATION',
          })}
        </div>
        <div data-pendoid='report-automation-filter'>
          <SelectNew
            className={cssFilter}
            value={selAutomationStatus}
            options={allAutomationStatusOptions}
            onChange={onAutomationStatusChange}
            plainValue
            placeholder={selectLabel}
          />
        </div>
      </div>
    </StyledReportListFilters>
  );
};
