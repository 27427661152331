import React, {useCallback, useMemo, useReducer, useState, useRef} from 'react';

import Popper from '@material-ui/core/Popper';
import {constant, find, isNil, map, pull} from 'lodash/fp';
import Select, {components, StylesConfig} from 'react-select';

import {MultiSelectValueContainer} from 'common-components/CheckboxMultiSelect/CheckboxMultiSelect';
import {
  CLEAR_INDICATOR_TEST_ID,
  selectNewStylesOverride,
  selectNewTheme,
} from 'common-components/SelectNew/SelectNew';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';
import {isPopulated} from 'utils/lodash+';

import {BACKDROP_Z_INDEX, Backdrop} from '../Backdrop/Backdrop';
import {CombiSelectMenu} from './CombiSelectMenu';
import {CombiSelectReducer} from './state';
import {craftInitialPickersValues} from './utils';

import {
  Picker,
  CombiSelectState,
  CombiSelectStateActions,
  CombiSelectStateActionTypes,
  PickersValues,
} from './types';
import {Option} from 'modules/common/types';

const hiddenInputOnNonSearchableSelectStyles = {
  valueContainer: {
    input: {
      height: 0,
    },
  },
  placeholder: {
    position: 'relative',
    top: 0,
    transform: 'none',
  },
};

// @ts-expect-error: Types are not fully defined
export const CombiSelectStylesOverride: StylesConfig<any, any, any> = {
  ...selectNewStylesOverride,
  placeholder: (...args) => ({
    // @ts-expect-error: Types are not fully defined
    ...selectNewStylesOverride.placeholder(...args),
    ...hiddenInputOnNonSearchableSelectStyles.placeholder,
  }),
  valueContainer: (...args) => ({
    // @ts-expect-error: Types are not fully defined
    ...selectNewStylesOverride.valueContainer(...args),
    ...hiddenInputOnNonSearchableSelectStyles.valueContainer,
  }),
};

export type CombiSelectProps = {
  pickers: Picker[];
  showWarning?: boolean;
  formMessage?: string;
  emptyValuesMessage?: string;
  onChange: (values: string[]) => void;
  value?: string[]; // contains value strings that need to be transformed into Option with craftOptionFromValueString
  craftOptionFromValueString?: (valueString: string) => Option; // used to populate initial selectedValues Options
  combinePickersValues: (pickersValues: PickersValues) => Option | null; // must return a valid Option or null if pickersValues are invalid
};
export const CombiSelect = ({
  pickers: pickersData,
  formMessage,
  emptyValuesMessage,
  combinePickersValues,
  craftOptionFromValueString,
  value: valueStrings,
  onChange,
  showWarning,
}: CombiSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const initialPickersState: CombiSelectState = useMemo(() => {
    const initialSelectedValues: Option[] =
      isPopulated(valueStrings) && craftOptionFromValueString
        ? map(craftOptionFromValueString)(valueStrings)
        : [];

    return {
      selectedValues: initialSelectedValues,
      pickersValues: craftInitialPickersValues(pickersData),
    };
  }, [craftOptionFromValueString, pickersData, valueStrings]);

  const [state, dispatch] = useReducer<
    React.Reducer<CombiSelectState, CombiSelectStateActions>
  >(CombiSelectReducer, initialPickersState);

  const currentCombinedValue = combinePickersValues(state.pickersValues);

  const hasValueBeenAdded = (value: Option) =>
    Boolean(find(value, state.selectedValues));
  const isValidCombinedValue =
    !isNil(currentCombinedValue) && !hasValueBeenAdded(currentCombinedValue);

  // handlers
  const handleChange = useCallback(
    (newValues: Option[]) => {
      onChange(map('value', newValues));
    },
    [onChange]
  );

  const handleAddValue = useCallback(() => {
    if (!isValidCombinedValue) {
      return;
    }

    const updatedSelectedValues: Option[] = [
      currentCombinedValue!,
      ...state.selectedValues,
    ];

    dispatch({
      type: CombiSelectStateActionTypes.UPDATE_SELECTED_VALUES,
      payload: updatedSelectedValues,
    });

    handleChange(updatedSelectedValues);
  }, [
    currentCombinedValue,
    handleChange,
    isValidCombinedValue,
    state.selectedValues,
  ]);

  const handleDeleteValue = useCallback(
    (option: Option) => {
      const updatedSelectedValues: Option[] = pull(
        option,
        state.selectedValues
      );

      dispatch({
        type: CombiSelectStateActionTypes.UPDATE_SELECTED_VALUES,
        payload: updatedSelectedValues,
      });

      handleChange(updatedSelectedValues);
    },
    [handleChange, state.selectedValues]
  );

  const handleClearValues = useCallback(() => {
    const updatedSelectedValues: Option[] = [];

    dispatch({
      type: CombiSelectStateActionTypes.UPDATE_SELECTED_VALUES,
      payload: updatedSelectedValues,
    });

    handleChange(updatedSelectedValues);
  }, [handleChange]);

  const handlePickerChange = useCallback((pickerId: string, value: Option) => {
    dispatch({
      type: CombiSelectStateActionTypes.UPDATE_PICKER_VALUE,
      payload: {pickerId, value},
    });
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback((event: any) => {
    // @ts-expect-error: ref is not typed
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    dispatch({
      type: CombiSelectStateActionTypes.CLEAN_PICKER_VALUES,
    });
    setIsOpen(false);
  }, []);

  // render
  const ClearIndicatorOverride = useCallback(
    ({children, ...props}) => (
      <div
        role='button'
        tabIndex={0}
        onClick={handleClearValues}
        data-testid={CLEAR_INDICATOR_TEST_ID}
      >
        <components.ClearIndicator {...props}>
          {children}
        </components.ClearIndicator>
      </div>
    ),
    [handleClearValues]
  );

  return (
    <div>
      <div ref={anchorRef}>
        {/* @ts-expect-error: not all props match */}
        <Select
          isMulti
          isCompact
          isClearable
          isSearchable={false}
          showWarning={showWarning}
          menuPlacement='auto'
          placeholder={
            <FormattedMessage id='aa.label.select' defaultMessage='Select' />
          }
          value={state.selectedValues}
          menuIsOpen={isOpen}
          onMenuOpen={handleOpen}
          components={{
            MultiValueRemove: constant(null),
            Menu: constant(null),
            ValueContainer: MultiSelectValueContainer,
            ClearIndicator: ClearIndicatorOverride,
          }}
          theme={{
            ...selectNewTheme,
          }}
          styles={CombiSelectStylesOverride}
        />
      </div>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: BACKDROP_Z_INDEX + 1,
        }}
      >
        <CombiSelectMenu
          state={state}
          isFormButtonDisabled={!isValidCombinedValue}
          emptyValuesMessage={emptyValuesMessage}
          formMessage={formMessage}
          onPickerChange={handlePickerChange}
          onCreateValue={handleAddValue}
          onDeleteValue={handleDeleteValue}
          pickersData={pickersData}
        />
      </Popper>
      <Backdrop onClick={handleClose} open={isOpen} />
    </div>
  );
};
