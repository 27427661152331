import React, {useMemo} from 'react';

import {Button, Paper} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import cx from 'classnames';
import {isEmpty, map} from 'lodash/fp';

import {Label, SelectNew} from 'common-components';
import {selectNewStylesOverride} from 'common-components/SelectNew/SelectNew';
import {TwoLinesMax} from 'common-components/TwoLinesMax';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';

import {
  cssPickerSelectContainer,
  cssPickerSelectLabel,
  cssValueContainer,
  cssValueLabel,
  cssValueRemoveButton,
  cssDeleteOptionIcon,
  cssEmptyValuesContainer,
  cssLineBreak,
  cssTextAlignCenter,
  cssPanel,
  cssLeftTile,
  cssFormLabel,
  cssFormButtonContainer,
  cssRightTile,
} from './CombiSelect.styles';

import {Picker, CombiSelectState} from './types';
import {Option} from 'modules/common/types';

export const COMBI_SELECT_MENU_TEST_ID = 'COMBI_SELECT_MENU_TEST_ID';
export const createCombiSelectValueRemoveTestId = (value: string) =>
  `COMBI_SELECT_VALUE_REMOVE_TEST_ID_${value}`;

type PickerSelectProps = Picker & {
  onChange: (value: Option) => void;
  value: Option | null;
};
export const PickerSelect = ({
  onChange,
  value,
  label,
  id: pickerId,
  options,
  isRequired,
}: PickerSelectProps) => {
  const intl = useIntl();

  const pickerLabel = isRequired
    ? label
    : intl.formatMessage(
        {
          id: 'aa.placeholder.itemOptional',
          defaultMessage: '{item} (optional)',
        },
        {
          item: label,
        }
      );

  return (
    <div className={cssPickerSelectContainer}>
      <Label className={cssPickerSelectLabel}>{pickerLabel}</Label>
      <SelectNew
        hasDarkBackground
        isClearable={!isRequired}
        inputName={pickerId}
        options={options}
        value={value}
        onChange={onChange}
        placeholder={
          <FormattedMessage id='aa.label.select' defaultMessage='Select' />
        }
        styles={{
          control: (...args: any[]) => ({
            ...selectNewStylesOverride.control(...args),
            '&:hover': {
              borderBottomColor: styleVariables.colorWarmGreyThree,
            },
          }),
        }}
      />
    </div>
  );
};

export const CombiSelectValue = ({
  option,
  onClick,
  pendoId,
}: {
  option: Option;
  onClick: (option: Option) => void;
  pendoId?: string;
}) => (
  <div className={cssValueContainer}>
    <div className={cssValueLabel}>
      <TwoLinesMax breakOnWord>{option.title}</TwoLinesMax>
    </div>
    <CloseIcon
      data-testid={createCombiSelectValueRemoveTestId(option.value)}
      data-pendoid={pendoId}
      onClick={() => onClick(option)}
      className={cx(cssDeleteOptionIcon, cssValueRemoveButton)}
    />
  </div>
);

type CombiSelectMenuProps = {
  formMessage?: string;
  emptyValuesMessage?: string;
  state: CombiSelectState;
  pickersData: Picker[];
  isFormButtonDisabled: boolean;
  onPickerChange: (pickerId: string, value: Option) => void;
  onDeleteValue: (value: Option) => void;
  onCreateValue: () => void;
};
export const CombiSelectMenu = ({
  state,
  isFormButtonDisabled,
  emptyValuesMessage,
  formMessage,
  onPickerChange,
  onCreateValue,
  onDeleteValue,
  pickersData,
}: CombiSelectMenuProps) => {
  const emptyValuesContent = useMemo(
    () => (
      <div className={cssEmptyValuesContainer}>
        <span className={cssTextAlignCenter}>
          <FormattedMessage
            id='aa.label.noValuesAdded'
            defaultMessage='No values added'
          />
        </span>
        <div className={cssLineBreak} />
        <span className={cssTextAlignCenter}>{emptyValuesMessage}</span>
      </div>
    ),
    [emptyValuesMessage]
  );

  const renderedPickers = map((picker: Picker) => {
    const pickerId = picker.id;

    return (
      <div key={pickerId}>
        <PickerSelect
          value={state.pickersValues[pickerId]}
          onChange={(value: Option) => onPickerChange(pickerId, value)}
          {...picker}
        />
      </div>
    );
  })(pickersData);

  const renderedValues = map((option: Option) => (
    <div key={option.value}>
      <CombiSelectValue option={option} onClick={onDeleteValue} />
    </div>
  ))(state.selectedValues);

  return (
    <Paper
      style={{
        background: styleVariables.colorMenuBackground,
      }}
      className={cssPanel}
      data-testid={COMBI_SELECT_MENU_TEST_ID}
    >
      <div className={cssLeftTile}>
        <span className={cssFormLabel}>{formMessage}</span>
        <div>{renderedPickers}</div>
        <div className={cssFormButtonContainer}>
          <Button
            size='small'
            color='primary'
            variant='contained'
            type='button'
            onClick={onCreateValue}
            disabled={isFormButtonDisabled}
          >
            <FormattedMessage id='aa.label.add' defaultMessage='Add' />
          </Button>
        </div>
      </div>
      <div className={cssRightTile}>
        {isEmpty(state.selectedValues) ? emptyValuesContent : renderedValues}
      </div>
    </Paper>
  );
};
