import React, {FC} from 'react';

import {css} from '@linaria/core';
import WarningIcon from '@material-ui/icons/Warning';

import {styleVariables} from 'constants/globalVariables';
import {isPopulated} from 'utils/lodash+';

const cssContainer = css`
  display: flex;
  align-items: baseline;
  font-size: 16px;
  font-weight: ${styleVariables.fontWeightMedium};
`;
const cssValidationError = css`
  margin-left: 20px;
  font-size: 14px;
  font-weight: normal;
`;
const cssWarningIcon = css`
  &.MuiSvgIcon-root {
    vertical-align: bottom;
    color: #f7bc2f;
    width: 18px;
    height: 21px;
    margin-right: 8px;
  }
`;
const cssChildren = css`
  margin-top: 5px;
`;

export type SectionProps = {
  pendoId?: string | null;
  className?: string;
  label: React.ReactNode;
  errorMessage?: string;
};
export const Section: FC<SectionProps> = ({
  pendoId = null,
  className = '',
  label,
  children,
  errorMessage = '',
}) => (
  <div className={className}>
    <div className={cssContainer}>
      {label}
      {isPopulated(errorMessage) && (
        <span className={cssValidationError}>
          <WarningIcon className={cssWarningIcon} />
          {errorMessage}
        </span>
      )}
    </div>

    <div data-pendoid={pendoId} className={cssChildren}>
      {children}
    </div>
  </div>
);
