import {omit, curry, pickBy, flatMap, keys, includes} from 'lodash/fp';

import {PARENT_REPORT_ID} from 'utils/parentReportId';

import {isBuilderParam, isColumnParam} from '../postfix';

import type {Dictionary} from 'types/utils';

const GENERAL_PARAMS = [
  'drilldown',
  'focus',
  'sort',
  'date_period',
  PARENT_REPORT_ID,
];

const isGeneralParam = (_: any, key: string) => includes(key, GENERAL_PARAMS);

const groupParams = curry(
  (quickFiltersNames: string[], parsedQuery: Dictionary<string | string[]>) => {
    const isQuickFilter = (_: any, key: string) =>
      quickFiltersNames.includes(key);
    const quickParams = pickBy(isQuickFilter, parsedQuery);
    const generalParams = pickBy(isGeneralParam, parsedQuery);
    const builderParams = pickBy(isBuilderParam, parsedQuery);
    const columnParams = pickBy(isColumnParam, parsedQuery);
    const knownKeys = flatMap(keys, [
      quickParams,
      generalParams,
      builderParams,
      columnParams,
    ]);
    const unknownParams = omit(knownKeys, parsedQuery);

    return {
      quick: quickParams,
      general: generalParams,
      builder: builderParams,
      unknown: unknownParams,
      column: columnParams,
    };
  }
);

export default groupParams;
