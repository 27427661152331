import React from 'react';

import {NullState} from 'common-components';
import {FormattedMessage} from 'common-components/utils/libs/ReactIntl';

export const ReportListNullState = () => (
  <NullState
    title={
      <FormattedMessage
        id='aa.reportList.emptyDataLabel'
        defaultMessage='No results found'
      />
    }
  />
);
