import {diff} from 'deep-diff';
import {
  defaults,
  find,
  flow,
  isEmpty,
  isNaN,
  isNil,
  omitBy,
  reduce,
  update,
  remove,
  isEqual,
  orderBy,
  filter,
  difference,
  includes,
  overEvery,
  map,
} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {put, take, takeEvery, takeLatest, fork} from 'redux-saga/effects';

import {history} from 'browserHistory';
import {PATHNAME_REPORT} from 'constants/globalVariables';
import {
  loadDrilldownLabelsRequest,
  loadDrilldownLabelsSuccess,
} from 'modules/common/actions';
import {
  fetchFiltersFor,
  fetchFiltersOptions,
  fetchDrilldownLabels,
} from 'modules/common/api';
import {
  selectCanViewAttr,
  selectCustomerReports,
  selectDefaultUrl,
} from 'modules/common/reducers/customerConfig';
import {showConfirmationDialog} from 'modules/common/sagas/common';
import {
  applyReportParams,
  removeParentReportId,
  closeAutomationPanel,
  initReportFailure,
  initReportRequest,
  initReportSuccess,
  loadReportAttrsRequest,
  loadReportSpecRequest,
  loadReportSpecSuccess,
  loadReportFiltersRequest,
  loadReportFiltersSuccess,
  loadReportDataFailure,
  loadReportDataRequest,
  loadReportDataSuccess,
  navigateToReport,
  setFilters,
  setFiltersOptions,
  setReportDebugMode,
  setReportParams,
  updateReportParams,
  loadUpdateReportRequest,
  loadUpdateReportUrlRequest,
  updateReportUrlQuerySuccess,
  loadAddPivotReportDataRequest,
  loadAddPivotReportDataSuccess,
  loadMoreReportAttrsRequest,
  updateParentReportId,
} from 'modules/report/actions';
import {fetchReportSpec, fetchReportData} from 'modules/report/api';
import {
  selectFiltersOptions,
  selectIsAutomationPanelOpen,
  selectParams,
  selectRawFilters,
  selectActiveReport,
  selectGeneralParams,
  selectReportDataByHash,
  selectReportSpecDimensions,
  selectAllVisibleDimensions,
  selectReportSpecMetrics,
} from 'modules/report/reducers';
import {
  selectRsData,
  selectIsDebugMode,
} from 'modules/report/reducers/debugInfo';
import {
  sanitizeColumnOrder,
  dropOutdatedColumnFilters,
  isTemplateReportId,
} from 'modules/report/utils';
import {cancelAwareCall, safe} from 'sagas/utils';
import {
  hasPercentageChange,
  hasRelativeChange,
  hasAbsoluteChange,
  isMoneyAttribute,
} from 'utils/attributes';
import {
  getMajorChangesDialogParams,
  getUpdateParamDialogParams,
  getUrlValidationDialogParams,
} from 'utils/dialog';
import {
  getEnabledFilterDefaultParams,
  extractEnabledFilterOptionsNames,
} from 'utils/filters';
import {call, select} from 'utils/libs/TypedReduxSaga';
import {isPopulated} from 'utils/lodash+';
import {
  addParentReportId,
  PARENT_REPORT_ID,
  trimParentReportId,
} from 'utils/parentReportId';
import {parseReportQuery, buildReportQuery, stringifyQuery} from 'utils/query';
import {extractMetricFilterParams} from 'utils/reportFilters';

import {
  cancelOnReportUnmount,
  cancelOnStaleReport,
  enhancePivotDrillParams,
  enhanceReportResponseData,
  enhanceReportSpec,
  craftParamsWithAllDimensionsAndEmptyIndex,
} from './utils';

import {
  convertDrilldown,
  stringifyDrilldown,
} from 'modules/report/components/Drilldown';

import type {
  FiltersOptions,
  PivotRowData,
  UpdateAttributeModel,
} from 'modules/common/types';
import type {Query, ReportParams} from 'modules/report/types';
import {BE_types} from 'types/backendServicesTypes';
import type {Dictionary} from 'types/utils';

export function* loadReportDataFlow() {
  try {
    const debug = yield* select(selectIsDebugMode);
    const rsData = yield* select(selectRsData);
    const reportParams = yield* select(selectParams);
    const isPivotReport = isPopulated(reportParams.quick.index);
    let reportData: BE_types['ReportResponseModel'];

    // DEBUG MODE
    if (debug && isPopulated(rsData)) {
      reportData = rsData;
    } else {
      reportData = yield cancelAwareCall(fetchReportData, reportParams);
    }

    if (isPivotReport) {
      // warmup RS/report for subsequent pivot requests
      const prefetchingReportParams =
        craftParamsWithAllDimensionsAndEmptyIndex(reportParams);
      yield fork(cancelAwareCall, fetchReportData, prefetchingReportParams);
    }

    reportData = enhanceReportResponseData(reportData, reportParams);

    yield put(loadReportDataSuccess(reportData));

    const selectedAttributes = reportParams.quick.attributes;
    const canViewAttr = yield* select(selectCanViewAttr);

    if (canViewAttr && isPopulated(selectedAttributes)) {
      yield put(
        loadReportAttrsRequest({reportData, attributes: selectedAttributes})
      );
    }
  } catch (error) {
    console.error(error);
    yield put(loadReportDataFailure(error));
  }
}

export function* watchLoadReportDataFlow() {
  yield takeLatest(
    loadReportDataRequest,
    cancelOnReportUnmount(safe(loadReportDataFlow))
  );
}

export function* loadAddPivotReportDataFlow({
  payload: hash,
}: {
  payload: string;
}) {
  const reportParams = yield* select(selectParams);
  const backendDimensions = yield* select(selectReportSpecDimensions);
  const reportRowData = yield* select(state =>
    selectReportDataByHash(state, hash)
  );

  if (!reportRowData) {
    return;
  }

  const pivotDrillParams = enhancePivotDrillParams(
    reportParams,
    backendDimensions,
    reportRowData as PivotRowData
  );

  const pivotData: BE_types['ReportResponseModel'] = yield cancelAwareCall(
    fetchReportData,
    pivotDrillParams
  );

  const enhancedPivotData = enhanceReportResponseData(
    pivotData,
    pivotDrillParams,
    reportRowData
  );

  yield put(
    loadAddPivotReportDataSuccess({
      hash,
      newPivotData: enhancedPivotData,
    })
  );

  const selectedAttributes = reportParams.quick.attributes;
  const canViewAttr = yield* select(selectCanViewAttr);
  if (canViewAttr && isPopulated(selectedAttributes)) {
    yield put(
      loadMoreReportAttrsRequest({
        reportData: enhancedPivotData,
        attributes: selectedAttributes,
      })
    );
  }
}

export function* watchLoadAddPivotReportDataFlow() {
  yield takeEvery(
    loadAddPivotReportDataRequest,
    cancelOnReportUnmount(safe(loadAddPivotReportDataFlow))
  );
}

function* refreshReportUrl(originalUrl: string) {
  const currentUrl = history.location.search;

  const paramsDiff = diff(parseSearch(originalUrl), parseSearch(currentUrl));

  if (!paramsDiff) return;

  const reports = yield* select(selectCustomerReports);
  const report = find({url: originalUrl}, reports);
  const isTemplateReport = report && isTemplateReportId(report.id);

  if (!report || isTemplateReport) return;

  yield put(
    loadUpdateReportRequest({
      ...report,
      url: currentUrl,
    })
  );
}

export function* validateReportParams(payload: ReportParams) {
  const defaultUrl = yield* select(selectDefaultUrl);
  const {dimensions, index} = payload.quick;
  const metrics = extractMetricFilterParams(payload.quick);

  if ((isEmpty(dimensions) || isEmpty(metrics)) && isEmpty(index)) {
    yield showConfirmationDialog(getUrlValidationDialogParams(defaultUrl));
    yield put(navigateToReport(defaultUrl));
  }
}

function* loadReportFiltersFlow() {
  const filters = yield* call(fetchFiltersFor, 'report');
  yield put(setFilters(filters));

  const optionsNames = extractEnabledFilterOptionsNames(filters);
  const filtersOptions: FiltersOptions = yield* call(fetchFiltersOptions, {
    filters: optionsNames,
  });
  yield put(setFiltersOptions(filtersOptions));
  yield put(loadReportFiltersSuccess());
}

export function* watchReportFiltersFlow() {
  yield takeLatest(loadReportFiltersRequest, safe(loadReportFiltersFlow));
}

export function* sanitizeColumnFiltersParams(params: ReportParams) {
  const dimensions = yield* select(selectAllVisibleDimensions);
  const backendMetrics = yield* select(selectReportSpecMetrics);
  const backendMetricsSlugs = map('id', backendMetrics);
  const {
    quick: {attributes},
    column,
  } = params;

  const quickMetrics = extractMetricFilterParams(params.quick);

  const allColumns = [
    ...attributes,
    ...quickMetrics,
    ...dimensions,
    ...backendMetricsSlugs,
  ];

  // Correct column filters
  const columnParams = dropOutdatedColumnFilters(allColumns, column);

  return {
    ...params,
    column: columnParams,
  };
}

const isValidDrill = (drill: any) =>
  Boolean(drill.level) && Boolean(drill.value);
export function* sanitizeOrderAndDrilldownParams(params: ReportParams) {
  const dimensions = yield* select(selectAllVisibleDimensions);
  const {
    quick: {attributes = [], index = []},
    general: {order, drilldown, focus},
  } = params;
  const metrics: string[] = extractMetricFilterParams(params.quick);

  let updatedParams = params;

  // https://app.asana.com/0/260834613116794/1183129316519310
  // Drop focus if both params are present
  if (drilldown && focus) {
    updatedParams = {
      ...updatedParams,
      general: {
        ...updatedParams.general,
        focus: '',
      },
    };
  }

  // Correct format for drilldown/focus
  if (drilldown || focus) {
    const isNotIndexDrill = (drill: any) => !includes(drill.level, index);

    const paramName = focus ? 'focus' : 'drilldown';
    const parsedDrill = convertDrilldown(focus || drilldown);
    const sanitizedDrill = filter(
      overEvery([isValidDrill, isNotIndexDrill]),
      parsedDrill
    );
    const correctDrilldown = stringifyDrilldown(sanitizedDrill);

    if (drilldown !== correctDrilldown) {
      updatedParams = {
        ...updatedParams,
        general: {
          ...updatedParams.general,
          [paramName]: correctDrilldown,
        },
      };
    }
  }

  // Order consistent with columns
  const sanitizedOrder = sanitizeColumnOrder(
    order,
    dimensions,
    attributes,
    metrics
  );

  if (diff(order, sanitizedOrder)) {
    updatedParams = {
      ...updatedParams,
      general: {
        ...updatedParams.general,
        order: sanitizedOrder,
      },
    };
  }

  return updatedParams;
}

export function sanitizeIndexDimensionsParams(
  params: ReportParams
): ReportParams {
  const {
    quick: {index, dimensions},
  } = params;

  let updatedParams = params;

  // Remove indexDimensions present in dimensions
  const sanitizedDimensions = difference(dimensions, index);

  if (!isEqual(dimensions, sanitizedDimensions)) {
    updatedParams = {
      ...updatedParams,
      quick: {
        ...updatedParams.quick,
        dimensions: sanitizedDimensions,
      },
    };
  }

  return updatedParams;
}

export function* ensureReportParamsConsistencyBeforeSpecFetch() {
  const params: ReportParams = yield* select(selectParams);
  const paramsWithSanitizedOrderAndDrilldown = yield* call(
    sanitizeOrderAndDrilldownParams,
    params
  );
  const sanitizedParams = yield* call(
    sanitizeIndexDimensionsParams,
    paramsWithSanitizedOrderAndDrilldown
  );

  const hasParamsChanged = !isEqual(sanitizedParams, params);

  if (hasParamsChanged) {
    yield put(updateReportParams(sanitizedParams));
  }

  return sanitizedParams;
}

export function* ensureReportParamsConsistencyAfterSpecFetch() {
  const params: ReportParams = yield* select(selectParams);
  const sanitizedParams = yield* call(sanitizeColumnFiltersParams, params);

  const hasParamsChanged = !isEqual(sanitizedParams, params);

  if (hasParamsChanged) {
    yield put(updateReportParams(sanitizedParams));
  }

  return sanitizedParams;
}

function* removeParentReportIdFlow() {
  yield put(
    applyReportParams({
      params: {general: {[PARENT_REPORT_ID]: null}},
      skipDataReloading: true,
    })
  );
}
export function* watchRemoveParentReportIdFlow() {
  yield takeLatest(removeParentReportId, safe(removeParentReportIdFlow));
}

export function* updateParentReportIdFlow({
  payload: parentReportId,
}: {
  payload: BE_types['ReportModel']['id'];
}) {
  const params = yield* select(selectParams);
  const paramsToUpdate = addParentReportId(params, parentReportId);

  yield put(updateReportParams(paramsToUpdate));
  yield put(loadUpdateReportUrlRequest());
}
export function* watchUpdateParentReportIdFlow() {
  yield takeLatest(updateParentReportId, safe(updateParentReportIdFlow));
}

function* loadReportSpecFlow() {
  const reportParams = yield* select(selectParams);

  const reportSpec: BE_types['SpecResponseModel'] = yield cancelAwareCall(
    fetchReportSpec,
    reportParams
  );

  const enhancedReportSpec = enhanceReportSpec(reportSpec, reportParams);

  yield put(loadReportSpecSuccess(enhancedReportSpec));
}

export function* watchLoadReportSpecFlow() {
  yield takeLatest(loadReportSpecRequest, safe(loadReportSpecFlow));
}

export function* updateUrlQuery(params: ReportParams, isSameEntry?: boolean) {
  try {
    const query = buildReportQuery(params);

    const newSearch = stringifyQuery(query);
    const navigationMethod = isSameEntry ? 'replace' : 'push';
    yield call(history[navigationMethod], {
      pathname: PATHNAME_REPORT,
      search: newSearch,
    });
    yield put(updateReportUrlQuerySuccess());
  } catch (error) {
    console.error(error);
  }
}

export function* updateReportUrlFlow({
  payload,
}: {
  payload?: {isSameEntry: boolean};
}) {
  const isSameEntry = payload?.isSameEntry;

  yield call(ensureReportParamsConsistencyBeforeSpecFetch);
  yield put(loadDrilldownLabelsRequest());

  yield put(loadReportSpecRequest());
  yield take(loadReportSpecSuccess);

  const updatedParams = yield* call(
    ensureReportParamsConsistencyAfterSpecFetch
  );

  yield call(cancelOnStaleReport(updateUrlQuery), updatedParams, isSameEntry);
}
export function* watchUpdateReportUrlFlow() {
  yield takeLatest(
    loadUpdateReportUrlRequest,
    cancelOnReportUnmount(safe(updateReportUrlFlow))
  );
}

export function* setReportParamsUrl(params: ReportParams) {
  yield put(setReportParams(params));
  yield put(loadUpdateReportUrlRequest({isSameEntry: true}));
  yield take(updateReportUrlQuerySuccess);
}

export function* updateReportParamsUrl(params: Partial<ReportParams>) {
  yield put(updateReportParams(params));
  yield put(loadUpdateReportUrlRequest());
  yield take(updateReportUrlQuerySuccess);
}

export function* initReportFlow() {
  try {
    yield put(loadReportFiltersRequest());
    yield take(loadReportFiltersSuccess);
    const filters = yield* select(selectRawFilters);
    const defaultParams = getEnabledFilterDefaultParams(filters);
    const filtersOptions = yield* select(selectFiltersOptions);
    const defaultUrl = yield* select(selectDefaultUrl);
    const originalUrl = history.location.search || defaultUrl!;
    const query = parseSearch(originalUrl) as Query;

    if (isEmpty(query)) {
      // redirect or delete entire block
      console.error('Somehow report params were empty');
    }
    if (query.debug === 'true') {
      yield put(setReportDebugMode(true));
    }

    const newParams = parseReportQuery(filters, filtersOptions, query);

    const applyDefaultsQuickParams = defaults(defaultParams);
    const fullParams = update('quick', applyDefaultsQuickParams, newParams);

    yield* call(validateReportParams, fullParams);

    yield* call(setReportParamsUrl, fullParams);

    yield* call(refreshReportUrl, originalUrl);

    yield put(loadReportDataRequest());
    yield put(initReportSuccess());
  } catch (error) {
    console.error(error);
    yield put(initReportFailure(error));
  }
}

export function* watchInitReportFlow() {
  yield takeLatest(
    initReportRequest,
    cancelOnReportUnmount(safe(initReportFlow))
  );
}

function* getFiltersWithOptionsAsync() {
  const filters = yield* select(selectRawFilters);
  const filtersOptions = yield* select(selectFiltersOptions);

  if (isPopulated(filtersOptions)) {
    return {filters, filtersOptions};
  }
  yield take(loadReportFiltersSuccess);

  return {
    filters: yield* select(selectRawFilters),
    filtersOptions: yield* select(selectFiltersOptions),
  };
}
export function* navigateToReportFlow({payload: url}: {payload: string}) {
  yield put(closeAutomationPanel());
  const {filters, filtersOptions} = yield getFiltersWithOptionsAsync();
  const defaultParams = getEnabledFilterDefaultParams(filters);

  const newQuery = parseSearch(url) as Query;
  const newParams = parseReportQuery(filters, filtersOptions, newQuery);
  const applyDefaultsQuickParams = defaults(defaultParams);
  const fullParams = update('quick', applyDefaultsQuickParams, newParams);

  yield* call(setReportParamsUrl, fullParams);

  yield* call(refreshReportUrl, url);

  yield put(loadReportDataRequest());
}
export function* watchNavigateToReportFlow() {
  yield takeLatest(
    navigateToReport,
    cancelOnReportUnmount(safe(navigateToReportFlow))
  );
}

export function* applyReportParamsFlow({
  payload,
}: {
  payload: {
    params: Partial<ReportParams>;
    skipDataReloading: boolean;
  };
}) {
  try {
    let shouldUpdateActiveReport = false;

    const isPanelOpen = yield* select(selectIsAutomationPanelOpen);
    const activeReport = yield* select(selectActiveReport);

    const shouldAsk = isPanelOpen && Boolean(activeReport);
    if (shouldAsk) {
      shouldUpdateActiveReport = yield showConfirmationDialog(
        getUpdateParamDialogParams()
      );
      if (!shouldUpdateActiveReport) {
        yield put(closeAutomationPanel());
      }
    }

    const paramsToUpdate = activeReport
      ? addParentReportId(payload.params, activeReport.id)
      : payload.params;

    yield call(updateReportParamsUrl, paramsToUpdate);

    if (shouldUpdateActiveReport) {
      const {
        location: {search},
      } = history;
      const url = trimParentReportId(search);
      yield put(
        loadUpdateReportRequest({
          id: activeReport!.id,
          url,
        })
      );
      yield put(removeParentReportId());
    }

    if (!payload.skipDataReloading) {
      yield put(loadReportDataRequest());
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchApplyReportParamsFlow() {
  yield takeEvery(applyReportParams, safe(applyReportParamsFlow));
}

export function* loadDrilldownLabelsFlow() {
  const generalParams = yield* select(selectGeneralParams);
  const drillName = generalParams.focus ? 'focus' : 'drilldown';
  const parsedDrill = convertDrilldown(generalParams[drillName]);
  const drilldownParams = flow(
    reduce((acc, {level, value}) => ({...acc, [level]: value}), {}),
    omitBy(isNaN)
  )(parsedDrill) as Dictionary<string>;

  if (isPopulated(drilldownParams)) {
    const {drilldown: drilldownLabels} = yield* call(
      fetchDrilldownLabels,
      drilldownParams
    );
    const sanitizedDrilldownLabels = omitBy(isNil, drilldownLabels);
    yield put(loadDrilldownLabelsSuccess(sanitizedDrilldownLabels));
  }
}
export function* watchLoadDrilldownLabelsFlow() {
  yield takeLatest(loadDrilldownLabelsRequest, safe(loadDrilldownLabelsFlow));
}

export function* approveAttrChanges(
  changes: UpdateAttributeModel[]
): Generator {
  const moneyAttrChanges = changes.filter(({type}) => isMoneyAttribute(type));
  const majorChanges = moneyAttrChanges.map(change => {
    const {
      attribute_slug,
      value_from: {amount: oldValueString},
      value_to: {amount: newValueString},
    } = change;
    const oldValueNum = parseFloat(oldValueString.replace(/[^0-9.]/g, ''));
    const newValueNum = parseFloat(newValueString.replace(/[^0-9.]/g, ''));
    const plusOrMinus = RegExp(/[+-]/g);
    const isPercentage = newValueString.endsWith('%');
    const isRelative = plusOrMinus.test(newValueString);

    if (isPercentage) {
      return hasPercentageChange(
        newValueString,
        newValueNum,
        oldValueNum,
        attribute_slug
      );
    }
    if (isRelative) {
      return hasRelativeChange(
        newValueString,
        newValueNum,
        oldValueNum,
        attribute_slug
      );
    }
    return hasAbsoluteChange(
      newValueString,
      newValueNum,
      oldValueNum,
      attribute_slug
    );
  });
  const allMajorChanges = remove(isEmpty)(majorChanges);
  const largestChange = orderBy('changeAmount', 'desc', allMajorChanges);

  if (isPopulated(largestChange)) {
    const {body, values} = largestChange[0];
    return yield showConfirmationDialog(
      getMajorChangesDialogParams({body, values})
    );
  }

  return true;
}
