import React from 'react';

import {hot} from 'react-hot-loader';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import {Store} from 'redux';

import {I18nLoader} from 'i18n';

import {history} from './browserHistory';
import {Routes} from './Routes';

type Props = {
  store: Store;
};

const Root = ({store}: Props) => (
  <Provider store={store}>
    <I18nLoader>
      <Router history={history}>
        <Routes />
      </Router>
    </I18nLoader>
  </Provider>
);

export default hot(module)(Root);
