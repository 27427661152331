import React, {memo} from 'react';

import {cssRow} from 'common-components/ModernGrid/ModernGrid.styles';

import {MODERNGRID_ROW_TEST_ID} from '../../../constants';
import BodyCell from './BodyCell';

import type {
  ExtendedColumnData,
  RowData,
} from 'common-components/ModernGrid/types';

export type BodyRowProps = {
  row?: RowData;
  columns: ExtendedColumnData[];
  rowIndex: number;
};
const BodyRow = ({row, columns, rowIndex}: BodyRowProps) => (
  <div
    className={cssRow}
    data-testid={MODERNGRID_ROW_TEST_ID}
    data-pendoid={MODERNGRID_ROW_TEST_ID}
  >
    {columns.map(columnData => (
      <BodyCell
        key={columnData.key}
        column={columnData}
        row={row}
        rowIndex={rowIndex}
      />
    ))}
  </div>
);

export default memo(BodyRow);
