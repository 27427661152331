import {ActionPattern, cancel, take} from '@redux-saga/core/effects';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {isArray} from 'lodash/fp';

import {history} from 'browserHistory';
import {SENTRY_CATEGORY_TAGS} from 'constants/globalVariables';
import {
  ERROR_STATUS_REPORTING_THRESHOLD,
  NoConnectionError,
  NetworkError,
} from 'utils/apiUtils';
import {reportError} from 'utils/errorReportingUtils';
import {call, fork, cancelled} from 'utils/libs/TypedReduxSaga';

import type {Dictionary} from 'types/utils';

export type Fn = (...args: any[]) => unknown;

export function safe(saga: Fn) {
  return function* safeInternal(...args: unknown[]) {
    try {
      yield* call(saga, ...args);
    } catch (e) {
      if (
        e instanceof NoConnectionError ||
        (e as NetworkError)?.response?.status < ERROR_STATUS_REPORTING_THRESHOLD
      ) {
        return;
      }

      Sentry.withScope(scope => {
        scope.setTag('category', SENTRY_CATEGORY_TAGS.REDUX_SAGA_SAFE);
        reportError(e);
      });
    }
  };
}

export function* cancelAwareCall(
  fn: Fn,
  data: unknown,
  config: Dictionary<unknown> = {},
  ...rest: unknown[]
) {
  const source = axios.CancelToken.source();

  try {
    return yield* call(
      fn,
      data,
      {cancelToken: source.token, ...config},
      ...rest
    );
  } finally {
    if (yield* cancelled()) {
      source.cancel();
    }
  }
}

export const callOnlyWithinPathnames =
  (allowedPathnames?: Array<string>) => (saga: Fn) =>
    function* internalCallOnlyWithinPathnames(...args: unknown[]) {
      if (
        isArray(allowedPathnames) &&
        !allowedPathnames.includes(history.location.pathname)
      ) {
        return;
      }
      yield call(saga, ...args);
    };

export const cancelOnDispatched =
  (cancelTriggerAction: ActionPattern) => (saga: Fn) =>
    function* internalCancelOnDispatched(...args: unknown[]) {
      const task = yield* fork(saga, ...args);
      yield take(cancelTriggerAction);
      yield cancel(task);
    };
