import React, {useCallback} from 'react';

import {css} from '@linaria/core';
import {TextField} from '@material-ui/core';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import cx from 'classnames';
import {noop} from 'lodash/fp';

import {SelectNew} from 'common-components';
import {IntlShape, useIntl} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';
import {createAttrValueRegExp} from 'modules/report/utils';

import type {AttributeFormatting} from 'modules/common/types';
import type {BE_types} from 'types/backendServicesTypes';

const cssInput = css`
  & .MuiInput-input {
    color: black;
    outline: none;
    padding-top: 10px;
  }
`;
const cssSidePanelInput = css`
  & .MuiInputBase-input {
    color: white;
    width: 320px;
  }
`;
const cssDisabledLabelDarkBackground = css`
  color: white;
`;
const cssInputWrapper = css`
  margin-bottom: 16px;
  width: 100%;
`;
const cssSelectInput = css`
  position: relative;
  min-width: 186px;

  @media all and (max-width: 420px) {
    width: 186px;
  }
`;
const cssSidePanelSelectInput = css`
  position: relative;
  width: 320px;
  margin-bottom: 16px;
`;
const cssLabel = css`
  font-size: 1.1em;
  margin-right: 1em;
`;
const cssAttributeLabel = css`
  text-transform: uppercase;
  color: #96a1bd;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
`;
const cssAttributeContainer = css`
  display: grid;
  grid-template-rows: auto 1fr;

  &:not(:last-child) {
    margin-right: 2em;
  }

  @media all and (max-width: 420px) {
    margin-bottom: 25px;
  }
`;
const cssDisabledInput = css`
  background-color: transparent;
  border-bottom-color: ${styleVariables.disabledColor};

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${styleVariables.disabledColor};
  }
`;
const cssDisabledLabel = css`
  color: ${styleVariables.disabledColor};
`;
const cssFrontTooltip = css`
  &.MuiTooltip-popper {
    margin-bottom: 5px;
    z-index: 1520;
  }
`;

const craftMoneyAttributeMessage = (attrType: string, intl: IntlShape) => {
  if (attrType === 'bid') {
    return intl.formatMessage({
      id: 'aa.reportActionPanel.tooltip.bid',
      defaultMessage: 'e.g. +0.20, -10%, 2.0',
    });
  }
  if (attrType === 'daily_budget') {
    return intl.formatMessage({
      id: 'aa.reportActionPanel.tooltip.daily_budget',
      defaultMessage: 'e.g. +50, -10%, 50',
    });
  }
  return '';
};

const FrontTooltip = ({classes, ...props}: TooltipProps) => (
  <Tooltip
    classes={{
      ...classes,
      popper: cx(cssFrontTooltip, classes?.popper),
    }}
    {...props}
  />
);

type InputProps = {
  id: string;
  name: string;
  placeholder?: string;
  value?: string;
  setValue?: (newValue: string) => void;
  isReadonly?: boolean;
  isSidePanel?: boolean;
};

const Input = ({
  id,
  name,
  value = '',
  setValue = noop,
  isReadonly = false,
  placeholder,
  isSidePanel,
}: InputProps) => {
  const intl = useIntl();
  const inputPattern = createAttrValueRegExp();
  const tooltipMessage = craftMoneyAttributeMessage(id, intl);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    inputPattern.test(e.target.value) && setValue(e.target.value);

  return (
    <FrontTooltip
      arrow
      placement='top'
      disableHoverListener
      title={tooltipMessage}
      disableFocusListener={isSidePanel}
    >
      <div className={isSidePanel ? cssInputWrapper : ''}>
        <TextField
          id={id}
          type='text'
          autoComplete='off'
          value={value}
          placeholder={placeholder}
          disabled={isReadonly}
          className={cx(cssInput, {
            [cssDisabledInput]: isReadonly,
            [cssSidePanelInput]: isSidePanel,
          })}
          size='small'
          name={name}
          onChange={handleChange}
        />
      </div>
    </FrontTooltip>
  );
};

type AttributeProps = {
  pendoId?: string | null;
  id: BE_types['AttributeSlug'];
  readonly: boolean;
  formatting: AttributeFormatting;
  statusOptions?: string[];
  isSidePanel?: boolean;
  isClearable?: boolean;
} & InputProps;
export const AttributeField = ({
  pendoId = null,
  id,
  readonly,
  formatting,
  statusOptions = [],
  isSidePanel = false,
  isClearable = false,
  ...inputProps
}: AttributeProps) => {
  const intl = useIntl();
  const placeholderMessage = craftMoneyAttributeMessage(id, intl);

  const {name, value, setValue} = inputProps;

  const readOnlyLabel = readonly
    ? intl.formatMessage({
        id: 'aa.label.readOnly',
        defaultMessage: 'Read only',
      })
    : undefined;
  const selectLabel = intl.formatMessage({
    id: 'aa.label.select',
    defaultMessage: 'Select',
  });
  const selectMessage = readonly ? readOnlyLabel : selectLabel;
  const placeholder = isSidePanel ? placeholderMessage : readOnlyLabel;
  const handleChange = useCallback(
    (newValue?: string) => {
      setValue?.(newValue || '');
    },
    [setValue]
  );

  const shouldShowInput = formatting === 'money' || formatting === 'percent';

  return (
    <div data-pendoid={pendoId} className={cssAttributeContainer}>
      <label
        htmlFor={id}
        className={cx(cssLabel, {
          [cssAttributeLabel]: isSidePanel,
          [cssDisabledLabel]: readonly && !isSidePanel,
          [cssDisabledLabelDarkBackground]: readonly && isSidePanel,
        })}
      >
        {name}
      </label>

      {shouldShowInput ? (
        <Input
          {...inputProps}
          id={id}
          isReadonly={readonly}
          placeholder={placeholder}
          isSidePanel={isSidePanel}
        />
      ) : (
        <SelectNew
          id={id}
          isClearable={isClearable}
          hasDarkBackground={isSidePanel}
          style={{minWidth: 186}}
          className={cx(cssSelectInput, {
            [cssSidePanelSelectInput]: isSidePanel,
          })}
          isDisabled={readonly}
          plainValue
          options={statusOptions}
          value={value}
          placeholder={selectMessage}
          onChange={handleChange}
        />
      )}
    </div>
  );
};
