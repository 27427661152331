import React, {FC} from 'react';

import {styled} from '@linaria/react';
import {Button} from '@material-ui/core';

import {styleVariables} from 'constants/globalVariables';

import {Icon} from '@adjust/components';

const SButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    color: white;
    border-radius: 4px;

    &.Mui-disabled {
      border: 1px solid ${styleVariables.colorWarmGreyOne};
      color: white;
      opacity: 0.5;
    }
  }
`;

type RulesPanelButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  pendoId?: string;
};
export const RulesPanelButton: FC<RulesPanelButtonProps> = ({
  disabled = false,
  onClick,
  children,
  pendoId,
}) => (
  <Button
    color='default'
    variant='contained'
    type='button'
    disabled={disabled}
    onClick={onClick}
    fullWidth
    data-pendoid={pendoId}
  >
    {children}
  </Button>
);

const TrashIcon = (
  <Icon aria-label='Delete automation' name='Trash' color='ColorWhite' />
);

type RemoveButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  pendoId?: string;
};
export const RemoveButton: FC<RemoveButtonProps> = ({
  onClick,
  children,
  pendoId = null,
  ...props
}) => (
  <SButton
    color='secondary'
    variant='outlined'
    type='button'
    onClick={onClick}
    startIcon={TrashIcon}
    data-pendoid={pendoId}
    {...props}
  >
    {children}
  </SButton>
);
