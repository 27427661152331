import React, {useRef, useState} from 'react';

import cx from 'classnames';

import {ExpandableContext} from '../ExpandableContext';
import {cssRolledUpWrapper} from '../ExpandableGrid.styles';

type ResizingContainerProps = {
  minimumVisibleRowsThreshold: number;
};
export const ResizingContainer: React.FC<ResizingContainerProps> = ({
  minimumVisibleRowsThreshold,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={cx({
        [cssRolledUpWrapper]: !isExpanded,
      })}
    >
      <ExpandableContext.Provider
        value={{containerRef, setIsExpanded, minimumVisibleRowsThreshold}}
      >
        {children}
      </ExpandableContext.Provider>
    </div>
  );
};
