import {
  filter,
  reject,
  flow,
  flatMap,
  sortBy,
  compact,
  reduce,
  overEvery,
  get,
  isEqual,
  uniq,
} from 'lodash/fp';

import type {Filter, Filters} from 'modules/common/types';
import type {Dictionary} from 'types/utils';

export const processFilters = (enabledFilters: Filters, canViewAttr: boolean) =>
  flow(
    reject<Filter>(
      ({options_name}) =>
        (!canViewAttr && isEqual(options_name, ['attributes'])) ||
        // to avoid ignoring "Search" filter on history page,
        // we compare with empty array instead of using `isEmpty`
        isEqual(options_name, [])
    ),
    sortBy<Filter>('order')
  )(enabledFilters);

export const getEnabledFilterDefaultParams = flow(
  filter(overEvery([get('enabled'), get('default')])),
  reduce<Filter & {default: string}, Dictionary<string>>(
    (acc, {filter_param, default: defaultParam}) => ({
      ...acc,
      [filter_param]: defaultParam,
    }),
    {}
  )
);

export const extractEnabledFilterOptionsNames: (a: any) => string[] = flow(
  filter<Filter>('enabled'),
  flatMap('options_name'),
  compact,
  uniq
);
