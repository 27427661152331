import {isUndefined} from 'lodash/fp';

import {
  DEFAULT_MIN_AMOUNT,
  DEFAULT_MAX_AMOUNT,
  DEFAULT_MIN_CV,
  DEFAULT_MAX_CV,
  DEFAULT_CUT_OFF,
  DEFAULT_CURRENCY,
} from '../constants';
import {convert} from '../utils';

import {FormState, CVInputs} from '../types';

export const transformInputs = ({
  revenue_min,
  revenue_max,
  cv_min,
  cv_max,
  cap_range,
  app_store_id,
  cut_off_period,
  reporting_currency,
  errors,
}: FormState): CVInputs => ({
  revenue_min: convert(revenue_min, DEFAULT_MIN_AMOUNT),
  revenue_max: convert(revenue_max, DEFAULT_MAX_AMOUNT),
  cv_min: convert(cv_min, DEFAULT_MIN_CV, Math.floor),
  cv_max: convert(cv_max, DEFAULT_MAX_CV, Math.floor),
  ...(!cap_range ? {} : {cap_range: Number(cap_range)}),
  ...(isUndefined(app_store_id) ? {} : {app_store_id}),
  ...(isUndefined(reporting_currency)
    ? {}
    : {reporting_currency: reporting_currency || DEFAULT_CURRENCY}),
  ...(isUndefined(cut_off_period)
    ? {}
    : {cut_off_period: convert(cut_off_period, DEFAULT_CUT_OFF, Math.floor)}),
  errors,
});
