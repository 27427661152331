import React, {useCallback, useMemo, useEffect, FC} from 'react';

import {css} from '@linaria/core';
import {
  compact,
  every,
  find,
  first,
  flow,
  isEmpty,
  isEqual,
  isNil,
  keys,
  map,
  mapValues,
  parseInt,
  pick,
  pickBy,
  set,
  split,
  values,
} from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';

import {ModernGrid} from 'common-components';
import {
  useSelectableRows,
  SelectableRowsContext,
  SELECTABLE_COLUMN_KEY,
} from 'common-components/ModernGrid/extensions/SelectableRows';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {updateCustomerColumnSizeRequest} from 'modules/common/actions';
import {selectCanViewAttr} from 'modules/common/reducers/customerConfig';
import {selectReportColumnSizes} from 'modules/common/reducers/customerPreferences';
import {applyReportParams} from 'modules/report/actions';
import {
  selectFilteredReportData,
  selectReportIsLoading,
  selectGeneralParams,
  selectQuickParams,
  selectColumnParams,
  selectReportTotalData,
  selectAttrData,
  selectFiltersOptions,
  selectIsAutomationPanelOpen,
  selectColumnFiltersInputValues,
  selectAllVisibleDimensions,
  selectReportSpecDimensions,
  selectReportSpecMetrics,
} from 'modules/report/reducers';
import {
  updateColumnOrder,
  ColumnFiltersContext,
  getColumnsWithSizes,
} from 'modules/report/utils';
import {getNewDrilldown} from 'utils/drilldown';
import {isPopulated} from 'utils/lodash+';

import {AttrChangesActionPanel} from './ActionPanel';
import {ChartsContainer as Charts} from './Chart/ChartsContainer';
import {INDEX_COLUMN_KEY} from './columns/IndexColumn/constants';
import {useIndexColumn} from './columns/IndexColumn/useIndexColumn';
import {ReportNullState} from './ReportNullState';
import {useColumnFilters} from './useColumnFilters';

import {DrillableDimension} from 'modules/report/components/columns/DrillableDimension';
import {ReportAttributeCell} from 'modules/report/components/columns/ReportAttributeCell';
import {
  FilterableHeader,
  SelectableHeader,
} from 'modules/report/components/FilterableHeader';
import FormatCell from 'modules/report/components/FormatCell';

import {
  ColumnResizeCallback,
  ColumnData,
  RenderHeaderFn,
} from 'common-components/ModernGrid/types';
import {FilterOption} from 'modules/common/types';

const REPORT_TABLE_KEY = 'report';

const cssTableContainer = css`
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  margin-top: 4px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  min-height: 0;
  overflow: auto;
  flex: 1;
`;

export const ReportTable = () => {
  const dispatch = useDispatch();

  const generalParams = useSelector(selectGeneralParams);
  const quickParams = useSelector(selectQuickParams);
  const columnParams = useSelector(selectColumnParams);
  const columnInitialValues = useSelector(selectColumnFiltersInputValues);
  const dataLoading = useSelector(selectReportIsLoading);
  const dimensions = useSelector(selectAllVisibleDimensions);
  const total = useSelector(selectReportTotalData);
  const attributes = useSelector(selectAttrData);
  const canViewAttr = useSelector(selectCanViewAttr);
  const lockedMode = useSelector(selectIsAutomationPanelOpen);
  const backendDimensions = useSelector(selectReportSpecDimensions);
  const selectedMetrics = useSelector(selectReportSpecMetrics);
  const {dimensions: allDimensions, attributes: attributesOptions} =
    useSelector(selectFiltersOptions);

  const {index: indexQuickParam, period_over_period: popQuickParam} =
    quickParams;
  const {
    sort: generalParamsSort,
    order: srcColumnsOrder,
    drilldown,
    focus,
  } = generalParams;
  const selectedAttributes = useMemo(
    () => quickParams.attributes || [],
    [quickParams.attributes]
  );

  const hasPopParam: boolean = useMemo(
    () => isPopulated(popQuickParam),
    [popQuickParam]
  );
  const hasIndexParam: boolean = useMemo(
    () => isPopulated(indexQuickParam),
    [indexQuickParam]
  );
  const hasSelectableColumn = useMemo(
    () => isPopulated(selectedAttributes),
    [selectedAttributes]
  );

  // TODO: simplify and move logic inside MG hooks
  const columnsOrder = useMemo(() => {
    const selectableColumnPosition = 0;
    const indexColumnPosition = Number(hasSelectableColumn);
    const orderOffset = Number(hasSelectableColumn) + Number(hasIndexParam);

    return {
      ...(hasSelectableColumn
        ? {[SELECTABLE_COLUMN_KEY]: selectableColumnPosition}
        : {}),
      ...(hasIndexParam ? {[INDEX_COLUMN_KEY]: indexColumnPosition} : {}),
      ...mapValues(order => order + orderOffset, srcColumnsOrder),
    };
  }, [hasSelectableColumn, hasIndexParam, srcColumnsOrder]);

  const intl = useIntl();
  const columnSizes = useSelector(selectReportColumnSizes);
  const data = useSelector(selectFilteredReportData);

  const handleAddDrilldown = useCallback(
    (columnKey, attr_dependency, drilldownKey) => {
      const currentDrilldown = focus || drilldown;
      const newDrilldownValue = getNewDrilldown({
        columnKey,
        attr_dependency,
        drilldownKey,
        currentDrilldown,
      });

      dispatch(
        applyReportParams({
          params: {
            general: {
              [focus ? 'focus' : 'drilldown']: newDrilldownValue,
            },
          },
        })
      );
    },
    [drilldown, focus, dispatch]
  );

  const formatTitle = useCallback(
    (key, column) => {
      const hasColumnFilter = columnInitialValues[column];

      const title = intl.formatMessage({id: key});

      return hasColumnFilter ? `${title}*` : title;
    },
    [columnInitialValues, intl]
  );

  // ControlCenter sandbox (/report/sandbox) should be changed on any attribute shape or logic change
  const attributeColumns: ColumnData[] = useMemo(() => {
    if (!canViewAttr) return [];

    const renderHeader: RenderHeaderFn = ({
      value = '',
      key: columnKey,
      headerAlign,
      isMovableColumn = false,
    }) => (
      <SelectableHeader
        columnType='attribute'
        align={headerAlign}
        labelText={value as string}
        columnKey={columnKey}
        isMovableColumn={isMovableColumn}
      />
    );

    return selectedAttributes.map((attrKey: string) => {
      const {name, short_name, formatting} =
        find<FilterOption>(
          {
            id: attrKey,
          },
          attributesOptions
        ) || {};

      return {
        key: attrKey,
        title: formatTitle(short_name || name || attrKey, attrKey),
        align: formatting === 'money' ? 'right' : 'left',
        isSortable: false,
        orderCollection: 1,
        render: ReportAttributeCell,
        defaultWidth: formatting === 'select' ? 140 : undefined,
        renderHeader,
      };
    });
  }, [selectedAttributes, formatTitle, attributesOptions, canViewAttr]);

  const dimensionColumns: ColumnData[] = useMemo(
    () =>
      dimensions.map((dim: string) => {
        const dimInfo = find({id: dim}, allDimensions);
        const drilldownKey = find({slug: dim}, backendDimensions)?.navigation;

        return {
          key: dim,
          title: formatTitle(dimInfo?.short_name || dimInfo?.name || dim, dim),
          align: 'left',
          isFixed: !hasIndexParam,
          orderCollection: 0,
          defaultWidth: ['campaign', 'adgroup', 'creative'].includes(dim)
            ? 300
            : 150,
          render: ({value, key: columnKey, rowData}) => (
            <DrillableDimension
              value={value}
              columnKey={columnKey}
              drilldownKey={drilldownKey}
              attr_dependency={rowData?.attr_dependency}
              handleAddDrilldown={handleAddDrilldown}
            />
          ),
          renderHeader: ({
            value,
            headerAlign,
            key: columnKey,
            headerClickHandler,
            isMovableColumn,
          }) => (
            <FilterableHeader
              initialValue={columnInitialValues[dim]}
              columnType='dimension'
              align={headerAlign}
              columnKey={columnKey}
              headerClickHandler={headerClickHandler}
              isMovableColumn={isMovableColumn}
              labelText={value as string}
            />
          ),
        } as ColumnData;
      }),
    [
      dimensions,
      allDimensions,
      backendDimensions,
      formatTitle,
      hasIndexParam,
      handleAddDrilldown,
      columnInitialValues,
    ]
  );
  const metricsColumns: ColumnData[] = useMemo(
    () =>
      selectedMetrics.map(({id: metricId, name, short_name, formatting}) => ({
        key: metricId,
        title: formatTitle(short_name || name || metricId, metricId),
        isArrangeable: !hasPopParam,
        align: 'right',
        orderCollection: 2,
        initialSortDirection: 'desc',
        maxWidth: 300,
        render: ({value}) => (
          <FormatCell value={value} format={formatting || 'integer'} />
        ),
        renderTotal: ({value}) => (
          <FormatCell value={value} format={formatting || 'integer'} />
        ),
        renderHeader: ({
          value,
          headerAlign,
          key: columnKey,
          headerClickHandler,
          isMovableColumn,
        }) => (
          <FilterableHeader
            initialValue={columnInitialValues[metricId]}
            columnType='metric'
            align={headerAlign}
            columnKey={columnKey}
            headerClickHandler={headerClickHandler}
            isMovableColumn={isMovableColumn}
            labelText={value as string}
          />
        ),
      })),
    [selectedMetrics, formatTitle, hasPopParam, columnInitialValues]
  );

  const {indexColumn: IndexColumn} = useIndexColumn({
    indexDimensions: indexQuickParam,
  });

  const tableColumns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = compact([
      hasIndexParam ? IndexColumn : null,
      ...dimensionColumns,
      // ...(debug ? attributesDeclaration.flatMap(col => getDebugColumns(col.key)) : attributeColumns),
      ...attributeColumns,
      ...metricsColumns,
    ]);

    const columnsResizable = map(
      set('isResizable', true),
      columns
    ) as ColumnData[];
    const columnsWithSizes = getColumnsWithSizes(columnsResizable, columnSizes);

    return columnsWithSizes;
  }, [
    hasIndexParam,
    dimensionColumns,
    attributeColumns,
    metricsColumns,
    columnSizes,
    IndexColumn,
  ]);

  const handleColumnResize: ColumnResizeCallback = useCallback(
    (columnKey, columnSize) =>
      dispatch(
        updateCustomerColumnSizeRequest({
          tableKey: REPORT_TABLE_KEY,
          columnKey,
          columnSize,
        })
      ),
    [dispatch]
  );

  const getFirstSort = flow(split(','), first);
  const sort = getFirstSort(generalParamsSort);

  const handleSortChange = useCallback(
    newSortKey =>
      dispatch(
        applyReportParams({
          params: {
            general: {
              sort: newSortKey,
            },
          },
        })
      ),
    [dispatch]
  );

  const handleMoveColumn = useCallback(
    ({oldIndex, newIndex}) => {
      const updatedColumnOrder = updateColumnOrder(
        columnsOrder,
        oldIndex,
        newIndex
      );

      const shouldUpdateOrder = !isEqual(updatedColumnOrder, columnsOrder);
      if (shouldUpdateOrder) {
        dispatch(
          applyReportParams({
            params: {general: {order: updatedColumnOrder}},
            skipDataReloading: true,
          })
        );
      }
    },
    [columnsOrder, dispatch]
  );

  const handleResetColumnFilters = useCallback(() => {
    dispatch(applyReportParams({params: {column: []}}));
  }, [dispatch]);

  const handleResetDrilldown = useCallback(() => {
    dispatch(applyReportParams({params: {general: {drilldown: ''}}}));
  }, [dispatch]);

  const TableNullState: FC = useMemo<FC>(
    () => () =>
      (
        <ReportNullState
          showResetColumnFiltersButton={isPopulated(columnParams)}
          onResetColumnFilters={handleResetColumnFilters}
          showResetDrilldownButton={isPopulated(drilldown)}
          onResetDrilldown={handleResetDrilldown}
        />
      ),
    [columnParams, drilldown, handleResetColumnFilters, handleResetDrilldown]
  );

  const readOnlyRows = useMemo(() => {
    if (isEmpty(attributes) || isNil(attributes)) {
      return [];
    }
    const attrsFromTable = map(row => {
      const attrHashes = pick(selectedAttributes, row);
      const attrReadonly = mapValues(hash => {
        const attrValue = attributes[hash];
        return attrValue.readonly;
      }, attrHashes);
      return every(Boolean, values(attrReadonly));
    }, data);
    const disabledRows = keys(pickBy(Boolean, attrsFromTable));
    return map(parseInt(10), disabledRows);
  }, [attributes, selectedAttributes, data]);

  const columnFilters = useColumnFilters();
  const {setColumnFilterDrafts} = columnFilters;

  const selectableRows = useSelectableRows({
    rows: data,
    disabledRows: readOnlyRows,
    lockedMode,
  });

  const columns = useMemo(
    () =>
      hasSelectableColumn
        ? [selectableRows.checkboxColumn, ...tableColumns]
        : tableColumns,
    [selectableRows.checkboxColumn, hasSelectableColumn, tableColumns]
  );

  // flush drafts if user change settings
  useEffect(() => {
    setColumnFilterDrafts({});
  }, [setColumnFilterDrafts, quickParams, generalParams]);

  return (
    <SelectableRowsContext.Provider value={selectableRows}>
      <ColumnFiltersContext.Provider value={columnFilters}>
        <Charts />
        <ModernGrid
          keyProp='hash'
          className={cssTableContainer}
          columns={columns}
          isLoading={dataLoading}
          data={selectableRows.rows}
          totalData={total || {}}
          onSortChange={handleSortChange}
          sortKey={sort}
          onMoveColumn={handleMoveColumn}
          columnsOrder={columnsOrder}
          headerHeight={50}
          nullState={TableNullState}
          showHeaderInNullState
          onColumnResize={handleColumnResize}
        />
      </ColumnFiltersContext.Provider>
      <AttrChangesActionPanel />
    </SelectableRowsContext.Provider>
  );
};
