import React, {useState, useCallback, useMemo} from 'react';

import UILink from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import WarningIcon from '@material-ui/icons/Warning';
import {map, find, size, isEmpty, isNil, filter, sortBy} from 'lodash/fp';
import escapeRegex from 'regex-escape';

import ButtonSelect from 'common-components/ButtonSelect';
import SpaceFiller from 'common-components/SpaceFiller';
import Title from 'common-components/Title';
import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {styleVariables} from 'constants/globalVariables';
import {isPopulated} from 'utils/lodash+';

const styles = {
  topBar: {
    display: 'flex',
    alignItems: 'baseline',
  },
  groupTitle: {
    fontSize: 14,
    fontWeight: styleVariables.fontWeightBold,
  },
  block: {
    marginTop: 10,
  },
  selectLinksInTopBar: {
    marginLeft: 8,
  },
  selectLink: {
    cursor: 'pointer',
    marginRight: 8,
  },
  validationError: {
    marginLeft: '20px',
    fontSize: '14px',
  },
  warningIcon: {
    verticalAlign: 'bottom',
    color: '#f7bc2f',
    width: 18,
    height: 21,
    marginRight: 8,
  },
};

const NO_GROUP = 'no_group';
const metricSortCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

function orderAwareNaturalComparator(m1, m2) {
  if (!isNil(m1.order) && !isNil(m2.order)) {
    return m1.order - m2.order;
  }
  if (!isNil(m1.order) && isNil(m2.order)) {
    return -1;
  }
  if (isNil(m1.order) && !isNil(m2.order)) {
    return 1;
  }

  return metricSortCollator.compare(m1.label, m2.label);
}

// type Props = {
//   allMetrics: Array<string>,
//   groups: {
//     [string]: Array<string>
//   },
//   title?: string,
//   showTitle?: boolean,
//   showSearch?: boolean,
//   showSelectAll?: boolean,
//   value: Array<string>,
//   onChange: Array<string> => *,
//   i18n: *
// };

const matchOptions =
  matchingOptions =>
  ({value}) =>
    find({value}, matchingOptions);

const GenericMetricSelect = ({
  className,
  allMetrics,
  groups = {
    [NO_GROUP]: allMetrics,
  },
  pendoId,
  title,
  value,
  onChange,
  smallPadding = true,
  showTitle = true,
  showSearch = true,
  showSelectAll = true,
  showTrailingSelectAll = true,
  errorMessage,
}) => {
  const intl = useIntl();

  const [query, setQuery] = useState('');
  const matchingOptions = useMemo(
    () =>
      query
        ? filter(
            ({label}) => new RegExp(escapeRegex(query), 'i').test(label),
            allMetrics
          )
        : allMetrics,
    [allMetrics, query]
  );
  const handleQueryChange = useCallback(e => {
    setQuery(e.target.value);
  }, []);
  const handleSelectAll = useCallback(() => {
    onChange(map('value', matchingOptions));
  }, [matchingOptions, onChange]);
  const handleDeselectAll = useCallback(() => onChange([]), [onChange]);
  const flatGroups = map(
    ([group, metrics]) => ({
      metrics,
      group,
    }),
    Object.entries(groups)
  );

  const orderedGroups = sortBy(
    [
      ({group}) => (group === NO_GROUP ? 1 : -1),
      ({group}) => group.toLowerCase(),
    ],
    flatGroups
  );

  const selectAllLabel = intl.formatMessage({
    id: 'aa.label.selectAll',
    defaultMessage: 'Select All',
  });

  const deselectAllLabel = intl.formatMessage({
    id: 'aa.label.deselectAll',
    defaultMessage: 'Deselect All',
  });

  const selectLinksBlock = (
    <>
      <UILink
        underline='none'
        style={styles.selectLink}
        title={selectAllLabel}
        onClick={handleSelectAll}
      >
        {selectAllLabel}
      </UILink>
      <UILink
        underline='none'
        style={styles.selectLink}
        title={deselectAllLabel}
        onClick={handleDeselectAll}
      >
        {deselectAllLabel}
      </UILink>
    </>
  );

  return (
    <div className={className}>
      <div style={styles.topBar}>
        {showTitle &&
          (title || (
            <Title>
              {intl.formatMessage({
                id: 'aa.label.metrics',
                defaultMessage: 'Metrics',
              })}
            </Title>
          ))}

        {showSelectAll && (
          <div style={styles.selectLinksInTopBar}>{selectLinksBlock}</div>
        )}
        {isPopulated(errorMessage) && (
          <div style={styles.validationError}>
            <WarningIcon style={styles.warningIcon} />
            {errorMessage}
          </div>
        )}
        <SpaceFiller />

        {showSearch && (
          <TextField
            data-pendoid={`builder-${pendoId}SearchBar`}
            type='search'
            value={query}
            label={intl.formatMessage({
              id: 'aa.filters.history.search.label',
              defaultMessage: 'Search',
            })}
            onChange={handleQueryChange}
          />
        )}
      </div>

      <div data-pendoid={`builder-${pendoId}`}>
        {map(({metrics = [], group}) => {
          const sortedOptions = metrics.sort(orderAwareNaturalComparator);
          const matchingGroupOptions = filter(
            matchOptions(matchingOptions),
            sortedOptions
          );

          if (isEmpty(matchingGroupOptions)) {
            // return null;
            return (
              <span key='noMatching'>
                <FormattedMessage
                  id='aa.label.noMatchingOptions'
                  defaultMessage='No matching options'
                />
              </span>
            );
          }

          return (
            <div
              key={group}
              style={{
                marginTop: group !== NO_GROUP || size(groups) === 1 ? 10 : 30,
              }}
            >
              {group !== NO_GROUP && (
                <div style={styles.groupTitle}>{group}</div>
              )}

              <ButtonSelect
                style={styles.block}
                multiline
                smallPadding={smallPadding}
                allowMultiSelect
                allowNoSelection
                options={matchingGroupOptions}
                value={value}
                onChange={onChange}
              />
            </div>
          );
        }, orderedGroups)}
      </div>

      {showSelectAll && showTrailingSelectAll && (
        <div style={styles.block}>{selectLinksBlock}</div>
      )}
    </div>
  );
};

export default GenericMetricSelect;
