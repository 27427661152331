import {isNaN, identity, map} from 'lodash/fp';

import {DEFAULT_MAX_CV, DEFAULT_MIN_CV} from './constants';

export const formattedDate = () => new Date().toISOString().slice(0, 10);

export const download = (data: any, scheme: string) => {
  const element = document.createElement('a');
  const JSONContent = JSON.stringify(data, null, 2);
  element.setAttribute(
    'href',
    `data:application/json;charset=utf-8,${encodeURIComponent(JSONContent)}`
  );
  element.setAttribute('download', `${formattedDate()}_${scheme}.json`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};

export const roundDecimal = (value: number, places: number = 2): number => {
  const multiplier = 10 ** places;
  return Math.round(value * multiplier) / multiplier;
};
export const roundInputToPrecision = (input: string, precision: number) => {
  // HTML input value is always represented with floating point,
  // even though it can display it with a comma
  const decimalPartRegExp = /\.(\d+)/;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, decimalPart] = input.match(decimalPartRegExp) ?? '';

  if (decimalPart?.length > precision) {
    return Number(input).toFixed(precision);
  }

  return input;
};

export const convert = (
  value: string,
  fallback: number,
  modifier: (x: number) => number = identity
) => {
  const numeric = modifier(parseFloat(value));
  return isNaN(numeric) ? fallback : numeric;
};

// convert map to also include index argument
// @ts-expect-error: LodashMap type doesn't have 'convert' listed
export const mapWithIndices = map.convert({cap: false});

export const composeNegativeValueMsg = (label: string) =>
  `${label} can not be negative`;
export const composeLessThanMsg = (label: string) =>
  `${label} can not be less than maximum`;
export const composeGreaterThanMsg = (label: string) =>
  `${label} can not be greater than maximum`;
export const composeOutOfRangeMsg = (label: string) =>
  `${label} should be within ${DEFAULT_MIN_CV}-${DEFAULT_MAX_CV} range`;

// test utils
export const buildLocation = (search: string) => ({
  search,
  pathname: '',
  state: '',
  hash: '',
});
export const getRenderedJSON = (preview: HTMLElement) => {
  const generatedJSON = preview.firstChild?.textContent;
  const strippedJSON = generatedJSON?.replace(/\s/g, '');
  return strippedJSON;
};
