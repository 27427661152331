import {createReducer} from '@reduxjs/toolkit';
import {constant, filter, find, map, merge, pick} from 'lodash/fp';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';

import {selectCanViewAttr} from 'modules/common/reducers/customerConfig';
import * as HistoryActions from 'modules/history/actions/HistoryActions';
import {flattenAttributeResponse} from 'utils/attributes';
import {processFilters} from 'utils/filters';
import {prepareOpsForAttrs} from 'utils/operations';
import {combineActions} from 'utils/reduxUtils';
import {
  allWarningsSelectorFactory,
  extractAttributesFormattingsFromFiltersOptions,
} from 'utils/selectors';

import type {
  RootState,
  Filters,
  Filter,
  FiltersOptions,
  FiltersWithOptions,
  AttributeValue,
} from 'modules/common/types';

const data = createReducer<any[]>([], builder =>
  builder
    .addCase(
      HistoryActions.loadHistoryDataSuccess,
      (_, {payload: {rows}}) => rows
    )
    // optimistic update of value_to for edited changes
    .addCase(
      HistoryActions.loadEditChangesOptimisticSuccess,
      (state, {payload: editChangeRows}) =>
        map((row: any) => {
          const matchedChangeRow = find(
            {attr_change_id: row.id},
            editChangeRows
          );
          return matchedChangeRow
            ? {...row, value_to: matchedChangeRow.value_to}
            : row;
        })(state)
    )
    .addCase(HistoryActions.loadHistoryAttrsSuccess, (state, {payload}) =>
      state.map((row, idx) => ({
        ...row,
        attr: payload[row.attribute_slug].mapping[idx],
      }))
    )
    .addCase(
      HistoryActions.loadEditChangesFailure,
      (_, {payload: prevState}) => prevState
    )
    // @deprecated
    // .addCase(
    //   HistoryActions.changeAttributeFromHistorySuccess,
    //   (state, {payload: {rows}}) => [...rows, ...state]
    // )
    .addMatcher(
      combineActions([
        HistoryActions.loadApproveChangesSuccess,
        HistoryActions.loadEditChangesSuccess,
        HistoryActions.loadDiscardChangesSuccess,
        HistoryActions.loadRetryChangesSuccess,
      ]),
      (state, {payload: {rows}}) => {
        const updatedState = map(row => {
          const {name, attr, id} = row;
          const newRow = find({id}, rows);

          if (!newRow) {
            return row;
          }

          return {...newRow, name, attr};
        }, state);

        return updatedState;
      }
    )
);

const warningsReducer = createReducer<string[]>([], builder =>
  builder.addCase(
    HistoryActions.loadHistoryDataSuccess,
    (_, {payload: {warnings}}) => warnings
  )
);

interface AttributesState {
  [hash: string]: AttributeValue;
}
const attributes = createReducer<AttributesState>(
  {},
  builder =>
    builder
      .addCase(HistoryActions.loadHistoryAttrsSuccess, (_, {payload}) =>
        flattenAttributeResponse(payload)
      )
      .addCase(HistoryActions.loadHistoryDataSuccess, () => ({}))
      .addCase(
        HistoryActions.loadOpsForHistoryAttrsSuccess,
        (state, {payload}) => merge(state, prepareOpsForAttrs(payload.rows))
      )
  // @deprecated
  // .addCase(
  //   HistoryActions.changeAttributeFromHistorySuccess,
  //   (state, {payload}) => ({
  //     ...state,
  //     ...payload.attribute_changes,
  //   })
  // )
);

interface HistoryParamsState {
  [k: string]: any;
  ['date_period']: string;
}
export const initialParamsState: HistoryParamsState = {
  date_period: '-13d:-0d',
};
const params = createReducer<HistoryParamsState>(initialParamsState, builder =>
  builder
    .addCase(HistoryActions.setHistoryParams, (_, {payload}) => ({
      ...initialParamsState,
      ...payload,
    }))
    .addCase(HistoryActions.setDefaultHistoryParams, (state, {payload}) => ({
      ...initialParamsState,
      ...state,
      ...payload,
    }))
);

const isLoading = createReducer<boolean>(false, builder =>
  builder
    .addCase(HistoryActions.loadHistoryDataRequest, constant(true))
    .addCase(HistoryActions.loadHistoryDataSuccess, constant(false))
    .addCase(HistoryActions.loadHistoryDataFailure, constant(false))
);

const isInitialized = createReducer<boolean>(false, builder =>
  builder
    .addCase(HistoryActions.initHistoryRequest, constant(false))
    .addCase(HistoryActions.initHistorySuccess, constant(true))
    .addCase(HistoryActions.initHistoryFailure, constant(false))
);

const filtersReducer = createReducer<Filters>([], builder =>
  builder.addCase(HistoryActions.setFilters, (_, {payload}) => payload)
);

const filtersOptionsReducer = createReducer<FiltersOptions>({}, builder =>
  builder.addCase(HistoryActions.setFiltersOptions, (_, {payload}) => payload)
);

export default combineReducers({
  data,
  warnings: warningsReducer,
  filters: filtersReducer,
  filtersOptions: filtersOptionsReducer,
  attributes,
  params,
  isLoading,
  isInitialized,
});

export const selectHistoryAttrData = (state: RootState) =>
  state.history.attributes;

export const selectAttributeSelectorFactory = (hash: string) =>
  createSelector(selectHistoryAttrData, attrs => attrs[hash]);

export const selectHistoryParams = (state: RootState) => state.history.params;
export const selectIsHistoryDataLoading = (state: RootState) =>
  state.history.isLoading;
export const selectIsHistoryInitialized = (state: RootState) =>
  state.history.isInitialized;
export const selectRawFilters = (state: RootState) => state.history.filters;
const selectEnabledFilters = createSelector(
  selectRawFilters,
  filter<Filter>('enabled')
);
const getRawFiltersOptions = (state: RootState) => state.history.filtersOptions;

export const selectHistoryWarnings = (state: RootState) =>
  state.history.warnings;
export const getAllHistoryWarnings = allWarningsSelectorFactory(
  selectHistoryWarnings
);

export const selectFiltersOptions = getRawFiltersOptions;

export const selectAttributesFormattings = createSelector(
  selectFiltersOptions,
  extractAttributesFormattingsFromFiltersOptions
);

const selectFilters = createSelector(
  selectEnabledFilters,
  selectCanViewAttr,
  processFilters
);

export const selectFiltersWithOptions = createSelector(
  selectFilters,
  selectFiltersOptions,
  (filters, filtersOptions): FiltersWithOptions =>
    map(
      filterObj => ({
        ...filterObj,
        options: filterObj.options_name
          ? pick(filterObj.options_name, filtersOptions)
          : null,
      }),
      filters
    )
);

export const selectHistoryData = (state: RootState) => state.history.data;
