import React, {useCallback, useContext} from 'react';

import {css} from '@linaria/core';
import {IconButton, Tooltip} from '@material-ui/core';
import RemoteIcon from '@material-ui/icons/Launch';
import UndoIcon from '@material-ui/icons/Refresh';
import {lowerCase, isNil, get, toString, flow, upperFirst} from 'lodash/fp';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {SelectableRowsContext} from 'common-components/ModernGrid/extensions/SelectableRows';
import {OneLine} from 'common-components/OneLine/OneLine';
import {useIntl} from 'common-components/utils/libs/ReactIntl';
import {PATHNAME_REPORT} from 'constants/globalVariables';
import {showInformationDialog} from 'modules/common/actions';
import {
  loadRevertChangesRequest,
  loadRetryChangesRequest,
} from 'modules/history/actions/HistoryActions';
import {getRevertChangeDialogParams} from 'utils/dialog';
import {isOperationFailureStatus} from 'utils/operations';

import type {AttributeValue} from 'modules/common/types';

const cssLink = css`
  display: contents;
`;
const cssUndoIcon = css`
  transform: scaleX(-1);
`;

type Props = {
  value?: string;
  rowData: {
    status: string;
    attribute: AttributeValue;
    id: number;
    value_from?: {amount: number};
    entity_name: string;
  };
};

const getMoneyValue = flow(get('amount'), toString);
const getStatusValue = flow(get('value'), upperFirst);

const getValueFactory = (type?: string) => {
  switch (type) {
    case 'select':
      return getStatusValue;
    default:
    case 'money':
      return getMoneyValue;
  }
};

export const ActionsColumn = ({
  value,
  rowData: {status, attribute, id, value_from, entity_name: name},
}: Props) => {
  const getValue = getValueFactory(attribute?.type);
  const intl = useIntl();
  const dispatch = useDispatch();
  const {discardSelection} = useContext(SelectableRowsContext);

  const currentValueString = getValue(attribute?.value);
  const previousValueString = getValue(value_from);

  const handleRevert = useCallback(() => {
    const revertChange = () => {
      dispatch(
        loadRevertChangesRequest([
          {attr_change_id: id, current_value: attribute.value},
        ])
      );
      discardSelection();
    };
    dispatch(
      showInformationDialog(
        getRevertChangeDialogParams(revertChange, {
          slug: lowerCase(attribute.slug),
          current: currentValueString,
          previous: previousValueString,
          name: name || 'entity',
        })
      )
    );
  }, [
    attribute,
    dispatch,
    discardSelection,
    id,
    currentValueString,
    previousValueString,
    name,
  ]);

  const handleRetry = useCallback(() => {
    dispatch(loadRetryChangesRequest([id]));
    discardSelection();
  }, [discardSelection, dispatch, id]);

  const canRevertOperation =
    status === 'success' && attribute && !attribute.readonly && value_from;
  const canRetryOperation =
    isOperationFailureStatus(status) &&
    value_from &&
    attribute &&
    currentValueString === previousValueString;
  const canViewInReport = !isNil(value);

  return (
    <OneLine>
      {canRetryOperation && (
        <Tooltip
          title={intl.formatMessage({
            id: 'aa.history.actions.retryOperation',
            defaultMessage: 'Retry operation',
          })}
        >
          <IconButton
            data-pendoid='retry-operation'
            color='primary'
            onClick={handleRetry}
          >
            <UndoIcon />
          </IconButton>
        </Tooltip>
      )}

      {canRevertOperation && (
        <Tooltip
          title={intl.formatMessage({
            id: 'aa.historyChangeRevertChangeActionDesc',
            defaultMessage: 'Revert this change',
          })}
        >
          <IconButton
            data-pendoid='history-revertOperation'
            color='primary'
            onClick={handleRevert}
          >
            <UndoIcon className={cssUndoIcon} />
          </IconButton>
        </Tooltip>
      )}

      {canViewInReport && (
        <Link
          to={{pathname: PATHNAME_REPORT, search: value}}
          className={cssLink}
          target='_blank'
        >
          <Tooltip
            title={intl.formatMessage({
              id: 'aa.historyChangeViewInReportActionDesc',
              defaultMessage: 'View in report',
            })}
          >
            <IconButton data-pendoid='history-viewInReport' color='primary'>
              <RemoteIcon />
            </IconButton>
          </Tooltip>
        </Link>
      )}
    </OneLine>
  );
};
