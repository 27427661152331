import {createContext, createRef, RefObject} from 'react';

import {noop} from 'lodash/fp';

type ExpandableContextReturnType = {
  containerRef: RefObject<HTMLDivElement>;
  minimumVisibleRowsThreshold: number;
  setIsExpanded: (isExpanded: boolean) => void;
};

export const ExpandableContext = createContext<ExpandableContextReturnType>({
  containerRef: createRef<HTMLDivElement>(),
  minimumVisibleRowsThreshold: 999,
  setIsExpanded: noop,
});
