import React, {useCallback, useContext} from 'react';

import cx from 'classnames';
import {map, includes, noop, without, isEmpty, size} from 'lodash/fp';

import {ThemeContext} from 'common-components/Contexts';
import {isPopulated} from 'utils/lodash+';

import styles from './ButtonSelect.css';

// type Option = {
//   label: string,
//   value: any,
//   disabled?: boolean
// };

// type Props = BasicComponent & {|
//   theme?: 'white' | 'gray',
//   allowNoSelection?: boolean,
//   allowMultiSelect?: boolean,
//   noSpacing?: boolean,
//   noBorders?: boolean,
//   multiline?: boolean,
//   smallPadding?: boolean,
//   noWrap?: boolean,
//   options: Array<Option>,
//   value?: *[],
//   onChange?: (string[]) => void
// |};

const ButtonSelect = props => {
  const contextTheme = useContext(ThemeContext);
  const {
    className,
    multiline,
    noBorders,
    noSpacing,
    noWrap,
    options,
    smallPadding,
    allowNoSelection,
    allowMultiSelect,
    style,
    theme = contextTheme,
    value: currentValue = [],
    onChange = noop,
  } = props;

  const handleButtonClick = useCallback(
    optionValue => {
      let result;

      if (includes(optionValue, currentValue)) {
        result = without([optionValue], currentValue);
      } else {
        result = allowMultiSelect
          ? [...currentValue, optionValue]
          : [optionValue];
      }

      if (isPopulated(result) || (isEmpty(result) && allowNoSelection)) {
        onChange(result);
      }
    },
    [currentValue, allowMultiSelect, allowNoSelection, onChange]
  );

  return (
    <div
      className={cx(
        styles.wrapper,
        {
          [styles.wrapperMultiLine]: multiline,
          [styles.adjustTheme]: theme === 'adjust',
        },
        className
      )}
      style={style}
    >
      {map(({label, value: optionValue, disabled = false}) => {
        const selected = includes(optionValue, currentValue);

        return (
          <div
            className={cx(styles.button, {
              [styles.buttonGrayTheme]: theme === 'acquired',
              [styles.buttonWhiteTheme]: theme === 'white',
              [styles.buttonClickable]:
                !disabled &&
                (!selected || (selected && size(currentValue) > 1)),
              [styles.buttonSelected]: selected,
              [styles.buttonNoSpace]: noSpacing,
              [styles.noBorders]: noBorders,
              [styles.buttonNoWrap]: noWrap,
              [styles.buttonSmallPadding]: smallPadding,
              [styles.buttonDisabled]: disabled,
            })}
            key={optionValue}
            onClick={
              !disabled ? () => handleButtonClick(optionValue) : undefined
            }
            title={label}
          >
            <span
              className={cx(styles.label, {
                [styles.labelNoWrap]: noWrap,
              })}
            >
              {label}
            </span>
          </div>
        );
      }, options)}
    </div>
  );
};

export default ButtonSelect;
