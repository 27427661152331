import {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {without, range} from 'lodash/fp';

import {isPopulated} from 'utils/lodash+';

import {
  buildNewSelectedRangesFrom,
  checkIsAllSelected,
  selectRows,
  extractSelectedRows,
} from './utils';

import {CheckboxColumn} from './components/Checkbox';

import {ColumnData, RowData} from 'common-components/ModernGrid/types';

type SelectRowCallback = (
  rowIndex: number,
  e: SyntheticEvent<any, KeyboardEvent>
) => void;

type Params = {
  rows: RowData[];
  disabledRows: number[];
  lockedMode?: boolean;
};

export type UseSelectableRowsReturnType = {
  rows: RowData[];
  selectedRows: number[];
  disabledRows: number[];
  isAllSelected: boolean;
  onSelect: SelectRowCallback;
  onSelectAll: () => void;
  discardSelection: () => void;
  checkboxColumn: ColumnData;
};

type UseSelectableRowsType = (params: Params) => UseSelectableRowsReturnType;

export const useSelectableRows: UseSelectableRowsType = ({
  rows: srcRows,
  disabledRows,
  lockedMode = false,
}) => {
  const [rows, setRows] = useState<RowData[]>([]);
  const selectedRows = useMemo(() => extractSelectedRows(rows), [rows]);
  const wholeTableRange = useMemo(
    () => range(0, srcRows.length),
    [srcRows.length]
  );

  /**
   * updates the extended rows state
   * based on new source rows and previous selection
   * if source rows are changed.
   *
   * extended row is a source row with added `isSelected` field.
   */
  useEffect(() => {
    setRows(prevRows => {
      const prevSelectedRows = extractSelectedRows(prevRows);
      return selectRows(srcRows, prevSelectedRows);
    });
  }, [srcRows]);

  const isAllSelected =
    lockedMode || checkIsAllSelected(rows, selectedRows, disabledRows);

  const discardSelection = useCallback(() => {
    setRows(selectRows(rows, []));
  }, [rows]);

  const onSelect = useCallback<SelectRowCallback>(
    (rowIndex, e) => {
      setRows(prevRows => {
        const prevSelectedRows = extractSelectedRows(prevRows);
        let newSelectedRows;

        if (
          e.nativeEvent.shiftKey &&
          isPopulated(prevRows) &&
          !prevSelectedRows.includes(rowIndex)
        ) {
          newSelectedRows = buildNewSelectedRangesFrom(
            prevSelectedRows,
            rowIndex,
            disabledRows
          );
        } else {
          newSelectedRows = prevSelectedRows.includes(rowIndex)
            ? without([rowIndex], prevSelectedRows)
            : [...prevSelectedRows, rowIndex];
        }

        return selectRows(prevRows, newSelectedRows);
      });
    },
    [setRows, disabledRows]
  );

  const onSelectAll = useCallback(() => {
    setRows(prevRows => {
      const newSelectedRows = isAllSelected
        ? []
        : without(disabledRows, wholeTableRange);

      return selectRows(prevRows, newSelectedRows);
    });
  }, [isAllSelected, disabledRows, wholeTableRange]);

  return {
    rows: lockedMode ? selectRows(rows, wholeTableRange) : rows,
    disabledRows: lockedMode ? wholeTableRange : disabledRows,
    selectedRows: lockedMode ? wholeTableRange : selectedRows,
    isAllSelected,
    onSelect,
    onSelectAll,
    discardSelection,
    checkboxColumn: CheckboxColumn,
  };
};
