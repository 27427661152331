import {composeEnvAwareURL} from 'config';

import {TableKey} from 'modules/common/types';

// reused color sets in styleVariables
export const colorDarkBlue = '#4b577e';
const colorPrimaryLightBlue = '#4285f4';
export const colorPrimaryDarkBlueDisabled = '#CED2E0';

export const styleVariables = {
  primary1Color: colorPrimaryLightBlue,
  colorBlack54: 'rgba(0, 0, 0, 0.54)',
  colorLipstickTwo: '#dc2537',
  colorMidGreen: '#4caf50',
  colorWhite: '#fff',
  colorDodgerBlue: colorPrimaryLightBlue,
  colorWarmGreyOne: '#96a1bd',
  colorWarmGreyTwo: '#a1a1a1',
  colorWarmGreyThree: '#8a95b3',
  colorLightGrey: '#b5bdd1',
  colorAdjustGreenish: '#00A4BE',
  colorAdjustBlue: '#00bed5',
  colorMenuBackground: colorDarkBlue,
  colorFieldError: '#f7bc2e',
  colorFiltersTitles: colorDarkBlue,
  baseFontSize: 13,
  titleFontSize: 20,
  fontWeightMedium: 500,
  formLabelFontSize: 12,
  disabledColor: 'rgba(0, 0, 0, 0.26)',
};

export const EXTERNAL_LOGIN = '/accounts/login/';
export const EXTERNAL_LOGOUT = '/accounts/logout/';
export const EXTERNAL_HIJACK = '/hijack';
export const EXTERNAL_RELEASE_HIJACK = '/hijack/release-hijack/';
export const EXTERNAL_PROFILE = '/profile/';
export const EXTERNAL_SETTINGS = '/settings/';
export const EXTERNAL_ADMIN_DASHBOARD = '/admin/';
export const EXTERNAL_EDIT_APP = '/app/edit';
export const EXTERNAL_RULES = '/rules/';
export const EXTERNAL_PIVOT_SETTINGS = '/settings/pivot';
export const EXTERNAL_PAYWALL = '/expired/';
export const EXTERNAL_APPLE_KEYWORDS = '/asa/keywords/';
export const EXTERNAL_RECONCILE = '/settings/adjust/new/';
export const EXTERNAL_MODERATE_UPDATES = '/moderate/updates/';
export const EXTERNAL_CREATE_CREATIVE_REPORT =
  '/creatives_optimization/create/';
export const EXTERNAL_CREATIVE_REPORT_LIST = '/creatives_optimization/';
export const EXTERNAL_SUBPUBLISHERS = '/subpublishers/';
export const EXTERNAL_ORDERS = '/orders/';
export const EXTERNAL_CREATE_CAMPAIGN_ORDER = '/orders/create/campaign/';
export const EXTERNAL_MORE = '/more/';
export const EXTERNAL_CAMPAIGN_WORKFLOW = '/campaign-workflow/';
export const EXTERNAL_CONTACT_SALES = '/contact-sales/';
export const EXTERNAL_PROFILE_TEAM = '/profile/team/';
export const EXTERNAL_LEGAL_TERMS = '/legal/terms/';
export const EXTERNAL_MANAGE_AD_ACCOUNTS = '/settings/ad_accounts';

export const CONTROL_CENTER = 'control-center';
export const CONTROL_CENTER_BUILDER = 'builder';
export const CONTROL_CENTER_HISTORY = 'history';
export const CONTROL_CENTER_REPORT_LIST = 'reports';
export const CONTROL_CENTER_ATTRIBUTES_DEMO = 'attributes_demo';
export const CONTROL_CENTER_CV2_BUILDER = 'cv2-builder';

export const DASH_EXP = 'new';

export const PATHNAME_ATTRIBUTES_DEMO = `/${CONTROL_CENTER}/${CONTROL_CENTER_ATTRIBUTES_DEMO}`;
export const PATHNAME_BUILDER = `/${CONTROL_CENTER}/${CONTROL_CENTER_BUILDER}`;
export const PATHNAME_HISTORY = `/${CONTROL_CENTER}/${CONTROL_CENTER_HISTORY}`;
export const PATHNAME_REPORT_LIST = `/${CONTROL_CENTER}/${CONTROL_CENTER_REPORT_LIST}`;
export const PATHNAME_REPORT = `/${CONTROL_CENTER}`;
export const PATHNAME_CV2_BUILDER = `/${CONTROL_CENTER}/${CONTROL_CENTER_CV2_BUILDER}`;

// authentication
export const AUTHENTICATION_TOKEN_STORAGE_KEY = 'authentication';

// control tower
export const EXTERNAL_CT_ADMIN_LINK_TEMPLATE = (adjustAccountId: number) =>
  `//control.adjust.com/accounts/${adjustAccountId}/packages#control_center`;

// control center
export const EXTERNAL_CC_ADMIN_LINK_TEMPLATE = (adjustAccountId: number) =>
  `/${CONTROL_CENTER}/admin/configs/config/${adjustAccountId}/change/`;

export const EXTERNAL_CC_CREDENTIALS_LINK = composeEnvAwareURL(
  `${CONTROL_CENTER}/admin/credentials/add/`
);

export const NEW_EXTERNAL_REPORT_LINK = composeEnvAwareURL(
  `${DASH_EXP}/report`
);

export const EXTERNAL_CSV_REPORT = `${CONTROL_CENTER}/reports-service/csv_report?`;

export const SWITCHER = '/configs-service/go';

export const DEMO_URL = 'dash-demo';

export const OPERATION_FAILURE_STATUSES = [
  'network_failure',
  'permission_failure',
  'internal_failure',
];

export const OPERATION_NON_FAILURE_STATUSES = [
  'pending',
  'discarded',
  'approved',
  'queued',
  'success',
];

export const ALL_OPERATION_STATUSES = [
  ...OPERATION_NON_FAILURE_STATUSES,
  ...OPERATION_FAILURE_STATUSES,
];

export const TABLE_KEYS: TableKey[] = ['report', 'history'];

export const MAX_REPORT_NAME_LENGTH = 100;

export const SENTRY_CATEGORY_TAGS = {
  CONSOLE_ERROR: 'console.error',
  REACT_COMPONENT_DID_CATCH: 'react:componentDidCatch',
  REDUX_SAGA_UNHANDLED: 'redux-saga:unhandled',
  REDUX_SAGA_SAFE: 'redux-saga:safe',
  NETWORK: 'network',
  JSON_PARSE_CAUGHT: 'JSON.parse (caught)',
};

// Validation Message keys
export const NO_DIMENSIONS_OR_METRICS_ERROR_MESSAGE =
  'aa.validationMessage.dimenionsAndMetrics';
export const NO_DIMENSIONS_ERROR_MESSAGE = 'aa.validationMessage.dimensions';
export const NO_METRICS_ERROR_MESSAGE = 'aa.validationMessage.metrics';

// email regex used in type="email" inputs
// source: https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html#input.email.attrs.value.single
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
