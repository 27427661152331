import {IntlShape} from 'common-components/utils/libs/ReactIntl';
import {emailRegex} from 'constants/globalVariables';

export const extractEmails = (email: string) =>
  email.match(/<(.*?)>/)?.[1] ?? email;
export const validateEmail = (email: string) => emailRegex.test(email);

export const composeToEmailsErrorMessage = (
  isFormValid: boolean,
  toEmailsValid: boolean,
  intl: IntlShape
) => {
  if (!isFormValid) {
    return intl.formatMessage({
      id: 'aa.automationPanel.emailReports.required',
      defaultMessage: 'Email address required!',
    });
  }

  if (!toEmailsValid) {
    return intl.formatMessage({
      id: 'aa.alerts.containsInvalidEmail',
      defaultMessage: 'One or more emails are invalid',
    });
  }

  return '';
};
