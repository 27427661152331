import {Option} from 'modules/common/types';
import {Dictionary} from 'types/utils';

export type Picker = {
  id: string;
  isRequired: boolean;
  label: string;
  options: Option[];
};

export type PickersValues = Dictionary<null | Option>;

export type CombiSelectState = {
  selectedValues: Option[];
  pickersValues: PickersValues;
};

export enum CombiSelectStateActionTypes {
  UPDATE_PICKER_VALUE = 'UPDATE_PICKER_VALUE',
  CLEAN_PICKER_VALUES = 'CLEAN_PICKER_VALUES',
  UPDATE_SELECTED_VALUES = 'UPDATE_SELECTED_VALUES',
}

export type UpdatePickerValueAction = {
  type: typeof CombiSelectStateActionTypes.UPDATE_PICKER_VALUE;
  payload: {
    pickerId: string;
    value: Option;
  };
};
export type CleanPickerValueAction = {
  type: typeof CombiSelectStateActionTypes.CLEAN_PICKER_VALUES;
};
export type UpdateSelectedValuesAction = {
  type: typeof CombiSelectStateActionTypes.UPDATE_SELECTED_VALUES;
  payload: Option[];
};

export type CombiSelectStateActions =
  | CleanPickerValueAction
  | UpdatePickerValueAction
  | UpdateSelectedValuesAction;
