import {forEach, isNil, values as getValues} from 'lodash/fp';

import {DEFAULT_MIN_CV, DEFAULT_MAX_CV, Labels} from '../constants';
import {
  composeNegativeValueMsg,
  composeLessThanMsg,
  composeGreaterThanMsg,
  composeOutOfRangeMsg,
} from '../utils';
import {transformInputs} from './transformInputs';

import {FormState, InputFieldConfig} from '../types';

export const validateForm =
  (fields: InputFieldConfig[]) => (values: FormState) => {
    const errors: Partial<FormState> = {};
    const convertedValues = transformInputs(values);
    const {revenue_min, revenue_max, cv_min, cv_max, cap_range} =
      convertedValues;

    if (revenue_min > revenue_max) {
      // TODO: come up with something better when field list will be clarified
      errors.revenue_min = composeGreaterThanMsg(fields[0].label);
    }

    if (cv_min > cv_max) {
      errors.cv_min = composeGreaterThanMsg(Labels.MinCV);
    }

    if (cv_min < DEFAULT_MIN_CV || cv_min > DEFAULT_MAX_CV) {
      errors.cv_min = composeOutOfRangeMsg(Labels.MinCV);
    }

    if (cv_max < DEFAULT_MIN_CV || cv_max > DEFAULT_MAX_CV) {
      errors.cv_max = composeOutOfRangeMsg(Labels.MaxCV);
    }

    if (!isNil(cap_range) && cap_range < revenue_max) {
      errors.cap_range = composeLessThanMsg(Labels.CapRange);
    }

    // check every field for negative value
    // eslint-disable-next-line lodash-fp/no-for-each
    forEach(({id, label, type}: InputFieldConfig) => {
      const value = convertedValues[id];
      if (value && type !== 'text' && value < 0) {
        errors[id] = composeNegativeValueMsg(label);
      }
    })(getValues(fields));

    return errors;
  };
