import React from 'react';

import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import cx from 'classnames';
import {useSelector} from 'react-redux';

import {selectHasChartsEnabled} from 'modules/common/reducers/customerConfig';
import {selectIsChartsOpen} from 'modules/report/reducers/chartsOpen';

import {ReportChart} from './Chart';

const Wrapper = styled.div`
  display: flex;
  padding: 24px 0;
`;

const Container = styled.div`
  flex: 1;
  min-width: 0;
`;

const cssHidden = css`
  display: none;
`;

export const ChartsContainer = () => {
  const hasChartsFeature = useSelector(selectHasChartsEnabled);
  const isChartsOpen = useSelector(selectIsChartsOpen);

  if (!hasChartsFeature) {
    return null;
  }

  return (
    <Wrapper className={cx({[cssHidden]: !isChartsOpen})}>
      <Container>
        <ReportChart type='dimension' />
      </Container>
      <Container>
        <ReportChart type='total' />
      </Container>
    </Wrapper>
  );
};
