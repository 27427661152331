export const DEFAULT_MIN_AMOUNT = 0;
export const DEFAULT_MAX_AMOUNT = 100;
export const DEFAULT_MIN_CV = 1;
export const DEFAULT_MAX_CV = 63;
export const DEFAULT_CUT_OFF = 24;
export const DEFAULT_CURRENCY = 'USD';

export const HIGH_PRECISION = 6;
export const STANDARD_PRECISION = 3;
export const PLACEHOLDER_PRECISION = 2;

export enum CVSchemes {
  AdRevenue = 'adrevenue',
  Purchases = 'purchases',
  Facebook = 'facebook',
  FacebookAdrevenue = 'facebook_adrevenue',
}
export enum SchemeLabels {
  Purchases = 'Purchases',
  AdRevenue = 'Ad Revenue',
  Facebook = 'IAP buckets with Facebook VO support',
  FacebookAdrevenue = 'Adrevenue buckets with Facebook VO support',
}

export const CVSchemeOptions = [
  {
    label: SchemeLabels.Purchases,
    value: CVSchemes.Purchases,
  },
  {
    label: SchemeLabels.AdRevenue,
    value: CVSchemes.AdRevenue,
  },
  {
    label: SchemeLabels.Facebook,
    value: CVSchemes.Facebook,
  },
  {
    label: SchemeLabels.FacebookAdrevenue,
    value: CVSchemes.FacebookAdrevenue,
  },
];

export const WARNING_COLOR = '#f05655';
export const PREVIEW_TEST_ID = 'preview';
export const ALLOWED_CV_RANGE = `${DEFAULT_MIN_CV}-${DEFAULT_MAX_CV}`;

export enum Slugs {
  MinRevenue = 'revenue_min',
  MaxRevenue = 'revenue_max',
  MinCV = 'cv_min',
  MaxCV = 'cv_max',
  CapRange = 'cap_range',
  AppStoreId = 'app_store_id',
  CutOffPeriod = 'cut_off_period',
  Currency = 'reporting_currency',
}
export enum Labels {
  MinRevenue = 'Minimum revenue amount',
  MaxRevenue = 'Maximum revenue amount',
  MinPurchase = 'Minimum purchase amount',
  MaxPurchase = 'Maximum purchase amount',
  MinCV = 'Min CV',
  MaxCV = 'Max CV',
  CapRange = 'Cap range',
  AppStoreId = 'App Store ID',
  CutOffPeriod = 'Cut Off Period',
  Currency = 'Currency',

  PreviewErrorMessage = 'Please fix the errors.',
}
