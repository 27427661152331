import React from 'react';

import {noop} from 'lodash/fp';

import {RenderFn, RenderHeaderFn, RenderTotalFn} from './types';

export const DEFAULT_MIN_COLUMN_WIDTH = 120;
export const DEFAULT_MAX_COLUMN_WIDTH = 1000;
export const MAX_MOBILE_SCREEN_WIDTH = 420;

export const COLUMN_RESIZER_SHADOW_DOM_ID = 'COLUMN_RESIZER_SHADOW_DOM_ID';
export const TABLE_WRAPPER_DOM_ID = 'TABLE_WRAPPER_DOM_ID';
export const MODERNGRID_NULLSTATE_WRAPPER_TEST_ID =
  'MODERNGRID_NULLSTATE_WRAPPER_TEST_ID';
export const COLUMN_RESIZER_DEFAULT_MESSAGE =
  'Drag to resize, double-click to reset';

export const MODERNGRID_SORT_ICON_TEST_ID = 'sort-icon';
export const MODERNGRID_ROW_TEST_ID = 'gridRow';
export const MODERNGRID_HEADER_ROW_TEST_ID = 'gridHeaderRow';
export const MODERNGRID_TOTAL_ROW_TEST_ID = 'totalRow';
export const MODERNGRID_HEADER_CELL_ROOT_TEST_ID = 'header-cell-root';
export const MODERNGRID_FIXED_COLUMNS_CONTAINER_TEST_ID =
  'fixedColumnsContainer';
export const MODERNGRID_MOVE_COLUMNS_CONTAINER_TEST_ID = 'moveColumnsContainer';
export const MODERNGRID_LOADING_INDICATOR_TEST_ID = 'loadingIndicator';
export const MODERNGRID_CHECKBOX_CELL_TEST_ID = 'checkbox-cell';
export const MODERNGRID_ENABLED_CHECKBOX_QUERY = `[data-testid="${MODERNGRID_CHECKBOX_CELL_TEST_ID}"][data-disabled="false"] input`;

export const DEFAULT_ALIGN = 'right';
export const DEFAULT_INITIAL_SORT_DIRECTION = 'asc';
export const DEFAULT_IS_FIXED = false;
export const DEFAULT_IS_ARRANGEABLE = true;
export const DEFAULT_IS_SORTABLE = true;
export const DEFAULT_IS_RESIZABLE = false;

export const defaultRenderTotal: RenderTotalFn = ({value}) => <b>{value}</b>;
export const defaultRenderHeader: RenderHeaderFn = ({
  value,
  headerClickHandler,
}) => (
  <span onClick={headerClickHandler || noop} role='button' tabIndex={0}>
    {value}
  </span>
);
export const defaultRender: RenderFn = ({value}) =>
  value ? <>{value}</> : <>—</>;
