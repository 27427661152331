import React, {HTMLAttributes} from 'react';

import {styled} from '@linaria/react';

import {getJustification} from 'common-components/ModernGrid/utils';

import {Alignment} from 'common-components/ModernGrid/types';

export const SWrapper = styled.div<{
  align: 'center' | 'flex-start' | 'flex-end';
}>`
  justify-content: ${props => props.align};
`;

type DivAlignProps = HTMLAttributes<HTMLDivElement> & {
  // eslint-disable-next-line react/no-unused-prop-types
  align: Alignment | undefined;
};

export const DivAlign = React.memo(
  React.forwardRef<HTMLDivElement, DivAlignProps>(
    ({align, children, ...props}, ref) => (
      <SWrapper {...props} ref={ref} align={getJustification(align)}>
        {children}
      </SWrapper>
    )
  )
);
