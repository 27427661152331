import React, {useState, useEffect, useMemo, useCallback} from 'react';

import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/CloseRounded';
import cx from 'classnames';
import {find, flow, intersection, isEqual, map, zipObject} from 'lodash/fp';
import {parse as parseSearch} from 'query-string';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {
  FormattedMessage,
  useIntl,
} from 'common-components/utils/libs/ReactIntl';
import {
  showInformationDialog,
  showInputDialog,
  showToast,
} from 'modules/common/actions';
import {selectCanViewAndEditAttr} from 'modules/common/reducers/customerConfig';
import {
  loadCreateRuleRequest,
  loadToggleRuleStateRequest,
  loadDeleteRuleRequest,
  loadUpdateRuleRequest,
  closeAutomationPanel,
  loadUpdateReportRequest,
  loadSaveReportAsNewRequest,
} from 'modules/report/actions/ReportActions';
import {
  selectQuickParams,
  selectIsAutomationPanelOpen,
  selectGeneralParams,
  selectAttrData,
  selectAttributesFormattings,
  selectReportMatchedByParentReportId,
  selectAttrsNameBySlug,
  selectActiveReportActions,
  selectActiveReport,
} from 'modules/report/reducers';
import {arrFromOrder, isTemplateReportId} from 'modules/report/utils';
import {
  getDeleteAutomationDialogParams,
  getUnsavedChangesDialogParams,
  createSaveReportWithAutomationDialogParams,
  createSaveReportDialogParams,
  REPORT_SAVED_TOAST_PARAMS,
} from 'utils/dialog';
import {isPopulated} from 'utils/lodash+';
import {trimParentReportId} from 'utils/parentReportId';
import {useNameValidator} from 'utils/reportActions';
import {
  countTotalEmails,
  extractChangeActions,
  extractEmailAction,
  craftRuleActions,
  composeAlertsRecipientsLabel,
} from 'utils/reportAutomationActions';

import {
  getFixedStatusOptions,
  getStatusOptionsBySlugFromAttrs,
} from '../ActionPanel/utils';
import {AttributeField} from '../AttributeField';
import {
  cssWrapper,
  cssNote,
  cssSection,
  cssSectionHeader,
  cssButtons,
  cssRemoveAutomation,
  cssSwitchContainer,
  cssAlertsSection,
  cssAlertsLabel,
  cssTitleContainer,
  cssTitle,
  cssCloseIcon,
  cssIconPosition,
  cssSwitch,
  cssSidePanel,
} from './AutomationSidePanel.styles';
import {RemoveButton, RulesPanelButton} from './buttons';
import {EmailAlerts} from './EmailAlerts';

import {Switch} from '@adjust/components';
import {SidePanel} from 'modules/common/components/SidePanel';

import {BE_types} from 'types/backendServicesTypes';

export const AutomationSidePanel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const open = useSelector(selectIsAutomationPanelOpen);
  const activeReport = useSelector(selectActiveReport);
  const currentParentReport = useSelector(selectReportMatchedByParentReportId);
  const {attributes: quickParamsAttributes} = useSelector(selectQuickParams);
  const {order} = useSelector(selectGeneralParams);
  const attrData = useSelector(selectAttrData);
  const attributesFormattings = useSelector(selectAttributesFormattings);
  const validateReportNameInput = useNameValidator();
  const attrSlugToReadableName = useSelector(selectAttrsNameBySlug);

  const canViewAndEditAttr = useSelector(selectCanViewAndEditAttr);

  const inputs = useMemo(
    () => flow(arrFromOrder, intersection(quickParamsAttributes))(order),
    [quickParamsAttributes, order]
  ) as BE_types['AttributeSlug'][];

  const initialValues = useMemo(() => {
    const ruleActions = flow(
      extractChangeActions,
      map('value')
    )(activeReport?.actions);
    const findActionValueBySlug = (slug: BE_types['AttributeSlug']) =>
      find({attribute_slug: slug}, ruleActions)?.operation ?? '';
    const actionValues = map(findActionValueBySlug)(inputs);
    return zipObject(inputs, actionValues);
  }, [inputs, activeReport]);
  const [inputValues, setInputValues] = useState(initialValues || {});
  const handleInput = (inputType: string) => (newValue: string) => {
    setInputValues(oldInputValues => ({
      ...oldInputValues,
      [inputType]: newValue,
    }));
  };

  const [showEmailAlerts, setShowEmailAlerts] = useState(false);

  const currentRuleActions = craftRuleActions(inputValues, inputs);

  const hasActionsConfigured = isPopulated(activeReport?.actions);
  const hasActionsEnabled = Boolean(activeReport?.are_actions_enabled);
  const isTemplateReport =
    currentParentReport && isTemplateReportId(currentParentReport.id);
  const isCurrentReportPristine = activeReport?.isCurrentQueryMatched; // report matching by URL means it hasn't been changed

  const reportActions = useSelector(selectActiveReportActions);
  const reportEmailAction = extractEmailAction(reportActions);
  const emailRecipientsCount = countTotalEmails(reportEmailAction?.value);

  const createActionHandler = useCallback(() => {
    if (isCurrentReportPristine && !isTemplateReport) {
      dispatch(loadCreateRuleRequest({actions: currentRuleActions}));
      return;
    }

    const currentUrl = trimParentReportId(search);
    const isParentReportChanged =
      currentParentReport &&
      !isEqual(
        parseSearch(currentParentReport.url || ''),
        parseSearch(currentUrl)
      );
    const areActionsEnabled = !!currentRuleActions.length;

    if (isParentReportChanged && !isTemplateReport) {
      const updateCurrentReportWithAutomation = () => {
        const updatedReport = {
          id: currentParentReport!.id,
          url: currentUrl,
          actions: currentRuleActions,
          are_actions_enabled: areActionsEnabled,
        };
        dispatch(loadUpdateReportRequest(updatedReport));
        dispatch(showToast(REPORT_SAVED_TOAST_PARAMS));
      };

      dispatch(
        showInformationDialog({
          ...createSaveReportWithAutomationDialogParams(
            updateCurrentReportWithAutomation
          ),
        })
      );
    } else {
      const saveNewReportWithAutomation = (newReportName: string) => {
        const newReportPayload = {
          url: currentUrl,
          title: newReportName,
          actions: currentRuleActions,
          are_actions_enabled: areActionsEnabled,
        };

        dispatch(loadSaveReportAsNewRequest(newReportPayload));
        dispatch(showToast(REPORT_SAVED_TOAST_PARAMS));
      };

      dispatch(
        showInputDialog(
          createSaveReportDialogParams({
            onSubmit: saveNewReportWithAutomation,
            validate: validateReportNameInput,
          })
        )
      );
    }
  }, [
    currentParentReport,
    currentRuleActions,
    dispatch,
    isCurrentReportPristine,
    isTemplateReport,
    search,
    validateReportNameInput,
  ]);

  useEffect(() => {
    if (open && !isEqual(inputValues, initialValues)) {
      setInputValues(initialValues);
    }
    // don't need to fire effect on inputValues change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initialValues]);

  const someInputChanged = !isEqual(initialValues, inputValues);

  const statusOptionsByAttr = useMemo(
    () => getStatusOptionsBySlugFromAttrs(attrData),
    [attrData]
  );
  const fixedStatusOptions = useMemo(() => getFixedStatusOptions(intl), [intl]);

  const updateActionHandler = () => {
    dispatch(loadUpdateRuleRequest({actions: currentRuleActions}));
  };
  const onSwitchToggleHandler = () => {
    dispatch(loadToggleRuleStateRequest());
  };
  const deleteCallback = () => {
    dispatch(loadDeleteRuleRequest());
    dispatch(closeAutomationPanel());
  };
  const deleteRuleHandler = () => {
    dispatch(
      showInformationDialog(getDeleteAutomationDialogParams(deleteCallback))
    );
  };

  const discardHandler = () => {
    setInputValues(initialValues);
  };

  const handleClosePanel = () => {
    dispatch(closeAutomationPanel());
  };
  const onUnsavedChanges = () => {
    dispatch(
      showInformationDialog(getUnsavedChangesDialogParams(handleClosePanel))
    );
  };
  const handleOnClose = () => {
    if (someInputChanged) {
      onUnsavedChanges();
    } else {
      handleClosePanel();
      discardHandler();
    }

    // TODO: add timer for proper slide
    setShowEmailAlerts(false);
  };
  const handleEmailAlertsClose = () => {
    setShowEmailAlerts(false);
  };

  const title = intl.formatMessage({
    id: 'aa.manageAutomation',
    defaultMessage: 'Manage automation',
  });
  const closeButtonTitle = intl.formatMessage({
    id: 'aa.rulesActionPanel.closeBtn',
    defaultMessage: 'Close automation',
  });
  const alertsRecipientsLabel = composeAlertsRecipientsLabel(
    intl,
    emailRecipientsCount
  );
  const alertsStatusLabel =
    emailRecipientsCount > 0 ? (
      <FormattedMessage id='aa.label.enabled' defaultMessage='ON' />
    ) : (
      <FormattedMessage id='aa.label.disabled' defaultMessage='OFF' />
    );

  // TODO: This will change to a options selection so no need to translate
  // this right now.
  const runDaily =
    'Rules and alerts are triggered daily at:\n05:00 - UTC(+00:00)';

  return (
    <SidePanel open={open} className={cssSidePanel}>
      {showEmailAlerts ? (
        <EmailAlerts onClose={handleEmailAlertsClose} />
      ) : (
        <>
          <div className={cssTitleContainer}>
            <div className={cssTitle}>{title}</div>
            <IconButton
              color='primary'
              title={closeButtonTitle}
              onClick={handleOnClose}
              className={cssIconPosition}
            >
              <CloseIcon className={cssCloseIcon} />
            </IconButton>
          </div>

          <div className={cssWrapper}>
            <div className={cssSwitchContainer}>
              <FormattedMessage
                id='aa.reportList.header.automation'
                defaultMessage='Automation'
              />
              <div className={cssSwitch}>
                <Switch
                  disabled={!hasActionsConfigured || !canViewAndEditAttr}
                  checked={hasActionsEnabled}
                  onChange={onSwitchToggleHandler}
                  size='medium'
                  aria-label='Rule toggling switch'
                  data-pendoid='automation-status-switch'
                />
              </div>
            </div>
            <div className={cssNote}>{runDaily}</div>

            <div className={cssSection}>
              <div className={cssSectionHeader}>
                <FormattedMessage
                  id='aa.automationPanel.notifications'
                  defaultMessage='Notifications'
                />
              </div>

              <div
                role='button'
                tabIndex={0}
                onClick={() => setShowEmailAlerts(true)}
                className={cx(cssSection, cssAlertsSection)}
              >
                {alertsRecipientsLabel}
                <div
                  className={cssAlertsLabel}
                  data-pendoid='automation-notifications-status-label'
                >
                  {alertsStatusLabel}
                  <ChevronRight />
                </div>
              </div>
            </div>

            <div className={cssSectionHeader}>
              <FormattedMessage
                id='aa.automationPanel.attributeRules'
                defaultMessage='Attribute Rules'
              />
            </div>
            <div>
              {inputs.map(attrSlug => {
                const statusOptions =
                  statusOptionsByAttr[attrSlug] ?? fixedStatusOptions[attrSlug];
                return (
                  <AttributeField
                    isSidePanel
                    isClearable
                    key={attrSlug}
                    id={attrSlug}
                    name={attrSlugToReadableName[attrSlug]}
                    formatting={attributesFormattings[attrSlug]}
                    readonly={!canViewAndEditAttr}
                    value={inputValues[attrSlug]}
                    setValue={handleInput(attrSlug)}
                    statusOptions={statusOptions}
                    pendoId={`automation-attribute-rule-${attrSlug}`}
                  />
                );
              })}
            </div>
          </div>
          <div className={cssButtons}>
            {hasActionsConfigured ? (
              <>
                <div className={cssRemoveAutomation}>
                  <RemoveButton
                    onClick={deleteRuleHandler}
                    fullWidth
                    pendoId='delete-automation-rules'
                    disabled={!canViewAndEditAttr}
                  >
                    <FormattedMessage
                      id='aa.automationPanel.button.deleteAutomation'
                      defaultMessage='DELETE AUTOMATION'
                    />
                  </RemoveButton>
                </div>
                <RulesPanelButton
                  onClick={updateActionHandler}
                  disabled={!someInputChanged || !canViewAndEditAttr}
                >
                  <FormattedMessage
                    id='aa.automationPanel.button.saveChanges'
                    defaultMessage='SAVE CHANGES'
                  />
                </RulesPanelButton>
              </>
            ) : (
              <RulesPanelButton
                onClick={createActionHandler}
                disabled={!someInputChanged || !canViewAndEditAttr}
              >
                <FormattedMessage
                  id='aa.automationPanel.button.createAutomation'
                  defaultMessage='CREATE AUTOMATION'
                />
              </RulesPanelButton>
            )}
          </div>
        </>
      )}
    </SidePanel>
  );
};
