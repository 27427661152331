import {useState, useLayoutEffect, MutableRefObject} from 'react';

export const useIsRenderedOutsideScreen = <T extends HTMLElement>(
  ref: MutableRefObject<T | undefined>
) => {
  const [isOutside, setOutside] = useState(false);
  useLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setOutside(rect.right >= window.innerWidth);
    }
  }, [ref]);

  return isOutside;
};
