import {get, getOr, flow, keyBy, mapValues, isObject} from 'lodash/fp';
import {createSelector} from 'reselect';

import {selectIsProductionMode} from 'modules/common/reducers/customerConfig';

import type {
  AttributeFormatting,
  FilterOption,
  FiltersOptions,
  RootState,
} from 'modules/common/types';
import {BE_types} from 'types/backendServicesTypes';
import type {Dictionary} from 'types/utils';

export const allWarningsSelectorFactory = (
  warningsSelector: (
    state: RootState
  ) => BE_types['ReportResponseModel']['data_warnings'] | string[]
) =>
  createSelector(
    warningsSelector,
    selectIsProductionMode,
    (reportWarnings = [], isProductionMode) => {
      const formattedWarnings = reportWarnings.map(warning =>
        isObject(warning) ? warning : {title: '', body: warning}
      );

      return isProductionMode
        ? formattedWarnings
        : [{title: '', body: 'aa.sandboxWarning'}, ...formattedWarnings];
    }
  );

const attributeFormattingFallback = 'money';
export const extractAttributesFormattingsFromFiltersOptions: (
  options: FiltersOptions
) => Dictionary<AttributeFormatting> = flow(
  get('attributes'),
  keyBy('id'),
  mapValues<FilterOption, string>(
    getOr(attributeFormattingFallback, 'formatting')
  )
);
