import {flow, compact, join} from 'lodash/fp';

import type {Dictionary} from 'types/utils';

const buildDrilldown = flow(compact, join('|'));
const buildDrilldownPart = join(':');

type getNewDrilldownArguments = {
  columnKey: string;
  drilldownKey: string;
  attr_dependency: Dictionary<string>; // we only support flat attr_deps for drilling
  currentDrilldown: string;
};

const partnerDimension = {
  slug: 'partner',
  navigation: 'partner_id',
};

export const getNewDrilldown = ({
  columnKey,
  drilldownKey,
  attr_dependency,
  currentDrilldown,
}: getNewDrilldownArguments) => {
  const {slug: partnerColumnKey, navigation: partnerDrilldownKey} =
    partnerDimension;

  const columnDrilldownValue = attr_dependency[drilldownKey];
  const partnerDrilldownValue = attr_dependency[partnerDrilldownKey];

  const columnPart = buildDrilldownPart([columnKey, columnDrilldownValue]);

  const isNewDrilldownPartPartner = drilldownKey === partnerDrilldownKey;
  const isPartnerAlreadyInDrilldown = currentDrilldown.includes(
    `${partnerColumnKey}:`
  );

  if (
    isNewDrilldownPartPartner ||
    isPartnerAlreadyInDrilldown ||
    !partnerDrilldownValue
  ) {
    return buildDrilldown([currentDrilldown, columnPart]);
  }

  const partnerPart = buildDrilldownPart([
    partnerColumnKey,
    partnerDrilldownValue,
  ]);

  return buildDrilldown([partnerPart, currentDrilldown, columnPart]);
};
