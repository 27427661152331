import React from 'react';

import {css} from '@linaria/core';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import cx from 'classnames';

import {styleVariables} from 'constants/globalVariables';

export const CHANGE_ARROW_TESTID_SLUG = 'change-arrow-';

const cssPoPChangeCell = css`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
`;
const cssPositiveChange = css`
  color: ${styleVariables.colorMidGreen};
`;
const cssNegativeChange = css`
  color: ${styleVariables.colorLipstickTwo};
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const EmptyArrow = (props: any) => <div {...props} />;

type ValueStatus = 'positive' | 'negative' | 'none';
const valueStatusToArrow: Record<ValueStatus, React.FC> = {
  positive: ArrowUp,
  negative: ArrowDown,
  none: EmptyArrow,
};

type Props = {
  formattedValue: string;
  numValue: number;
};
export const PoPChangeCell = ({formattedValue, numValue}: Props) => {
  const isPositive = numValue > 0;
  const isNegative = numValue < 0;

  let valueStatus: ValueStatus;
  if (isPositive) {
    valueStatus = 'positive';
  } else if (isNegative) {
    valueStatus = 'negative';
  } else {
    valueStatus = 'none';
  }

  const ArrowIcon = valueStatusToArrow[valueStatus];

  return (
    <span
      className={cx(cssPoPChangeCell, {
        [cssPositiveChange]: isPositive,
        [cssNegativeChange]: isNegative,
      })}
    >
      {formattedValue}
      <ArrowIcon data-testid={`${CHANGE_ARROW_TESTID_SLUG}${valueStatus}`} />
    </span>
  );
};
