import React, {useCallback} from 'react';

import {css} from '@linaria/core';
import {Tooltip} from '@material-ui/core';
import cx from 'classnames';
import {
  filter,
  isFunction,
  join,
  map,
  flow,
  get,
  split,
  truncate,
  values,
  zipObject,
} from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import {
  PATHNAME_REPORT,
  PATHNAME_ATTRIBUTES_DEMO,
  PATHNAME_BUILDER,
  PATHNAME_HISTORY,
} from 'constants/globalVariables';
import {selectDrilldownLabels} from 'modules/common/reducers';
import {applyReportParams} from 'modules/report/actions/ReportActions';
import {selectDrilldownParam, selectFocusParam} from 'modules/report/reducers';

const cssWrapper = css`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #96a1bd;
  top: 90%;
  font-size: 12px;
  z-index: 1;
  width: max-content;
  & :last-child {
    color: #4b577e;
    &:not(:only-child) {
      margin-left: 6px;
    }
  }
`;
const cssBullet = css`
  display: inline-block;
  background-color: #96a1bd;
  margin: 0 6px 0;
  border-radius: 4px;
  height: 8px;
  width: 8px;
`;
const cssText = css`
  &:not(:last-child) {
    margin-right: 6px;
  }
`;
const cssClickable = css`
  cursor: pointer;
  &:hover {
    color: #4b577e;
  }
  &:hover.bullet {
    background-color: #4b577e;
  }
`;

type DrillElementType = {
  drill: ParsedDrill;
  isBullet: boolean;
  clickHandler?: () => void;
};

const DrillElement = ({drill, isBullet, clickHandler}: DrillElementType) => {
  const drilldownLabels = useSelector(selectDrilldownLabels);
  const drillLabel = drilldownLabels?.[drill.level] || drill.value;
  return (
    <Tooltip title={drill.level ? `${drill.level}: ${drillLabel}` : ''} arrow>
      <span
        onClick={clickHandler}
        role='button'
        tabIndex={0}
        className={cx({
          [cssBullet]: isBullet,
          [cssText]: !isBullet,
          [cssClickable]: isFunction(clickHandler),
        })}
      >
        {isBullet ? '' : truncate({length: 30}, drillLabel)}
      </span>
    </Tooltip>
  );
};

const separateLevels = split('|');
const parseValues = map(flow(split(':'), zipObject(['level', 'value'])));
const dropEmpty = filter(flow(get('value'), Boolean));
export const convertDrilldown = flow(separateLevels, parseValues, dropEmpty);

const joinLevels = join('|');
const stringifyValues = map(flow(values, join(':')));
export const stringifyDrilldown = flow(stringifyValues, joinLevels);

type ParsedDrill = {
  level: string;
  value: string;
};

export const Drilldown = () => {
  const drilldown = useSelector(selectDrilldownParam);
  const focus = useSelector(selectFocusParam);
  const parsedDrilldown = convertDrilldown(focus || drilldown) as ParsedDrill[];
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (clickedLvl: number) => {
      const trimmedDrilldown = parsedDrilldown.slice(0, clickedLvl);
      const newDrilldownString = stringifyDrilldown(trimmedDrilldown);
      dispatch(
        applyReportParams({
          params: {
            general: {
              [focus ? 'focus' : 'drilldown']: newDrilldownString,
            },
          },
        })
      );
    },
    [parsedDrilldown, focus, dispatch]
  );

  return (
    <div className={cssWrapper}>
      <Switch>
        <Route path={PATHNAME_HISTORY}>
          <span>History</span>
        </Route>
        <Route
          path={[PATHNAME_REPORT, PATHNAME_BUILDER, PATHNAME_ATTRIBUTES_DEMO]}
        >
          {parsedDrilldown.map((drill, idx) => (
            <DrillElement
              key={drill.level}
              isBullet={idx !== parsedDrilldown.length - 1}
              drill={drill}
              clickHandler={
                idx !== parsedDrilldown.length
                  ? () => handleClick(idx)
                  : undefined
              }
            />
          ))}
        </Route>
      </Switch>
    </div>
  );
};
