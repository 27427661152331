import React, {Component, Suspense} from 'react';

import {css} from '@linaria/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import {ThemeContext, LoadingIndicator} from 'common-components';
import {SENTRY_CATEGORY_TAGS} from 'constants/globalVariables';
import {reportError} from 'utils/errorReportingUtils';

import {InformationDialog} from '../InformationDialog';
import {InputDialog} from '../InputDialog';
import {Toast} from '../Toast/Toast';
import {adjustTheme} from './materialUITheme';
import './App.global.css';

const cssWrapper = css`
  height: 100%;
`;
export class App extends Component {
  static getDerivedStateFromError() {
    return {
      error: true,
    };
  }

  componentDidCatch(error: Error | null, errorInfo: unknown) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setTag(
          'category',
          SENTRY_CATEGORY_TAGS.REACT_COMPONENT_DID_CATCH
        );
        scope.setExtra('errorInfo', errorInfo);
        reportError(error);
      });
    }
  }

  render() {
    const {children} = this.props;
    return (
      <MuiThemeProvider theme={adjustTheme}>
        <ThemeContext.Provider value='adjust'>
          <style>
            {`
                :root {
                  --global-font-family: ${adjustTheme.typography.fontFamily};
                  --global-text-color: ${adjustTheme.palette.text.primary};
                  --global-background-color: #f3f4f6;
                }
              `}
          </style>

          <Suspense fallback={<LoadingIndicator verticallyCentered />}>
            <div className={cssWrapper}>
              {children}
              <InformationDialog />
              <InputDialog />
              <Toast />
            </div>
          </Suspense>
        </ThemeContext.Provider>
      </MuiThemeProvider>
    );
  }
}
